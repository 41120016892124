import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Form } from 'react-bootstrap';

import { changeCategory } from '../../../../../actions';

import Preloader from '../../../../UI/Preloader/Preloader';

const CategoriesCategoryForm = ({
  data, isUnsaved, isUpdating, onUpdateCategory,
}) => {
  const dispatch = useDispatch();

  const handleChangeCategory = (params) => {
    dispatch(changeCategory(data._id, params));
  };

  const handleUpdateCategory = () => {
    onUpdateCategory(data);
  };

  return (
    <div className="template-bunch-item__form">
      <div className="template-bunch-item__form-row template-bunch-item__form-title">
        <div className="template-bunch-item__form-label">Название:</div>
        <Form.Control
          disabled={isUpdating}
          value={data.title}
          onChange={(e) => handleChangeCategory({ title: e.target.value })}
        />
      </div>
      <div className="template-bunch-item__form-btn">
        <Button disabled={isUpdating || !isUnsaved} variant="success" onClick={handleUpdateCategory}>
          Сохранить
        </Button>
        {isUpdating && <Preloader medium />}
      </div>
    </div>
  );
};

CategoriesCategoryForm.propTypes = {
  data: PropTypes.object.isRequired,
  isUnsaved: PropTypes.bool,
  isUpdating: PropTypes.bool,
  onUpdateCategory: PropTypes.func,
};

export default memo(CategoriesCategoryForm);
