import React from 'react';

import { NavLink, useLocation } from 'react-router-dom';

import './LandingSubnav.scss';

const LandingSubnav = () => {
  const location = useLocation();

  const isPagesRoute = typeof location?.pathname === 'string'
    && (location?.pathname.includes('/landing/pages') || location?.pathname.includes('landing/categories'));

  return (
    <div className="subnav">
      <NavLink to="/landing/pages" className={`${isPagesRoute ? 'is-active' : ''}`}>
        Страницы и категории
      </NavLink>
      <NavLink activeClassName="is-active" to="/landing/menu">
        Меню
      </NavLink>
    </div>
  );
};

export default LandingSubnav;
