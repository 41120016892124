import ReactDOMServer from 'react-dom/server';
import SweetAlert2 from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const Swal = withReactContent(SweetAlert2);

export default class sweetAlert {
  static fire(props) {
    const {
      icon, type, customClasses, ...rest
    } = props;

    return new Promise((resolve) => {
      Swal.fire({
        ...rest,
        ...(icon || icon === 'none' ? { iconHtml: ReactDOMServer.renderToStaticMarkup(icon) } : {}),
        ...(icon ? { customClass: { popup: icon === 'none' ? 'custom-icon custom-icon_hidden' : 'custom-icon', ...customClasses || {} } } : { customClass: customClasses || {} }),
        ...(type ? { icon: type } : {}),
        heightAuto: false,
      })
        .then((result) => resolve(result));
    });
  }

  static close() {
    return Swal.close();
  }
}
