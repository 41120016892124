import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './SocialLinks.scss';

class SocialLinks extends React.PureComponent {
  list = {
    vk: {
      title: 'Vkontakte',
      link: 'https://vk.com/supavideo',
      icon: 'vk',
    },
    facebook: {
      title: 'Facebook',
      link: 'https://www.facebook.com/SupaApp',
      icon: 'facebook-square',
    },
    twitter: {
      title: 'Twitter',
      link: 'https://twitter.com/SupaApp',
      icon: 'twitter',
    },
    instagram: {
      title: 'Instagram',
      link: 'https://www.instagram.com/supamp4/',
      icon: 'instagram',
    },
    youtube: {
      title: 'Youtube',
      link: 'https://www.youtube.com/channel/UCw8An3D5XuXNmNvaGJ01P5w',
      icon: 'youtube',
    },
  };

  render() {
    const items = Object.entries(this.list).map(([key, value]) => ({
      key,
      title: value.title,
      link: value.link,
      icon: value.icon,
    }));

    return items.length ? (
      <div className="social-links">
        <div className="social-links__title">Социальные сети:</div>
        <div className="social-links__list">
          {items.map((item) => (
            <a
              key={item.key}
              className={`social-links__item social-links__item_${item.key}`}
              href={item.link}
              rel="noopener noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon icon={['fab', item.icon]} />
            </a>
          ))}
        </div>
      </div>
    ) : null;
  }
}

export default SocialLinks;
