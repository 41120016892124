import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  APP_LOAD,
} from '../../actions';

import App from './App';

const mapStateToProps = (state) => ({
  auth: state.auth,
  common: state.common,
});

const mapDispatchToProps = (dispatch) => ({
  onLoad: () => dispatch({ type: APP_LOAD }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
