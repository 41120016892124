import { PREFIX_ACTION_TYPE_LANDING_PAGES } from '../../constants/common';

export const GET_LANDING_PAGES = 'GET_LANDING_PAGES';
export const GET_TILDA_PAGES = 'GET_TILDA_PAGES';
export const CREATE_PAGE = 'CREATE_PAGE';
export const DELETE_PAGE = 'DELETE_PAGE';
export const RESET_LANDING = 'RESET_LANDING';
export const UPDATE_PAGE = 'UPDATE_PAGE';
export const GET_PAGE = 'GET_PAGE';
export const SYNC_PAGE = 'SYNC_PAGE';

export function getTildaPages(requestToken) {
  return {
    type: PREFIX_ACTION_TYPE_LANDING_PAGES + GET_TILDA_PAGES,
    request: {
      method: 'get',
      url: '/admin/landing/pages_tilda',
      requestToken,
    },
  };
}

export function getLandingPages(params, requestToken) {
  return {
    type: PREFIX_ACTION_TYPE_LANDING_PAGES + GET_LANDING_PAGES,
    request: {
      method: 'get',
      url: '/admin/landing/pages',
      params,
      requestToken,
    },
  };
}

export function getPage(id, requestToken) {
  return {
    type: PREFIX_ACTION_TYPE_LANDING_PAGES + GET_PAGE,
    request: {
      method: 'get',
      url: `/admin/landing/pages/${id}`,
      requestToken,
    },
    payload: { id },
  };
}

export function syncPage(id, requestToken) {
  return {
    type: PREFIX_ACTION_TYPE_LANDING_PAGES + SYNC_PAGE,
    request: {
      method: 'get',
      url: `/admin/landing/pages/${id}/sync`,
      requestToken,
    },
    payload: { id },
  };
}

export function createPage(data) {
  return {
    type: PREFIX_ACTION_TYPE_LANDING_PAGES + CREATE_PAGE,
    request: {
      method: 'post',
      url: '/admin/landing/pages',
      data,
    },
  };
}

export function deletePage(id) {
  return {
    type: PREFIX_ACTION_TYPE_LANDING_PAGES + DELETE_PAGE,
    request: {
      method: 'delete',
      url: `/admin/landing/pages/${id}`,
    },
    payload: { id },
  };
}

export function updatePage(id, data) {
  return {
    type: PREFIX_ACTION_TYPE_LANDING_PAGES + UPDATE_PAGE,
    request: {
      method: 'patch',
      url: `/admin/landing/pages/${id}`,
      data,
    },
    payload: { id },
  };
}
