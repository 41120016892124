import {
  CREATE_OBJECT_CATEGORY,
  DELETE_OBJECT_CATEGORY,
  GET_OBJECT_CATEGORIES,
  GET_OBJECT_CATEGORY,
  RESET_OBJECT_CATEGORIES,
  UPDATE_OBJECT_CATEGORY,
} from '../../actions';

const defaultState = {
  docs: [],
  error: null,
  isFetching: false,
  isCategoryChanging: {
    create: false,
    remove: null,
    update: null,
  },
  editedObjectCategory: null,
  editedObjectCategoryIsFetching: false,
};

function returnError(response) {
  if (response.message) return { message: 'first_request_failed' };

  return { message: 'admin_privileges_required' };
}

const setIsCategoryChanging = (isCategoryChanging, props) => ({
  ...isCategoryChanging,
  ...props,
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case `${CREATE_OBJECT_CATEGORY}_REQUEST`:
      return {
        ...state,
        docs: [],
        error: null,
        isFetching: true,
        isCategoryChanging: setIsCategoryChanging(state.isCategoryChanging, { create: true }),
      };
    case `${UPDATE_OBJECT_CATEGORY}_REQUEST`:
      return {
        ...state,
        docs: [],
        error: null,
        isFetching: true,
        isCategoryChanging: setIsCategoryChanging(state.isCategoryChanging, { update: action.payload.id }),
      };
    case `${DELETE_OBJECT_CATEGORY}_REQUEST`:
      return {
        ...state,
        error: null,
        isCategoryChanging: setIsCategoryChanging(state.isCategoryChanging, { remove: action.payload.id }),
      };
    case `${GET_OBJECT_CATEGORIES}_REQUEST`:
      return {
        ...state,
        docs: [],
        error: null,
        isFetching: true,
      };
    case `${GET_OBJECT_CATEGORY}_REQUEST`:
      return {
        ...state,
        error: null,
        editedObjectCategory: null,
        editedObjectCategoryIsFetching: true,
      };
    case GET_OBJECT_CATEGORIES:
      return {
        ...state,
        docs: action.response,
        isFetching: false,
      };
    case CREATE_OBJECT_CATEGORY:
      return {
        ...state,
        isFetching: false,
        isCategoryChanging: setIsCategoryChanging(state.isCategoryChanging, { create: false }),
      };
    case UPDATE_OBJECT_CATEGORY:
      return {
        ...state,
        isFetching: false,
        isCategoryChanging: setIsCategoryChanging(state.isCategoryChanging, { update: false }),
      };
    case DELETE_OBJECT_CATEGORY:
      return {
        ...state,
        docs: state.docs.filter((item) => item._id !== action.payload.id),
        error: null,
        isCategoryChanging: setIsCategoryChanging(state.isCategoryChanging, { remove: false }),
      };
    case GET_OBJECT_CATEGORY:
      return {
        ...state,
        editedObjectCategory: action.response,
        editedObjectCategoryIsFetching: false,
      };
    case `${CREATE_OBJECT_CATEGORY}_FAILURE`:
      return {
        ...state,
        isFetching: false,
        isCategoryChanging: setIsCategoryChanging(state.isCategoryChanging, { create: false }),
      };
    case `${UPDATE_OBJECT_CATEGORY}_FAILURE`:
      return {
        ...state,
        isFetching: false,
        isCategoryChanging: setIsCategoryChanging(state.isCategoryChanging, { update: false }),
      };
    case `${DELETE_OBJECT_CATEGORY}_FAILURE`:
      return {
        ...state,
        isCategoryChanging: setIsCategoryChanging(state.isCategoryChanging, { remove: false }),
      };
    case `${GET_OBJECT_CATEGORIES}_FAILURE`:
      return {
        ...state,
        error: returnError(action.response),
        isFetching: false,
      };
    case `${GET_OBJECT_CATEGORY}_FAILURE`:
      return {
        ...state,
        error: returnError(action.response),
        editedObjectCategoryIsFetching: false,
      };
    case RESET_OBJECT_CATEGORIES:
      return defaultState;
    default:
      return state;
  }
};
