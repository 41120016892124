import React, {
  memo, useCallback, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { diff } from 'deep-object-diff';
import { isEqual } from 'lodash';

import {
  getLandingMenus,
  getPageCategory,
  initLandingForm,
  resetLandingForm,
  updateLandingForm,
  updatePageCategory,
} from '../../../../../actions';

import withRequest from '../../../../HOCs/withRequest';

import { toast } from '../../../../../utils';

import CategoryFormContent from '../CategoryFormContent/CategoryFormContent';

const CategoryFormEdit = memo((props) => {
  const { dispatch, createCanceler, lang } = props;

  const params = useParams();

  const history = useHistory();

  const data = useSelector(({ landing }) => landing.form);
  const isFetching = useSelector(({ landing }) => landing.isFetching);

  const [isMenusFetching, setIsMenusFetching] = useState(false);
  const [menus, setMenus] = useState([]);
  const [category, setCategory] = useState([]);

  const isUnmounted = useRef(false);

  const handleUpdateForm = useCallback((updateData) => dispatch(updateLandingForm(updateData)), [dispatch]);

  const fetchMenus = useCallback(() => {
    const request = createCanceler();

    setIsMenusFetching(true);

    dispatch(getLandingMenus({ simple_list: true, lang }, request.token))
      .then((response) => setMenus(response))
      .finally(() => {
        if (!isUnmounted.current) setIsMenusFetching(false);
      });
  }, [createCanceler, dispatch, lang]);

  const fetchCategory = useCallback(() => {
    const getCategoryReq = createCanceler();

    dispatch(getPageCategory(params?.id, getCategoryReq.token))
      .then((response) => {
        const { _id, ...categoryData } = response;

        handleUpdateForm(categoryData);
        setCategory(categoryData);
      })
      .catch(() => toast('error', 'Ошибка получения страниц. Попробуйте позже'));
  }, [createCanceler, dispatch, params?.id, handleUpdateForm]);

  const handleSubmitForm = useCallback((e) => {
    e.preventDefault();

    if (!params?.id) return;

    const {
      side_menu_id, name, alias, show_headers, show_page_navigation,
    } = data;

    // eslint-disable-next-line
    const updateData = diff(category, { side_menu_id, name, alias, show_headers, show_page_navigation });

    dispatch(updatePageCategory(params?.id, updateData))
      .then(() => {
        toast('success', 'Категория успешно обновлена!');

        history.push('/landing/pages');
      })
      .catch(({ message }) => {
        if (message === 'alias_already_exist') return toast('error', 'Категория с таким алиасом уже существует');

        return toast('error', 'Ошибка обновления категории');
      });
  }, [data, dispatch, history, category, params?.id]);

  useEffect(() => {
    dispatch(initLandingForm());

    return () => {
      isUnmounted.current = true;

      dispatch(resetLandingForm());
    };
  }, [dispatch]);

  useEffect(() => {
    fetchMenus();
    fetchCategory();
  }, [fetchMenus, fetchCategory]);

  const {
    side_menu_id, name, alias, show_headers, show_page_navigation,
  } = data;

  const isFormDisabled = isFetching
    || isMenusFetching
    || !data?.name
    || !data?.alias
    // eslint-disable-next-line
    || isEqual(category, { side_menu_id, name, alias, show_headers, show_page_navigation });

  return (
    <CategoryFormContent
      isFormDisabled={isFormDisabled}
      isFetching={isFetching}
      isMenusFetching={isMenusFetching}
      data={data}
      mode="edit"
      menus={menus}
      onSubmit={handleSubmitForm}
      onUpdate={handleUpdateForm}
    />
  );
});

CategoryFormEdit.propTypes = {
  createCanceler: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  lang: PropTypes.string,
};

export default withRequest(CategoryFormEdit);
