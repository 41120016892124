import PropTypes from 'prop-types';
import React from 'react';

import View from '../../../Layout/View/View';

import ServicesForm from '../ServicesForm/ServicesForm';

import { requestCanceler } from '../../../../middlewares/api';

import errorMessages from '../../../../constants/errors';

import { smoothScrollTo, toast } from '../../../../utils';

class ServicesCreate extends React.PureComponent {
  requestCancelers = [];

  componentDidUpdate(prevProps) {
    const { services: { error } } = this.props;

    if (error && error !== prevProps.services.error) {
      let message = errorMessages[error.message] || error.message;

      if (error.message === 'service_already_exists' && error.params) {
        message = errorMessages[error.message]
          .replace('(id)', error.params.id)
          .replace('(freeID)', error.params.freeID || '');
      }

      toast('error', message);
    }
  }

  componentWillUnmount() {
    const { onUnload } = this.props;

    this.requestCancelers.forEach((canceler) => canceler.cancelRequest());

    onUnload();
  }

  handleCreate = (data) => {
    const { history, onCreateService } = this.props;

    const requestCreateServiceCanceler = requestCanceler();

    this.requestCancelers.push(requestCreateServiceCanceler);

    onCreateService(data)
      .then(() => {
        toast('success', 'Тариф создан!');

        smoothScrollTo(document.documentElement, 0);

        history.push('/services?sort=desc&group=individual');
      });
  };

  render() {
    const { services } = this.props;

    return (
      <View
        isFetching={false}
        preloaderCaption="Загружаем..."
        title="Создать тариф"
        viewClass="service-create"
        content={<ServicesForm services={services} onSubmit={this.handleCreate} />}
      />
    );
  }
}

ServicesCreate.propTypes = {
  history: PropTypes.object,
  services: PropTypes.object.isRequired,
  onCreateService: PropTypes.func.isRequired,
  onUnload: PropTypes.func.isRequired,
};

export default ServicesCreate;
