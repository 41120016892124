import React, { memo, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory, Link } from 'react-router-dom';

import sweetAlert from '../../../../HOCs/sweetAlert';

import withRequest from '../../../../HOCs/withRequest';

import { deletePageCategory } from '../../../../../actions';

import { toast } from '../../../../../utils';

import Page from '../Page/Page';

const PageCategory = memo(({ category, dispatch }) => {
  const history = useHistory();

  const [isCollapsed, setIsCollapsed] = useState(true);

  const pages = useSelector(({ landing }) => landing.pages?.docs);
  const isFetching = useSelector(({ landing }) => landing.isFetching);

  const categoryPages = useMemo(() => (pages || []).filter((p) => p.category_id === category._id), [pages, category._id]);

  const handleToggleCollapse = () => setIsCollapsed((prev) => !prev);

  const handleClickAddPage = (e) => {
    e.stopPropagation();

    history.push(`/landing/pages/create?category_id=${category._id}`);
  };

  const handleClickDeleteCategory = (e) => {
    e.stopPropagation();

    if (categoryPages.length > 0) {
      sweetAlert.fire({
        confirmButtonText: 'Ок',
        type: 'error',
        title: 'Ошибка',
        html: 'Нельзя удалить категорию пока к ней прикреплены страницы',
      });

      return;
    }

    sweetAlert.fire({
      cancelButtonText: 'Отмена',
      confirmButtonText: 'Да, удалить',
      icon: <FontAwesomeIcon icon={['fal', 'exclamation-triangle']} />,
      type: 'warning',
      showCancelButton: true,
      title: 'Вы уверены?',
      html: 'Вы действительно хотите удалить данную категорию? Это действие нельзя будет отменить',
    }).then((result) => {
      if (!result.value) return;

      dispatch(deletePageCategory(category._id))
        .then(() => toast('success', 'Категория успешно удалена!'))
        .catch(() => toast('error', 'Произошла ошибка. Попробуйте позже'));
    });
  };

  return (
    <div className="page-category landing__category">
      { /* eslint-disable-next-line */ }
      <div
        className="landing__category-header"
        role="button"
        tabIndex={0}
        onClick={handleToggleCollapse}
      >
        <div className="landing__category-name">
          <span>{category.name}</span>
          <FontAwesomeIcon
            icon={['far', 'chevron-right']}
            style={{ transform: `rotate(${isCollapsed ? '0' : '90'}deg)` }}
          />
        </div>
        <div className="landing__category-actions">
          <button disabled={isFetching} type="button" className="landing__category-action" onClick={handleClickAddPage}>
            <FontAwesomeIcon icon={['fas', 'plus']} />
          </button>
          <Link to={`/landing/categories/${category._id}/edit`} className="landing__category-action" disabled={isFetching}>
            <FontAwesomeIcon icon={['fas', 'pen']} />
          </Link>
          <button disabled={isFetching} type="button" className="landing__category-action" onClick={handleClickDeleteCategory}>
            <FontAwesomeIcon icon={['far', 'times']} />
          </button>
        </div>
      </div>

      {!isCollapsed && (
        <div className="landing__category-body">
          {categoryPages.length === 0 && <div className="landing__info">В этой категории еще нет страниц</div>}
          {categoryPages.map((page) => (
            <Page
              key={page._id}
              data={page}
              isFetching={isFetching}
              category={category}
            />
          ))}
        </div>
      )}
    </div>
  );
});

PageCategory.propTypes = {
  category: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default withRequest(PageCategory);
