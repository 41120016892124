import PropTypes from 'prop-types';
import React from 'react';
import { Switch, Route } from 'react-router-dom';

import errorMessages from '../../../constants/errors';

import { requestCanceler } from '../../../middlewares/api';

import { toast } from '../../../utils';

import View from '../../Layout/View/View';

import PromocodesList from './PromocodesList/PromocodesList';
import PromocodesCreate from './PromocodesCreate/PromocodesCreate';
import PromocodesEdit from './PromocodesEdit/PromocodesEdit';

import './Promocodes.scss';

class Promocodes extends React.PureComponent {
  state = {
    isPromocodeChanging: false,
    viewTitle: 'Промокоды',
  };

  requestCancelers = [];

  componentDidMount() {
    const { promocodes } = this.props;

    if (!promocodes.docs.length) this.getPromocodes();
  }

  componentDidUpdate(prevProps) {
    const { promocodes } = this.props;
    const { error } = promocodes;

    if (error && error.message && error !== prevProps.promocodes.error) {
      const message = ['Network Error', 'permission_denied', 'server_error', 'incorrect_query', 'Promocode already created', 'admin_privileges_required'].includes(error.message)
        ? errorMessages[error.message]
        : error.message;

      toast('error', message);
    }
  }

  componentWillUnmount() {
    const { onUnload } = this.props;

    this.requestCancelers.forEach((canceler) => canceler.cancelRequest());

    onUnload();
  }

  getPromocodes = () => {
    const { onGetPromocodes } = this.props;

    const requestGetPromocodesCanceler = requestCanceler();

    this.requestCancelers.push(requestGetPromocodesCanceler);

    onGetPromocodes(requestGetPromocodesCanceler.token).catch(() => {});
  };

  handleUpdatePromocode = (id, formData) => {
    const { history, onUpdatePromocode } = this.props;

    this.handleIsPromocodeChanging(true);

    const requestUpdatePromocodeCanceler = requestCanceler();

    this.requestCancelers.push(requestUpdatePromocodeCanceler);

    onUpdatePromocode(id, formData, requestUpdatePromocodeCanceler.token)
      .then(() => {
        toast('success', 'Промокод обновлен');
        history.push('/promocodes');
      })
      .finally(() => this.handleIsPromocodeChanging(false));
  };

  handleCreatePromocode = (formData) => {
    const { history, onCreatePromocode } = this.props;

    this.handleIsPromocodeChanging(true);

    const requestCreatePromocodeCanceler = requestCanceler();

    this.requestCancelers.push(requestCreatePromocodeCanceler);

    onCreatePromocode(formData, requestCreatePromocodeCanceler.token)
      .then(() => {
        toast('success', 'Промокод создан');
        history.push('/promocodes');
      })
      .finally(() => this.handleIsPromocodeChanging(false));
  };

  handleIsPromocodeChanging = (isPromocodeChanging) => this.setState({ isPromocodeChanging });

  setViewTitle = (viewTitle) => this.setState({ viewTitle });

  render() {
    const { promocodes } = this.props;
    const { error, isFetching } = promocodes;

    const { isPromocodeChanging, viewTitle } = this.state;

    return (
      <View
        errorMessage={errorMessages[error?.message]}
        isError={error && ['admin_privileges_required', 'first_request_failed'].includes(error.message)}
        isFetching={isFetching}
        preloaderCaption="Загружаем..."
        title={viewTitle}
        viewClass="promocodes"
        content={(
          <Switch>
            <Route
              exact
              path="/promocodes"
              render={() => (
                <PromocodesList
                  promocodes={promocodes}
                  setViewTitle={this.setViewTitle}
                />
              )}
            />
            <Route
              exact
              path="/promocodes/create"
              render={(props) => (
                <PromocodesCreate
                  history={props.history}
                  isEditing={isPromocodeChanging}
                  promocodes={promocodes}
                  setViewTitle={this.setViewTitle}
                  onCreatePromocode={this.handleCreatePromocode}
                />
              )}
            />
            <Route
              exact
              path="/promocodes/edit/:id"
              render={(props) => (
                <PromocodesEdit
                  {...props}
                  isEditing={isPromocodeChanging}
                  promocodes={promocodes}
                  setViewTitle={this.setViewTitle}
                  onUpdatePromocode={this.handleUpdatePromocode}
                />
              )}
            />
          </Switch>
        )}
      />
    );
  }
}

Promocodes.propTypes = {
  history: PropTypes.object,
  promocodes: PropTypes.object.isRequired,
  onCreatePromocode: PropTypes.func.isRequired,
  onGetPromocodes: PropTypes.func.isRequired,
  onUpdatePromocode: PropTypes.func.isRequired,
  onUnload: PropTypes.func.isRequired,
};

export default Promocodes;
