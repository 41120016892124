export const GROUP_CATEGORY_DISPLAYS = {
  in_list: 'Список',
  in_spotlight: 'Spotlight',
};

export const GROUP_CATEGORY_SIZES = {
  all: 'Все размеры',
  square: 'Квадратный',
  landscape: 'Горизонтальный',
  stories: 'Stories',
  portrait: 'Портрет',
  custom: 'Пользовательский',
  none: 'Не показывать',
};

export const GROUP_CATEGORY_SOURCES = {
  category: 'Базовая категория',
  group_ref: 'Ссылка на группу',
  group_category_ref: 'Ссылка на категорию группы',
};

export const GROUP_CATEGORY_TYPES = {
  all: 'Все типы',
  image: 'Изображение',
  video: 'Видео',
};
