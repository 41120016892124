import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { memo, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { smoothScrollTo } from '../../../../utils';

import IdeasForm from '../IdeasForm/IdeasForm';

const IdeasCreate = ({
  ideas, isEditing, location, setViewTitle, onCreateIdea,
}) => {
  useEffect(() => {
    setViewTitle('Создать идею');

    smoothScrollTo(document.documentElement, 0);

    return () => smoothScrollTo(document.documentElement, 0);
  }, [setViewTitle]);

  const lang = queryString.parse(location.search)?.lang || 'ru';

  return (
    !ideas.isFetching ? (
      <div className="ideas__create">
        <Link to={`/ideas${lang !== 'ru' ? `?lang=${lang}` : ''}`} className="ideas__link">Вернуться назад</Link>
        <IdeasForm
          buttonText="Создать идею"
          disabled={isEditing}
          location={location}
          onSubmit={onCreateIdea}
        />
      </div>
    ) : null
  );
};

IdeasCreate.propTypes = {
  location: PropTypes.object,
  ideas: PropTypes.shape({
    isFetching: PropTypes.bool,
  }),
  isEditing: PropTypes.bool,
  setViewTitle: PropTypes.func,
  onCreateIdea: PropTypes.func,
};

export default memo(IdeasCreate);
