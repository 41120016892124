import { connect } from 'react-redux';

import { RESET_LANDING } from '../../../actions';

import Landing from './Landing';

const mapStateToProps = ({ landing }) => ({
  error: landing.error,
  isFetching: landing.isFetching,
  isAnyFormVisible: landing.isFormVisible,
});

const mapDispatchToProps = (dispatch) => ({
  onUnload: () => dispatch({ type: RESET_LANDING }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Landing);
