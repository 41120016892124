import React, {
  memo, useCallback, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { withRouter } from 'react-router-dom';
import {
  Button, Dropdown, Form,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getBankData, getCompanyData, getCompanyUser } from '../../../../actions';

import { smoothScrollTo } from '../../../../utils';

import errorMessages from '../../../../constants/errors';

import withRequest from '../../../HOCs/withRequest';

import Preloader from '../../../UI/Preloader/Preloader';

export const TYPES = {
  russian_legal_entity: 'Российское юр. лицо или ИП',
  foreign_legal_entity: 'Иностранное юр. лицо',
};

const CompaniesForm = ({
  companies, comments, createCanceler, defaultData, dispatch, history, isEditMode, onCreateCompanyComment, onDeleteCompanyComment, onSubmit,
}) => {
  const [formData, setFormData] = useState(defaultData || {
    login: '',
    type: 'russian_legal_entity',
    inn: '',
    full_name: '',
    short_name: '',
    legal_address: '',
    actual_address: '',
    mail_address: '',
    kpp: '',
    ogrn: '',
    bic: '',
    bank: '',
    account_number: '',
    corr_account_number: '',
    foreign_details: '',
  });
  const [validFields, setValidFields] = useState({
    login: {
      valid: !!defaultData?.login,
      message: '',
      type: 'warning',
    },
    inn: {
      valid: !!defaultData?.inn,
      message: '',
      type: 'warning',
    },
    full_name: {
      valid: !!defaultData?.full_name,
      message: '',
      type: 'warning',
    },
  });
  const [pristineFields, setPristineFields] = useState({
    login: true,
    inn: true,
    bic: true,
  });
  const [isUserFetching, setIsUserFetching] = useState(false);
  const [user, setUser] = useState(defaultData ? { login: defaultData.login } : null);

  const [isCompanyDataFetching, setIsCompanyDataFetching] = useState(false);
  const [companyData, setCompanyData] = useState(null);

  const [isBankDataFetching, setIsBankDataFetching] = useState(false);
  const [bankData, setBankData] = useState(null);

  const [commentText, setCommentText] = useState('');

  const timeout = useRef(null);

  const goBack = useCallback(() => {
    smoothScrollTo(document.documentElement, 0);

    history.push('/companies');
  }, [history]);

  const handleChangeData = useCallback((value) => setFormData({ ...formData, ...value }), [formData]);

  const handleChangeLogin = useCallback((e) => {
    if (isEditMode) return;

    const isInvalid = !e.target.value || !e.target.value.match(/.+@.+\..+/i);

    setValidFields({
      ...validFields,
      login: {
        valid: !isInvalid,
        message: isInvalid ? 'Это обязательное поле' : '',
        type: 'warning',
      },
    });

    setPristineFields({ ...pristineFields, login: false });

    handleChangeData({ login: e.target.value });
  }, [handleChangeData, pristineFields, validFields, isEditMode]);

  const handleGetUser = useCallback(() => { // eslint-disable-line
    if (isEditMode || !formData.login || !validFields.login.valid || pristineFields.login || isUserFetching) return null;

    setIsUserFetching(true);

    const getCompanyUserRequestCanceler = createCanceler();

    dispatch(getCompanyUser(formData.login, getCompanyUserRequestCanceler.token))
      .then((res) => {
        setIsUserFetching(false);

        setUser(res);
      })
      .catch((error) => {
        setIsUserFetching(false);

        setValidFields({
          ...validFields,
          login: {
            valid: false,
            message: error.message ? errorMessages[error.message] : 'Ошибка',
            type: 'error',
          },
        });
      });
  }, [createCanceler, dispatch, formData.login, isUserFetching, pristineFields.login, validFields, isEditMode]);

  const handleChangeType = useCallback((val) => {
    handleChangeData({
      type: val,
      ...(val === 'russian_legal_entity' && {
        foreign_details: '',
      }),
      ...(val === 'foreign_legal_entity' && {
        legal_address: '',
        actual_address: '',
        mail_address: '',
        kpp: '',
        ogrn: '',
        bic: '',
        bank: '',
        account_number: '',
        corr_account_number: '',
      }),
    });
  }, [handleChangeData]);

  const handleChangeINN = useCallback((e) => {
    const isInvalid = !e.target.value || typeof +e.target.value !== 'number' || Number.isNaN(+e.target.value) || e.target.value.length < 10;

    setValidFields({
      ...validFields,
      inn: {
        valid: !isInvalid,
        message: isInvalid ? 'Это обязательное поле' : '',
        type: 'error',
      },
    });

    setPristineFields({ ...pristineFields, inn: false });

    setCompanyData(null);

    handleChangeData({ inn: e.target.value.trim() });
  }, [handleChangeData, pristineFields, validFields]);

  const handleGetCompanyByINN = useCallback((e) => { // eslint-disable-line
    if (e) e.stopPropagation();

    if (
      (e && e.key !== 'Enter')
      || formData.type === 'foreign_legal_entity'
      || !formData.inn
      || !validFields.inn.valid
      || (!e && pristineFields.inn)
      || isCompanyDataFetching
    ) return null;

    if (timeout.current) clearTimeout(timeout.current);

    timeout.current = setTimeout(() => {
      setIsCompanyDataFetching(true);

      const getCompanyDataRequestCanceler = createCanceler();

      dispatch(getCompanyData(formData.inn, getCompanyDataRequestCanceler.token))
        .then((res) => {
          setIsCompanyDataFetching(false);

          if (res.isExists && formData.inn !== defaultData?.inn) {
            setValidFields({
              ...validFields,
              inn: {
                ...validFields.inn,
                message: 'Внимание! Такой ИНН уже существует',
                type: 'warning',
              },
            });
          }

          setCompanyData(res.docs);
        })
        .catch((error) => {
          setIsCompanyDataFetching(false);

          setValidFields({
            ...validFields,
            inn: {
              valid: false,
              message: error.message ? errorMessages[error.message] : 'Ошибка',
              type: 'error',
            },
          });
        });
    }, e ? 20 : 500);
  }, [createCanceler, dispatch, defaultData, formData.inn, formData.type, isCompanyDataFetching, validFields, pristineFields]);

  useEffect(() => {
    handleGetCompanyByINN();
  }, [formData.inn]); // eslint-disable-line

  const handleChangeFullName = useCallback((e) => {
    const isInvalid = !e.target.value;

    setValidFields({
      ...validFields,
      full_name: {
        valid: !isInvalid,
        message: isInvalid ? 'Это обязательное поле' : '',
        type: 'warning',
      },
    });

    handleChangeData({ full_name: e.target.value });
  }, [handleChangeData, validFields]);

  const handleChangeShortName = useCallback((e) => handleChangeData({ short_name: e.target.value }), [handleChangeData]);

  const handleChangeLegalAddress = useCallback((e) => handleChangeData({ legal_address: e.target.value }), [handleChangeData]);

  const handleChangeActualAddress = useCallback((e) => handleChangeData({ actual_address: e.target.value }), [handleChangeData]);

  const handleChangeMailAddress = useCallback((e) => handleChangeData({ mail_address: e.target.value }), [handleChangeData]);

  const handleChangeKPP = useCallback((e) => handleChangeData({ kpp: e.target.value.trim() }), [handleChangeData]);

  const handleCangeOGRN = useCallback((e) => handleChangeData({ ogrn: e.target.value.trim() }), [handleChangeData]);

  const handleChangeBIC = useCallback((e) => {
    setPristineFields({ ...pristineFields, bic: false });

    setBankData(null);

    handleChangeData({ bic: e.target.value.trim() });
  }, [handleChangeData, pristineFields]);

  const handleGetBankByBIC = useCallback((e) => { // eslint-disable-line
    if (e) e.stopPropagation();

    if (
      (e && e.key !== 'Enter')
      || !formData.bic
      || (!e && pristineFields.bic)
      || isBankDataFetching
    ) return null;

    if (timeout.current) clearTimeout(timeout.current);

    timeout.current = setTimeout(() => {
      setIsBankDataFetching(true);

      const getBankDataRequestCanceler = createCanceler();

      dispatch(getBankData(formData.bic, getBankDataRequestCanceler.token))
        .then((res) => {
          setIsBankDataFetching(false);

          setBankData(res);
        })
        .catch(() => {
          setIsBankDataFetching(false);
        });
    }, e ? 20 : 500);
  }, [createCanceler, dispatch, formData.bic, isBankDataFetching, pristineFields]);

  useEffect(() => {
    handleGetBankByBIC();
  }, [formData.bic]); // eslint-disable-line

  const handleChangeBank = useCallback((e) => handleChangeData({ bank: e.target.value }), [handleChangeData]);

  const handleChangeAccountNumber = useCallback((e) => handleChangeData({ account_number: e.target.value }), [handleChangeData]);

  const handleChangeCorrAccountNumber = useCallback((e) => handleChangeData({ corr_account_number: e.target.value }), [handleChangeData]);

  const handleChangeForeignDetails = useCallback((e) => handleChangeData({ foreign_details: e.target.value }), [handleChangeData]);

  const handleFormSubmit = useCallback(() => {
    if (!user || companies.isFetching || Object.values(validFields).some((value) => !value.valid)) return;

    if (timeout.current) clearTimeout(timeout.current);

    onSubmit(formData, () => goBack());
  }, [companies.isFetching, user, validFields, formData, goBack, onSubmit]);

  const handleOutsideClick = (e) => {
    if (e.target.closest('.company-form__inn .form-control')
      || e.target.closest('.company-form__bic .form-control')
    ) return;

    setCompanyData(null);
    setBankData(null);
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);

      if (timeout.current) clearTimeout(timeout.current);
    };
  });

  const handleClickCompanyHint = (item) => {
    setValidFields({
      ...validFields,
      full_name: { valid: !!item.full_name, message: '', type: 'warning' },
    });

    handleChangeData({
      full_name: item.full_name || '',
      short_name: item.short_name || '',
      legal_address: item.legal_address || '',
      kpp: item.kpp || '',
      ogrn: item.ogrn || '',
    });
  };

  const handleClickBankHint = (item) => {
    handleChangeData({
      bank: item.bank || '',
      corr_account_number: item.corr_account_number || '',
    });
  };

  const handleChangeCommentText = (e) => setCommentText(e.target.value);

  const handleCreateComment = () => {
    if (!commentText) return;

    onCreateCompanyComment(commentText, () => setCommentText(''));
  };

  const renderFormFieldHint = (data, titleProp, descrProp, descrPrefix, onClick) => (
    <Popover className="company-form__field-hint">
      <Popover.Content>
        {data && data.map((item) => (
          // eslint-disable-next-line
          <div className="company-form__field-hint-content" key={item.id} onClick={() => onClick(item)}>
            <div className="company-form__field-hint-title">{item[titleProp]}</div>
            <div className="company-form__field-hint-desc">
              {`${descrPrefix} `}
              <span>{item[descrProp]}</span>
            </div>
          </div>
        ))}
      </Popover.Content>
    </Popover>
  );

  const renderFormFieldMessage = (msg, msgType = 'warning') => (
    <Form.Text className={`company-form__message ${msgType}`}>
      <FontAwesomeIcon icon={['fas', msgType === 'warning' ? 'exclamation-triangle' : 'times-circle']} />
      {msg}
    </Form.Text>
  );

  return (
    <div className="company-form">
      <div className={`company-form__row company-form__login${!validFields.login.valid ? ' invalid' : ''}`}>
        <Form.Label className="company-form__label">Логин: *</Form.Label>
        <div className="company-form__field">
          <Form.Control
            disabled={companies.isFetching || isUserFetching}
            readOnly={isEditMode}
            placeholder=""
            value={formData.login}
            isInvalid={!validFields.login.valid}
            onChange={handleChangeLogin}
            onBlur={handleGetUser}
          />
          {isUserFetching && <Preloader medium />}
          {!validFields.login.valid && validFields.login.message && renderFormFieldMessage(validFields.login.message, validFields.login.type)}
        </div>
      </div>
      {isEditMode ? (
        <>
          <div className="company-form__title">Комментарии</div>
          <div className="company-form__comments">
            {comments && comments.length > 0 && comments.sort((a, b) => new Date(b.date) - new Date(a.date)).map((comment) => comment.deleted ? null : ( // eslint-disable-line
              <div className="company-form__comment" key={comment._id}>
                <div className="company-form__comment-date">{dayjs(comment.date).format('DD.MM.YYYY')}</div>
                <div className="company-form__comment-text">{comment.text}</div>
                <Button
                  className="company-form__comment-btn"
                  onClick={() => onDeleteCompanyComment(comment._id)}
                  variant="light"
                  size="sm"
                  disabled={companies.isFetching || isCompanyDataFetching}
                >
                  <FontAwesomeIcon icon={['fal', 'times']} />
                </Button>
              </div>
            ))}
          </div>
          <div className="company-form__comment-form">
            <Form.Control
              as="textarea"
              rows={2}
              disabled={companies.isFetching || isCompanyDataFetching}
              placeholder="Введите ваш комментарий..."
              value={commentText}
              isInvalid={false}
              onChange={handleChangeCommentText}
            />
            <Button
              className="company-form__comment-create-btn"
              onClick={handleCreateComment}
              variant="light"
              size="sm"
              disabled={!commentText || companies.isFetching || isCompanyDataFetching}
            >
              Добавить
            </Button>
          </div>
        </>
      ) : null}
      <div className="company-form__title">Данные организации</div>
      <div className="company-form__row service-form__type">
        <div className="company-form__label">Тип: *</div>
        <Dropdown>
          <Dropdown.Toggle as="button" className="btn btn-light" disabled={companies.isFetching}>
            {TYPES[formData.type]}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {Object.keys(TYPES).map((type) => (
              <Dropdown.Item
                as="button"
                disabled={type === formData.type}
                eventKey={type}
                key={type}
                onSelect={handleChangeType}
              >
                {TYPES[type]}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className={`company-form__row company-form__inn${(!validFields.inn.valid || (validFields.inn.type === 'warning' && validFields.inn.message)) ? ' invalid' : ''}`}>
        <Form.Label className="company-form__label">{formData.type === 'foreign_legal_entity' ? 'Идентификатор: *' : 'ИНН: *'}</Form.Label>
        <div className="company-form__field">
          <OverlayTrigger show={!!(companyData && companyData?.length > 0)} placement="bottom" overlay={renderFormFieldHint(companyData, 'full_name', 'inn', 'ИНН', handleClickCompanyHint)}>
            <Form.Control
              disabled={companies.isFetching}
              placeholder=""
              value={formData.inn}
              isInvalid={!validFields.inn.valid}
              onChange={handleChangeINN}
              onKeyDown={handleGetCompanyByINN}
            />
          </OverlayTrigger>
          {isCompanyDataFetching && <Preloader medium />}
          {(!validFields.inn.valid || (validFields.inn.type === 'warning' && validFields.inn.message)) && validFields.inn.message && renderFormFieldMessage(validFields.inn.message, validFields.inn.type)}
        </div>
      </div>
      <div className={`company-form__row company-form__full-name${!validFields.full_name.valid ? ' invalid' : ''}`}>
        <Form.Label className="company-form__label">Полное название: *</Form.Label>
        <div className="company-form__field">
          <Form.Control
            disabled={companies.isFetching}
            placeholder=""
            value={formData.full_name}
            isInvalid={!validFields.full_name.valid}
            onChange={handleChangeFullName}
          />
          {!validFields.full_name.valid && validFields.full_name.message && renderFormFieldMessage(validFields.full_name.message, validFields.full_name.type)}
        </div>
      </div>
      <div className="company-form__row company-form__short-name">
        <Form.Label className="company-form__label">Краткое название:</Form.Label>
        <div className="company-form__field">
          <Form.Control
            disabled={companies.isFetching}
            placeholder=""
            value={formData.short_name}
            onChange={handleChangeShortName}
          />
        </div>
      </div>
      {formData.type === 'russian_legal_entity' && (
        <>
          <div className="company-form__row company-form__legal-address">
            <Form.Label className="company-form__label">Юридический адрес:</Form.Label>
            <div className="company-form__field">
              <Form.Control
                disabled={companies.isFetching}
                placeholder=""
                value={formData.legal_address}
                onChange={handleChangeLegalAddress}
              />
            </div>
          </div>
          <div className="company-form__row company-form__actual-address">
            <Form.Label className="company-form__label">Физический адрес:</Form.Label>
            <div className="company-form__field">
              <Form.Control
                disabled={companies.isFetching}
                placeholder=""
                value={formData.actual_address}
                onChange={handleChangeActualAddress}
              />
            </div>
          </div>
          <div className="company-form__row company-form__mail-address">
            <Form.Label className="company-form__label">Почтовый адрес:</Form.Label>
            <div className="company-form__field">
              <Form.Control
                disabled={companies.isFetching}
                placeholder=""
                value={formData.mail_address}
                onChange={handleChangeMailAddress}
              />
            </div>
          </div>
          <div className="company-form__row company-form__kpp">
            <Form.Label className="company-form__label">КПП:</Form.Label>
            <div className="company-form__field">
              <Form.Control
                disabled={companies.isFetching}
                placeholder=""
                value={formData.kpp}
                onChange={handleChangeKPP}
              />
            </div>
          </div>
          <div className="company-form__row company-form__ogrn">
            <Form.Label className="company-form__label">ОГРН(ИП):</Form.Label>
            <div className="company-form__field">
              <Form.Control
                disabled={companies.isFetching}
                placeholder=""
                value={formData.ogrn}
                onChange={handleCangeOGRN}
              />
            </div>
          </div>
          <div className="company-form__title">Банковские реквизиты</div>
          <div className="company-form__row company-form__bic">
            <Form.Label className="company-form__label">БИК:</Form.Label>
            <div className="company-form__field">
              <OverlayTrigger show={!!(bankData && bankData?.length > 0)} placement="bottom" overlay={renderFormFieldHint(bankData, 'bank', 'bic', 'БИК', handleClickBankHint)}>
                <Form.Control
                  disabled={companies.isFetching}
                  placeholder=""
                  value={formData.bic}
                  onChange={handleChangeBIC}
                  onKeyDown={handleGetBankByBIC}
                />
              </OverlayTrigger>
              {isBankDataFetching && <Preloader medium />}
            </div>
          </div>
          <div className="company-form__row company-form__bank">
            <Form.Label className="company-form__label">Банк:</Form.Label>
            <div className="company-form__field">
              <Form.Control
                disabled={companies.isFetching}
                placeholder=""
                value={formData.bank}
                onChange={handleChangeBank}
              />
            </div>
          </div>
          <div className="company-form__row company-form__account-number">
            <Form.Label className="company-form__label">Расчетный счет:</Form.Label>
            <div className="company-form__field">
              <Form.Control
                disabled={companies.isFetching}
                placeholder=""
                value={formData.account_number}
                onChange={handleChangeAccountNumber}
              />
            </div>
          </div>
          <div className="company-form__row company-form__corr-account-number">
            <Form.Label className="company-form__label">Корр. счет:</Form.Label>
            <div className="company-form__field">
              <Form.Control
                disabled={companies.isFetching}
                placeholder=""
                value={formData.corr_account_number}
                onChange={handleChangeCorrAccountNumber}
              />
            </div>
          </div>
        </>
      )}
      {formData.type === 'foreign_legal_entity' ? (
        <div className="company-form__row company-form__foreign-details">
          <Form.Label className="company-form__label">Реквизиты:</Form.Label>
          <div className="company-form__field">
            <Form.Control
              as="textarea"
              rows={6}
              disabled={companies.isFetching}
              placeholder=""
              value={formData.foreign_details}
              onChange={handleChangeForeignDetails}
            />
          </div>
        </div>
      ) : null}
      <div className="company-form__footer">
        <Button
          className="company-form__submit-btn"
          onClick={handleFormSubmit}
          variant="success"
          size="sm"
          disabled={!user || companies.isFetching || Object.entries(validFields).filter(([key, value]) => ['login', 'inn', 'full_name'].includes(key) ? [key, value] : null).some(([, value]) => !value.valid)} // eslint-disable-line
        >
          {isEditMode ? 'Изменить контрагента' : 'Создать контрагента'}
        </Button>
        <div // eslint-disable-line
          role="button"
          onClick={goBack}
          className="company-form__link"
        >
          Назад
        </div>
      </div>
    </div>
  );
};

CompaniesForm.propTypes = {
  companies: PropTypes.object.isRequired,
  comments: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    deleted: PropTypes.bool,
  })),
  createCanceler: PropTypes.func.isRequired,
  defaultData: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object,
  isEditMode: PropTypes.bool,
  // requestCancelers: PropTypes.array.isRequired,
  onCreateCompanyComment: PropTypes.func,
  onDeleteCompanyComment: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
};

export default memo(withRequest(withRouter(CompaniesForm)));
