import { connect } from 'react-redux';

import {
  getService,
  updateService,
  RESET_SERVICES,
} from '../../../../actions';

import ServicesEdit from './ServicesEdit';

const mapStateToProps = (state) => ({
  services: state.services,
});

const mapDispatchToProps = (dispatch) => ({
  onGetService: (id, requestToken) => dispatch(getService(id, requestToken)),
  onUpdateService: (id, data, requestToken) => dispatch(updateService(id, data, requestToken)),
  onUnload: () => dispatch({ type: RESET_SERVICES }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ServicesEdit);
