import React, { memo, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';

import { isURLValid } from '../../../../../utils';

import { useDidUpdateEffect } from '../../../../hooks';

import { ReactComponent as DuplicateSvg } from '../../icons/duplicate.svg';

import './MenuElement.scss';
import SearchDropdown from '../../../../UI/SearchDropdown/SearchDropdown';

const MENU_ELEMENT_ACTIONS = {
  page: 'Страница',
  url: 'Ссылка',
  menu: 'Меню',
  none: 'Нет действия',
};

const FORBIDDEN_MENUS_NAMES = ['header', 'footer'];

const MenuElement = ({
  element,
  dragHandleProps,
  isFetching,
  isLevelDisabled,
  onChange,
  onChangeLevel,
  onCopy,
  onDelete,
}) => {
  const menusDocs = useSelector(({ landing }) => landing.menu.docs);
  const pages = useSelector(({ landing }) => landing.pages.docs);

  const [search, setSearch] = useState('');

  const dropdownItems = useMemo(() => {
    const items = pages.filter(({ name }) => {
      const itemText = name?.toLowerCase() || '';

      return itemText.includes(search.trim().toLowerCase());
    }) || [];

    return search.trim() ? items : items.reverse();
  }, [search, pages]);

  const menus = useMemo(() => (
    menusDocs.filter((m) => m._id !== element.menu_id && !FORBIDDEN_MENUS_NAMES.includes(m.name))
  ), [menusDocs, element.menu_id]);

  const handleClickToggleLevel = () => {
    if (!element.parent_element_id && element.action === 'menu') {
      onChange(element._id, { action: 'none', action_menu_id: '' });
    }

    onChangeLevel(element._id);
  };

  const handleClickToggleStatus = () => onChange(element._id, { stage_only: !element.stage_only });

  const handleClickDeleteElement = () => onDelete(element._id);

  const handleClickCopyElement = () => onCopy(element._id);

  const handleChangeElementName = (name) => onChange(element._id, { name });

  const handleChangeElementAction = (action) => {
    onChange(element._id, {
      action_menu_id: undefined,
      action_page_id: undefined,
      action_url: undefined,
      action,
      ...action === 'menu' && { action_menu_id: menus[0]?._id },
      ...action === 'page' && { action_page_id: pages[0]?._id },
      ...action === 'url' && { action_url: '' },
    });
  };

  const handleChangeElementActionMenu = (id) => onChange(element._id, { action_menu_id: id });

  const handleChangeElementActionUrl = (value) => onChange(element._id, { action_url: value });

  const handleChangeElementActionPage = (id) => onChange(element._id, { action_page_id: id });

  const getCurrentPageTitle = () => {
    const page = pages.find((m) => m._id === element.action_page_id);

    if (!page) return 'Выберите страницу...';

    const category = page.category ? `${page.category.name} → ` : '';

    return `${category}${page.name}`;
  };

  useDidUpdateEffect(() => {
    const selectedMenu = menus.find((m) => m._id === element.action_menu_id);

    if (!selectedMenu && element.action_menu_id) onChange(element._id, { action_menu_id: '' });
  }, [menus, element.action_menu_id, element._id, onChange]);

  return (
    <div className="menu-element">
      <div className="menu-element__fields">
        <div className="menu-element__field" data-type="name">
          <Form.Control
            disabled={isFetching}
            placeholder="Введите название..."
            value={element.name}
            onChange={(e) => handleChangeElementName(e.target.value)}
          />
        </div>
        <div className="menu-element__field" data-type="action">
          <Dropdown className="landing__dropdown">
            <Dropdown.Toggle
              as="button"
              type="button"
              className="btn btn-light"
              disabled={isFetching}
            >
              {MENU_ELEMENT_ACTIONS[element.action]}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {Object.keys(MENU_ELEMENT_ACTIONS).map((key) => (
                element.parent_element_id && key === 'menu'
                  ? null
                  : (
                    <Dropdown.Item
                      key={key}
                      active={element.action === key}
                      as="button"
                      type="button"
                      onClick={() => handleChangeElementAction(key)}
                    >
                      {MENU_ELEMENT_ACTIONS[key]}
                    </Dropdown.Item>
                  )))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        {element.action !== 'none' && (
          <div className="menu-element__field" data-type={`action-${element.action}`}>
            {element.action === 'menu' && (
              <Dropdown className="landing__dropdown">
                <Dropdown.Toggle
                  as="button"
                  type="button"
                  className="btn btn-light"
                  disabled={isFetching}
                >
                  {menus.find((m) => m._id === element.action_menu_id)?.name || 'Выберите меню...'}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {menus.map(({ _id, name }) => (
                    <Dropdown.Item
                      key={_id}
                      active={element.action_menu_id === _id}
                      as="button"
                      type="button"
                      onClick={() => handleChangeElementActionMenu(_id)}
                    >
                      {name}
                    </Dropdown.Item>
                  ))}
                  {menus.length === 0 && (
                    <Dropdown.Item
                      as="button"
                      type="button"
                      style={{ opacity: 0.5, pointerEvents: 'none' }}
                    >
                      Список пуст
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            )}
            {element.action === 'page' && (
              <SearchDropdown
                className="menu-element__search-dropdown"
                placeholder="Найти раздел"
                value={getCurrentPageTitle()}
                onSearch={setSearch}
              >
                {dropdownItems.map(({ _id, name, category }) => (
                  <li className="menu-element__search-dropdown-item" key={_id}>
                    <button
                      className={`${element.action_page_id === _id ? 'active' : ''}`}
                      type="button"
                      onClick={() => handleChangeElementActionPage(_id)}
                    >
                      {category ? `${category.name} → ${name}` : `${name}`}
                    </button>
                  </li>
                ))}
              </SearchDropdown>
            )}
            {element.action === 'url' && (
              <Form.Control
                disabled={isFetching}
                placeholder="Ссылка (относительная или абсолютная)..."
                value={element.action_url || ''}
                style={isURLValid(element.action_url) ? {} : { borderColor: 'red' }}
                onChange={(e) => handleChangeElementActionUrl(e.target.value)}
              />
            )}
          </div>
        )}
      </div>
      <div className="menu-element__actions">
        {element.order !== 0 && !isLevelDisabled && (
          <button
            data-icon="chevron"
            disabled={isFetching}
            type="button"
            className="landing__category-action"
            onClick={handleClickToggleLevel}
          >
            {element.parent_element_id && <FontAwesomeIcon icon={['fas', 'chevron-double-right']} style={{ transform: 'rotate(180deg)' }} />}
            {!element.parent_element_id && <FontAwesomeIcon icon={['fas', 'chevron-double-right']} />}
          </button>
        )}
        <button
          data-icon="duplicate"
          disabled={isFetching}
          type="button"
          className="landing__category-action"
          onClick={handleClickCopyElement}
        >
          <DuplicateSvg />
        </button>
        <button
          data-icon="flask"
          disabled={isFetching}
          type="button"
          className={`landing__category-action${element.stage_only ? ' landing__category-action_highlighted' : ''}`}
          onClick={handleClickToggleStatus}
        >
          <FontAwesomeIcon icon={['fas', 'flask']} />
        </button>
        <button
          data-icon="times"
          disabled={isFetching}
          type="button"
          className="landing__category-action"
          onClick={handleClickDeleteElement}
        >
          <FontAwesomeIcon icon={['far', 'times']} />
        </button>
        <button
          {...dragHandleProps}
          data-icon="bars"
          disabled={isFetching}
          type="button"
          className="landing__category-action"
        >
          <FontAwesomeIcon icon={['far', 'bars']} />
        </button>
      </div>
    </div>
  );
};

MenuElement.propTypes = {
  element: PropTypes.object.isRequired,
  dragHandleProps: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isLevelDisabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onCopy: PropTypes.func.isRequired,
  onChangeLevel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default memo(MenuElement);
