import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Dropdown, Pagination as BPagination } from 'react-bootstrap';

const offsetLeft = 2;
const offsetRight = 2;

const Pagination = ({
  className, currentPage = 1, disabled, limit, limitDropdown, pages, total, onChange, onChangeLimit,
}) => {
  const handleChangePage = (page) => {
    onChange(page);
  };

  const handleChangeLimit = (value) => {
    onChangeLimit(+value);

    handleChangePage(1);
  };

  const renderLimitDropdown = () => (
    <Dropdown>
      <Dropdown.Toggle as="button" className="btn btn-light" disabled={disabled}>
        {`${limit} на странице`}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {[10, 100, 500].map((val) => (
          <Dropdown.Item
            as="button"
            eventKey={val}
            key={val}
            onSelect={() => handleChangeLimit(val)}
          >
            {val}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );

  return (
    (total && total >= limit) || limitDropdown ? (
      <div className={classNames('pagination', className)}>
        <BPagination>
          {currentPage !== 1 && (
            <>
              <BPagination.First disabled={disabled} onClick={() => handleChangePage(1)} />
              <BPagination.Prev disabled={disabled} onClick={() => handleChangePage(currentPage - 1)} />
            </>
          )}
          {currentPage !== 1 && currentPage > 1 + offsetLeft && (
            <BPagination.Item disabled={disabled} onClick={() => handleChangePage(1)}>{1}</BPagination.Item>
          )}
          {currentPage >= 3 && <BPagination.Ellipsis disabled className="pagination__ellipsis" />}

          {pages && Array.from(Array(pages).keys())
            .filter((p) => p + 1 >= currentPage - offsetLeft && p + 1 <= currentPage + offsetRight)
            .map((p) => {
              const page = p + 1;
              return (
                <BPagination.Item
                  active={currentPage === page}
                  disabled={disabled}
                  onClick={() => handleChangePage(page)}
                  key={page}
                >
                  {page}
                </BPagination.Item>
              );
            })}

          {currentPage <= pages - 3 && <BPagination.Ellipsis disabled className="pagination__ellipsis" />}
          {currentPage !== pages && currentPage < pages - offsetRight && (
            <BPagination.Item disabled={disabled} onClick={() => handleChangePage(pages)}>{pages}</BPagination.Item>
          )}
          {currentPage !== pages && (
            <>
              <BPagination.Next disabled={disabled} onClick={() => handleChangePage(currentPage + 1)} />
              <BPagination.Last disabled={disabled} onClick={() => handleChangePage(pages)} />
            </>
          )}
        </BPagination>
        {limitDropdown && typeof onChangeLimit === 'function' && renderLimitDropdown()}
      </div>
    ) : null
  );
};

Pagination.propTypes = {
  className: PropTypes.string,
  currentPage: PropTypes.number,
  disabled: PropTypes.bool,
  limitDropdown: PropTypes.bool,
  limit: PropTypes.number,
  pages: PropTypes.number,
  total: PropTypes.number,
  onChange: PropTypes.func,
  onChangeLimit: PropTypes.func,
};

export default memo(Pagination);
