import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './Preloader.scss';

function Preloader({
  caption, inline, medium, small,
}) {
  return (
    <div className={classNames('preloader', { preloader_inline: inline, preloader_medium: medium, preloader_small: small })}>
      <div className="preloader__icon">
        <FontAwesomeIcon spin icon={[small ? 'fas' : 'fal', 'spinner-third']} />
      </div>
      {caption && <div className="preloader__caption" dangerouslySetInnerHTML={{ __html: caption }} />}
    </div>
  );
}

Preloader.propTypes = {
  caption: PropTypes.string,
  inline: PropTypes.bool,
  medium: PropTypes.bool,
  small: PropTypes.bool,
};

export default Preloader;
