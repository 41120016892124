export const CREATE_OBJECT_CATEGORY_OBJECT = 'CREATE_OBJECT_CATEGORY_OBJECT';
export const CREATE_OBJECT_CATEGORY_EMPTY_OBJECT = 'CREATE_OBJECT_CATEGORY_EMPTY_OBJECT';
export const DELETE_OBJECT_CATEGORY_OBJECT = 'DELETE_OBJECT_CATEGORY_OBJECT';
export const GET_OBJECT_CATEGORY_OBJECTS = 'GET_OBJECT_CATEGORY_OBJECTS';
export const ORDER_OBJECT_CATEGORY_OBJECTS = 'ORDER_OBJECT_CATEGORY_OBJECTS';
export const SAVE_OBJECT_CATEGORY_OBJECTS_ORDER = 'SAVE_OBJECT_CATEGORY_OBJECTS_ORDER';
export const RESET_OBJECT_CATEGORY_OBJECTS = 'RESET_OBJECT_CATEGORY_OBJECTS';
export const UPDATE_OBJECT_CATEGORY_OBJECT = 'UPDATE_OBJECT_CATEGORY_OBJECT';

/* --------------- */
/* Action creators */

/* --------------- */

export function getObjectCategoryObjects(categoryID, requestToken) {
  return {
    type: GET_OBJECT_CATEGORY_OBJECTS,
    request: {
      method: 'get',
      url: `/admin/objects/${categoryID}`,
      requestToken,
    },
  };
}

export function createObjectCategoryObject(data, requestToken) {
  return {
    type: CREATE_OBJECT_CATEGORY_OBJECT,
    request: {
      method: 'post',
      url: '/admin/objects',
      data,
      requestToken,
    },
    payload: { data },
  };
}

export function createObjectCategoryEmptyObject(data) {
  return {
    type: CREATE_OBJECT_CATEGORY_EMPTY_OBJECT,
    payload: { data },
  };
}

export function updateObjectCategoryObject(data, requestToken) {
  return {
    type: UPDATE_OBJECT_CATEGORY_OBJECT,
    request: {
      method: 'patch',
      url: `/admin/objects/${data._id}`,
      data,
      requestToken,
    },
    payload: { data },
  };
}

export function deleteObjectCategoryObject(id, requestToken) {
  return {
    type: DELETE_OBJECT_CATEGORY_OBJECT,
    ...(requestToken && {
      request: {
        method: 'delete',
        url: `/admin/objects/${id}`,
        requestToken,
      },
    }),
    payload: { id },
  };
}

export function orderObjectCategoryObjects(targetID, objectIDs) {
  return {
    type: ORDER_OBJECT_CATEGORY_OBJECTS,
    payload: { targetID, objectIDs },
  };
}

export function saveObjectCategoryObjectsOrder(data, requestToken) {
  return {
    type: SAVE_OBJECT_CATEGORY_OBJECTS_ORDER,
    request: {
      method: 'post',
      url: '/admin/objects/order',
      data,
      requestToken,
    },
  };
}
