const errorMessages = {
  // common
  'Network Error': 'Ошибка сети, проверьте подключение к интернету',
  admin_privileges_required: 'Недостаточно прав',
  first_request_failed: 'Ошибка загрузки, обновите страницу браузера',
  permission_denied: 'Отказано в доступе',
  server_error: 'Что-то пошло не так',
  incorrect_query: 'Некорректный запрос',
  incorrect_data: 'Некорректный данные',
  data_incorrect: 'Некорректный данные',
  unknown_error: 'Неизвестная ошибка. Повторите позднее',
  slug_exist: 'Такой алиас уже существует',

  // profile
  password_not_match: 'Пароли не совпадают',
  old_password_wrong: 'Старый пароль неправильный',
  account_blocked: 'Ошибка изменения пароля. Пожалуйста, обратитесь в поддержку.',

  // signin
  user_not_found: 'Логин не найден в системе',
  password_incorrect: 'Неверный пароль',

  // users
  email_incorrect: 'Некорректный E-mail',
  email_exist: 'Этот E-mail уже существует',
  // incorrect_data: 'Этот E-mail уже существует',
  min_search_chars: 'Кол-во символов должно быть не меньше 3',

  // invoices
  invoice_not_exist: 'Счёт не найден',
  service_group_not_match: 'Тарифы не совпадают',
  incorrect_blank_format: 'Некорректный формат',
  invoice_date_to_late: 'Дата создания счёта не может быть позже даты оплаты счёта',
  invoice_pay_date_to_early: 'Дата оплаты счёта не может быть раньше даты создания счёта',
  invoice_close_date_to_early: 'Дата закрытия счёта не может быть раньше даты создания счёта',

  // files
  incorrect_file: 'Некорректный файл',
  filesize_limit_exceeded: 'Превышен допустимый размера файла',
  emails_not_found: 'Пользователи не найдены',

  // ideas
  idea_not_exist: 'Идея не найдена',
  idea_template_not_exist: 'Шаблон template_ids (lang) не найден или скрыт',
  idea_templates_not_exist: 'Шаблоны template_ids (lang) не найдены или скрыты',

  // promocodes
  'Promocode already created': 'Такой промокод уже существует',

  // templates
  video_not_exist: 'Не удалось добавить вариацию',
  payment_failed: 'Сохранение не удалось. Уведомления не отправлены (ошибка выплаты)',
  notification_failed: 'Уведомления не отправлены (ошибка отправки)',

  // tags
  tag_not_found: 'Тег не найден',
  tag_name_taken: 'Выбранное имя уже занято',
  tag_alias_taken: 'Выбранный алиас уже занят',
  tag_is_used: 'Этот тег используется',
  alias_incorrect: 'Некорректный формат алиаса',
  name_incorrect: 'Некорректный формат названия',

  // object_categories, objects
  category_already_exist: 'Такая категория уже существует',
  category_not_exist: 'Категория не найдена',
  object_not_exist: 'Объект не найден',
  variation_not_exist: 'Не удалось добавить вариацию',

  // services
  currency_incorrect: 'Некорректная валюта',
  days_incorrect: 'Некорректный период',
  group_incorrect: 'Некорректная группа',
  names_ru_incorrect: 'Некорректное название',
  names_en_incorrect: 'Некорректное название',
  sum_incorrect: 'Некорректная стоимость',
  type_incorrect: 'Некорректный тип',
  comment_incorrect: 'Некорректный комментарий',
  features_incorrect: 'Некорректное поле features',
  discount_percent_incorrect: 'Некорректное поле discount_percent',
  fastspring_plan_id_incorrect: 'Некорректное поле fastspring_plan_id',
  service_not_found: 'Тариф не найден',
  service_has_paid_invoices: 'По тарифу уже есть оплаченые счета',
  service_has_invoices: 'По тарифу уже есть выставленные счета',
  service_already_exists: 'Сервис (id) уже существует, следующий свободный в этой группе - (freeID)',

  // access
  incorrect_ip_address: 'Некорректный адрес',
  ip_address_already_exists: 'Такой адрес уже существует',
  ip_address_not_found: 'IP-адрес не найден',
  ip_address_can_not_be_removed: 'Вы не можете удалить свой ip-адрес',
  user_already_admin: 'Пользователь уже является администратором',
  admin_permissions_incorrect: 'Некорректные права доступа',

  // companies
  login_not_exist: 'Логина не существует',
  company_not_exist: 'Компании не существует',
};

export default errorMessages;
