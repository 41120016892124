export const GET_TAGS = 'GET_TAGS';
export const RESET_TAGS = 'RESET_TAGS';
export const UPDATE_TAG = 'UPDATE_TAG';
export const DELETE_TAG = 'DELETE_TAG';

/* --------------- */
/* Action creators */

/* --------------- */

export function getTags(params, requestToken) {
  return {
    type: GET_TAGS,
    request: {
      method: 'get',
      url: '/admin/tags',
      params,
      requestToken,
    },
  };
}

export function updateTag(id, data, requestToken) {
  return {
    type: UPDATE_TAG,
    request: {
      method: 'patch',
      url: `/admin/tags/${id}`,
      data,
      requestToken,
    },
  };
}

export function deleteTag(id, params, requestToken) {
  return {
    type: DELETE_TAG,
    request: {
      method: 'delete',
      url: `/admin/tags/${id}`,
      params,
      requestToken,
    },
    payload: { id },
  };
}

/* ------ */
/* Thunks */

/* ------ */
