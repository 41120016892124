export const GET_COMPANIES = 'GET_COMPANIES';
export const GET_COMPANY = 'GET_COMPANY';
export const GET_COMPANY_USER = 'GET_COMPANY_USER';
export const GET_COMPANY_DATA = 'GET_COMPANY_DATA';
export const GET_BANK_DATA = 'GET_BANK_DATA';
export const GET_COMPANY_BLANK = 'GET_COMPANY_BLANK';
export const CREATE_COMPANY = 'CREATE_COMPANY';
export const CREATE_COMPANY_COMMENT = 'CREATE_COMPANY_COMMENT';
export const DELETE_COMPANY = 'DELETE_COMPANY';
export const DELETE_COMPANY_COMMENT = 'DELETE_COMPANY_COMMENT';
export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const RESET_COMPANIES = 'RESET_COMPANIES';

/* --------------- */
/* Action creators */

/* --------------- */

export function getCompanies(params, isSearch, requestToken) {
  return {
    type: GET_COMPANIES,
    request: {
      method: 'get',
      url: '/admin/companies',
      params,
      requestToken,
    },
    payload: { isSearch, params },
  };
}

export function getCompany(id, requestToken) {
  return {
    type: GET_COMPANY,
    request: {
      method: 'get',
      url: `/admin/companies/${id}`,
      requestToken,
    },
  };
}

export function createCompany(data, requestToken) {
  return {
    type: CREATE_COMPANY,
    request: {
      method: 'post',
      url: '/admin/companies',
      data,
      requestToken,
    },
  };
}

export function updateCompany(id, data, requestToken) {
  return {
    type: UPDATE_COMPANY,
    request: {
      method: 'patch',
      url: `/admin/companies/${id}`,
      data,
      requestToken,
    },
  };
}

export function deleteCompany(id, requestToken) {
  return {
    type: DELETE_COMPANY,
    request: {
      method: 'delete',
      url: `/admin/companies/${id}`,
      requestToken,
    },
    payload: { id },
  };
}

export function deleteCompanyComment(id, commentID, requestToken) {
  return {
    type: DELETE_COMPANY_COMMENT,
    request: {
      method: 'delete',
      url: `/admin/companies/${id}/comment?comment_id=${commentID}`,
      requestToken,
    },
  };
}

export function createCompanyComment(id, commentText, requestToken) {
  return {
    type: CREATE_COMPANY_COMMENT,
    request: {
      method: 'post',
      url: `/admin/companies/${id}/comment`,
      requestToken,
      data: { text: commentText },
    },
  };
}

export function getCompanyUser(login, requestToken) {
  return {
    type: GET_COMPANY_USER,
    request: {
      method: 'get',
      url: `/admin/companies/user?login=${login}`,
      requestToken,
    },
  };
}

export function getCompanyData(inn, requestToken) {
  return {
    type: GET_COMPANY_DATA,
    request: {
      method: 'get',
      url: `/admin/companies/data?inn=${inn}`,
      requestToken,
    },
  };
}

export function getBankData(bic, requestToken) {
  return {
    type: GET_BANK_DATA,
    request: {
      method: 'get',
      url: `/admin/companies/bank?bic=${bic}`,
      requestToken,
    },
  };
}

export function getCompanyBlank({
  id, format = 'xlsx', from, to,
}, requestToken) {
  return {
    type: GET_COMPANY_BLANK,
    request: {
      method: 'get',
      url: `/admin/companies/${id}/act-blank?format=${format}&from=${from}&to=${to}`,
      responseType: 'blob',
      requestToken,
    },
  };
}
