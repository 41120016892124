import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import './Error.scss';

function Error({
  action,
  caption,
  description,
  icon,
  inline,
  title,
}) {
  return (
    <div className={`error${inline ? ' error_inline' : ''}`}>
      <div className="error__icon">{icon}</div>
      {caption && <div className="error__caption" dangerouslySetInnerHTML={{ __html: caption }} />}
      {title && <h2 className="error__title heading" dangerouslySetInnerHTML={{ __html: title }} />}
      {description && <div className="error__description" dangerouslySetInnerHTML={{ __html: description }} />}
      {action && (
        <div className="error__action">
          <Button variant={action.variant || 'primary'} onClick={() => action.onClick()}>{action.caption}</Button>
        </div>
      )}
    </div>
  );
}

Error.propTypes = {
  action: PropTypes.oneOfType([
    PropTypes.shape({
      caption: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      variant: PropTypes.string,
    }),
    PropTypes.bool,
  ]),
  caption: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.element.isRequired,
  inline: PropTypes.bool,
  title: PropTypes.string,
};

export default Error;
