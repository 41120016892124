import React from 'react';
import PropTypes from 'prop-types';
import LogoURL from './Logo.svg';

import { s3Replace } from '../../../utils/common';

import './Logo.scss';

const Logo = ({
  href,
  imgURL,
  title,
  target,
}) => {
  const renderLink = () => (
    <a href={href} className="logo" target={target} rel={target ? 'noopener noreferrer' : null}>
      <img src={LogoURL} alt={title} />
    </a>
  );

  const renderBlock = () => (
    <div className="logo">
      <img src={(imgURL ? s3Replace(imgURL) : undefined) || LogoURL} alt={title} />
    </div>
  );

  return href ? renderLink() : renderBlock();
};

Logo.propTypes = {
  href: PropTypes.string,
  imgURL: PropTypes.string,
  target: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default Logo;
