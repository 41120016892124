import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Form } from 'react-bootstrap';
import cloneDeep from 'lodash/cloneDeep';

import { permissions, permissionNames } from '../../../../constants/common';

import { smoothScrollTo } from '../../../../utils';

import CheckBox from '../../../UI/CheckBox/CheckBox';

const AccessAdminsForm = ({
  defaultData, history, isEditMode, admins, userID, onSubmit,
}) => {
  const user = useSelector(({ auth }) => auth.user);

  const [formData, setFormData] = useState(defaultData || {
    email: '',
    name: '',
    admin_permissions: [],
  });
  const [validFields, setValidFields] = useState({
    email: !!defaultData?.email,
    name: true,
  });
  const [pristineFields, setPristineFields] = useState({
    email: true,
    name: true,
  });

  const goBack = useCallback(() => {
    smoothScrollTo(document.documentElement, 0);

    history.push('/access/admins');
  }, [history]);

  const handleChangeData = useCallback((value) => setFormData({ ...formData, ...value }), [formData]);

  const handleChangeLogin = useCallback((e) => {
    if (isEditMode) return;

    const isInvalid = !e.target.value || !e.target.value.match(/.+@.+\..+/i);

    setValidFields({ ...validFields, email: !isInvalid });

    setPristineFields({ ...pristineFields, email: false });

    handleChangeData({ email: e.target.value });
  }, [handleChangeData, pristineFields, validFields, isEditMode]);

  const handleChangeName = useCallback((e) => {
    const isInvalid = e.target.value.length > 100;

    setValidFields({ ...validFields, name: !isInvalid });

    setPristineFields({ ...pristineFields, name: false });

    handleChangeData({ name: e.target.value });
  }, [handleChangeData, pristineFields, validFields]);

  const handleChangePermission = useCallback((val, permission) => {
    if (permission === 'all') {
      handleChangeData({ admin_permissions: val ? ['all'] : [] });
    } else {
      if (val && !formData.admin_permissions.includes(permission)) {
        handleChangeData({ admin_permissions: [...formData.admin_permissions, permission] });
      }

      if (!val && formData.admin_permissions.includes(permission)) {
        handleChangeData({ admin_permissions: formData.admin_permissions.filter((item) => item !== permission) });
      }
    }
  }, [formData.admin_permissions, handleChangeData]);

  const handleFormSubmit = useCallback(() => {
    const resultData = cloneDeep(formData);

    if (admins.isFetching || Object.values(validFields).some((value) => !value)) return;

    if (resultData.admin_permissions.includes('all')) resultData.admin_permissions = ['all'];

    onSubmit(resultData, goBack);
  }, [admins.isFetching, formData, goBack, onSubmit, validFields]);

  return (
    <div className="admin-form">
      <div className="admin-form__row admin-form__login">
        <div className="admin-form__label">Логин:</div>
        <Form.Control
          disabled={admins.isFetching}
          readOnly={isEditMode}
          placeholder=""
          value={formData.email}
          isInvalid={!validFields.email && !pristineFields.email}
          onChange={handleChangeLogin}
        />
      </div>
      <div className="admin-form__row admin-form__name">
        <div className="admin-form__label">Имя администратора:</div>
        <Form.Control
          disabled={admins.isFetching}
          placeholder=""
          value={formData.name || ''}
          isInvalid={!validFields.name}
          onChange={handleChangeName}
        />
      </div>
      <div className="admin-form__row admin-form__permissions">
        <div className="admin-form__label">Права доступа:</div>
        <div className="admin-form__permissions-list">
          {Object.values(permissions).map((permission) => (
            <CheckBox
              className="admin-form__checkbox"
              checked={
                formData.admin_permissions.includes(permission)
                || (formData.admin_permissions.includes('all') && permission !== 'support')
              }
              disabled={
                (isEditMode && user?.id === +userID)
                || admins.isFetching
                || (formData.admin_permissions.includes('all') && permission !== 'all')
              }
              id={permission}
              isFetching={false}
              key={permission}
              eventKey={permission}
              onChange={handleChangePermission}
            >
              {permissionNames[permission.toUpperCase()]}
            </CheckBox>
          ))}
        </div>
      </div>
      <div className="admin-form__footer">
        <Button
          className="admin-form__submit-btn"
          onClick={handleFormSubmit}
          variant="success"
          size="sm"
          disabled={admins.isFetching || Object.values(validFields).some((value) => !value)}
        >
          {isEditMode ? 'Изменить администратора' : 'Добавить администратора'}
        </Button>
        <div // eslint-disable-line
          role="button"
          onClick={goBack}
          className="admin-form__link"
        >
          Назад
        </div>
      </div>
    </div>
  );
};

AccessAdminsForm.propTypes = {
  defaultData: PropTypes.object,
  history: PropTypes.object,
  isEditMode: PropTypes.bool,
  admins: PropTypes.object.isRequired,
  userID: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

export default withRouter(AccessAdminsForm);
