export const CHANGE_CATEGORY = 'CHANGE_CATEGORY';
export const ADD_CATEGORY_TEMPLATES_COUNT = 'ADD_CATEGORY_TEMPLATES_COUNT';
export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const ORDER_CATEGORY = 'ORDER_CATEGORY';
export const RESET_CATEGORIES = 'RESET_CATEGORIES';
export const SAVE_CATEGORIES_ORDER = 'SAVE_CATEGORIES_ORDER';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';

/* --------------- */
/* Action creators */

/* --------------- */

export function getCategories(params, requestToken) {
  return {
    type: GET_CATEGORIES,
    request: {
      method: 'get',
      url: '/admin/template_categories',
      params,
      requestToken,
    },
  };
}

export function setCategoriesOrder(moveData) {
  return {
    type: ORDER_CATEGORY,
    payload: moveData,
  };
}

export function saveCategoriesOrder(data, requestToken) {
  return {
    type: SAVE_CATEGORIES_ORDER,
    request: {
      method: 'post',
      url: '/admin/template_categories/order',
      data,
      requestToken,
    },
  };
}

export function createCategory(data, requestToken) {
  return {
    type: CREATE_CATEGORY,
    request: {
      method: 'post',
      url: '/admin/template_categories',
      data,
      requestToken,
    },
  };
}

export function removeCategory(id, requestToken) {
  return {
    type: DELETE_CATEGORY,
    request: {
      method: 'delete',
      url: `/admin/template_categories/${id}`,
      requestToken,
    },
    payload: { id },
  };
}

export function changeCategory(id, params) {
  return {
    type: CHANGE_CATEGORY,
    payload: { id, params },
  };
}

export function addCategoryTemplatesCount(id, count, force) {
  return {
    type: ADD_CATEGORY_TEMPLATES_COUNT,
    payload: { id, count, force },
  };
}

export function updateCategory(data, requestToken) {
  return {
    type: UPDATE_CATEGORY,
    request: {
      method: 'patch',
      url: `/admin/template_categories/${data._id}`,
      data,
      requestToken,
    },
    payload: { id: data._id },
  };
}
