import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import errorMessages from '../../../../constants/errors';

import { toast } from '../../../../utils';

import { createCompany } from '../../../../actions';

import withRequest from '../../../HOCs/withRequest';

import View from '../../../Layout/View/View';

import CompaniesForm from '../CompaniesForm/CompaniesForm';

const CompaniesCreate = ({ createCanceler, dispatch }) => {
  const companies = useSelector(({ companies: companiesStore }) => companiesStore);

  const handleCreate = useCallback((data, cb) => {
    const createCompanyRequestCanceler = createCanceler();

    dispatch(createCompany(data, createCompanyRequestCanceler.token))
      .then(() => {
        toast('success', 'Компания создана!');

        cb();
      })
      .catch((error) => {
        const message = error?.errors ? errorMessages[error.errors[0]] || error.errors[0] : errorMessages[error.message] || error.message;

        toast('error', message);
      });
  }, [createCanceler, dispatch]);

  return (
    <View
      isFetching={false}
      preloaderCaption="Загружаем..."
      title="Создать контрагента"
      viewClass="company-create"
      content={<CompaniesForm companies={companies} onSubmit={handleCreate} />}
    />
  );
};

CompaniesCreate.propTypes = {
  createCanceler: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  // onUnload: PropTypes.func.isRequired,
};

export default memo(withRequest(CompaniesCreate));
