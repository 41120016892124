import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { requestCanceler } from '../../middlewares/api';

export default function withRequest(Component) {
  return (props) => {
    const dispatch = useDispatch();

    const requestCancelers = useRef([]);

    useEffect(() => {
      const cancelers = requestCancelers.current;

      return () => {
        cancelers.forEach((canceler) => canceler.cancelRequest());
      };
    }, []);

    const createCanceler = useCallback(() => {
      const request = requestCanceler();

      requestCancelers.current.push(request);

      return request;
    }, []);

    return (
      <Component
        {...props}
        dispatch={dispatch}
        requestCancelers={requestCancelers.current}
        createCanceler={createCanceler}
      />
    );
  };
}
