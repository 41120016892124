import { connect } from 'react-redux';

import Profile from './Profile';

import { changePassword, PROFILE } from '../../../../actions';

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
});

const mapDispatchToProps = (dispatch) => ({
  changePassword: (data) => dispatch(changePassword(data)),
  onUnload: () => dispatch({ type: `${PROFILE}_PAGE_UNLOADED` }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
