import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  Button, Form, Tab, Tabs,
} from 'react-bootstrap';

import errorMessages from '../../../constants/errors';
import cacheGroups from '../../../constants/apicache';

import { requestCanceler } from '../../../middlewares/api';

import { toast } from '../../../utils';

import View from '../../Layout/View/View';

import Preloader from '../../UI/Preloader/Preloader';
import CheckBox from '../../UI/CheckBox/CheckBox';

import sweetAlert from '../../HOCs/sweetAlert';

import './Apicache.scss';

class Apicache extends React.PureComponent {
  requestCancelers = [];

  isUnmounted = React.createRef();

  state = {
    isFetching: false,
    url: '',
    groups: {},
  };

  componentDidMount() {
    this.initGroups();
  }

  componentWillUnmount() {
    this.requestCancelers.forEach((canceler) => canceler.cancelRequest());

    this.isUnmounted.current = true;
  }

  initGroups = () => {
    this.setState({ groups: cacheGroups.reduce((a, v) => ({ ...a, [v]: false }), {}) });
  };

  showAlert = (callback) => {
    sweetAlert.fire({
      cancelButtonText: 'Отмена',
      confirmButtonText: 'Да, я уверен',
      icon: <FontAwesomeIcon icon={['fal', 'exclamation-triangle']} />,
      showCancelButton: true,
      type: 'warning',
      title: 'Вы уверены?',
      html: 'Очистить кеш? Это действие нельзя будет отменить',
    }).then((result) => {
      if (!result.value) return;

      callback();
    });
  };

  applyToggleGroupListener = (group) => (value) => (
    this.setState((prev) => ({ ...prev, groups: { ...prev.groups, [group]: value } }))
  );

  setIsFetching = (value) => {
    if (!this.isUnmounted.current) this.setState({ isFetching: value });
  };

  handleChangeUrl = (e) => {
    const { value } = e.target;

    this.setState({ url: value.trim() });
  };

  handleClearAllCache = () => {
    const { onClearAllCache } = this.props;

    const requestClearAllCacheCanceler = requestCanceler();

    this.requestCancelers.push(requestClearAllCacheCanceler);

    const clearAllCache = () => {
      this.setIsFetching(true);

      onClearAllCache(requestClearAllCacheCanceler.token)
        .then(({ deletedCount }) => {
          this.initGroups();

          toast('success', `Кеш очищен успешно! Количество очищенных запросов: ${deletedCount} `);
        })
        .catch(({ message }) => toast('error', errorMessages[message] || 'Что-то пошло не так, попробуйте позже'))
        .finally(() => this.setIsFetching(false));
    };

    this.showAlert(clearAllCache);
  };

  handleClearCache = (e) => {
    e.preventDefault();

    const { onClearCache } = this.props;
    const { url, groups } = this.state;

    const requestClearCacheCanceler = requestCanceler();

    this.requestCancelers.push(requestClearCacheCanceler);

    const clearCache = () => {
      const checkedGroups = Object.keys(groups).filter((g) => groups[g]);

      this.setIsFetching(true);

      onClearCache({ url, groups: checkedGroups }, requestClearCacheCanceler.token)
        .then(({ deletedCount }) => {
          this.initGroups();

          toast('success', `Кеш очищен успешно! Количество очищенных запросов: ${deletedCount} `);
        })
        .catch(({ message }) => toast('error', errorMessages[message] || 'Что-то пошло не так, попробуйте позже'))
        .finally(() => this.setIsFetching(false));
    };

    this.showAlert(clearCache);
  };

  renderContent = () => {
    const { isFetching, url, groups } = this.state;

    const checkedGroups = Object.keys(groups).filter((g) => groups[g]);

    return (
      <div className="apicache">
        <div className="apicache__info">Можно очистить весь кеш, либо задать url или группу, чтобы очистить кеш частично. Для этого воспользуйтесь вкладками</div>

        <Tabs
          defaultActiveKey="particular"
          id="cache-tabs"
          className="apicache__tabs mb-3"
        >
          <Tab tabClassName="apicache__tab" eventKey="all" title="Весь кеш">
            <div className="apicache__actions">
              <Button disabled={isFetching} onClick={this.handleClearAllCache}>
                Очистить весь кеш
              </Button>
              {isFetching && <Preloader inline medium />}
            </div>
          </Tab>

          <Tab tabClassName="apicache__tab" eventKey="particular" title="Конкретный кеш">
            <Form className="apicache__form" onSubmit={this.handleClearCache}>
              <div className="apicache__form-title">Очистить по конкретному url</div>
              <div className="apicache__form-field">
                <div className="apicache__form-label">URL запроса:</div>
                <Form.Control
                  className="apicache__form-input"
                  value={url}
                  placeholder="Например: /library/lotties?page=1&category=recent"
                  disabled={isFetching}
                  onChange={this.handleChangeUrl}
                />
              </div>

              <div className="apicache__form-title">Очистить по группам</div>
              {Object.keys(groups).map((group) => (
                <div className="apicache__form-field" key={group}>
                  <div className="apicache__form-label apicache__form-label_wide">{group}</div>
                  <CheckBox
                    checked={groups[group]}
                    disabled={isFetching}
                    className="flat"
                    id={group}
                    onChange={this.applyToggleGroupListener(group)}
                  />
                </div>
              ))}

              <div className="apicache__actions">
                <Button disabled={isFetching || (checkedGroups.length === 0 && !url)} type="submit" onClick={this.handleClearCache}>
                  Очистить кеш
                </Button>
                {isFetching && <Preloader inline medium />}
              </div>
            </Form>
          </Tab>
        </Tabs>
      </div>
    );
  };

  render() {
    return (
      <View
        title="Api кеш"
        viewClass="apicache"
        content={this.renderContent()}
      />
    );
  }
}

Apicache.propTypes = {
  onClearCache: PropTypes.func.isRequired,
  onClearAllCache: PropTypes.func.isRequired,
  // onGetCachedGroups: PropTypes.func.isRequired,
};

export default Apicache;
