import {
  GET_TAGS,
  RESET_TAGS,
  UPDATE_TAG,
  DELETE_TAG,
} from '../actions';

const defaultState = {
  error: null,
  isFetching: false,
  docs: [],
  lastActionType: null,
  page: 0,
  pages: 1,
};

function returnError(response, state) {
  if (response.message && state.page === 0) return { message: 'first_request_failed' };
  if (response.message) return response;

  return { message: 'admin_privileges_required' };
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case `${GET_TAGS}_REQUEST`:
      return {
        ...state,
        docs: [],
        error: null,
        isFetching: true,
        lastActionType: action.type,
      };
    case `${UPDATE_TAG}_REQUEST`:
    case `${DELETE_TAG}_REQUEST`:
      return {
        ...state,
        error: null,
        isFetching: true,
        lastActionType: action.type,
      };
    case GET_TAGS:
      return {
        ...state,
        ...action.response,
        docs: action.response.docs,
        isFetching: false,
        lastActionType: null,
      };
    case UPDATE_TAG:
      return {
        ...state,
        docs: state.docs.map((item) => {
          if (item._id !== action.response._id) return item;

          return {
            ...item,
            ...action.response,
          };
        }),
        isFetching: false,
      };
    case DELETE_TAG:
      return {
        ...state,
        docs: state.docs.filter((doc) => doc._id !== action.payload.id),
        isFetching: false,
      };
    case `${GET_TAGS}_FAILURE`:
      return {
        ...state,
        error: returnError(action.response, state),
        isFetching: false,
      };
    case `${UPDATE_TAG}_FAILURE`:
    case `${DELETE_TAG}_FAILURE`:
      return {
        ...state,
        error: action.response,
        isFetching: false,
      };
    case RESET_TAGS:
      return defaultState;
    default:
      return state;
  }
};
