import { UPDATE_LANDING_FORM, RESET_LANDING_FORM, INIT_LANDING_FORM } from '../../../actions';

import { PREFIX_ACTION_TYPE_LANDING_FORM } from '../../../constants/common';

export const defaultFormState = {
  // Shared fields
  name: '',
  alias: '',
  // Category form field
  side_menu_id: undefined,
  show_headers: false,
  show_page_navigation: false,
  // Page form fields
  is_main: false,
  data_provider: 'tilda',
  data_provider_resource: {
    project_id: '',
    page_id: '',
  },
  status: 'public', // stage_only
  category_id: '',
  seo: {
    title: '',
    description: '',
    keywords: '',
  },
};

const getFullType = (type) => PREFIX_ACTION_TYPE_LANDING_FORM + type;

export default (state, action) => {
  switch (action.type) {
    case getFullType(INIT_LANDING_FORM):
      return {
        ...state,
        isFormVisible: true,
      };
    case getFullType(UPDATE_LANDING_FORM):
      return {
        ...state,
        form: { ...state.form, ...action.payload },
      };
    case getFullType(RESET_LANDING_FORM):
      return {
        ...state,
        form: defaultFormState,
        isFormVisible: false,
      };
    default:
      return state;
  }
};
