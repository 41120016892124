import React, { useState } from 'react';
import PropTypes from 'prop-types';

import DatePicker from '../../../UI/DatePicker/DatePicker';

const CompaniesActPopup = ({ defaultDate, onChange }) => {
  const [actDate, setActDate] = useState({
    from: new Date(defaultDate.from),
    to: new Date(defaultDate.to),
  });

  return (
    <div className="companies-popup__datepicker">
      <span>c</span>
      <DatePicker
        value={actDate.from}
        onChange={(from) => {
          setActDate({
            ...actDate,
            from,
          });

          onChange({
            ...actDate,
            from,
          });
        }}
      />
      <span>по</span>
      <DatePicker
        value={actDate.to}
        onChange={(to) => {
          setActDate({
            ...actDate,
            to,
          });

          onChange({
            ...actDate,
            to,
          });
        }}
      />
    </div>
  );
};

CompaniesActPopup.propTypes = {
  defaultDate: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

export default CompaniesActPopup;
