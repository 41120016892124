import {
  useCallback, useEffect, useLayoutEffect, useMemo, useReducer, useRef,
} from 'react';
import debounce from 'lodash/debounce';

export default function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
}

export function useDidUpdateEffect(fn, deps = []) {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) {
      fn();

      return;
    }
    didMountRef.current = true;
    // eslint-disable-next-line
  }, deps);
}

export function useMount(callback) {
  // eslint-disable-next-line
  useEffect(callback, []);
}

export function useUnmount(callback) {
  // eslint-disable-next-line
  useEffect(() => callback, []);
}

export function useSetState(initState) {
  const [state, setState] = useReducer(
    (current, update) => ({ ...current, ...update }),
    initState,
  );

  return [state, setState];
}

export function useEvent(fn) {
  const fnRef = useRef(fn);

  useLayoutEffect(() => {
    fnRef.current = fn;
  }, [fn]);

  const eventCb = useCallback((...args) => fnRef.current.apply(null, args), [fnRef]);

  return eventCb;
}

export function useDebounce(fn, ms) {
  const memoizedFn = useEvent(fn);

  const debouncedFn = useMemo(
    () => (
      debounce((...args) => {
        memoizedFn(...args);
      }, ms)
    ),
    [ms, memoizedFn],
  );

  useEffect(() => () => {
    debouncedFn.cancel();
  }, [debouncedFn]);

  return debouncedFn;
}

export function useClickOutside(cb, allowedSelectors = []) {
  const ref = useRef(null);
  const refCb = useEvent(cb);
  const refSelectors = useRef(allowedSelectors || []);

  useEffect(() => {
    const handler = (e) => {
      const element = ref.current;

      if (!element || element.contains(e.target)) {
        return;
      }

      if (Array.isArray(refSelectors.current)) {
        for (let i = 0; i < refSelectors.current.length; i += 1) {
          if ((e.target)?.closest(refSelectors.current[i])) {
            return;
          }
        }
      }

      if (typeof refCb === 'function') refCb(e);
    };

    document.addEventListener('mousedown', handler);
    document.addEventListener('touchstart', handler);

    return () => {
      document.removeEventListener('mousedown', handler);
      document.removeEventListener('touchstart', handler);
    };
  }, [refCb]);

  return ref;
}

export function useCachedListener(fn) {
  const handlers = useRef({});
  const refCb = useEvent(fn);

  const listener = useCallback((setterName, ...args) => {
    if (!Object.prototype.hasOwnProperty.call(handlers.current, setterName)) {
      handlers.current[setterName] = (value) => refCb(setterName, value, ...args);
    }

    return handlers.current[setterName];
  }, [refCb]);

  return listener;
}
