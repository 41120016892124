import dayjs from 'dayjs';
import React from 'react';
import WebFont from 'webfontloader';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import { store, history } from './store';

import AppContainer from './components/App/AppContainer';

import 'dayjs/locale/ru';

import './fontawesome';

import './styles/main.scss';

WebFont.load({
  google: {
    families: ['Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i:cyrillic,cyrillic-ext'],
  },
});

dayjs.locale('ru');

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <AppContainer />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('app'),
);
