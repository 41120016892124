import { connect } from 'react-redux';

import {
  RESET_IDEAS,
  createIdea,
  getIdea,
  getIdeas,
  updateIdea,
} from '../../../actions/ideas';

import Ideas from './Ideas';

const mapStateToProps = (state) => ({
  ideas: state.ideas,
});

const mapDispatchToProps = (dispatch) => ({
  onCreateIdea: (data, requestToken) => dispatch(createIdea(data, requestToken)),
  onGetIdeas: (data, isSearch, requestToken) => dispatch(getIdeas(data, isSearch, requestToken)),
  onGetIdea: (id, requestToken) => dispatch(getIdea(id, requestToken)),
  onUpdateIdea: (id, data, requestToken) => dispatch(updateIdea(id, data, requestToken)),
  onUnload: () => dispatch({ type: RESET_IDEAS }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Ideas);
