export const GET_USERS = 'GET_USERS';
export const RESET_USERS = 'RESET_USERS';
export const UPDATE_USER = 'UPDATE_USER';
export const CHANGE_USER_PERIOD_DATE = 'CHANGE_USER_PERIOD_DATE';
export const CREATE_INVOICE = 'CREATE_INVOICE';
export const USER_SIGNIN = 'USER_SIGNIN';
export const UNBIND_USER_OAUTH = 'UNBIND_USER_OAUTH';
export const DELETE_USER_PERIOD = 'DELETE_USER_PERIOD';
export const DELETE_USER_PERIOD_MIGRATION_ID = 'DELETE_USER_PERIOD_MIGRATION_ID';
export const ADD_USER_VIDEO = 'ADD_USER_VIDEO';
export const USER_GET_LOGS = 'USER_GET_LOGS';
export const USER_ADD_LOGS = 'USER_ADD_LOGS';

/* --------------- */
/* Action creators */

/* --------------- */

export function getElamaUsers(params, isSearch, requestToken) {
  return {
    type: GET_USERS,
    request: {
      method: 'get',
      url: '/admin/elama-users',
      params,
      requestToken,
    },
    payload: { isSearch },
  };
}

export function getUsers(params, isSearch, requestToken) {
  return {
    type: GET_USERS,
    request: {
      method: 'get',
      url: '/admin/users',
      params,
      requestToken,
    },
    payload: { isSearch },
  };
}

export function updateUser(id, data, requestToken) {
  return {
    type: UPDATE_USER,
    request: {
      method: 'put',
      url: `/admin/users/${id}`,
      data,
      requestToken,
    },
  };
}

export function createInvoice(params, requestToken) {
  return {
    type: CREATE_INVOICE,
    request: {
      method: 'post',
      url: '/admin/invoices/',
      params,
      requestToken,
    },
  };
}

export function elamaUserSignIn(data, requestToken) {
  return {
    type: USER_SIGNIN,
    request: {
      method: 'post',
      url: '/admin/elama-users/auth_link/',
      data,
      requestToken,
    },
  };
}

export function userSignIn(data, requestToken) {
  return {
    type: USER_SIGNIN,
    request: {
      method: 'post',
      url: '/admin/users/auth_link/',
      data,
      requestToken,
    },
  };
}

export function userUnbindOauth(id, requestToken) {
  return {
    type: UNBIND_USER_OAUTH,
    request: {
      method: 'post',
      url: `/admin/users/${id}/unbind-oauth`,
      requestToken,
    },
    payload: { id },
  };
}

export function deletePeriod(id, periodId, requestToken) {
  return {
    type: DELETE_USER_PERIOD,
    request: {
      method: 'delete',
      url: `/admin/periods/${periodId}`,
      requestToken,
    },
    payload: { id, periodId },
  };
}

export function changePeriodDate(userId, periodId, data, requestToken) {
  return {
    type: CHANGE_USER_PERIOD_DATE,
    request: {
      data,
      method: 'put',
      url: `/admin/periods/${periodId}`,
      requestToken,
    },
    payload: { userId, periodId },
  };
}

export function removePeriodMigrationID(id, periodId, requestToken) {
  return {
    type: DELETE_USER_PERIOD_MIGRATION_ID,
    request: {
      method: 'put',
      url: `/admin/periods/${periodId}`,
      data: {
        migration_service_id: null,
      },
      requestToken,
    },
    payload: { id, periodId },
  };
}

export function addVideo(data, requestToken) {
  return {
    type: ADD_USER_VIDEO,
    request: {
      method: 'post',
      url: '/admin/videos/transfer',
      data,
      requestToken,
    },
  };
}

export function fetchUserLogs(id, params, requestToken) {
  return {
    type: USER_GET_LOGS,
    request: {
      method: 'get',
      url: `/admin/users/${id}/logs`,
      params,
      requestToken,
    },
    payload: { id },
  };
}

function addUserLogs(data) {
  return {
    type: USER_ADD_LOGS,
    payload: data,
  };
}

/* ------ */
/* Thunks */

/* ------ */

export function getUserLogs(id, params, requestToken) {
  return (dispatch) => dispatch(fetchUserLogs(id, params, requestToken))
    .then((response) => dispatch(addUserLogs({ id, logs: response })))
    .catch(() => {});
}
