import {
  GET_USERS,
  RESET_USERS,
  UPDATE_USER,
  CREATE_INVOICE,
  USER_SIGNIN,
  DELETE_USER_PERIOD,
  DELETE_USER_PERIOD_MIGRATION_ID,
  CHANGE_USER_PERIOD_DATE,
  ADD_USER_VIDEO,
  USER_GET_LOGS,
  USER_ADD_LOGS,
  UNBIND_USER_OAUTH,
} from '../actions';

const defaultState = {
  error: null,
  isFetching: false,
  docs: [],
  lastActionType: null,
  page: 0,
  pages: 1,
};

function returnError(response, state) {
  if (response.message && state.page === 0) return { message: 'first_request_failed' };
  if (response.message) return response;

  return { message: 'admin_privileges_required' };
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case `${GET_USERS}_REQUEST`:
      return {
        ...state,
        docs: action.payload.isSearch ? [] : state.docs,
        error: null,
        isFetching: true,
        lastActionType: action.type,
      };
    case `${UPDATE_USER}_REQUEST`:
    case `${UNBIND_USER_OAUTH}_REQUEST`:
    case `${DELETE_USER_PERIOD}_REQUEST`:
    case `${CHANGE_USER_PERIOD_DATE}_REQUEST`:
    case `${DELETE_USER_PERIOD_MIGRATION_ID}_REQUEST`:
      return {
        ...state,
        error: null,
        lastActionType: action.type,
      };
    case `${USER_GET_LOGS}_REQUEST`:
      return {
        ...state,
        docs: state.docs.map((item) => {
          if (item.id !== action.payload.id) return item;

          return {
            ...item,
            logsIsFetching: true,
          };
        }),
        error: null,
        lastActionType: action.type,
      };
    case GET_USERS:
      return {
        ...state,
        ...action.response,
        docs: action.response.page === 1
          ? action.response.docs
          : [...state.docs, ...action.response.docs],
        isFetching: false,
        lastActionType: null,
      };
    case UPDATE_USER:
      return {
        ...state,
        docs: state.docs.map((item) => {
          if (item.id !== action.response.id) return item;

          return {
            ...item,
            ...action.response,
          };
        }),
      };
    case DELETE_USER_PERIOD:
      return {
        ...state,
        docs: state.docs.map((item) => {
          if (item.id !== action.payload.id) return item;

          return {
            ...item,
            periods: item.periods.filter((period) => period.id !== action.payload.periodId),
          };
        }),
      };
    case DELETE_USER_PERIOD_MIGRATION_ID:
      return {
        ...state,
        docs: state.docs.map((item) => {
          if (item.id !== action.payload.id) return item;

          return {
            ...item,
            periods: item.periods.map((period) => {
              if (period.id !== action.payload.periodId) return period;

              return {
                ...period,
                migration_service_id: null,
              };
            }),
          };
        }),
      };
    case CHANGE_USER_PERIOD_DATE:
      return {
        ...state,
        docs: state.docs.map((item) => {
          if (item.id !== action.payload.userId) return item;

          return {
            ...item,
            periods: item.periods.map((period) => {
              if (period.id !== action.payload.periodId) return period;

              return {
                ...period,
                ...action.response,
              };
            }),
          };
        }),
      };
    case USER_ADD_LOGS:
      return {
        ...state,
        docs: state.docs.map((item) => {
          if (item.id !== action.payload.id) return item;

          if (item.logs) {
            return {
              ...item,
              logsIsFetching: false,
              logs: {
                ...action.payload.logs,
                docs: [...item.logs.docs, ...action.payload.logs.docs],
              },
            };
          }

          return {
            ...item,
            logsIsFetching: false,
            logs: action.payload.logs,
          };
        }),
      };
    case UNBIND_USER_OAUTH:
      return {
        ...state,
        docs: state.docs.map((item) => {
          if (item.id !== action.payload.id) return item;

          return {
            ...item,
            oauth_provider: undefined,
            oauth_provider_email: undefined,
            oauth_provider_userid: undefined,
          };
        }),
      };
    case `${GET_USERS}_FAILURE`:
      return {
        ...state,
        error: returnError(action.response, state),
        isFetching: false,
      };
    case `${UPDATE_USER}_FAILURE`:
    case `${CREATE_INVOICE}_FAILURE`:
    case `${USER_SIGNIN}_FAILURE`:
    case `${DELETE_USER_PERIOD}_FAILURE`:
    case `${DELETE_USER_PERIOD_MIGRATION_ID}_FAILURE`:
    case `${CHANGE_USER_PERIOD_DATE}_FAILURE`:
    case `${ADD_USER_VIDEO}_FAILURE`:
    case `${UNBIND_USER_OAUTH}_FAILURE`:
      return {
        ...state,
        error: action.response,
      };
    case `${USER_GET_LOGS}_FAILURE`:
      return {
        ...state,
        docs: state.docs.map((item) => {
          if (item.id !== action.payload.id) return item;

          return {
            ...item,
            logsIsFetching: false,
          };
        }),
        error: action.response,
      };
    case RESET_USERS:
      return defaultState;
    default:
      return state;
  }
};
