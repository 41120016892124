import { connect } from 'react-redux';

import {
  RESET_TAGS,
  deleteTag,
  getTags,
  updateTag,
} from '../../../actions';

import Tags from './Tags';

const mapStateToProps = (state) => ({
  tags: state.tags,
});

const mapDispatchToProps = (dispatch) => ({
  onGetTags: (params, requestToken) => dispatch(getTags(params, requestToken)),
  onDelete: (id, params, requestToken) => dispatch(deleteTag(id, params, requestToken)),
  onUpdateTag: (id, data, requestToken) => dispatch(updateTag(id, data, requestToken)),
  onUnload: () => dispatch({ type: RESET_TAGS }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Tags);
