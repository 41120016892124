import { connect } from 'react-redux';

import {
  RESET_PARTNERS,
  getPartners,
  makePayout,
  updateUser,
} from '../../../../actions';

import Partners from './Partners';

const mapStateToProps = (state) => ({
  partners: state.partners,
});

const mapDispatchToProps = (dispatch) => ({
  onChangeUser: (id, data, requestToken) => dispatch(updateUser(id, data, requestToken)),
  onGetPartners: (params, isSearch, requestToken) => dispatch(getPartners(params, isSearch, requestToken)),
  onMakePayout: (id, sum, requestToken) => dispatch(makePayout(id, sum, requestToken)),
  onUnload: () => dispatch({ type: RESET_PARTNERS }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Partners);
