import {
  CHANGE_PASS,
  PROFILE,
} from '../actions';

const defaultState = {
  error: null,
  isFetching: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case `${CHANGE_PASS}_REQUEST`:
      return {
        ...state,
        error: null,
        isFetching: true,
      };
    case CHANGE_PASS:
      return {
        ...state,
        error: null,
        isFetching: false,
        response: action.response,
      };
    case `${CHANGE_PASS}_FAILURE`:
      return {
        ...state,
        error: action.response,
        isFetching: false,
      };
    case `${PROFILE}_PAGE_UNLOADED`:
      return defaultState;
    default:
      return state;
  }
};
