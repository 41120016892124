export const CREATE_OBJECT_CATEGORY = 'CREATE_OBJECT_CATEGORY';
export const DELETE_OBJECT_CATEGORY = 'DELETE_OBJECT_CATEGORY';
export const GET_OBJECT_CATEGORIES = 'GET_OBJECT_CATEGORIES';
export const GET_OBJECT_CATEGORY = 'GET_OBJECT_CATEGORY';
export const RESET_OBJECT_CATEGORIES = 'RESET_OBJECT_CATEGORIES';
export const UPDATE_OBJECT_CATEGORY = 'UPDATE_OBJECT_CATEGORY';

/* --------------- */
/* Action creators */

/* --------------- */

export function getObjectCategories(params, requestToken) {
  return {
    type: GET_OBJECT_CATEGORIES,
    request: {
      method: 'get',
      url: '/admin/object_categories',
      params,
      requestToken,
    },
  };
}

export function getObjectCategory(id, requestToken) {
  return {
    type: GET_OBJECT_CATEGORY,
    request: {
      method: 'get',
      url: `/admin/object_categories/${id}`,
      params: { id },
      requestToken,
    },
  };
}

export function createObjectCategory(data, requestToken) {
  return {
    type: CREATE_OBJECT_CATEGORY,
    request: {
      method: 'post',
      url: '/admin/object_categories',
      data,
      requestToken,
    },
  };
}

export function deleteObjectCategory(id, requestToken) {
  return {
    type: DELETE_OBJECT_CATEGORY,
    request: {
      method: 'delete',
      url: `/admin/object_categories/${id}`,
      requestToken,
    },
    payload: { id },
  };
}

export function updateObjectCategory(data, requestToken) {
  return {
    type: UPDATE_OBJECT_CATEGORY,
    request: {
      method: 'patch',
      url: `/admin/object_categories/${data._id}`,
      data,
      requestToken,
    },
    payload: { id: data._id },
  };
}
