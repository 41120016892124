import PropTypes from 'prop-types';
import React from 'react';
import queryString from 'query-string';
import { Button, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import errorMessages from '../../../../constants/errors';

import { requestCanceler } from '../../../../middlewares/api';

import { toast } from '../../../../utils';

import View from '../../../Layout/View/View';

import sweetAlert from '../../../HOCs/sweetAlert';

import Pagination from '../../../Includes/Pagination/Pagination';

import NoMedia from '../../../UI/NoMedia/NoMedia';
import Preloader from '../../../UI/Preloader/Preloader';

import TemplatesSearch from './TemplatesSearch/TemplatesSearch';
import TemplatesTemplate from './TemplatesTemplate/TemplatesTemplate';

import TemplatesBreadcrumbs from './TemplatesBreadcrumbs/TemplatesBreadcrumbs';

import './Templates.scss';

class Templates extends React.PureComponent {
  requestCancelers = [];

  limits = [20, 40, 60, 80, 100];

  componentDidMount() {
    const { location, templates, onGetCategories } = this.props;

    if (!templates.docs.length) {
      const requestGetCategoriesCanceler = requestCanceler();

      this.requestCancelers.push(requestGetCategoriesCanceler);

      this.getTemplates({ page: 1 });

      const lang = queryString.parse(location.search)?.lang || 'ru';
      onGetCategories({ all: 1, service: 1, lang }, false, requestGetCategoriesCanceler.token);
    }
  }

  componentDidUpdate(prevProps) {
    const { templates, location } = this.props;
    const { error } = templates;

    if (location.search !== prevProps.location.search) {
      this.getTemplates({});
    }

    if (error && error.message && error !== prevProps.templates.error) {
      const message = ['Network Error', 'permission_denied', 'server_error', 'incorrect_data', 'video_not_exist', 'payment_failed', 'notification_failed'].includes(error.message)
        ? errorMessages[error.message]
        : error.message;

      toast('error', message);
    }
  }

  componentWillUnmount() {
    const { onUnload } = this.props;

    this.requestCancelers.forEach((canceler) => canceler.cancelRequest());

    onUnload();
  }

  getTemplates = ({ page = 1 }) => {
    const {
      autoCheckVariations, addCategoryTemplatesCount, onGetTemplates, match: { params: { categoryId } }, location, templates,
    } = this.props;

    const requestParams = {
      category_id: categoryId,
      // data: 1,
      limit: templates.limit,
      page,
      // show_hidden: 1,
    };

    if (location.search) {
      Object.assign(requestParams, queryString.parse(location.search));
    }

    // const isSearch = Boolean(queryString.parse(location.search)?.query || queryString.parse(location.search)?.search_type);

    const requestGetTemplatesCanceler = requestCanceler();

    this.requestCancelers.push(requestGetTemplatesCanceler);

    onGetTemplates(requestParams, requestGetTemplatesCanceler.token)
      .then((res) => addCategoryTemplatesCount(+categoryId, res.total, true))
      .then(() => autoCheckVariations(null));
  };

  handleSearchTemplates = ({ query, search_type }) => {
    const { history, location, match: { params: { categoryId } } } = this.props;

    const lang = queryString.parse(location.search)?.lang || 'ru';

    const queryStr = query ? `/templates/categories/${categoryId}?query=${query}&search_type=${search_type}&lang=${lang}` : `/templates/categories/${categoryId}&lang=${lang}`;

    if (queryString.parse(location.search)?.query === query && queryString.parse(location.search)?.search_type === search_type) {
      this.getTemplates({});
    } else {
      history.push(queryStr);
    }
  };

  handleChangePage = (page, limit = this.props.templates.limit) => {
    const {
      templates, history, location, match: { params: { categoryId } },
    } = this.props;

    if (templates.isFetching) return;

    const requestParams = location.search ? queryString.parse(location.search) : {};

    Object.assign(requestParams, { page, limit });

    const queryStr = `/templates/categories/${categoryId}?${queryString.stringify(requestParams)}`;

    history.push(queryStr);
  };

  handleChangeLimit = (value) => {
    const { templates } = this.props;
    const { page, total } = templates;

    const nextPagesCount = Math.ceil(total / value);
    const nextPage = page <= nextPagesCount ? page : nextPagesCount;

    this.handleChangePage(nextPage, value);
  };

  handleCreateTemplate = () => {
    const { addCategoryTemplatesCount, match: { params: { categoryId } }, onCreateTemplate } = this.props;

    sweetAlert.fire({
      cancelButtonText: 'Отмена',
      confirmButtonText: 'Добавить',
      icon: null,
      showCancelButton: true,
      title: 'Добавить шаблон',
      html: 'Введите название:',
      input: 'text',
      // eslint-disable-next-line
      inputValidator: (value) => {
        if (!value) return 'Название не может быть пустым';
      },
    }).then((result) => {
      if (!result.value) return;

      const requestCreateTemplateCanceler = requestCanceler();

      this.requestCancelers.push(requestCreateTemplateCanceler);

      onCreateTemplate({ title: result.value, category_id: categoryId }, requestCreateTemplateCanceler.token)
        .then(() => {
          addCategoryTemplatesCount(+categoryId, 1);

          toast('success', 'Шаблон успешно создан');
        });
    });
  };

  renderPagination = ({
    page, pages, total, limit, isFetching, onChange,
  }) => (
    <Pagination
      currentPage={page}
      disabled={isFetching}
      pages={pages}
      total={total}
      limit={limit}
      onChange={onChange}
    />
  );

  render() {
    const {
      categories, location, templates, match: { params: { categoryId } },
    } = this.props;
    const {
      docs, error, isFetching, limit, page, pages, total, isTemplateChanging,
    } = templates;

    return (
      <View
        errorMessage={errorMessages[error?.message]}
        isError={error && ['admin_privileges_required'].includes(error.message)}
        title="Шаблоны"
        viewClass="templates"
        content={(
          <>
            <TemplatesBreadcrumbs
              lang={queryString.parse(location.search)?.lang}
              category={categories.find((item) => item.id === +categoryId)}
            />
            <Button disabled={isFetching || isTemplateChanging.create} onClick={this.handleCreateTemplate}>
              Добавить шаблон
            </Button>
            <TemplatesSearch onSearchTemplates={this.handleSearchTemplates} />
            {this.renderPagination({
              page, pages, total, limit, isFetching, onChange: (val) => this.handleChangePage(val),
            })}
            {docs.length ? (
              <div className="templates__list">
                {docs.map((template) => (
                  <div className="templates__list-template" key={template.id}>
                    <TemplatesTemplate
                      data={template}
                      categories={categories}
                      isTemplateChanging={isTemplateChanging}
                    />
                  </div>
                ))}
              </div>
            ) : (
              !isFetching && (
                <NoMedia
                  caption={location.search ? 'Шаблоны не найдены' : 'Нет шаблонов в категории'}
                  icon={<FontAwesomeIcon icon={['fas', 'exclamation-circle']} />}
                />
              )
            )}
            {isFetching && (
              <div className="templates__list-preloader">
                <Preloader caption="Загружаем список..." />
              </div>
            )}
            {this.renderPagination({
              page, pages, total, limit, isFetching, onChange: (val) => this.handleChangePage(val),
            })}

            <Dropdown className="templates__list-limit" drop="up">
              <Dropdown.Toggle as="button" className="btn btn-light" disabled={isFetching}>
                Шаблонов на страницу:&nbsp;
                {limit}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {this.limits.map((item) => (
                  <Dropdown.Item
                    as="button"
                    eventKey={item}
                    key={item}
                    disabled={item === limit}
                    onSelect={this.handleChangeLimit}
                  >
                    {item}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>

            <Button disabled={isFetching || isTemplateChanging.create} onClick={this.handleCreateTemplate}>
              Добавить шаблон
            </Button>
          </>
        )}
      />
    );
  }
}

Templates.propTypes = {
  autoCheckVariations: PropTypes.func.isRequired,
  addCategoryTemplatesCount: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  templates: PropTypes.object.isRequired,
  onCreateTemplate: PropTypes.func.isRequired,
  onGetCategories: PropTypes.func.isRequired,
  onGetTemplates: PropTypes.func.isRequired,
  onUnload: PropTypes.func.isRequired,
};

export default Templates;
