import React, {
  memo,
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { diff } from 'deep-object-diff';
import { isEqual } from 'lodash';

import {
  getLandingPageCategories,
  getPage,
  getTildaPages,
  initLandingForm,
  resetLandingForm,
  updateLandingForm,
  updatePage,
} from '../../../../../actions';

import withRequest from '../../../../HOCs/withRequest';

import { toast } from '../../../../../utils';

import PageFormContent from '../PageFormContent/PageFormContent';

const PageFormEdit = memo((props) => {
  const { dispatch, createCanceler, lang } = props;

  const params = useParams();

  const history = useHistory();

  const data = useSelector(({ landing }) => landing.form);
  const categories = useSelector(({ landing }) => landing.categories.docs);
  const isFetching = useSelector(({ landing }) => landing.isFetching);

  const [isPagesFetching, setIsPagesFetching] = useState(false);
  const [providerProjects, setProviderProjects] = useState(null);
  const [page, setPage] = useState(null);

  const isUnmounted = useRef(false);

  const projectPages = useMemo(() => {
    if (!providerProjects?.length || !data?.data_provider_resource?.project_id) return [];

    return providerProjects.find((pr) => pr.id === data?.data_provider_resource?.project_id).pages || [];
  }, [providerProjects, data?.data_provider_resource?.project_id]);

  const handleUpdateForm = useCallback((updateData) => dispatch(updateLandingForm(updateData)), [dispatch]);

  const fetchProviderPages = useCallback(() => {
    const request = createCanceler();

    setIsPagesFetching(true);

    dispatch(getTildaPages(request.token))
      .then((response) => setProviderProjects(response))
      .catch(() => toast('error', 'Ошибка получения информации от провайдера. Попробуйте еще раз'))
      .finally(() => {
        if (!isUnmounted.current) setIsPagesFetching(false);
      });
  }, [lang]); // eslint-disable-line

  const fetchPage = useCallback(() => {
    const getPageReq = createCanceler();

    dispatch(getPage(params?.id, getPageReq.token))
      .then((response) => {
        const { _id, ...pageData } = response;

        handleUpdateForm(pageData);
        setPage({ ...pageData, ...response.category_id ? {} : { category_id: '' } });
      })
      .catch(() => toast('error', 'Ошибка получения страниц. Попробуйте позже'));
  }, [createCanceler, dispatch, params?.id, handleUpdateForm]);

  const fetchPageCategories = useCallback(() => {
    const getPageCategoriesReq = createCanceler();

    dispatch(getLandingPageCategories({ lang }, getPageCategoriesReq.token))
      .catch(() => toast('error', 'Ошибка получения категорий страниц. Попробуйте позже'));
  }, [lang, createCanceler, dispatch]);

  const handleResponseError = ({ message }) => {
    if (message === 'main_page_already_exist') return toast('error', 'Главная страница для данного языка уже выбрана');
    if (message === 'page_already_exist') return toast('error', 'Эта страница Тильды уже используется');
    if (message === 'alias_already_exist') return toast('error', 'Страница с таким алиасом в этой категории уже существует');

    return toast('error', 'Произошла ошибка. Попробуйте позже');
  };

  const handleSubmitForm = useCallback((e) => {
    e.preventDefault();

    if (!params?.id) return;

    const {
      side_menu_id, show_headers, show_page_navigation, ...updateRestData
    } = data;

    const updateData = diff(page, updateRestData);

    if ('is_main' in updateData) {
      updateData.alias = updateData.is_main ? '' : updateRestData.alias;
      updateData.category_id = updateData.is_main ? '' : updateRestData.category_id;
    }

    dispatch(updatePage(params?.id, updateData))
      .then(() => {
        toast('success', 'Страница успешно обновлена!');

        history.push('/landing/pages');
      })
      .catch(handleResponseError);
  }, [page, dispatch, data, history, params?.id]);

  useEffect(() => {
    dispatch(initLandingForm());

    return () => {
      isUnmounted.current = true;

      dispatch(resetLandingForm());
    };
  }, [dispatch]);

  useEffect(() => {
    fetchProviderPages();
    fetchPage();
    fetchPageCategories();
  }, [fetchProviderPages, fetchPage, fetchPageCategories]);

  // Reset chosen project and page if server returned another info
  useEffect(() => {
    if (!data?.data_provider_resource?.project_id || !providerProjects) return;

    const chosenProject = providerProjects?.find((pr) => pr.id === data?.data_provider_resource?.project_id);
    const chosenPage = projectPages?.find((pr) => pr.id === data?.data_provider_resource?.page_id);

    if (!chosenProject || (!chosenPage && data?.data_provider_resource?.page_id)) {
      dispatch(updateLandingForm({
        data_provider_resource: { project_id: '', page_id: '' },
      }));
    }
  }, [data?.data_provider_resource, projectPages, dispatch, providerProjects]); // eslint-disable-line

  const {
    side_menu_id, show_headers, show_page_navigation, ...updateData
  } = data;

  const isFormDisabled = isFetching
    || isPagesFetching
    || !data?.name
    || (!data?.alias && !data.is_main)
    || !data?.data_provider_resource?.project_id
    || !data?.data_provider_resource?.page_id
    || isEqual(page, updateData);

  return (
    <PageFormContent
      isFormDisabled={isFormDisabled}
      isFetching={isFetching}
      isPagesFetching={isPagesFetching}
      data={data}
      mode="edit"
      providerProjects={providerProjects}
      categories={categories}
      projectPages={projectPages}
      onSubmit={handleSubmitForm}
      onSyncProviderPages={fetchProviderPages}
      onUpdate={handleUpdateForm}
    />
  );
});

PageFormEdit.propTypes = {
  createCanceler: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  lang: PropTypes.string,
};

export default withRequest(PageFormEdit);
