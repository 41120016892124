import { connect } from 'react-redux';

import { clearAllCache, clearCache } from '../../../actions';

import Apicache from './Apicache';

const mapDispatchToProps = (dispatch) => ({
  onClearCache: (data, requestToken) => dispatch(clearCache(data, requestToken)),
  onClearAllCache: (requestToken) => dispatch(clearAllCache(requestToken)),
});

export default connect(null, mapDispatchToProps)(Apicache);
