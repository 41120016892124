import React, { memo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getAdminIPAddresses, deleteAdminIPAddress, RESET_ACCESS_IP_ADDRESSES } from '../../../../actions';

import errorMessages from '../../../../constants/errors';
import { DELETE_ALERT_CONFIG, LIMIT_PER_PAGE } from '../../../../constants/access';

import { toast } from '../../../../utils';

import sweetAlert from '../../../HOCs/sweetAlert';
import withRequest from '../../../HOCs/withRequest';

import useScrollFix from '../../../Hooks/useScrollFix';

import View from '../../../Layout/View/View';

import AccessSubnav from '../../../Includes/Access/AccessSubnav/AccessSubnav';

import NoMedia from '../../../UI/NoMedia/NoMedia';
import Preloader from '../../../UI/Preloader/Preloader';

const AccessIPAddress = memo(({ data: { id, address, description }, isCurrentUser, onDelete }) => {
  const handleDelete = () => onDelete(id);

  return (
    <div className="ip-address">
      <div className="ip-address__info">
        <div className="ip-address__address">
          <Link to={`/access/ip-addresses/edit/${id}`}>{address}</Link>
        </div>
        <div className="ip-address__description">{description}</div>
      </div>
      <div className="ip-address__actions">
        <Link className="ip-address__action" to={`/access/ip-addresses/edit/${id}`} title="Редактировать">
          <FontAwesomeIcon icon={['fas', 'pencil']} />
        </Link>
        {!isCurrentUser && (
          <Button className="ip-address__action ip-address__action-delete" variant="light" title="Удалить" onClick={handleDelete}>
            <FontAwesomeIcon icon={['far', 'times']} />
          </Button>
        )}
      </div>
    </div>
  );
});

AccessIPAddress.propTypes = {
  data: PropTypes.object.isRequired,
  isCurrentUser: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
};

const AccessIPAddresses = ({
  createCanceler,
  dispatch,
}) => {
  const user = useSelector(({ auth }) => auth.user);
  const addresses = useSelector(({ accessIPAddresses }) => accessIPAddresses);

  const [scrollRef, scrollCallback, isEmptyList] = useScrollFix();

  const getAddresses = (page) => {
    if (addresses.error) return;

    const getIPAddressesRequest = createCanceler();

    dispatch(getAdminIPAddresses({ page, limit: LIMIT_PER_PAGE }, getIPAddressesRequest.token))
      .then((res) => scrollCallback(res, getAddresses));
  };

  const loadMore = () => {
    if (addresses.isFetching) return;

    getAddresses(addresses.page + 1);
  };

  const handleDelete = useCallback((id) => {
    sweetAlert.fire({
      ...DELETE_ALERT_CONFIG,
      icon: <FontAwesomeIcon icon={['fal', 'exclamation-triangle']} />,
      html: 'Удалить адрес? Это действие нельзя будет отменить',
    }).then((result) => {
      if (!result.value) return;

      const requestDeleteAddressCanceler = createCanceler();

      dispatch(deleteAdminIPAddress(id, requestDeleteAddressCanceler.token))
        .then(() => toast('success', 'IP-адрес удалён!'))
        .catch((error) => {
          const message = errorMessages[error.message] || error.message || 'Ошибка';

          toast('error', message);
        });
    });
  }, [createCanceler, dispatch]);

  useEffect(() => {
    getAddresses(1);
  }, []); // eslint-disable-line

  useEffect(() => () => {
    dispatch({ type: RESET_ACCESS_IP_ADDRESSES });
  }, [dispatch]);

  return (
    <View
      errorMessage={errorMessages[addresses.error?.message]}
      isError={addresses.error && ['admin_privileges_required'].includes(addresses.error.message)}
      viewClass="access"
      header={<AccessSubnav />}
      content={(
        <div className="access__ip-addresses">
          <Link to="/access/ip-addresses/create" className="btn btn-success access__btn">Создать IP-адрес</Link>
          <InfiniteScroll
            dataLength={addresses.docs.length}
            hasMore={addresses.page < addresses.pages}
            loader={null}
            next={loadMore}
            style={{ overflow: 'visible' }}
          >
            {addresses.docs.length > 0 && (
              <div className="access__ip-addresses-list" ref={scrollRef}>
                {addresses.docs.map((item) => <AccessIPAddress key={item.id} data={item} isCurrentUser={user?.remote_ip === item.address} onDelete={handleDelete} />)}
              </div>
            )}
            {!addresses.docs.length && !addresses.isFetching && isEmptyList && (
              <div className="access__no-media">
                <NoMedia
                  caption="Ничего не найдено"
                  icon={<FontAwesomeIcon icon={['fas', 'exclamation-circle']} />}
                />
              </div>
            )}
            {addresses.isFetching && (
              <div className="access__preloader">
                <Preloader caption="Загружаем..." />
              </div>
            )}
          </InfiniteScroll>
        </div>
      )}
    />
  );
};

AccessIPAddresses.propTypes = {
  createCanceler: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default memo(withRequest(AccessIPAddresses));
