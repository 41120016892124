import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

const RequireAuthRoute = ({
  component: Component,
  condition,
  defaultRedirect,
  location,
  ...rest
}) => {
  const { token, user } = useSelector((store) => store.auth);

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          {!token && (
            <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
          )}
          {/* eslint-disable-next-line no-nested-ternary */}
          {user ? (
            (typeof condition !== 'undefined' ? condition : true)
              ? <Component {...props} /> : <Redirect to={defaultRedirect || '/'} />
          ) : (
            <div className="overlay-loading">
              <div className="loading-spinner" />
            </div>
          )}
        </>
      )}
    />
  );
};

RequireAuthRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
  condition: PropTypes.bool,
  defaultRedirect: PropTypes.string,
  location: PropTypes.object,
  token: PropTypes.string,
};

export default RequireAuthRoute;
