import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import './Nav.scss';

import LinkDuo from '../../HOCs/LinkDuo';

const DropdownWrapper = React.forwardRef(({ children, className }, ref) => (
  <li
    ref={ref}
    className={className}
  >
    {children}
  </li>
));

DropdownWrapper.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

const DropdownMenu = React.forwardRef(({ children, className }, ref) => (
  <div
    ref={ref}
    className={className}
  >
    {children}
  </div>
));

DropdownMenu.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

class Nav extends React.PureComponent {
  checkListsCondition = (list) => list.filter((item) => typeof item.condition === 'undefined' || item.condition);

  renderToggleInner = (item) => (
    <>
      {item.icon && <i className="nav__icon">{item.icon}</i>}
      {item.text && <span className="nav__text">{item.text}</span>}
      {item.badge && <span className="nav__badge">{item.badge}</span>}
    </>
  );

  renderDropdownMenu = (item) => {
    const list = this.checkListsCondition(item.menu);

    return list.map((menuItem) => (
      <Dropdown.Item key={menuItem.text} as="button" onClick={menuItem.onClick}>
        {item.link && typeof menuItem.onClick === 'function' && (
          <Link to="/create">{menuItem.text}</Link>
        )}
        {menuItem.link && (
          <LinkDuo
            to={menuItem.link}
          >
            {this.renderToggleInner(menuItem)}
          </LinkDuo>
        )}
      </Dropdown.Item>
    ));
  };

  renderNavItem = (item) => {
    if (item.menu) {
      return (
        <Dropdown key={item.key} className={`nav__item${item.itemClassName ? ` nav__item_${item.itemClassName}` : ''}`}>
          <Dropdown.Toggle as="button" className="nav__link nav__link-dropdown">
            {item.link ? (
              <LinkDuo
                to={item.link}
                activeClassName="is-active"
                id={item.toggleID}
                className="nav__link"
                title={item.title}
                onClick={(e) => e.preventDefault()}
              >
                {this.renderToggleInner(item)}
                <FontAwesomeIcon icon={['fal', 'angle-down']} />
              </LinkDuo>
            ) : (
              this.renderToggleInner(item)
            )}
          </Dropdown.Toggle>
          <Dropdown.Menu className="nav__link-menu">
            {this.renderDropdownMenu(item)}
          </Dropdown.Menu>
        </Dropdown>
      );
    }

    if (item.dropdown) {
      return (
        <Dropdown as={DropdownWrapper} key={item.key} className={`nav__item${item.itemClassName ? ` nav__item_${item.itemClassName}` : ''}`}>
          <Dropdown.Toggle as="button" className="nav__link">
            {this.renderToggleInner(item)}
          </Dropdown.Toggle>
          <Dropdown.Menu as={DropdownMenu} renderOnMount={typeof item.renderOnMount === 'undefined' ? true : item.renderOnMount} alignRight={item.alignRight}>
            {item.dropdown}
          </Dropdown.Menu>
        </Dropdown>
      );
    }

    return (
      <li className={`nav__item${item.itemClassName ? ` nav__item_${item.itemClassName}` : ''}`} key={item.key}>
        <LinkDuo
          exact={item.exact}
          to={item.link}
          activeClassName="is-active"
          id={item.toggleID}
          className="nav__link"
          title={item.title}
          onClick={item.onClick}
        >
          {this.renderToggleInner(item)}
        </LinkDuo>
      </li>
    );
  };

  renderList() {
    const { list } = this.props;

    let items = this.checkListsCondition(list);

    items = items.filter((item) => (
      !item.menu ? item : this.checkListsCondition(item.menu).length > 0));

    return (
      <ul className="nav__list">
        {items.map(this.renderNavItem)}
      </ul>
    );
  }

  render() {
    const { semantic } = this.props;

    return semantic
      ? <nav className="nav">{this.renderList()}</nav>
      : <div className="nav">{this.renderList()}</div>;
  }
}

Nav.propTypes = {
  semantic: PropTypes.bool,
  list: PropTypes.array.isRequired,
};

export default Nav;
