import PropTypes from 'prop-types';
import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';

import errorMessages from '../../../constants/errors';

import { requestCanceler } from '../../../middlewares/api';

import { toast } from '../../../utils';

import View from '../../Layout/View/View';

import MailingCreateListTab from './MailingCreateListTab/MailingCreateListTab';
import MailingUnsubscribeTab from './MailingUnsubscribeTab/MailingUnsubscribeTab';

import './Mailing.scss';

const MAX_FILE_SIZE = 1048576 * 2;

class Mailing extends React.PureComponent {
  requestCancelers = [];

  filterActions = {
    include: 'Добавить',
    exclude: 'Исключить',
    crossing: 'Пересечение',
  };

  filterLists = {
    all: 'Все пользователи',
    confirmed: 'Подтвержденные',
    paid: 'Платные',
    // paid_for_year: 'Платили на год',
    generated_video: 'Генерировали видео',
  };

  componentDidUpdate(prevProps) {
    const { mailing } = this.props;
    const { error } = mailing;

    if (error && error.message && error !== prevProps.mailing.error) {
      const message = ['Network Error', 'permission_denied', 'server_error', 'incorrect_query', 'incorrect_file', 'filesize_limit_exceeded', 'emails_not_found', 'email_incorrect'].includes(error.message)
        ? errorMessages[error.message]
        : error.message;

      toast('error', message);
    }
  }

  componentWillUnmount() {
    const { onUnload } = this.props;

    this.requestCancelers.forEach((canceler) => canceler.cancelRequest());

    onUnload();
  }

  handleDownloadList = (data) => {
    const { onDownloadList } = this.props;

    const requestDownloadListCanceler = requestCanceler();

    this.requestCancelers.push(requestDownloadListCanceler);

    onDownloadList(data, requestDownloadListCanceler.token)
      .then(() => {
        // const prepared = emails.map((el) => `${el}\n`).join('');

        // this.downloadList(prepared);

        toast('success', `Список будет отправлен на почту ${data.email} после окончания формирования`, false);
      });
  };

  downloadList = (content) => {
    const file = new Blob([content], { type: 'text/plain' });

    let a = document.createElement('a');
    a.href = URL.createObjectURL(file);
    a.download = 'users.txt';
    a.click();
    a = undefined;
  };

  handleUnsubscribeList = (file, finallyCallback) => {
    if (file.size > MAX_FILE_SIZE) {
      toast('error', errorMessages.filesize_limit_exceeded);

      return;
    }

    const { onUnsubscribeList } = this.props;

    const requestUnsubscribeListCanceler = requestCanceler();

    this.requestCancelers.push(requestUnsubscribeListCanceler);

    onUnsubscribeList({ file, name: file.name }, requestUnsubscribeListCanceler.token)
      .then((res) => {
        toast('success', 'Пользователи отписаны');

        finallyCallback(res.nModified);
      })
      .catch(finallyCallback);
  };

  render() {
    const { mailing } = this.props;
    const { error, isFetching } = mailing;

    return (
      <View
        errorMessage={errorMessages[error?.message]}
        isError={error && ['admin_privileges_required'].includes(error.message)}
        title="Рассылки"
        viewClass="mailing"
        content={(
          <Tabs defaultActiveKey="list" transition={false}>
            <Tab eventKey="list" title="Составить список">
              <MailingCreateListTab
                isFetching={isFetching}
                filterActions={this.filterActions}
                filterLists={this.filterLists}
                onDownloadList={this.handleDownloadList}
              />
            </Tab>
            <Tab eventKey="unsubscribed" title="Добавить отписавшихся">
              <MailingUnsubscribeTab
                isFetching={isFetching}
                onUnsubscribeList={this.handleUnsubscribeList}
              />
            </Tab>
          </Tabs>
        )}
      />
    );
  }
}

Mailing.propTypes = {
  mailing: PropTypes.object.isRequired,
  onDownloadList: PropTypes.func.isRequired,
  onUnsubscribeList: PropTypes.func.isRequired,
  onUnload: PropTypes.func.isRequired,
};

export default Mailing;
