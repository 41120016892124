import PropTypes from 'prop-types';
import React, { useState, memo } from 'react';
import queryString from 'query-string';
import { Form } from 'react-bootstrap';

import SearchForm from '../../../Includes/SearchForm/SearchForm';

function UsersSearch({ isElama, onSearch }) {
  const [searchType, setSearchType] = useState(queryString.parse(window.location.search)?.search_type || 'full_email');

  const onChangeSearchType = ({ target }) => setSearchType(target.dataset.type);

  const handleSearch = (query) => onSearch({ query, search_type: searchType });

  return (
    <div className="users__search">
      <div className="users__search-options">
        <span>Поиск по:</span>
        <Form.Check
          checked={searchType === 'full_email'}
          className="form-check-custom"
          data-type="full_email"
          id="users__search-full-email"
          inline
          label="Полный E-mail"
          name="group1"
          type="radio"
          onChange={onChangeSearchType}
        />
        <Form.Check
          checked={searchType === 'id'}
          className="form-check-custom"
          data-type="id"
          id="users__search-id"
          inline
          label={isElama ? 'SUPA ID' : 'ID'}
          name="group1"
          type="radio"
          onChange={onChangeSearchType}
        />
        {isElama && (
          <Form.Check
            checked={searchType === 'elama_user_id'}
            className="form-check-custom"
            data-type="elama_user_id"
            id="users__search-elama-user-id"
            inline
            label="Elama ID"
            name="group1"
            type="radio"
            onChange={onChangeSearchType}
          />
        )}
        <Form.Check
          checked={searchType === 'email'}
          className="form-check-custom"
          data-type="email"
          id="users__search-email"
          inline
          label="Часть E-mail"
          name="group1"
          type="radio"
          onChange={onChangeSearchType}
        />
      </div>

      <SearchForm
        className="users__search-form"
        defaultValue={queryString.parse(window.location.search)?.search}
        placeholder="Поиск по E-mail или ID"
        onSearch={handleSearch}
      />
    </div>
  );
}

UsersSearch.propTypes = {
  isElama: PropTypes.bool,
  onSearch: PropTypes.func.isRequired,
};

export default memo(UsersSearch);
