export const GET_ADMIN_IP_ADDRESS = 'GET_ADMIN_IP_ADDRESS';
export const GET_ADMIN_IP_ADDRESSES = 'GET_ADMIN_IP_ADDRESSES';
export const CREATE_ADMIN_IP_ADDRESS = 'CREATE_ADMIN_IP_ADDRESS';
export const UPDATE_ADMIN_IP_ADDRESS = 'UPDATE_ADMIN_IP_ADDRESS';
export const DELETE_ADMIN_IP_ADDRESS = 'DELETE_ADMIN_IP_ADDRESS';
export const RESET_ACCESS_IP_ADDRESSES = 'RESET_ACCESS_IP_ADDRESSES';
export const GET_ADMIN_USERS = 'GET_ADMIN_USERS';
export const GET_ADMIN_USER = 'GET_ADMIN_USER';
export const ADD_ADMIN_PRIV = 'ADD_ADMIN_PRIV';
export const UPDATE_ADMIN_PRIV = 'UPDATE_ADMIN_PRIV';
export const DELETE_ADMIN_PRIV = 'DELETE_ADMIN_PRIV';
export const RESET_ACCESS_ADMINS = 'RESET_ACCESS_ADMINS';

/* --------------- */
/* Action creators */

/* --------------- */

export function getAdminIPAddress(id, requestToken) {
  return {
    type: GET_ADMIN_IP_ADDRESS,
    request: {
      method: 'get',
      url: `/admin/access/ip-addresses/${id}`,
      requestToken,
    },
  };
}

export function getAdminIPAddresses(params, requestToken) {
  return {
    type: GET_ADMIN_IP_ADDRESSES,
    request: {
      method: 'get',
      url: '/admin/access/ip-addresses',
      params,
      requestToken,
    },
    payload: { isSearch: false },
  };
}

export function createAdminIPAddress(data, requestToken) {
  return {
    type: CREATE_ADMIN_IP_ADDRESS,
    request: {
      method: 'post',
      url: '/admin/access/ip-addresses',
      data,
      requestToken,
    },
  };
}

export function updateAdminIPAddress(id, data, requestToken) {
  return {
    type: UPDATE_ADMIN_IP_ADDRESS,
    request: {
      method: 'patch',
      url: `/admin/access/ip-addresses/${id}`,
      data,
      requestToken,
    },
  };
}

export function deleteAdminIPAddress(id, requestToken) {
  return {
    type: DELETE_ADMIN_IP_ADDRESS,
    request: {
      method: 'delete',
      url: `/admin/access/ip-addresses/${id}`,
      requestToken,
    },
    payload: { id },
  };
}

export function getAdminUser(id, requestToken) {
  return {
    type: GET_ADMIN_USER,
    request: {
      method: 'get',
      url: `/admin/users/admins/${id}`,
      requestToken,
    },
  };
}

export function getAdminUsers(params, isSearch, requestToken) {
  return {
    type: GET_ADMIN_USERS,
    request: {
      method: 'get',
      url: '/admin/users/admins',
      params,
      requestToken,
    },
    payload: { isSearch },
  };
}

export function addAdminPrivileges(data, requestToken) {
  return {
    type: ADD_ADMIN_PRIV,
    request: {
      method: 'patch',
      url: '/admin/users/add-priv',
      data,
      requestToken,
    },
  };
}

export function updateAdminPrivileges(id, data, requestToken) {
  return {
    type: UPDATE_ADMIN_PRIV,
    request: {
      method: 'patch',
      url: `/admin/users/update-priv/${id}`,
      data,
      requestToken,
    },
    payload: { ...data, id },
  };
}

export function deleteAdminPrivileges(id, requestToken) {
  return {
    type: DELETE_ADMIN_PRIV,
    request: {
      method: 'patch',
      url: `/admin/users/delete-priv/${id}`,
      requestToken,
    },
    payload: { id },
  };
}
