import { PREFIX_ACTION_TYPE_LANDING_MENUS } from '../../constants/common';

export const ADD_ELEMENT_TO_MENU = 'ADD_ELEMENT_TO_MENU';
export const DELETE_MENU_ELEMENT = 'DELETE_MENU_ELEMENT';
export const CANCEL_MENU_CHANGES = 'CANCEL_MENU_CHANGES';
export const CHANGE_MENU_ELEMENTS_ORDER = 'CHANGE_MENU_ELEMENTS_ORDER';
export const CHANGE_MENU_ELEMENT_LEVEL = 'CHANGE_MENU_ELEMENT_LEVEL';
export const COPY_MENU_ELEMENT = 'COPY_MENU_ELEMENT';
export const DELETE_LANDING_MENU = 'DELETE_LANDING_MENU';
export const GET_LANDING_MENUS = 'GET_LANDING_MENUS';
export const CREATE_LANDING_MENU = 'CREATE_LANDING_MENU';
export const UPDATE_MENU_ELEMENT = 'UPDATE_MENU_ELEMENT';
export const UPDATE_LANDING_MENU_ELEMENTS = 'UPDATE_LANDING_MENU_ELEMENTS';
export const UPDATE_LANDING_MENU = 'UPDATE_LANDING_MENU';

export function addElementToMenu(id) {
  return {
    type: PREFIX_ACTION_TYPE_LANDING_MENUS + ADD_ELEMENT_TO_MENU,
    payload: { id },
  };
}

export function cancelMenuChanges(id) {
  return {
    type: PREFIX_ACTION_TYPE_LANDING_MENUS + CANCEL_MENU_CHANGES,
    payload: { id },
  };
}

export function updateMenuElement(menuId, id, data) {
  return {
    type: PREFIX_ACTION_TYPE_LANDING_MENUS + UPDATE_MENU_ELEMENT,
    payload: { data, menuId, id },
  };
}

export function deleteMenuElement(menuId, id) {
  return {
    type: PREFIX_ACTION_TYPE_LANDING_MENUS + DELETE_MENU_ELEMENT,
    payload: { menuId, id },
  };
}

export function changeMenuElementsOrder(id, data) {
  return {
    type: PREFIX_ACTION_TYPE_LANDING_MENUS + CHANGE_MENU_ELEMENTS_ORDER,
    payload: { id, data },
  };
}

export function changeMenuElementLevel(menuId, id) {
  return {
    type: PREFIX_ACTION_TYPE_LANDING_MENUS + CHANGE_MENU_ELEMENT_LEVEL,
    payload: { menuId, id },
  };
}

export function copyMenuElement(menuId, id) {
  return {
    type: PREFIX_ACTION_TYPE_LANDING_MENUS + COPY_MENU_ELEMENT,
    payload: { menuId, id },
  };
}

export function getLandingMenus(params, requestToken) {
  return {
    type: PREFIX_ACTION_TYPE_LANDING_MENUS + GET_LANDING_MENUS,
    request: {
      method: 'get',
      url: '/admin/landing/menus',
      params,
      requestToken,
    },
  };
}

export function createLandingMenu(data) {
  return {
    type: PREFIX_ACTION_TYPE_LANDING_MENUS + CREATE_LANDING_MENU,
    request: {
      method: 'post',
      url: '/admin/landing/menus',
      data,
    },
  };
}

export function deleteLandingMenu(id) {
  return {
    type: PREFIX_ACTION_TYPE_LANDING_MENUS + DELETE_LANDING_MENU,
    request: {
      method: 'delete',
      url: `/admin/landing/menus/${id}`,
    },
    payload: { id },
  };
}

export function updateLandingMenu(id, data) {
  return {
    type: PREFIX_ACTION_TYPE_LANDING_MENUS + UPDATE_LANDING_MENU,
    request: {
      method: 'patch',
      url: `/admin/landing/menus/${id}`,
      data,
    },
    payload: { id },
  };
}

export function updateMenuElements(id, data) {
  return {
    type: PREFIX_ACTION_TYPE_LANDING_MENUS + UPDATE_LANDING_MENU_ELEMENTS,
    request: {
      method: 'patch',
      url: `/admin/landing/menus/${id}/elements`,
      data,
    },
    payload: { id },
  };
}
