import React from 'react';

import { NavLink, useLocation } from 'react-router-dom';

const AccessSubnav = () => {
  const location = useLocation();

  const isPagesRoute = typeof location?.pathname === 'string'
    && (location?.pathname.includes('/access/ip-addresses'));

  return (
    <div className="subnav">
      <NavLink to="/access/ip-addresses" className={`${isPagesRoute ? 'is-active' : ''}`}>
        Разрешенные IP-адреса
      </NavLink>
      <NavLink activeClassName="is-active" to="/access/admins">
        Администраторы
      </NavLink>
    </div>
  );
};

export default AccessSubnav;
