import {
  ADD_CATEGORY_TEMPLATES_COUNT,
  CHANGE_CATEGORY,
  CREATE_CATEGORY,
  GET_CATEGORIES,
  DELETE_CATEGORY,
  ORDER_CATEGORY,
  SAVE_CATEGORIES_ORDER,
  RESET_CATEGORIES,
  UPDATE_CATEGORY,
} from '../../actions';

const defaultState = {
  docs: [],
  error: null,
  isFetching: false,
  isOrdered: false,
  isCategoryChanging: {
    create: false,
    order: false,
    remove: null,
    update: null,
  },
};

function returnError(response) {
  if (response.message) return { message: 'first_request_failed' };

  return { message: 'admin_privileges_required' };
}

const setIsCategoryChanging = (isCategoryChanging, props) => ({
  ...isCategoryChanging,
  ...props,
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case `${GET_CATEGORIES}_REQUEST`:
      return {
        ...state,
        docs: [],
        error: null,
        isFetching: true,
      };
    case `${SAVE_CATEGORIES_ORDER}_REQUEST`:
      return {
        ...state,
        error: null,
        isCategoryChanging: setIsCategoryChanging(state.isCategoryChanging, { order: true }),
      };
    case `${CREATE_CATEGORY}_REQUEST`:
      return {
        ...state,
        error: null,
        isCategoryChanging: setIsCategoryChanging(state.isCategoryChanging, { create: true }),
      };
    case `${DELETE_CATEGORY}_REQUEST`:
      return {
        ...state,
        error: null,
        isCategoryChanging: setIsCategoryChanging(state.isCategoryChanging, { remove: action.payload.id }),
      };
    case `${UPDATE_CATEGORY}_REQUEST`:
      return {
        ...state,
        error: null,
        isCategoryChanging: setIsCategoryChanging(state.isCategoryChanging, { update: action.payload.id }),
      };
    case SAVE_CATEGORIES_ORDER:
      return {
        ...state,
        isOrdered: false,
        isCategoryChanging: setIsCategoryChanging(state.isCategoryChanging, { order: false }),
      };
    case GET_CATEGORIES:
      return {
        ...state,
        docs: action.response,
        isFetching: false,
        isOrdered: false,
      };
    case ORDER_CATEGORY:
      // eslint-disable-next-line
      const newDocs = state.docs;

      newDocs.splice(action.payload.newPosition, 0, newDocs.splice(action.payload.currentPosition, 1)[0]);

      return {
        ...state,
        docs: newDocs,
        isOrdered: true,
      };
    case CREATE_CATEGORY:
      return {
        ...state,
        docs: [
          ...state.docs,
          action.response,
        ],
        isCategoryChanging: setIsCategoryChanging(state.isCategoryChanging, { create: false }),
      };
    case DELETE_CATEGORY:
      return {
        ...state,
        docs: state.docs.filter((item) => item._id !== action.payload.id),
        isCategoryChanging: setIsCategoryChanging(state.isCategoryChanging, { remove: null }),
      };
    case CHANGE_CATEGORY:
      return {
        ...state,
        docs: state.docs.map((item) => {
          if (item._id !== action.payload.id) return item;

          return {
            ...item,
            ...action.payload.params,
            isUnsaved: true,
          };
        }),
      };
    case ADD_CATEGORY_TEMPLATES_COUNT:
      return {
        ...state,
        docs: state.docs.map((item) => {
          if (item._id !== action.payload.id) return item;

          return {
            ...item,
            count_templates: action.payload.force ? action.payload.count : item.count_templates + action.payload.count,
          };
        }),
      };
    case UPDATE_CATEGORY:
      return {
        ...state,
        docs: state.docs.map((item) => {
          if (item._id !== action.payload.id) return item;

          return action.response;
        }),
        isCategoryChanging: setIsCategoryChanging(state.isCategoryChanging, { update: null }),
      };
    case `${GET_CATEGORIES}_FAILURE`:
      return {
        ...state,
        error: returnError(action.response),
        isFetching: false,
      };
    case `${SAVE_CATEGORIES_ORDER}_FAILURE`:
      return {
        ...state,
        error: action.response,
        isCategoryChanging: setIsCategoryChanging(state.isCategoryChanging, { order: false }),
      };
    case `${CREATE_CATEGORY}_FAILURE`:
      return {
        ...state,
        error: action.response,
        isCategoryChanging: setIsCategoryChanging(state.isCategoryChanging, { create: false }),
      };
    case `${DELETE_CATEGORY}_FAILURE`:
      return {
        ...state,
        error: action.response,
        isCategoryChanging: setIsCategoryChanging(state.isCategoryChanging, { remove: null }),
      };
    case `${UPDATE_CATEGORY}_FAILURE`:
      return {
        ...state,
        error: action.response,
        isCategoryChanging: setIsCategoryChanging(state.isCategoryChanging, { update: null }),
      };
    case RESET_CATEGORIES:
      return defaultState;
    default:
      return state;
  }
};
