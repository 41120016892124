import { connect } from 'react-redux';

import SignIn from './SignIn';

import { SIGN_IN, signIn } from '../../../../actions';

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  onSignIn: (data, requestToken) => dispatch(signIn(data, requestToken)),
  onUnload: () => dispatch({ type: `${SIGN_IN}_PAGE_UNLOADED` }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
