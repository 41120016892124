import Cookies from 'universal-cookie';

import {
  APP_LOAD,
  SIGN_IN,
  getUser,
} from '../actions';

const cookies = new Cookies();

export default (store) => (next) => (action) => {
  next(action);

  switch (action.type) {
    case APP_LOAD:
    case SIGN_IN:
      if (store.getState().auth.token) {
        if (!cookies.get('b_hash')) {
          const now = new Date();

          // eslint-disable-next-line
          cookies.set('b_hash', [...Array(32)].map(() => (~~(Math.random() * 36)).toString(36)).join(''), {
            path: '/',
            expires: new Date(now.getFullYear() + 1, now.getMonth(), now.getDate()),
          });
        }

        store.dispatch(getUser({ h: cookies.get('b_hash') })).catch(() => {});
      }

      break;
    default:
  }
};
