export const CREATE_SIZE = 'CREATE_SIZE';
export const CHANGE_SIZE = 'CHANGE_SIZE';
export const DELETE_SIZE = 'DELETE_SIZE';
export const GET_SIZES = 'GET_SIZES';
export const ORDER_SIZE = 'ORDER_SIZE';
export const RESET_SIZES = 'RESET_SIZES';
export const SAVE_SIZES_ORDER = 'SAVE_SIZES_ORDER';
export const UPDATE_SIZE = 'UPDATE_SIZE';

/* --------------- */
/* Action creators */

/* --------------- */

export function getSizes(params, requestToken) {
  return {
    type: GET_SIZES,
    request: {
      method: 'get',
      url: '/admin/template_sizes',
      params,
      requestToken,
    },
  };
}

export function setSizesOrder(moveData) {
  return {
    type: ORDER_SIZE,
    payload: moveData,
  };
}

export function saveSizesOrder(data, requestToken) {
  return {
    type: SAVE_SIZES_ORDER,
    request: {
      method: 'post',
      url: '/admin/template_sizes/order',
      data,
      requestToken,
    },
  };
}

export function createSize(data, requestToken) {
  return {
    type: CREATE_SIZE,
    request: {
      method: 'post',
      url: '/admin/template_sizes',
      data,
      requestToken,
    },
  };
}

export function deleteSize(id, requestToken) {
  return {
    type: DELETE_SIZE,
    request: {
      method: 'delete',
      url: `/admin/template_sizes/${id}`,
      requestToken,
    },
    payload: { id },
  };
}

export function changeSize(id, data) {
  return {
    type: CHANGE_SIZE,
    payload: { id, data },
  };
}

export function updateSize(data, requestToken) {
  return {
    type: UPDATE_SIZE,
    request: {
      method: 'patch',
      url: `/admin/template_sizes/${data._id}`,
      data,
      requestToken,
    },
    payload: { id: data._id },
  };
}
