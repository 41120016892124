import React from 'react';

import './Footer.scss';

import FooterNav from './FooterNav/FooterNav';
import SocialLinks from '../../Includes/SocialLinks/SocialLinks';

class Footer extends React.PureComponent {
  render() {
    return (
      <footer className="footer">
        <div className="footer__nav">
          <FooterNav />
        </div>
        <div className="footer__social-links">
          <SocialLinks />
        </div>
      </footer>
    );
  }
}

export default Footer;
