export const CHANGE_PASS = 'CHANGE_PASS';
export const PROFILE = 'PROFILE';

/* --------------- */
/* Action creators */

/* --------------- */

export function changePassword(data) {
  return {
    type: CHANGE_PASS,
    request: {
      method: 'post',
      url: '/change_password',
      data,
    },
  };
}

/* ------ */
/* Thunks */

/* ------ */
