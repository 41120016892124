import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { VIEW_LOAD, VIEW_UNLOAD } from '../../../actions';

import Error from '../../UI/Error/Error';
import Preloader from '../../UI/Preloader/Preloader';

import './View.scss';

class View extends React.PureComponent {
  viewRef = React.createRef();

  componentDidMount() {
    const {
      name, onViewLoad, pageClass, preventLoadEvent,
    } = this.props;

    // const pageDOM = document.getElementById('page');
    //
    // if (!pageDOM.contains(this.viewRef.current)) return;
    //
    // const classes = Array.from(pageDOM.classList);
    // const existingModifiers = classes.filter((className) => className.match(/^page_/));
    //
    // existingModifiers.forEach((className) => pageDOM.classList.remove(className));
    //
    // if (pageClass) pageDOM.classList.add(`page_${pageClass}`);

    if (preventLoadEvent) return;

    onViewLoad({ name, pageClass });
  }

  componentWillUnmount() {
    const { onViewUnLoad, preventLoadEvent } = this.props;

    if (preventLoadEvent) return;

    onViewUnLoad();
  }

  render() {
    const {
      content,
      errorMessage = '',
      footer,
      header,
      sidebar,
      subTitle,
      title,
      viewClass,
      isError,
      isFetching,
      isHeaderBeforeTitle,
      preloaderCaption = 'Загружаем список...',
    } = this.props;

    return (
      <div className={`view${viewClass ? ` view_${viewClass} ${viewClass}` : ''}`} ref={this.viewRef}>
        {(title || header) && (
          <div className="view__header">
            {isHeaderBeforeTitle && header}
            {title && <h1 className="view__title">{title}</h1>}
            {!isHeaderBeforeTitle && header}
          </div>
        )}
        <div className="view__main">
          {sidebar && (
            <div className="view__sidebar">
              {sidebar}
            </div>
          )}
          <div className="view__content">
            {subTitle && <h2 className="view__sub-title" title={subTitle}>{subTitle}</h2>}
            {isError ? (
              <Error
                action={{
                  onClick: () => window.location.reload(),
                  caption: 'Перезагрузить страницу',
                  variant: 'success',
                }}
                description={errorMessage}
                icon={<FontAwesomeIcon icon={['fal', 'exclamation-triangle']} />}
                title="Ошибка"
              />
            ) : content}
            {isFetching && (
              <div className="view__preloader">
                <Preloader caption={preloaderCaption} />
              </div>
            )}
          </div>
        </div>
        {footer && (
          <div className="view__footer">
            {footer}
          </div>
        )}
      </div>
    );
  }
}

View.propTypes = {
  content: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
  footer: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
  header: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.func,
    PropTypes.object,
  ]),
  sidebar: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
  errorMessage: PropTypes.string,
  pageClass: PropTypes.string,
  subTitle: PropTypes.string,
  title: PropTypes.string,
  isError: PropTypes.bool,
  isFetching: PropTypes.bool,
  isHeaderBeforeTitle: PropTypes.bool,
  preloaderCaption: PropTypes.string,
  name: PropTypes.string,
  viewClass: PropTypes.string,
  onViewLoad: PropTypes.func.isRequired,
  onViewUnLoad: PropTypes.func.isRequired,
  preventLoadEvent: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => ({
  onViewLoad: (data) => dispatch({
    type: VIEW_LOAD,
    payload: data,
  }),
  onViewUnLoad: () => dispatch({ type: VIEW_UNLOAD }),
});

export default connect(null, mapDispatchToProps)(View);
