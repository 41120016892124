import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { requestCanceler } from '../../../../middlewares/api';

import { toast } from '../../../../utils';

import { languages } from '../../../../constants/common';
import errorMessages from '../../../../constants/errors';

import View from '../../../Layout/View/View';

import sweetAlert from '../../../HOCs/sweetAlert';

import LanguageDropdown from '../../../Includes/LanguageDropdown/LanguageDropdown';

import NoMedia from '../../../UI/NoMedia/NoMedia';

import CategoriesCategory from './CategoriesCategory/CategoriesCategory';

import './Categories.scss';

class ObjectCategories extends React.PureComponent {
  requestCancelers = [];

  languages = Object.keys(languages);

  componentDidMount() {
    this.getCategories();
  }

  componentDidUpdate(prevProps) {
    const { objectCategories: { error }, location } = this.props;

    if (location.search !== prevProps.location.search) {
      this.getCategories();
    }

    if (error && error.message && error !== prevProps.objectCategories.error) {
      const message = ['Network Error', 'permission_denied', 'server_error', 'incorrect_query'].includes(error.message)
        ? errorMessages[error.message]
        : error.message;

      toast('error', message);
    }

    if (error && error.errors && error.errors.length) {
      toast('error', error.errors.join('<br /><br />'));
    }
  }

  componentWillUnmount() {
    const { onUnload } = this.props;

    this.requestCancelers.forEach((canceler) => canceler.cancelRequest());

    onUnload();
  }

  getCategories = () => {
    const { location, onGetObjectCategories } = this.props;

    const requestParams = {};

    if (location.search) {
      const defaultParams = queryString.parse(location.search);

      if (!defaultParams.lang || !Object.keys(languages).includes(defaultParams.lang)) {
        this.handleSearchCategories('ru');

        return;
      }

      Object.assign(requestParams, defaultParams);
    }

    const request = requestCanceler();

    this.requestCancelers.push(request);

    onGetObjectCategories(requestParams, request.token).catch(() => {});
  };

  handleSearchCategories = (lang) => {
    const { history, location } = this.props;

    const queryStr = !lang || lang === 'ru' ? '/objects/categories' : `/objects/categories?lang=${lang}`;

    if (queryString.parse(location.search)?.lang === lang) {
      this.getCategories();
    } else {
      history.push(queryStr);
    }
  };

  handleClickEdit = (categoryID) => {
    const { history } = this.props;

    history.push(`/objects/categories/edit/${categoryID}`);
  };

  handleDeleteCategory = (categoryID) => {
    const { onDeleteObjectCategory } = this.props;

    sweetAlert.fire({
      cancelButtonText: 'Нет',
      confirmButtonText: 'Да, удалить',
      icon: <FontAwesomeIcon icon={['fal', 'exclamation-triangle']} />,
      type: 'warning',
      showCancelButton: true,
      title: 'Вы уверены?',
      html: 'Вы действительно хотите удалить категорию?',
    }).then((result) => {
      if (!result.value) return;

      const request = requestCanceler();

      this.requestCancelers.push(request);

      onDeleteObjectCategory(categoryID, request.token)
        .then(() => toast('success', 'Категория удалена!'));
    });
  };

  renderActionButtons = () => {
    const { history, location, objectCategories: { isFetching, isCategoryChanging } } = this.props;

    const lang = queryString.parse(location.search)?.lang;

    const queryStr = !lang || lang === 'ru' ? '/objects/categories/create' : `/objects/categories/create?lang=${lang}`;

    return (
      <div className="object-categories__actions">
        {!isFetching && (
          <Button
            disabled={isCategoryChanging.create}
            variant="success"
            onClick={() => history.push(queryStr)}
          >
            Добавить категорию
          </Button>
        )}
        {this.renderLanguagesDropdown()}
      </div>
    );
  };

  renderLanguagesDropdown = () => {
    const { location, objectCategories: { isFetching } } = this.props;

    const defaultLang = queryString.parse(location.search)?.lang;
    const currentLang = defaultLang && this.languages.includes(defaultLang) ? defaultLang : this.languages[0];

    return (
      <div className="object-categories-language">
        <LanguageDropdown
          currentLang={currentLang}
          disabled={isFetching}
          onChange={this.handleSearchCategories}
        />
      </div>
    );
  };

  renderList = () => {
    const { objectCategories: { docs, isFetching, isCategoryChanging } } = this.props;

    return (
      <div className="template-bunch">
        {!isFetching && docs && docs.length > 0 && docs.map((item) => (
          <div key={item._id} className="template-bunch__item">
            <CategoriesCategory
              category={item}
              isRemoving={item._id === isCategoryChanging.remove}
              isUpdating={item._id === isCategoryChanging.update}
              onClickEdit={this.handleClickEdit}
              onDeleteCategory={this.handleDeleteCategory}
            />
          </div>
        ))}
        {!isFetching && docs.length === 0 && <NoMedia icon={<FontAwesomeIcon icon={['fas', 'info-circle']} />} caption="Список пуст" />}
      </div>
    );
  };

  render() {
    const { objectCategories: { error, isFetching } } = this.props;

    return (
      <View
        errorMessage={errorMessages[error?.message]}
        isError={error && ['admin_privileges_required', 'first_request_failed'].includes(error.message)}
        isFetching={isFetching}
        viewClass="object-categories"
        title="Категории объектов"
        header={this.renderActionButtons()}
        content={this.renderList()}
      />
    );
  }
}

ObjectCategories.propTypes = {
  objectCategories: PropTypes.object.isRequired,
  history: PropTypes.object,
  location: PropTypes.object,
  onGetObjectCategories: PropTypes.func.isRequired,
  onDeleteObjectCategory: PropTypes.func.isRequired,
  onUnload: PropTypes.func.isRequired,
};

export default ObjectCategories;
