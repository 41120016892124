import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Dropdown, Form } from 'react-bootstrap';
import {
  eachMonthOfInterval, format, isEqual, setDefaultOptions,
} from 'date-fns';

import { ru } from 'date-fns/locale';

import View from '../../../Layout/View/View';

import { toast } from '../../../../utils';

import Preloader from '../../../UI/Preloader/Preloader';

import PartnersNav from '../PartnersNav';

import CheckBox from '../../../UI/CheckBox/CheckBox';

setDefaultOptions({ locale: ru });

const startDate = new Date('2023/02');

const Elama = ({ onDownload }) => {
  const currentDate = useRef(new Date());

  const [selectedDate, setSelectedDate] = useState(currentDate.current);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [allAuthorizedUsers, setAllAuthorizedUsers] = useState(false);

  const months = eachMonthOfInterval({
    start: startDate,
    end: currentDate.current,
  });

  months.sort((a, b) => new Date(b) - new Date(a));

  const handleDownload = useCallback(() => {
    setIsLoading(true);

    const date = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 10);

    onDownload({ date: date.getTime(), email, all_authorized_users: allAuthorizedUsers })
      .then(() => {
        toast('success', 'Список будет сформирован и отправлен на E-Mail');
      })
      .catch(({ message }) => {
        toast('error', `Произошла ошибка {${message}}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [email, onDownload, selectedDate, allAuthorizedUsers]);

  return (
    <View
      viewClass="partners"
      header={<PartnersNav />}
      content={(
        <>
          <div className="partners__elama">
            Выгрузить логины юзеров достигших критериев успеха:
            <div className="form">
              <Form.Control
                placeholder="Введите E-Mail, куда следует отправить список"
                type="text"
                value={email}
                onChange={(event) => setEmail(event.target.value.trim().toLowerCase())}
              />
              <div>
                <CheckBox
                  checked={!!allAuthorizedUsers}
                  id="elama-all-authorized-users"
                  onChange={() => setAllAuthorizedUsers(!allAuthorizedUsers)}
                >
                  Сформировать список всех пользователей и тех, кто не достиг критериев
                </CheckBox>
              </div>
              <div>
                <Dropdown>
                  <Dropdown.Toggle as="button" className="btn btn-light">
                    {format(selectedDate, 'LLLL yyyy')}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {months.map((date) => (
                      <Dropdown.Item
                        as="button"
                        active={isEqual(date, selectedDate)}
                        key={date.toDateString()}
                        onSelect={() => setSelectedDate(date)}
                      >
                        {format(date, 'LLLL yyyy')}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                <Button disabled={isLoading || !email} variant="success" onClick={handleDownload}>Скачать</Button>
                {isLoading && <Preloader />}
              </div>
            </div>
          </div>
        </>
      )}
    />
  );
};

Elama.propTypes = {
  onDownload: PropTypes.func.isRequired,
};

export default Elama;
