import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {
  memo, useCallback, useEffect, useRef, useState,
} from 'react';
import { Button } from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SweetAlert2 from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { requestCanceler } from '../../../../../middlewares/api';

import { toast } from '../../../../../utils';

import usePrevious from '../../../../hooks';

import {
  createGroupCategory, getGroupCategories as getGroupCats, deleteGroup, deleteGroupCategory, updateGroup, setGroupCategoriesOrder, saveGroupCategoriesOrder,
} from '../../../../../actions';

import sweetAlert from '../../../../HOCs/sweetAlert';

import Preloader from '../../../../UI/Preloader/Preloader';

import GroupsGroupSettings from './GroupsGroupSettings/GroupsGroupSettings';
import GroupsGroupCategory from './GroupsGroupCategory/GroupsGroupCategory';

import './GroupsGroup.scss';

const Swal = withReactContent(SweetAlert2);

const GroupsGroup = ({
  group, groupCategories, isDeleting, isUpdating, lang,
}) => {
  const [isFormVisible, setIsFormVisible] = useState(false);

  const [shortTitle, setShortTitle] = useState(group.short_title);

  const prevGroup = usePrevious(group);
  const prevIsFormVisible = usePrevious(isFormVisible);

  const requestCancelers = useRef([]);

  const dispatch = useDispatch();

  const getGroupCategories = useCallback(() => {
    const requestGetGroupCategoriesCanceler = requestCanceler();

    requestCancelers.current.push({ key: 'get_group_categories', canceler: requestGetGroupCategoriesCanceler });

    dispatch(getGroupCats(group._id, requestGetGroupCategoriesCanceler.token))
      .catch(() => toast('error', 'Не удалось загрузить категории группы'));
  }, [dispatch, group._id]);

  const handleSetIsFormVisible = () => {
    if (isFormVisible && group.isUnsaved) {
      sweetAlert.fire({
        cancelButtonText: 'Перейти к редактированию',
        confirmButtonText: 'Свернуть без сохранения',
        icon: <FontAwesomeIcon icon={['fal', 'exclamation-triangle']} />,
        type: 'warning',
        showCancelButton: true,
        title: 'Имеются не сохраненные изменения!',
      }).then((result) => {
        if (!result.value) return;

        setIsFormVisible(false);
      });
    } else {
      setIsFormVisible(!isFormVisible);
    }
  };

  const showDeleteItemDialogue = useCallback((deleteItemType, callback) => {
    sweetAlert.fire({
      cancelButtonText: 'Отмена',
      confirmButtonText: 'Да, удалить',
      icon: <FontAwesomeIcon icon={['fal', 'exclamation-triangle']} />,
      type: 'warning',
      showCancelButton: true,
      title: 'Вы уверены?',
      html: `Вы действительно хотите удалить ${deleteItemType}?`,
    }).then((result) => {
      if (!result.value) return;

      callback();
    });
  }, []);

  const handleCreateCategory = () => {
    sweetAlert.fire({
      cancelButtonText: 'Отмена',
      confirmButtonText: 'Добавить',
      icon: null,
      showCancelButton: true,
      title: 'Добавить категорию',
      html:
        'Введите название и алиас:'
        + '<input id="swal-input-group-title" class="swal2-input" type="text" placeholder="Название" style="margin-top: 16px !important;">'
        + '<input id="swal-input-slug" class="swal2-input" type="text" placeholder="Алиас (транслитом, /^[a-z0-9-]{3,30}$/" style="margin-top: 16px !important;">',
      preConfirm: () => {
        const title = document.getElementById('swal-input-group-title').value.trim();
        const slug = document.getElementById('swal-input-slug').value.trim();

        if (!title || !slug) return Swal.showValidationMessage('Поля не могут быть пустыми');

        if (!/^[a-z0-9-]{3,30}$/.test(slug)) return Swal.showValidationMessage('Алиас не соответствует паттерну');

        return { title, slug };
      },
    }).then((result) => {
      if (!result.value || !result.value?.title || !result.value?.slug) return;

      const requestCreateGroupCategoryCanceler = requestCanceler();

      requestCancelers.current.push({ key: 'create_group_category', canceler: requestCreateGroupCategoryCanceler });

      dispatch(createGroupCategory(group._id, {
        short_title: result.value.title,
        slug: result.value.slug,
      }, requestCreateGroupCategoryCanceler.token))
        .then(() => toast('success', 'Категория успешно создана'))
        .catch(({ message }) => toast('error', message));
    });
  };

  const handleDeleteGroup = useCallback(() => {
    showDeleteItemDialogue('группу', () => {
      const requestDeleteGroupCanceler = requestCanceler();

      requestCancelers.current.push({ key: 'delete_group', canceler: requestDeleteGroupCanceler });

      dispatch(deleteGroup(group._id, requestDeleteGroupCanceler.token))
        .then(() => toast('success', 'Группа удалена'))
        .catch(({ message }) => toast('error', `Ошибка удаления группы, ${message}`));
    });
  }, [dispatch, group._id, showDeleteItemDialogue]);

  const handleDeleteGroupCategory = useCallback((groupId, groupCategoryId) => {
    showDeleteItemDialogue('категорию', () => {
      const requestDeleteGroupCanceler = requestCanceler();

      requestCancelers.current.push({ key: 'delete_group_category', canceler: requestDeleteGroupCanceler });

      dispatch(deleteGroupCategory(groupId, groupCategoryId, requestDeleteGroupCanceler.token))
        .then(() => toast('success', 'Категория удалена'))
        .catch(({ message }) => toast('error', `Ошибка удаления группы, ${message}`));
    });
  }, [dispatch, showDeleteItemDialogue]);

  const handleUpdateGroup = useCallback((data) => {
    if (!data.short_title) {
      toast('error', 'Укажите короткое название группы');

      return;
    }

    const requestUpdateGroupCanceler = requestCanceler();

    requestCancelers.current.push({ key: 'update_group', canceler: requestUpdateGroupCanceler });

    dispatch(updateGroup(data, requestUpdateGroupCanceler.token))
      .then(() => toast('success', 'Группа обновлена'))
      .catch(({ message }) => toast('error', message));
  }, [dispatch]);

  const onDragEnd = useCallback((result) => {
    const {
      destination, source, draggableId, type,
    } = result;

    if (!destination) return;

    if (destination.index === source.index) return;

    if (type === 'groupCategory') {
      dispatch(setGroupCategoriesOrder({
        groupId: group._id,
        categoryId: draggableId, // id перемещаемой категории
        currentPosition: source.index, // индекс текущей перемещаемой категории
        newPosition: destination.index, // индекс новой позиции в массиве
      }));

      const prepared = groupCategories.docs.map((item, order) => ({ _id: item._id, order }));

      const requestSaveGroupCategoriesOrderCanceler = requestCanceler();

      requestCancelers.current.push({ key: 'save_group_categories_order', canceler: requestSaveGroupCategoriesOrderCanceler });

      dispatch(saveGroupCategoriesOrder(group._id, prepared, requestSaveGroupCategoriesOrderCanceler.token))
        .then(() => toast('success', 'Список успешно отсортирован'));
    }
  }, [dispatch, group._id, groupCategories]);

  useEffect(() => {
    if (!prevIsFormVisible && isFormVisible) getGroupCategories();

    if (prevIsFormVisible && !isFormVisible) {
      requestCancelers.current
        .filter((item) => item.key === 'get_group_categories')
        .forEach((item) => item.canceler.cancelRequest());
    }
  }, [getGroupCategories, isFormVisible, prevIsFormVisible]);

  useEffect(() => {
    if (prevGroup && prevGroup.isUnsaved && !group.isUnsaved) {
      setShortTitle(group.short_title);
    }
  }, [group, prevGroup]);

  useEffect(() => () => requestCancelers.current.forEach((item) => item.canceler.cancelRequest()), []);

  return (
    <div
      className={classNames('template-bunch-item', {
        unsaved: group.isUnsaved,
        opened: isFormVisible,
      })}
    >
      <div className="template-bunch-item__header">
        <button className="template-bunch-item__button template-bunch-item__button_title" type="button" onClick={handleSetIsFormVisible}>
          {shortTitle}
          {isFormVisible
            ? <FontAwesomeIcon icon={['far', 'chevron-down']} />
            : <FontAwesomeIcon icon={['far', 'chevron-right']} />}
        </button>
        {isUpdating && <Preloader medium />}
        <span className="template-bunch-item__count">
          Категории:&nbsp;
          {group.count_categories || 0}
        </span>
        <button
          className={`template-bunch-item__button template-bunch-item__button_edit${isFormVisible ? ' is-active' : ''}`}
          type="button"
          onClick={handleSetIsFormVisible}
        >
          <FontAwesomeIcon icon={['fas', 'pencil']} />
        </button>
        <button onClick={handleDeleteGroup} disabled={isDeleting} className="template-bunch-item__button template-bunch-item__button_delete" type="button">
          <FontAwesomeIcon icon={['fal', 'times']} />
        </button>
      </div>
      {isFormVisible && (
        <>
          <div className="template-bunch-item__main">
            <GroupsGroupSettings
              data={group}
              isUpdating={isUpdating}
              onUpdateGroup={handleUpdateGroup}
            />
          </div>
          <div className="template-bunch-item__footer">
            <Button disabled={isUpdating || groupCategories?.isFetching} variant="primary" onClick={handleCreateCategory}>
              Добавить категорию
            </Button>
            {groupCategories?.isFetching && <Preloader medium />}
            {groupCategories && groupCategories.docs.length > 0 && (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable
                  droppableId="all-group-categories"
                  direction="vertical"
                  type="groupCategory"
                >
                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className="template-bunch"
                    >
                      {groupCategories.docs.map((groupCategory, index) => (
                        <Draggable draggableId={String(groupCategory._id)} index={index} key={groupCategory._id}>
                          {(provided2, snapshot) => (
                            <div
                              {...provided2.draggableProps}
                              ref={provided2.innerRef}
                              className={classNames('template-bunch__item', {
                                'template-bunch-item-categories__category': snapshot.isDragging,
                              })}
                            >
                              <GroupsGroupCategory
                                data={groupCategory}
                                groupId={group._id}
                                key={groupCategory._id}
                                lang={lang}
                                onDeleteGroupCategory={handleDeleteGroupCategory}
                              />
                              <div className="template-bunch__item-drag" {...provided2.dragHandleProps} />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            )}
          </div>
        </>
      )}
    </div>
  );
};

GroupsGroup.propTypes = {
  group: PropTypes.object,
  groupCategories: PropTypes.object,
  isDeleting: PropTypes.bool,
  isUpdating: PropTypes.bool,
  lang: PropTypes.string.isRequired,
};

export default memo(GroupsGroup);
