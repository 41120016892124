import { connect } from 'react-redux';

import {
  RESET_OBJECT_CATEGORIES,
  createObjectCategory,
} from '../../../../actions/objects/categories';

import ObjectCategoriesCreate from './CategoriesCreate';

const mapStateToProps = (state) => ({
  objectCategories: state.objectCategories,
});

const mapDispatchToProps = (dispatch) => ({
  onCreateObjectCategory: (data, requestToken) => dispatch(createObjectCategory(data, requestToken)),
  onUnload: () => dispatch({ type: RESET_OBJECT_CATEGORIES }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ObjectCategoriesCreate);
