import { connect } from 'react-redux';

import Sizes from './Sizes';

import {
  RESET_SIZES,
  createSize,
  deleteSize,
  getAllCategories,
  getSizes,
  saveSizesOrder,
  setSizesOrder,
  updateSize,
} from '../../../../actions';

const mapStateToProps = (state) => ({
  sizes: state.sizes,
});

const mapDispatchToProps = (dispatch) => ({
  onCreateSize: (data, requestToken) => dispatch(createSize(data, requestToken)),
  onDeleteSize: (id, requestToken) => dispatch(deleteSize(id, requestToken)),
  onGetCategories: (params, requestToken) => dispatch(getAllCategories(params, requestToken)),
  onGetSizes: (params, requestToken) => dispatch(getSizes(params, requestToken)),
  onSaveSizesOrder: (data, requestToken) => dispatch(saveSizesOrder(data, requestToken)),
  onSetSizesOrder: (orderData) => dispatch(setSizesOrder(orderData)),
  onUpdateSize: (data, requestToken) => dispatch(updateSize(data, requestToken)),
  onUnload: () => dispatch({ type: RESET_SIZES }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sizes);
