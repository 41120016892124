import PropTypes from 'prop-types';
import slugify from 'slugify';
import queryString from 'query-string';
import React, { memo, useState } from 'react';
import { Button, Dropdown, Form } from 'react-bootstrap';

import { languages } from '../../../../constants/common';

import Preloader from '../../../UI/Preloader/Preloader';

const IdeasForm = ({
  buttonText,
  data,
  disabled,
  location,
  onSubmit = () => {},
}) => {
  const [formData, setFormData] = useState(
    data?._id
      ? {
        ...data,
        description: data.description.replace(/<br \/>/g, '\n'),
        templates: data.templates.join('\n'),
      }
      : {
        title: '',
        description: '',
        templates: '',
        slug: '',
        lang: queryString.parse(location.search)?.lang || 'ru',
      },
  );

  const [isSlugPrestine, setIsSlugPrestine] = useState(!data?.slug);

  const handleChangeFormData = (props) => {
    setFormData((prev) => ({ ...prev, ...props }));
  };

  const handleChangeTitle = (value) => {
    handleChangeFormData({
      title: value,
      ...isSlugPrestine ? {
        slug: slugify(value, { replacement: '-', lower: true }),
      } : {},
    });
  };

  const handleChangeDescription = (value) => {
    handleChangeFormData({ description: value });
  };

  const handleChangeLang = (value) => {
    handleChangeFormData({ lang: value });
  };

  const handleChangeTemplatesList = (value) => {
    handleChangeFormData({ templates: value });
  };

  const handleChangeSlug = (value) => {
    setIsSlugPrestine(false);

    handleChangeFormData({ slug: value });
  };

  const handleSubmit = () => {
    if (!formData.title.trim()) return;
    if (!formData.slug.trim()) return;

    const prepared = { ...formData };

    prepared.description = prepared.description.replace(/\n/g, '<br />');

    const templates = prepared.templates.trim().split(/\r\n|\r|\n/g);

    prepared.templates = templates
      .filter((item) => {
        if (!item) return;

        if (!/^\d+$/.test(item)) return;

        // eslint-disable-next-line
        return item;
      })
      .map((item) => +item)
      .filter((item, index, arr) => arr.indexOf(item) === index);

    // eslint-disable-next-line
    if (prepared.__v) delete prepared.__v;

    onSubmit(prepared);
  };

  return (
    <div className="ideas__form">
      <div className="ideas__form-row ideas__form-lang">
        <div className="ideas__form-label">Язык:</div>
        <Dropdown>
          <Dropdown.Toggle as="button" className="btn btn-light" disabled={disabled}>
            {formData.lang && languages[formData.lang]}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {Object.keys(languages).map((lang) => (
              formData.lang !== lang ? (
                <Dropdown.Item
                  as="button"
                  eventKey={lang}
                  key={lang}
                  onSelect={handleChangeLang}
                >
                  {languages[lang]}
                </Dropdown.Item>
              ) : null
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="ideas__form-row ideas__form-title">
        <div className="ideas__form-label">Заголовок:</div>
        <Form.Control
          disabled={disabled}
          value={formData.title}
          onChange={(e) => handleChangeTitle(e.target.value)}
        />
      </div>
      <div className="ideas__form-row ideas__form-alias">
        <div className="ideas__form-label">{`Алиас${data?._id ? ' (*)' : ''}:`}</div>
        <Form.Control
          disabled={disabled}
          value={formData.slug}
          onChange={(e) => handleChangeSlug(e.target.value)}
        />
      </div>
      <div className="ideas__form-row ideas__form-description">
        <div className="ideas__form-label">Описание:</div>
        <Form.Control
          as="textarea"
          disabled={disabled}
          value={formData.description}
          onChange={(e) => handleChangeDescription(e.target.value)}
        />
      </div>
      <div className="ideas__form-row ideas__form-templates">
        <div className="ideas__form-label">Шаблоны (id шаблонов, 1 шаблон = 1 строка)</div>
        <Form.Control
          as="textarea"
          disabled={disabled}
          value={formData.templates}
          onChange={(e) => handleChangeTemplatesList(e.target.value)}
        />
      </div>
      <div className="ideas__form-row ideas__form-submit">
        <Button
          className="btn-success"
          disabled={disabled || !formData.title.trim() || !formData.slug.trim() || !formData.templates}
          onClick={handleSubmit}
        >
          {buttonText}
        </Button>
        {disabled && (
          <div className="ideas__form-preloader">
            <Preloader medium />
          </div>
        )}
      </div>
    </div>
  );
};

IdeasForm.propTypes = {
  buttonText: PropTypes.string,
  data: PropTypes.shape({
    _id: PropTypes.string,
    slug: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    templates: PropTypes.array,
    lang: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  location: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default memo(IdeasForm);
