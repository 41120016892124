import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Dropdown, Form } from 'react-bootstrap';

import Preloader from '../../../../UI/Preloader/Preloader';
import CheckBox from '../../../../UI/CheckBox/CheckBox';

import './CategoryFormContent.scss';

const CategoryFormContent = memo((props) => {
  const {
    isFormDisabled,
    isFetching,
    isMenusFetching,
    data,
    mode,
    menus,
    onSubmit,
    onUpdate,
  } = props;

  const handleChangeAlias = (value) => {
    const regExp = /^[a-z0-9]+(-[a-z0-9]+)*-?$/g;
    const isValid = regExp.test(value);

    if (!isValid && value) return;

    onUpdate({ alias: value.trim() });
  };

  const handleToggleShowPageNavigation = useCallback((state) => {
    onUpdate({ show_page_navigation: state });
  }, [onUpdate]);

  const handleToggleShowHeaders = useCallback((state) => {
    onUpdate({ show_headers: state });
  }, [onUpdate]);

  const handleChooseMenuId = (id) => () => onUpdate({ side_menu_id: id });

  return (
    <form className="category-form landing-form" onSubmit={onSubmit}>
      <h3 className="landing-form__title">{`${mode === 'create' ? 'Добавить' : 'Обновить'} категорию`}</h3>
      <div className="landing-form__fields">
        <div className="landing-form__field">
          <div className="landing-form__label" title="Название">Название:</div>
          <div className="landing-form__value" data-name="name">
            <Form.Control
              disabled={isFetching}
              value={data.name}
              onChange={(e) => onUpdate({ name: e.target.value })}
            />
          </div>
        </div>
        <div className="landing-form__field">
          <div className="landing-form__label" title="Алиас">Алиас:</div>
          <div className="landing-form__value" data-name="alias">
            <Form.Control
              disabled={isFetching}
              value={data.alias}
              onChange={(e) => handleChangeAlias(e.target.value)}
            />
          </div>
        </div>
        <div className="landing-form__field">
          <div className="landing-form__label" title="Боковое меню">Боковое меню:</div>
          <div className="landing-form__value" data-name="menu">
            <Dropdown className="landing__dropdown">
              <Dropdown.Toggle
                as="button"
                type="button"
                className="btn btn-light"
                disabled={isFetching || isMenusFetching}
              >
                {menus.find((menu) => menu._id === data.side_menu_id)?.name || 'Выберите меню...'}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  as="button"
                  type="button"
                  onClick={handleChooseMenuId('')}
                >
                  Выберите меню...
                </Dropdown.Item>
                {menus.map((menu) => (
                  <Dropdown.Item
                    key={menu._id}
                    active={menu._id === data.side_menu_id}
                    as="button"
                    type="button"
                    onClick={handleChooseMenuId(menu._id)}
                  >
                    {menu.name}
                  </Dropdown.Item>
                ))}
                {menus.length === 0 && (
                  <Dropdown.Item
                    as="button"
                    type="button"
                    style={{ opacity: 0.5, pointerEvents: 'none' }}
                  >
                    Список пуст
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        {!!data.side_menu_id && (
          <div className="landing-form__field">
            <div className="landing-form__label" />
            <div className="landing-form__value" data-name="checkboxes">
              <CheckBox
                checked={!!data.show_page_navigation}
                disabled={isFetching}
                className="flat landing-form__checkbox"
                id="page-navigation-checkbox"
                onChange={handleToggleShowPageNavigation}
              >
                {`${'Кнопки "Следующая / Предыдущая страница"'}`}
              </CheckBox>
              <CheckBox
                checked={!!data.show_headers}
                disabled={isFetching}
                className="flat landing-form__checkbox"
                id="show-headers-checkbox"
                onChange={handleToggleShowHeaders}
              >
                Выводить в меню заголовки H2
              </CheckBox>
            </div>
          </div>
        )}

      </div>
      <div className="landing-form__actions">
        <Button
          disabled={isFormDisabled}
          variant="success"
          className="landing-form__action"
          type="submit"
        >
          {`${mode === 'create' ? 'Добавить' : 'Обновить'} категорию`}
        </Button>
        <Link to="/landing/pages" className="landing-form__action landing-form__action_link">
          Назад
        </Link>

        {(isFetching || isMenusFetching) && <Preloader medium />}
      </div>
    </form>
  );
});

CategoryFormContent.propTypes = {
  isFormDisabled: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isMenusFetching: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  menus: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default CategoryFormContent;
