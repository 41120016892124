export const GET_PARTNERS = 'GET_PARTNERS';
export const RESET_PARTNERS = 'RESET_PARTNERS';
export const MAKE_PARTNER_PAYOUT = 'MAKE_PARTNER_PAYOUT';
export const DOWNLOAD_ELAMA_USER_LIST = 'DOWNLOAD_ELAMA_USER_LIST';

/* --------------- */
/* Action creators */

/* --------------- */

export function downloadElamaUsers(data, requestToken) {
  return {
    type: DOWNLOAD_ELAMA_USER_LIST,
    request: {
      method: 'post',
      url: '/admin/elama-users/list',
      data,
      requestToken,
    },
  };
}

export function getPartners(params, isSearch, requestToken) {
  return {
    type: GET_PARTNERS,
    request: {
      method: 'get',
      url: '/admin/partners',
      params,
      requestToken,
    },
    payload: { isSearch },
  };
}

export function makePayout(userId, sum, requestToken) {
  return {
    type: MAKE_PARTNER_PAYOUT,
    request: {
      method: 'post',
      url: `/admin/partners/${userId}/payout`,
      data: { sum: String(sum) },
      requestToken,
    },
    payload: { userId, sum },
  };
}
