import React from 'react';
import { useSelector } from 'react-redux';

import './Header.scss';

import Logo from '../../UI/Logo/Logo';

import HeaderPrimaryNav from './HeaderPrimaryNav/HeaderPrimaryNav';
import HeaderSecondaryNav from './HeaderSecondaryNav/HeaderSecondaryNav';

function Header() {
  const user = useSelector((state) => state.auth.user);

  return (
    <header className="header">
      <div className="header__logo">
        <Logo href={window.SUPA_DASHBOARD_URL} title="SUPA" target="_blank" />
      </div>
      <div className="header__nav">
        <HeaderPrimaryNav user={user} />
      </div>
      <div className="header__nav">
        <HeaderSecondaryNav />
      </div>
    </header>
  );
}

export default Header;
