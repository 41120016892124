import PropTypes from 'prop-types';
import React from 'react';
import queryString from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { requestCanceler } from '../../../../middlewares/api';

import { toast } from '../../../../utils';

import errorMessages from '../../../../constants/errors';

import View from '../../../Layout/View/View';

import SearchForm from '../../../Includes/SearchForm/SearchForm';

import NoMedia from '../../../UI/NoMedia/NoMedia';

import PartnersPartner from './PartnersPartner/PartnersPartner';
import PartnersNav from '../PartnersNav';

import './Partners.scss';

class Partners extends React.PureComponent {
  requestCancelers = [];

  componentDidMount() {
    const { location: { search }, partners } = this.props;

    const isSearch = queryString.parse(search)?.search;

    if (Boolean(isSearch) || !partners.docs.length) this.getPartners();
  }

  componentDidUpdate(prevProps) {
    const { location, partners } = this.props;
    const { error } = partners;

    if (location.search !== prevProps.location.search) this.getPartners(true);

    if (error && error.message && error !== prevProps.partners.error) {
      const message = ['Network Error', 'permission_denied', 'server_error', 'admin_privileges_required'].includes(error.message)
        ? errorMessages[error.message]
        : error.message;

      toast('error', message);
    }
  }

  componentWillUnmount() {
    const { onUnload } = this.props;

    this.requestCancelers.forEach((canceler) => canceler.cancelRequest());

    onUnload();
  }

  getPartners = (isSearch = false) => {
    const { onGetPartners, location } = this.props;

    const requestParams = {};

    if (location.search) {
      Object.assign(requestParams, queryString.parse(location.search));
    }

    const requestGetVideosCanceler = requestCanceler();

    this.requestCancelers.push(requestGetVideosCanceler);

    onGetPartners(requestParams, isSearch, requestGetVideosCanceler.token).catch(() => {});
  };

  handleSearchPartners = (query) => {
    const { history, location } = this.props;

    const queryStr = query ? `/partners?search_query=${query}` : '/partners';

    if (queryString.parse(location.search)?.search_query === query) {
      this.getPartners(true);
    } else {
      history.push(queryStr);
    }
  };

  renderListHeader = () => (
    <div className="partner partners__list-header">
      <div className="partner__email">Логин</div>
      <div className="partner__sum-ready">К выплате</div>
      <div className="partner__sum-avail">Заработано</div>
      <div className="partner__sum-payout">Выплачено</div>
      <div className="partner__requisites">Реквизиты</div>
      <div className="partner__pay-form">Выплатить</div>
    </div>
  );

  render() {
    const {
      partners,
      onChangeUser,
      onMakePayout,
    } = this.props;
    const { error, isFetching } = partners;

    return (
      <View
        errorMessage={errorMessages[error?.message]}
        isError={error && ['admin_privileges_required', 'first_request_failed'].includes(error.message)}
        isFetching={isFetching}
        viewClass="partners"
        header={<PartnersNav />}
        content={(
          <>
            <div className="partners__list-wrapper">
              <h4 className="partners__title">Готовы к выплате</h4>
              <div className="partners__list">
                {this.renderListHeader()}
                {partners.docs.map((partner) => partner.sum_avail >= partner.payout_min && (
                  <div className="partners__list-partner" key={partner.id + partner._id}>
                    <PartnersPartner
                      partner={partner}
                      readyToPay
                      onChangeUser={onChangeUser}
                      onMakePayout={onMakePayout}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="partners__list-wrapper">
              <h4 className="partners__title">Партнеры</h4>
              <div className="partners__search">
                <SearchForm
                  className="partners__search-form"
                  defaultValue={queryString.parse(window.location.search)?.search_query}
                  placeholder="Поиск по логину или ID"
                  onSearch={this.handleSearchPartners}
                />
              </div>
              <div className="partners__list">
                {this.renderListHeader()}
                {partners.docs.map((partner) => (
                  <div className="partners__list-user" key={partner.id + partner._id}>
                    <PartnersPartner
                      partner={partner}
                      onChangeUser={onChangeUser}
                      onMakePayout={onMakePayout}
                    />
                  </div>
                ))}
              </div>
            </div>
            {!partners.docs.length && !isFetching && (
              <div className="partners__no-media">
                <NoMedia
                  caption="Ничего не найдено"
                  icon={<FontAwesomeIcon icon={['fas', 'exclamation-circle']} />}
                />
              </div>
            )}
          </>
        )}
      />
    );
  }
}

Partners.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  partners: PropTypes.object.isRequired,
  onChangeUser: PropTypes.func.isRequired,
  onGetPartners: PropTypes.func.isRequired,
  onMakePayout: PropTypes.func.isRequired,
  onUnload: PropTypes.func.isRequired,
};

export default Partners;
