import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { toast as toastMessage } from 'react-toastify';

import { permissions } from '../constants/common';

export function baseName(str) {
  let base = String(str).substring(str.lastIndexOf('/') + 1);

  if (base.lastIndexOf('.') !== -1) base = base.substring(0, base.lastIndexOf('.'));

  return base;
}

export function getAbbrStr(str) {
  const splitWords = str.trim().split(' ');

  let abbrev = splitWords[0].charAt(0);

  if (splitWords.length > 1) abbrev += splitWords[1].charAt(0);

  return abbrev.toUpperCase();
}

export function deepFind(array, predicate, source) {
  const keys = Object.keys(predicate);

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < array.length; i++) {
    let match = true;

    // eslint-disable-next-line no-plusplus
    for (let j = 0; j < keys.length; j++) {
      if (array[i][keys[j]] !== predicate[keys[j]]) {
        match = false;

        break;
      }
    }

    if (match) {
      return array[i];
    }
  }

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < array.length; i++) {
    if (array[i][source] && array[i][source].length) {
      const result = deepFind(array[i][source], predicate, source);

      if (result) return result;
    }
  }

  return undefined;
}

export function isTabVisibleWatcher(visibilityChangeCallback) {
  let hidden;
  let visibilityChange;

  if (typeof document.hidden !== 'undefined') {
    hidden = 'hidden';
    visibilityChange = 'visibilitychange';
  } else if (typeof document.mozHidden !== 'undefined') {
    hidden = 'mozHidden';
    visibilityChange = 'mozvisibilitychange';
  } else if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden';
    visibilityChange = 'msvisibilitychange';
  } else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden';
    visibilityChange = 'webkitvisibilitychange';
  }

  const visibilityChangedHandler = () => {
    if (document[hidden]) visibilityChangeCallback();
  };

  document.addEventListener(visibilityChange, visibilityChangedHandler, { capture: true });

  const onUnload = () => document.removeEventListener(
    visibilityChange, visibilityChangedHandler, { capture: true },
  );

  return onUnload;
}

export function queryParams(search) {
  return new URLSearchParams(search);
}

export const round = (value, dp) => parseFloat(value.toFixed(dp));

export const smoothScrollTo = (() => {
  let timer;
  let start;
  let factor;

  return (target, duration = 1000, offsetTop = 0) => {
    const offset = window.pageYOffset;
    const delta = target.offsetTop - window.pageYOffset - offsetTop; // Y-offset difference

    start = Date.now(); // get start time
    factor = 0;

    if (timer) {
      clearInterval(timer); // stop any running animation
    }

    const step = () => {
      factor = (Date.now() - start) / duration; // get interpolation factor

      if (factor >= 1) {
        clearInterval(timer); // stop animation
        factor = 1; // clip to max 1.0
      }
      const y = factor * delta + offset;
      window.scrollBy(0, y - window.pageYOffset);
    };

    timer = setInterval(step, 10);

    return timer; // return the interval timer, so you can clear it elsewhere
  };
})();

export function s3Replace(str) {
  let result = str;

  if (result) {
    const replaces = [
      ['https://supa-dev-library-cache.s3.amazonaws.com', 'https://s3.eu-central-1.amazonaws.com/supa-dev-library-cache'],
      ['https://supa-dev-previews.s3.amazonaws.com', 'https://s3.eu-central-1.amazonaws.com/supa-dev-previews'],
      ['https://supa-dev-templates.s3.amazonaws.com', 'https://s3.eu-central-1.amazonaws.com/supa-dev-templates'], // Order is required
      ['https://supa-dev-temp.s3.amazonaws.com', 'https://s3.eu-central-1.amazonaws.com/supa-dev-temp'], // Order is required
      ['https://supa-dev-uploads.s3.amazonaws.com', 'https://s3.eu-central-1.amazonaws.com/supa-dev-uploads'],

      ['https://supa-content.s3.amazonaws.com', 'https://s3.eu-central-1.amazonaws.com/supa-content'],
      ['https://supa-fonts.s3.amazonaws.com', 'https://s3.eu-central-1.amazonaws.com/supa-fonts'],
      ['https://supa-landing.s3.amazonaws.com', 'https://s3.eu-central-1.amazonaws.com/supa-landing'],
      ['https://supa-library-cache.s3.amazonaws.com', 'https://s3.eu-central-1.amazonaws.com/supa-library-cache'],
      ['https://supa-previews.s3.amazonaws.com', 'https://s3.eu-central-1.amazonaws.com/supa-previews'],
      ['https://supa-templates.s3.amazonaws.com', 'https://s3.eu-central-1.amazonaws.com/supa-templates'], // Order is required
      ['https://supa-temp.s3.amazonaws.com', 'https://s3.eu-central-1.amazonaws.com/supa-temp'], // Order is required
      ['https://supa-uploads.s3.amazonaws.com', 'https://s3.eu-central-1.amazonaws.com/supa-uploads'],

      // Yandex Storage
      ['https://s3.eu-central-1.amazonaws.com/supa-dev-library-cache', 'https://supa-dev-library-cache.storage.yandexcloud.net'],
      ['https://s3.eu-central-1.amazonaws.com/supa-dev-previews', 'https://supa-dev-previews.storage.yandexcloud.net'],
      ['https://s3.eu-central-1.amazonaws.com/supa-dev-templates', 'https://supa-dev-templates.storage.yandexcloud.net'], // Order is required
      ['https://s3.eu-central-1.amazonaws.com/supa-dev-temp', 'https://supa-dev-temp.storage.yandexcloud.net'], // Order is required
      ['https://s3.eu-central-1.amazonaws.com/supa-dev-uploads', 'https://supa-dev-uploads.storage.yandexcloud.net'],

      ['https://s3.eu-central-1.amazonaws.com/supa-content', 'https://supa-content.storage.yandexcloud.net'],
      ['https://s3.eu-central-1.amazonaws.com/supa-fonts', 'https://supa-fonts.storage.yandexcloud.net'],
      ['https://s3.eu-central-1.amazonaws.com/supa-landing', 'https://supa-landing.storage.yandexcloud.net'],
      ['https://s3.eu-central-1.amazonaws.com/supa-library-cache', 'https://supa-library-cache.storage.yandexcloud.net'],
      ['https://s3.eu-central-1.amazonaws.com/supa-previews', 'https://supa-previews.storage.yandexcloud.net'],
      ['https://s3.eu-central-1.amazonaws.com/supa-templates', 'https://supa-templates.storage.yandexcloud.net'], // Order is required
      ['https://s3.eu-central-1.amazonaws.com/supa-temp', 'https://supa-temp.storage.yandexcloud.net'], // Order is required
      ['https://s3.eu-central-1.amazonaws.com/supa-uploads', 'https://supa-uploads.storage.yandexcloud.net'],
    ];

    replaces.forEach((item) => {
      result = result.replace(new RegExp(item[0], 'g'), item[1]);
    });
  }

  return result;
}

export function toast(type, msg, autoClose = 4000) { // time = bool or number
  toastMessage[type](<>{ReactHtmlParser(msg)}</>, {
    position: 'top-right',
    autoClose,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: true,
  });
}

export const normalizeParams = (params, allowed) => {
  // eslint-disable-next-line
  Object.keys(params).forEach((param) => {
    // eslint-disable-next-line
    if (allowed[param] && !allowed[param].includes(params[param])) params[param] = allowed[param][0];
  });

  return params;
};

export const copyToClipboard = (text) => {
  const textarea = document.createElement('textarea');
  textarea.value = text.trim();

  document.body.appendChild(textarea);

  // Copy the text in the fake `textarea` and remove the `textarea`
  textarea.select();
  textarea.setSelectionRange(0, 99999);

  document.execCommand('copy');

  document.body.removeChild(textarea);
};

export const isPermissionAccept = (user, permission) => {
  if (!user) return false;

  const { admin_permissions } = user;

  if (!admin_permissions) return false;

  if (admin_permissions.includes(permissions.ALL)) return true;

  return admin_permissions.includes(permission);
};

export const pluralize = (number, formats) => {
  const n = Math.abs(number) % 100;
  const n1 = n % 10;

  if (n > 10 && n < 20) return formats[2];
  if (n1 > 1 && n1 < 5) return formats[1];
  if (n1 === 1) return formats[0];

  return formats[2];
};

export const validateEmail = (email) => email.match(
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
);

export const generateId = (size = 24) => [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');

export function isURLValid(str) {
  const patternUrl = new RegExp('^(https?:\\/\\/)?' // protocol
    + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' // domain name
    + '((\\d{1,3}\\.){3}\\d{1,3}))' // OR ip (v4) address
    + '(\\:\\d+)?(\\/[-a-z\\d@%_.~+]*)*' // port and path
    + '(\\?[;&a-z\\d%_.~+=-]*)?' // query string
    + '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator

  const patternRelativeUrl = new RegExp('^(\\/[-a-zA-Z\\d@%_.~+]*)+(\\?[;&a-zA-Z\\d%_.~+=-]*)?(#[-a-z\\d_]*)?$', 'i');
  const patternMail = new RegExp('^mailto:[\\w]{1}[\\w-\\.]*@[\\w-]+\.[a-z]{2,5}$', 'i');
  const patternPhone = new RegExp('^tel:\\+?\\d{4,14}$');
  const patternSMS = new RegExp('^sms:\\+?\\d{4,14}(\\?body=[a-zA-Zа-яА-ЯЁё0-9 ,.]{1,100})?$');

  // eslint-disable-next-line
  return !!patternUrl.test(str) || !!patternRelativeUrl.test(str) || !!patternMail.test(str) || !!patternPhone.test(str) || !!patternSMS.test(str);
}

export function downloadFile(blob, fileName) {
  let a = document.createElement('a');

  a.href = window.URL.createObjectURL(blob);
  a.download = fileName.replace(/"|:|\/|\\|\?|<|>|\*/g, '');

  a.click();

  window.URL.revokeObjectURL(a.href);

  a = undefined;
}
