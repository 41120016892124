export const CREATE_PROMOCODE = 'CREATE_PROMOCODE';
export const GET_PROMOCODES = 'GET_PROMOCODES';
export const RESET_PROMOCODES = 'RESET_PROMOCODES';
export const UPDATE_PROMOCODE = 'UPDATE_PROMOCODE';

/* --------------- */
/* Action creators */

/* --------------- */

export function getPromocodes(requestToken) {
  return {
    type: GET_PROMOCODES,
    request: {
      method: 'get',
      url: '/admin/promocodes',
      requestToken,
    },
  };
}

export function updatePromocode(id, data, requestToken) {
  return {
    type: UPDATE_PROMOCODE,
    request: {
      method: 'patch',
      url: `/admin/promocodes/${id}`,
      data,
      requestToken,
    },
    payload: { id, data },
  };
}

export function createPromocode(data, requestToken) {
  return {
    type: CREATE_PROMOCODE,
    request: {
      method: 'post',
      url: '/admin/promocodes',
      data,
      requestToken,
    },
  };
}
