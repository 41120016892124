import { serialize as objectToFormData } from 'object-to-formdata';

export const DOWNLOAD_MAILING_LIST = 'DOWNLOAD_MAILING_LIST';
export const UNSUBSCRIBE_MAILING_LIST = 'UNSUBSCRIBE_MAILING_LIST';
export const RESET_MAILING = 'RESET_MAILING';

/* --------------- */
/* Action creators */

/* --------------- */

export function downloadList(data, requestToken) {
  return {
    type: DOWNLOAD_MAILING_LIST,
    request: {
      method: 'post',
      url: '/admin/mailing/list',
      data,
      requestToken,
    },
  };
}

export function unsubscribeList(data, requestToken) {
  const formData = objectToFormData(data);

  return {
    type: UNSUBSCRIBE_MAILING_LIST,
    request: {
      method: 'post',
      url: '/admin/mailing/unsubscribe',
      data: formData,
      requestToken,
    },
  };
}
