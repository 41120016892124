import {
  GET_ADMIN_USER,
  GET_ADMIN_USERS,
  ADD_ADMIN_PRIV,
  UPDATE_ADMIN_PRIV,
  DELETE_ADMIN_PRIV,
  RESET_ACCESS_ADMINS,
} from '../../actions';

const defaultState = {
  docs: [],
  error: null,
  isFetching: false,
  page: 0,
  pages: 1,
};

function returnError(response) {
  if (response.message) return { message: response.message, ...(response.params && { params: response.params }) };
  if (response.errors) return { message: response.errors[0] };

  return { message: 'admin_privileges_required' };
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case `${GET_ADMIN_USERS}_REQUEST`:
      return {
        ...state,
        docs: action.payload.isSearch ? [] : state.docs,
        error: null,
        isFetching: true,
      };
    case `${GET_ADMIN_USER}_REQUEST`:
    case `${ADD_ADMIN_PRIV}_REQUEST`:
    case `${UPDATE_ADMIN_PRIV}_REQUEST`:
      return {
        ...state,
        error: null,
        isFetching: true,
      };
    case GET_ADMIN_USERS:
      return {
        ...state,
        ...action.response,
        docs: action.response.page === 1
          ? action.response.docs
          : [...state.docs, ...action.response.docs],
        isFetching: false,
      };
    case GET_ADMIN_USER:
    case ADD_ADMIN_PRIV:
    case UPDATE_ADMIN_PRIV:
      return {
        ...state,
        error: null,
        isFetching: false,
      };
    case DELETE_ADMIN_PRIV:
      return {
        ...state,
        docs: state.docs.filter((item) => item.id !== action.payload.id),
      };
    case `${GET_ADMIN_USER}_FAILURE`:
    case `${GET_ADMIN_USERS}_FAILURE`:
    case `${ADD_ADMIN_PRIV}_FAILURE`:
    case `${UPDATE_ADMIN_PRIV}_FAILURE`:
      return {
        ...state,
        error: returnError(action.response),
        isFetching: false,
      };
    case RESET_ACCESS_ADMINS:
      return defaultState;
    default:
      return state;
  }
};
