export const GET_INVOICES = 'GET_INVOICES';
export const PAY_INVOICE = 'PAY_INVOICE';
export const SET_INVOICE_BONUS = 'SET_INVOICE_BONUS';
export const UPDATE_INVOICE_PAY_DATE = 'UPDATE_INVOICE_PAY_DATE';
export const UPDATE_INVOICE_DATE = 'UPDATE_INVOICE_DATE';
export const UPDATE_INVOICE_CLOSE_DATE = 'UPDATE_INVOICE_CLOSE_DATE';
export const UPDATE_INVOICE_COMPANY = 'UPDATE_INVOICE_COMPANY';
export const GET_INVOICE_BLANK = 'GET_INVOICE_BLANK';
export const RESET_INVOICES = 'RESET_INVOICES';

/* --------------- */
/* Action creators */

/* --------------- */

export function getInvoices(params, isSearch, requestToken) {
  return {
    type: GET_INVOICES,
    request: {
      method: 'get',
      url: '/admin/invoices',
      params,
      requestToken,
    },
    payload: { isSearch },
  };
}

export function payInvoice(id, requestToken) {
  return {
    type: PAY_INVOICE,
    request: {
      method: 'post',
      url: `/admin/invoices/${id}/pay`,
      requestToken,
    },
  };
}

export function toggleBonusInvoice(id, bonus, requestToken) {
  return {
    type: SET_INVOICE_BONUS,
    request: {
      method: 'put',
      url: `/admin/invoices/${id}`,
      data: { bonus },
      requestToken,
    },
    payload: { id, bonus },
  };
}

export function updateInvoicePayDate({ id, pay_date }, requestToken) {
  return {
    type: UPDATE_INVOICE_PAY_DATE,
    request: {
      method: 'patch',
      url: `/admin/invoices/${id}/update-pay-date`,
      requestToken,
      data: { pay_date },
    },
    payload: { id, pay_date },
  };
}

export function updateInvoiceDate({ id, date }, requestToken) {
  return {
    type: UPDATE_INVOICE_DATE,
    request: {
      method: 'patch',
      url: `/admin/invoices/${id}/update-date`,
      requestToken,
      data: { date },
    },
    payload: { id, date },
  };
}

export function updateInvoiceCloseDate({ id, close_date }, requestToken) {
  return {
    type: UPDATE_INVOICE_CLOSE_DATE,
    request: {
      method: 'patch',
      url: `/admin/invoices/${id}/update-close-date`,
      requestToken,
      data: { close_date },
    },
    payload: { id, close_date },
  };
}

export function updateInvoiceCompany({ id, company_id }, requestToken) {
  return {
    type: UPDATE_INVOICE_COMPANY,
    request: {
      method: 'patch',
      url: `/admin/invoices/${id}/update-company`,
      requestToken,
      data: { company_id },
    },
  };
}

export function getInvoiceBillBlank({ id, format }, requestToken) {
  return {
    type: GET_INVOICE_BLANK,
    request: {
      method: 'get',
      url: `/admin/invoices/${id}/blank?format=${format || 'xlsx'}`,
      responseType: 'blob',
      requestToken,
    },
  };
}

export function getInvoiceBlank({
  id, format = 'xlsx', type = 'bill', date,
}, requestToken) {
  return {
    type: GET_INVOICE_BLANK,
    request: {
      method: 'get',
      url: `/admin/invoices/${id}/${type}-blank?format=${format}${date ? `&date=${date}` : ''}`,
      responseType: 'blob',
      requestToken,
    },
  };
}
