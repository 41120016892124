export const GET_USER = 'GET_USER';
export const SIGN_IN = 'SIGN_IN';
export const LOGOUT = 'LOGOUT';

/* --------------- */
/* Action creators */

/* --------------- */

export function getUser(params) {
  return {
    type: GET_USER,
    request: {
      method: 'get',
      url: '/me',
      params,
    },
  };
}

export function logout() {
  return {
    type: LOGOUT,
    request: {
      method: 'post',
      url: '/logout',
    },
  };
}

export function signIn(data, requestToken) {
  return {
    type: SIGN_IN,
    request: {
      method: 'post',
      url: '/login',
      data,
      requestToken,
    },
  };
}

/* ------ */
/* Thunks */

/* ------ */

export function logoutThunk() {
  return (dispatch) => {
    dispatch(logout()).catch(() => {});
  };
}
