import classNames from 'classnames';
import PropTypes from 'prop-types';
import DatePicker, { registerLocale } from 'react-datepicker';
import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';

import ru from 'date-fns/locale/ru';

import 'react-datepicker/dist/react-datepicker.css';

import './DateTimePicker.scss';

registerLocale('ru', ru);

const DateTimePicker = ({
  selected,
  onOutsideClick,
  onChange,
  ...props
}) => {
  const blockRef = useRef(null);

  const [dateTime, setDateTime] = useState(selected);

  const handleOutsideClick = (e) => {
    if (!blockRef.current || blockRef.current.contains(e.target) || e.target.closest('.react-datepicker__navigation')) {
      return;
    }

    if (typeof onOutsideClick === 'function') onOutsideClick();
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  });

  return (
    <div
      className={classNames('datetime-picker')}
      ref={blockRef}
    >
      <DatePicker locale="ru" {...props} selected={dateTime} onChange={(value) => setDateTime(value)} />
      <div className="datetime-picker__footer">
        <Button onClick={() => onChange(dateTime)}>Подтвердить</Button>
      </div>
    </div>
  );
};

DateTimePicker.propTypes = {
  selected: PropTypes.number,
  onChange: PropTypes.func,
  onOutsideClick: PropTypes.func,
};

export default DateTimePicker;
