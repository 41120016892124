import PropTypes from 'prop-types';
import React from 'react';
import queryString from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { requestCanceler } from '../../../middlewares/api';

import { toast } from '../../../utils';

import { GET_TAGS } from '../../../actions';

import errorMessages from '../../../constants/errors';
import { languages } from '../../../constants/common';

import View from '../../Layout/View/View';

import NoMedia from '../../UI/NoMedia/NoMedia';

import Language from '../../Includes/LanguageDropdown/LanguageDropdown';

import Tag from './Tag/Tag';
import TagsSearch from './TagsSearch/TagsSearch';

import Pagination from '../../Includes/Pagination/Pagination';

import './Tags.scss';

const TAGS_ERRORS = [
  'Network Error',
  'tag_not_found',
  'alias_incorrect',
  'name_incorrect',
  'tag_is_used',
  'tag_name_taken',
  'tag_alias_taken',
  'permission_denied',
  'server_error',
  'admin_privileges_required',
];

class Tags extends React.PureComponent {
  state = {
    isTagsFetching: false,
  };

  requestCancelers = [];

  languages = Object.keys(languages);

  limit = 20;

  componentDidMount() {
    const { location: { search }, tags } = this.props;

    const isSearch = queryString.parse(search)?.search;

    if (Boolean(isSearch) || !tags.docs.length) this.getTags({ page: 1 });

    window.addEventListener('online', this.checkOnlineState);
  }

  componentDidUpdate(prevProps) {
    const { location, tags } = this.props;
    const { error } = tags;

    if (location !== prevProps.location) this.getTags({});

    if (error && error !== prevProps.tags.error) {
      const errMsg = error.message;
      const msg = TAGS_ERRORS.includes(errMsg) ? errorMessages[errMsg] : errMsg;

      toast('error', msg);
    }
  }

  componentWillUnmount() {
    const { onUnload } = this.props;

    this.requestCancelers.forEach((canceler) => canceler.cancelRequest());

    onUnload();
  }

  getTags = ({ page: pageNum }) => {
    const { onGetTags, location } = this.props;

    const page = +(pageNum || queryString.parse(location.search)?.page || 1);
    const limit = +(queryString.parse(location.search)?.limit || this.limit);
    const lang = queryString.parse(location.search)?.lang || 'ru';

    const requestParams = { limit, page, lang };

    if (location.search) {
      Object.assign(requestParams, queryString.parse(location.search));
    }

    const requestGetTagsCanceler = requestCanceler();

    this.requestCancelers.push(requestGetTagsCanceler);

    this.setState({ isTagsFetching: true });

    onGetTags(requestParams, requestGetTagsCanceler.token)
      .catch(() => {})
      .finally(() => this.setState({ isTagsFetching: false }));
  };

  handleChangePage = (page) => {
    const { tags, location, history } = this.props;

    if (tags.isFetching || tags.page === page) return;

    const limit = +(queryString.parse(location.search)?.limit || this.limit);

    const queryStr = `/tags?page=${page}&limit=${limit}`;

    history.push(queryStr);
  };

  handleSearchTags = ({ query, search_type }, language = '') => {
    const { history, location } = this.props;

    const lang = language || queryString.parse(location.search)?.lang;

    let queryStr = query ? `/tags?search=${query}&search_type=${search_type}` : '/tags';

    if (lang && lang !== 'ru') queryStr += queryStr === '/tags' ? `?lang=${lang}` : `&lang=${lang}`;

    history.push(queryStr);
  };

  handleChangeLang = (lang) => {
    const { location } = this.props;

    const query = queryString.parse(location.search)?.search || '';
    const search_type = queryString.parse(location.search)?.search_type || '';

    this.handleSearchTags({ query, search_type }, lang);
  };

  checkOnlineState = () => {
    const { lastActionType } = this.props.tags;

    if (navigator.onLine && lastActionType === `${GET_TAGS}_REQUEST`) this.loadMore();
  };

  renderLanguagesDropdown = () => {
    const { tags, location } = this.props;
    const { isFetching } = tags;

    const defaultLang = queryString.parse(location.search)?.lang;
    const currentLang = defaultLang && this.languages.includes(defaultLang) ? defaultLang : this.languages[0];

    return (
      <Language
        currentLang={currentLang}
        disabled={isFetching}
        onChange={this.handleChangeLang}
      />
    );
  };

  render() {
    const { tags, onUpdateTag, onDelete } = this.props;
    const { isTagsFetching } = this.state;
    const {
      error, isFetching, page, pages, total, limit,
    } = tags;

    return (
      <View
        errorMessage={errorMessages[error?.message]}
        isError={error && ['admin_privileges_required'].includes(error.message)}
        isFetching={isTagsFetching}
        title="Теги"
        viewClass="tags"
        header={this.renderLanguagesDropdown()}
        content={(
          <>
            <TagsSearch onSearch={this.handleSearchTags} />

            {tags.docs.length > 0 && (
              <div className="tags__list">
                {tags.docs.map((tag) => (
                  <div className="tags__list-tag" key={tag._id}>
                    <Tag
                      tag={tag}
                      isFetching={isFetching}
                      onUpdate={onUpdateTag}
                      onDelete={onDelete}
                    />
                  </div>
                ))}
              </div>
            )}
            {!tags.docs.length && !isFetching && (
              <div className="tags__no-media">
                <NoMedia
                  caption="Ничего не найдено"
                  icon={<FontAwesomeIcon icon={['fas', 'exclamation-circle']} />}
                />
              </div>
            )}

            {!isTagsFetching && (
              <div className="tags__pagination">
                <Pagination
                  currentPage={page}
                  disabled={isFetching}
                  pages={pages}
                  total={total}
                  limit={limit}
                  onChange={this.handleChangePage}
                />
              </div>
            )}
          </>
        )}
      />
    );
  }
}

Tags.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  onGetTags: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdateTag: PropTypes.func.isRequired,
  onUnload: PropTypes.func.isRequired,
  tags: PropTypes.object.isRequired,
};

export default Tags;
