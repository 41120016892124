import { connect } from 'react-redux';

import {
  RESET_TEMPLATES,
  RESET_CATEGORIES,
  autoCheckVariations,
  addCategoryTemplatesCount,
  createTemplate,
  getCategories,
  getTemplates,
} from '../../../../actions';

import Templates from './Templates';

const mapStateToProps = (state) => ({
  categories: state.categories.docs,
  templates: state.templates,
});

const mapDispatchToProps = (dispatch) => ({
  autoCheckVariations: (templateId) => dispatch(autoCheckVariations(templateId)),
  addCategoryTemplatesCount: (categoryId, count, force) => dispatch(addCategoryTemplatesCount(categoryId, count, force)),
  onCreateTemplate: (data, requestToken) => dispatch(createTemplate(data, requestToken)),
  onGetCategories: (params, isSearch, requestToken) => dispatch(getCategories(params, isSearch, requestToken)),
  onGetTemplates: (params, requestToken) => dispatch(getTemplates(params, requestToken)),
  onUnload: () => {
    dispatch({ type: RESET_TEMPLATES });
    dispatch({ type: RESET_CATEGORIES });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Templates);
