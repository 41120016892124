import { PREFIX_ACTION_TYPE_LANDING_FORM } from '../../constants/common';

export const RESET_LANDING_FORM = 'RESET_LANDING_FORM';
export const UPDATE_LANDING_FORM = 'UPDATE_LANDING_FORM';
export const INIT_LANDING_FORM = 'INIT_LANDING_FORM';

export function initLandingForm() {
  return {
    type: PREFIX_ACTION_TYPE_LANDING_FORM + INIT_LANDING_FORM,
  };
}

export function resetLandingForm() {
  return {
    type: PREFIX_ACTION_TYPE_LANDING_FORM + RESET_LANDING_FORM,
  };
}

export function updateLandingForm(data) {
  return {
    type: PREFIX_ACTION_TYPE_LANDING_FORM + UPDATE_LANDING_FORM,
    payload: data,
  };
}
