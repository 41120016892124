import {
  CREATE_PROMOCODE, GET_PROMOCODES, RESET_PROMOCODES, UPDATE_PROMOCODE,
} from '../actions';

const defaultState = {
  docs: [],
  error: null,
  isFetching: false,
};

function returnError(response) {
  if (response.message) return { message: 'first_request_failed' };

  return { message: 'admin_privileges_required' };
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case `${GET_PROMOCODES}_REQUEST`:
      return {
        ...state,
        error: null,
        isFetching: true,
      };
    case `${CREATE_PROMOCODE}_REQUEST`:
    case `${UPDATE_PROMOCODE}_REQUEST`:
      return {
        ...state,
        error: null,
      };
    case GET_PROMOCODES:
      return {
        ...state,
        docs: action.response,
        isFetching: false,
      };
    case UPDATE_PROMOCODE:
      return {
        ...state,
        docs: state.docs.map((item) => {
          if (item.id !== +action.payload.id) return item;

          return {
            ...item,
            ...action.payload.data,
          };
        }),
      };
    case CREATE_PROMOCODE:
      return {
        ...state,
        docs: [action.response, ...state.docs],
      };
    case `${GET_PROMOCODES}_FAILURE`:
      return {
        ...state,
        error: returnError(action.response),
        isFetching: false,
      };
    case `${CREATE_PROMOCODE}_FAILURE`:
    case `${UPDATE_PROMOCODE}_FAILURE`:
      return {
        ...state,
        error: action.response,
      };
    case RESET_PROMOCODES:
      return defaultState;
    default:
      return state;
  }
};
