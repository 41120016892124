import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dropdown } from 'react-bootstrap';

import View from '../../Layout/View/View';

import sweetAlert from '../../HOCs/sweetAlert';

import FieldForm from '../../Includes/FieldForm/FieldForm';
import SearchForm from '../../Includes/SearchForm/SearchForm';

import CommentIcon from '../../UI/CommentIcon/CommentIcon';
import NoMedia from '../../UI/NoMedia/NoMedia';
import CheckBox from '../../UI/CheckBox/CheckBox';

import { requestCanceler } from '../../../middlewares/api';

import errorMessages from '../../../constants/errors';
import {
  DAYS, LIMIT_PER_PAGE, SEARCH_TYPES, SORT_TYPES,
} from '../../../constants/services';

import { toast } from '../../../utils';

const ServicesService = ({ item, onCreateInvoice, onDeleteService }) => {
  const [isFetching, setIsFetching] = useState(false);
  const requestCancelers = useRef([]);

  const handleCreateInvoice = (userData) => { // eslint-disable-line
    if (!userData || userData === '') return toast('error', 'Введите логин или ID');

    const requestCreateInvoiceCanceler = requestCanceler();

    requestCancelers.current.push(requestCreateInvoiceCanceler);

    setIsFetching(true);

    onCreateInvoice({
      ...(Number.isInteger(+userData) && { user_id: +userData }),
      ...(userData.match(/.+@.+\..+/i) && { user_id: userData.toLowerCase().trim() }),
      service_id: item.id,
    }, requestCreateInvoiceCanceler.token)
      .then(() => {
        toast('success', 'Счёт создан');

        setIsFetching(false);
      })
      .catch(() => {
        toast('error', 'Ошибка');

        setIsFetching(false);
      });
  };

  const handleDeleteService = () => {
    sweetAlert.fire({
      cancelButtonText: 'Отмена',
      confirmButtonText: 'Да, я уверен',
      icon: <FontAwesomeIcon icon={['fal', 'exclamation-triangle']} />,
      showCancelButton: true,
      type: 'warning',
      title: 'Вы уверены?',
      html: 'Удалить тариф? Это действие нельзя будет отменить',
    }).then((result) => {
      if (!result.value) return;

      const requestDeleteServiceCanceler = requestCanceler();

      requestCancelers.current.push(requestDeleteServiceCanceler);

      setIsFetching(true);

      onDeleteService(item.id, requestDeleteServiceCanceler.token)
        .then(() => {
          toast('success', 'Тариф удалён!');

          setIsFetching(false);
        })
        .catch((error) => {
          const message = errorMessages[error.message] || error.message || 'Ошибка';

          toast('error', message);

          setIsFetching(false);
        });
    });
  };

  useEffect(() => () => requestCancelers.current.forEach((canceler) => canceler.cancelRequest()), []);

  return (
    <div className="service" key={item.id}>
      <div className="service__id">{`#${item.id}`}</div>
      <div className="service__main">
        <div className="service__info">
          <Link className="service__name" to={`/services/edit/${item.id}`}>{item.name}</Link>
          <div className="service__info-tags">
            {item.group === 'individual' ? <div className="service__info-tag">Индивидуальный</div> : null}
            {item.promocode ? <div className="service__info-tag">Промокод</div> : null}
            <div className="service__info-tag">{`${item.sum} ${item.currency}`}</div>
            {DAYS[item.days] ? <div className="service__info-tag">{DAYS[item.days]}</div> : null}
            {item?.features?.seats ? <div className="service__info-tag">{`Команда (${item.features.seats})`}</div> : null}
            {item?.features?.render_api ? <div className="service__info-tag">Render API</div> : null}
          </div>
          {item?.promocode && (
            <Link className="service__link" to={`/promocodes/edit/${item.id}`}>Редактировать промокод</Link>
          )}
        </div>
        {item?.comment && (
          <div className="service__comment">
            <CommentIcon comment={item.comment} />
          </div>
        )}
        <div className="service__invoice-form">
          <FieldForm
            buttonText="выставить счет"
            fieldType="input"
            disabled={isFetching}
            placeholder="введите логин или ID"
            resetOn
            onSubmit={(value) => handleCreateInvoice(value)}
          />
        </div>
        <div className="service__actions">
          <Link className="service__action" title="Найти счета по ID тарифа" to={`/invoices?query=${item.id}&search_type=service_id`}>
            <FontAwesomeIcon icon={['fas', 'search']} />
          </Link>
          <Link className="service__action" title="Редактировать тариф" to={`/services/edit/${item.id}`}>
            <FontAwesomeIcon icon={['fas', 'pencil']} />
          </Link>
          <Button className="service__action service__action-delete" variant="light" title="Удалить тариф" onClick={handleDeleteService}>
            <FontAwesomeIcon icon={['far', 'times']} />
          </Button>
        </div>
      </div>
    </div>
  );
};

ServicesService.propTypes = {
  item: PropTypes.object.isRequired,
  onCreateInvoice: PropTypes.func.isRequired,
  onDeleteService: PropTypes.func.isRequired,
};

class Services extends React.PureComponent {
  state = {
    searchQuery: queryString.parse(this.props.location.search)?.query || '',
    searchType: queryString.parse(this.props.location.search)?.search_type || 'id',
    sortType: queryString.parse(this.props.location.search)?.sort || 'desc',
    group: queryString.parse(this.props.location.search)?.group || '',
  };

  requestCancelers = [];

  componentDidMount() { // eslint-disable-line
    const { services } = this.props;
    const { searchQuery } = this.state;

    if (!services.docs.length) this.getServices(1, !!searchQuery);
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;

    if (location.search !== prevProps.location.search) {
      const {
        query, search_type, sort, group,
      } = queryString.parse(location.search);

      this.setState({ // eslint-disable-line
        searchQuery: query || '',
        searchType: search_type || 'id',
        sortType: sort || 'desc',
        group: group || '',
      }, () => this.getServices(1, true));
    }
  }

  componentWillUnmount() {
    const { onUnload } = this.props;

    this.requestCancelers.forEach((canceler) => canceler.cancelRequest());

    onUnload();
  }

  getServices = (page = 1, isSearch = false) => {
    const { onGetServices } = this.props;
    const {
      group, searchQuery, searchType, sortType,
    } = this.state;

    const requestGetServicesCanceler = requestCanceler();

    this.requestCancelers.push(requestGetServicesCanceler);

    onGetServices({
      page,
      limit: LIMIT_PER_PAGE,
      sort: sortType,
      ...(searchQuery && {
        query: searchQuery,
        search_type: searchType,
      }),
      ...(group === 'individual' && { group }),
    }, isSearch, requestGetServicesCanceler.token);
  };

  changeURL = () => {
    const { history } = this.props;
    const {
      group, searchQuery, searchType, sortType,
    } = this.state;

    const queryStr = searchQuery
      ? `/services?query=${searchQuery}&search_type=${searchType}&sort=${sortType}${group && !searchQuery ? `&group=${group}` : ''}`
      : `/services?sort=${sortType}${group && !searchQuery ? `&group=${group}` : ''}`;

    history.push(queryStr);
  };

  loadMore = () => {
    const { services } = this.props;

    if (services.isFetching) return;

    this.getServices(services.page + 1);
  };

  handleChangeSearchType = (type) => this.setState({ searchType: type });

  handleSearch = (query) => {
    const { searchType } = this.state;

    if (searchType === 'id' && Number.isNaN(+query)) return toast('error', 'Некорректный id');

    this.setState({ searchQuery: query, group: !!(query) === false ? 'individual' : '' }, this.changeURL);

    return null;
  };

  handleSort = (type) => {
    this.setState({ sortType: type }, this.changeURL);
  };

  handleToggleIndividual = (value) => {
    this.setState({ group: value ? 'individual' : '' }, this.changeURL);
  };

  renderHeader = () => {
    const { services } = this.props;
    const {
      group, searchQuery, searchType, sortType,
    } = this.state;

    return (
      <div className="services__header">
        <Link to="/services/create" className="btn btn-success">
          Создать тариф
        </Link>
        <div className="services__filters">
          <div className="services__dropdown">
            <span>Поиск по:</span>
            <Dropdown>
              <Dropdown.Toggle as="button" className="btn btn-light" disabled={services.isFetching}>
                {SEARCH_TYPES[searchType]}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {Object.keys(SEARCH_TYPES).map((type) => (
                  <Dropdown.Item
                    as="button"
                    disabled={type === searchType}
                    eventKey={type}
                    key={type}
                    onSelect={this.handleChangeSearchType}
                  >
                    {SEARCH_TYPES[type]}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="services__search">
            <SearchForm
              className="services__search-form"
              defaultValue={searchQuery}
              disabled={services.isFetching}
              onSearch={this.handleSearch}
              placeholder="введите запрос"
            />
          </div>
        </div>
        <div className="services__sort">
          <div className="services__checkbox-wrap">
            {!searchQuery && (
              <CheckBox
                className="services__checkbox"
                checked={group === 'individual'}
                disabled={services.isFetching}
                id="individual"
                isFetching={false}
                onChange={this.handleToggleIndividual}
              >
                Скрыть все тарифы кроме индивидуальных
              </CheckBox>
            )}
          </div>
          <div className="services__dropdown services__dropdown_light">
            <span>Сортировка:</span>
            <Dropdown>
              <Dropdown.Toggle as="button" className="btn" disabled={services.isFetching}>
                {SORT_TYPES[sortType]}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {Object.keys(SORT_TYPES).map((type) => (
                  <Dropdown.Item
                    as="button"
                    disabled={type === sortType}
                    eventKey={type}
                    key={type}
                    onSelect={this.handleSort}
                  >
                    {SORT_TYPES[type]}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    );
  };

  renderList = () => {
    const { services, onCreateInvoice, onDeleteService } = this.props;

    return (
      <InfiniteScroll
        dataLength={services.docs.length}
        hasMore={services.page < services.pages}
        loader={null}
        next={this.loadMore}
        style={{ overflow: 'visible' }}
      >
        {services.docs.length > 0 && (
          <div className="services__list">
            {services.docs.map((item) => (
              <ServicesService key={item._id} item={item} onCreateInvoice={onCreateInvoice} onDeleteService={onDeleteService} />
            ))}
          </div>
        )}
        {!services.docs.length && !services.isFetching && (
          <div className="services__no-media">
            <NoMedia
              caption="Ничего не найдено"
              icon={<FontAwesomeIcon icon={['fas', 'exclamation-circle']} />}
            />
          </div>
        )}
      </InfiniteScroll>
    );
  };

  render() {
    const { services: { error, isFetching } } = this.props;

    return (
      <View
        errorMessage={errorMessages[error?.message]}
        isError={error && ['admin_privileges_required', 'first_request_failed'].includes(error.message)}
        isFetching={isFetching}
        header={this.renderHeader()}
        preloaderCaption="Загружаем..."
        title="Тарифы"
        viewClass="services"
        content={this.renderList()}
      />
    );
  }
}

Services.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  services: PropTypes.object.isRequired,
  onCreateInvoice: PropTypes.func.isRequired,
  onDeleteService: PropTypes.func.isRequired,
  onGetServices: PropTypes.func.isRequired,
  onUnload: PropTypes.func.isRequired,
};

export default Services;
