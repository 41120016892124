import PropTypes from 'prop-types';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './CommentIcon.scss';

const CommentIcon = ({ comment }) => (
  comment
    ? (
      <span className="comment-icon">
        <OverlayTrigger
          placement="bottom"
          overlay={(
            <Tooltip id="tooltip-bottom">
              {comment}
            </Tooltip>
          )}
        >
          <FontAwesomeIcon icon={['fas', 'comment-alt']} />
        </OverlayTrigger>
      </span>
    )
    : null
);

CommentIcon.propTypes = {
  comment: PropTypes.string,
};

export default CommentIcon;
