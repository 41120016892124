import classNames from 'classnames';
import dayjs from 'dayjs';
import DayPicker from 'react-day-picker';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './DatePicker.scss';

const currentYear = new Date().getFullYear();
const fromMonth = new Date(2015, 0);
const toMonth = new Date(currentYear + 10, 11);
const MONTHS = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];

const YearMonthForm = ({ date, onChange }) => {
  const years = [];

  for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i);
  }

  const handleChange = (e) => {
    const { year, month } = e.target.form;

    onChange(new Date(year.value, month.value));
  };

  return (
    <form className="DayPicker-Caption">
      <select className="datepicker__select" name="month" onChange={handleChange} value={date.getMonth()}>
        {MONTHS.map((month, i) => (
          <option key={month} value={i}>
            {month}
          </option>
        ))}
      </select>
      <select className="datepicker__select" name="year" onChange={handleChange} value={date.getFullYear()}>
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
        {!years.includes(date.getFullYear()) && (
          <option key={date.getFullYear()} value={date.getFullYear()}>
            {date.getFullYear()}
          </option>
        )}
      </select>
    </form>
  );
};

YearMonthForm.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  onChange: PropTypes.func.isRequired,
};

const DatePicker = ({
  disabled, disableDaysBefore, disableDaysAfter, style, value, onChange = () => {},
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const [customDate, setCustomDate] = useState(value);

  const blockRef = useRef(null);

  const handleOutsideClick = (e) => {
    if (
      blockRef.current
      && !blockRef.current.contains(e.target)
      // && !e.target.closest('.datepicker')
    ) {
      setIsVisible(false);
      setCustomDate(value);
    }
  };

  const handleChange = (date) => {
    onChange(date);

    setCustomDate(date);
    setIsVisible(false);
  };

  const handleYearMonthChange = (date) => setCustomDate(date);

  useEffect(() => {
    setCustomDate(value);
  }, [value]);

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  });

  const toggleIsVisible = () => {
    setIsVisible(!isVisible);
    setCustomDate(value);
  };

  return (
    <div
      className={classNames('datepicker', { active: isVisible, disabled })}
      ref={blockRef}
      style={style}
    >
      {/* eslint-disable-next-line */}
      <div className="datepicker__selected" onClick={toggleIsVisible}>
        <FontAwesomeIcon icon={['far', 'calendar-alt']} />
        {dayjs(value).format('DD.MM.YYYY')}
      </div>
      {isVisible && (
        <DayPicker
          captionElement={({ date, localeUtils }) => (
            <YearMonthForm
              date={new Date(date)}
              localeUtils={localeUtils}
              onChange={handleYearMonthChange}
            />
          )}
          months={MONTHS}
          weekdaysLong={[
            'Воскресенье',
            'Понедельник',
            'Вторник',
            'Среда',
            'Четверг',
            'Пятница',
            'Суббота',
          ]}
          weekdaysShort={['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']}
          firstDayOfWeek={1}
          // selectedDays={value}
          // initialMonth={value}
          selectedDays={customDate}
          initialMonth={customDate}
          month={customDate}
          onDayClick={handleChange}
          modifiers={{
            disabled: {
              before: disableDaysBefore,
              after: disableDaysAfter,
            },
          }}
        />
      )}
    </div>
  );
};

DatePicker.propTypes = {
  disabled: PropTypes.bool,
  disableDaysBefore: PropTypes.object,
  disableDaysAfter: PropTypes.object,
  style: PropTypes.object,
  value: PropTypes.object,
  onChange: PropTypes.func,
};

export default DatePicker;
