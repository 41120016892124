import { connect } from 'react-redux';

import Groups from './Groups';

import {
  RESET_GROUPS,
  createGroup,
  getCategories,
  getGroups,
  saveGroupsOrder,
  setGroupsOrder,
} from '../../../../actions';

const mapStateToProps = (state) => ({
  groups: state.groups,
  groupsCategories: state.groupsCategories,
});

const mapDispatchToProps = (dispatch) => ({
  onCreateGroup: (data, requestToken) => dispatch(createGroup(data, requestToken)),
  onGetCategories: (params, requestToken) => dispatch(getCategories(params, requestToken)),
  onGetGroups: (params, requestToken) => dispatch(getGroups(params, requestToken)),
  onSaveGroupsOrder: (data, requestToken) => dispatch(saveGroupsOrder(data, requestToken)),
  onSetGroupsOrder: (orderData) => dispatch(setGroupsOrder(orderData)),
  onUnload: () => dispatch({ type: RESET_GROUPS }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Groups);
