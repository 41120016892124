import { connect } from 'react-redux';

import {
  RESET_OBJECT_CATEGORIES,
  getObjectCategory,
  updateObjectCategory,
} from '../../../../actions/objects/categories';

import ObjectCategoriesEdit from './CategoriesEdit';

const mapStateToProps = (state) => ({
  objectCategories: state.objectCategories,
});

const mapDispatchToProps = (dispatch) => ({
  onGetObjectCategory: (id, requestToken) => dispatch(getObjectCategory(id, requestToken)),
  onUpdateObjectCategory: (data, requestToken) => dispatch(updateObjectCategory(data, requestToken)),
  onUnload: () => dispatch({ type: RESET_OBJECT_CATEGORIES }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ObjectCategoriesEdit);
