import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { s3Replace, toast } from '../../../../../utils';

import TagsInput from '../../../../Includes/TagsInput/TagsInput';

import CheckBox from '../../../../UI/CheckBox/CheckBox';

const ObjectsObject = ({
  data, isDisabled, isOrdering, orderIDs, onCreate, onDelete, onApplyOrder, onSetOrder, onUpdate,
}) => {
  const [objectData, setObjectData] = useState(data);
  const [isUnsaved, setIsUnsaved] = useState(false);

  const handleSetData = (value) => {
    setObjectData({ ...objectData, ...value });

    setIsUnsaved(true);
  };

  const handleUpdate = () => { // eslint-disable-line
    if (!objectData.template_variation_id || /^\d+$/.test(objectData.template_variation_id) === false) return toast('error', 'Некорректный ID вариации');
    if (!objectData.name) return toast('error', 'Некорректное название');

    if (objectData.is_empty) {
      onCreate(objectData);
    } else {
      onUpdate(objectData);
    }

    setIsUnsaved(false);
  };

  const handleDelete = () => {
    onDelete({ ...data, deleted: !data.deleted });
  };

  const handleSetOrder = () => onSetOrder(objectData._id);

  const handleApplyOrder = () => onApplyOrder(objectData._id);

  useEffect(() => {
    setObjectData(data);
  }, [data]);

  return (
    <div className="object">
      <div className="object__preview">
        {data?.variation && <img alt="preview" src={s3Replace(data?.variation?.image)} />}
      </div>
      <div className="object__body">
        <div className="object__row">
          <div className="object__input object__name">
            <div className="object__label">Название:</div>
            <Form.Control
              disabled={isDisabled}
              value={objectData.name}
              onChange={(e) => handleSetData({ name: e.target.value })}
            />
          </div>
          <div className="object__input object__variation">
            <div className="object__label">ID вариации:</div>
            <Form.Control
              disabled={isDisabled}
              type="number"
              min={0}
              value={objectData.template_variation_id}
              onChange={(e) => handleSetData({ template_variation_id: +e.target.value })}
            />
          </div>
          {data?.variation && (
            <div className="object__input object__watch">
              <Button
                disabled={isDisabled}
                variant="light"
                onClick={() => window.open(`${window.SUPA_DASHBOARD_URL}/designs/create?type=template&template_id=${objectData.template_variation_id}${data.variation.type === 'image' ? '&mode=image' : ''}&from=admin`, '_blank')}
              >
                <FontAwesomeIcon icon={['fas', 'eye']} />
              </Button>
            </div>
          )}
          <div className="object__input object__premium">
            <CheckBox
              className="invoice__checkbox"
              checked={objectData.premium_only}
              disabled={isDisabled}
              id={String(objectData._id)}
              isFetching={false}
              onChange={(value) => handleSetData({ premium_only: value })}
            >
              Премиум
            </CheckBox>
          </div>
        </div>
        <div className="object__row">
          <div className="object__input object__tags">
            <div className="object__label">Теги:</div>
            <TagsInput
              defaultTags={objectData.tags}
              disabled={isDisabled}
              onChange={(value) => handleSetData({ tags: value })}
            />
          </div>
        </div>
        {(isUnsaved || objectData.is_empty) && (
          <div className="object__row">
            <div className="object__label" />
            <Button
              disabled={isDisabled}
              variant="success"
              onClick={handleUpdate}
            >
              Сохранить
            </Button>
          </div>
        )}
      </div>
      <div className="object__actions">
        <Button
          className="object__action"
          disabled={orderIDs.length === 0 || isDisabled || isOrdering || objectData.is_empty}
          variant="light"
          onClick={handleApplyOrder}
        >
          <FontAwesomeIcon icon={['fas', 'plane-arrival']} />
        </Button>
        <Button
          className={`object__action${isOrdering ? ' active' : ''}`}
          disabled={isDisabled || objectData.is_empty}
          variant="light"
          onClick={handleSetOrder}
        >
          <FontAwesomeIcon icon={['fas', 'plane-departure']} />
        </Button>
        <Button
          className={`object__action${objectData.stage_only ? ' active' : ''}`}
          disabled={isDisabled}
          variant="light"
          onClick={() => handleSetData({ stage_only: !objectData.stage_only })}
        >
          <FontAwesomeIcon icon={['fas', 'flask']} />
        </Button>
        <Button
          className={`object__action object__action-delete${objectData.deleted ? ' active' : ''}`}
          disabled={isDisabled}
          variant="light"
          onClick={handleDelete}
        >
          <FontAwesomeIcon icon={['fal', 'times']} />
        </Button>
      </div>
    </div>
  );
};

ObjectsObject.propTypes = {
  data: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isOrdering: PropTypes.bool.isRequired,
  orderIDs: PropTypes.array.isRequired,
  onCreate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onApplyOrder: PropTypes.func.isRequired,
  onSetOrder: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default ObjectsObject;
