import {
  GET_ADMIN_IP_ADDRESS,
  GET_ADMIN_IP_ADDRESSES,
  CREATE_ADMIN_IP_ADDRESS,
  UPDATE_ADMIN_IP_ADDRESS,
  DELETE_ADMIN_IP_ADDRESS,
  RESET_ACCESS_IP_ADDRESSES,
} from '../../actions';

const defaultState = {
  docs: [],
  error: null,
  isFetching: false,
  page: 0,
  pages: 1,
};

function returnError(response) {
  if (response.message) return { message: response.message, ...(response.params && { params: response.params }) };
  if (response.errors) return { message: response.errors[0] };

  return { message: 'admin_privileges_required' };
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case `${GET_ADMIN_IP_ADDRESSES}_REQUEST`:
      return {
        ...state,
        docs: action.payload.isSearch ? [] : state.docs,
        error: null,
        isFetching: true,
      };
    case `${GET_ADMIN_IP_ADDRESS}_REQUEST`:
    case `${CREATE_ADMIN_IP_ADDRESS}_REQUEST`:
    case `${UPDATE_ADMIN_IP_ADDRESS}_REQUEST`:
      return {
        ...state,
        error: null,
        isFetching: true,
      };
    case GET_ADMIN_IP_ADDRESSES:
      return {
        ...state,
        ...action.response,
        docs: action.response.page === 1
          ? action.response.docs
          : [...state.docs, ...action.response.docs],
        isFetching: false,
      };
    case GET_ADMIN_IP_ADDRESS:
    case CREATE_ADMIN_IP_ADDRESS:
    case UPDATE_ADMIN_IP_ADDRESS:
      return {
        ...state,
        isFetching: false,
      };
    case DELETE_ADMIN_IP_ADDRESS:
      return {
        ...state,
        docs: state.docs.filter((item) => item.id !== action.payload.id),
      };
    case `${GET_ADMIN_IP_ADDRESS}_FAILURE`:
    case `${GET_ADMIN_IP_ADDRESSES}_FAILURE`:
      return {
        ...state,
        error: returnError(action.response),
        isFetching: false,
      };
    case `${CREATE_ADMIN_IP_ADDRESS}_FAILURE`:
    case `${UPDATE_ADMIN_IP_ADDRESS}_FAILURE`:
      return {
        ...state,
        isFetching: false,
      };
    case RESET_ACCESS_IP_ADDRESSES:
      return defaultState;
    default:
      return state;
  }
};
