import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

const DropdownMenuSearch = React.forwardRef(({
  children, style, className, 'aria-labelledby': labeledBy,
}, ref) => {
  const [value, setValue] = useState('');

  return (
    <div
      ref={ref}
      style={style}
      className={className}
      aria-labelledby={labeledBy}
    >
      <Form.Control
        autoFocus
        placeholder="Введите для поиска..."
        onChange={(e) => setValue(e.target.value)}
        value={value}
      />
      {React.Children.toArray(children).filter(
        (child) => !value || child.props.children.toLowerCase().includes(value.toLowerCase()),
      )}
    </div>
  );
});

DropdownMenuSearch.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  'aria-labelledby': PropTypes.string,
};

export default DropdownMenuSearch;
