import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import './SidebarNav.scss';

class SidebarNav extends React.Component {
  renderItemInner = (item) => (
    <>
      {item.icon && <i className="sidebar-nav__icon">{item.icon}</i>}
      <span className="sidebar-nav__text">{item.title}</span>
      {item.badge && <sup className="sidebar-nav__badge">{item.badge}</sup>}
    </>
  );

  renderList = (list, key, caption = null) => {
    const items = list.filter((item) => typeof item.condition === 'undefined' || item.condition);

    return (
      <React.Fragment key={key}>
        {caption && <div className="sidebar-nav__caption">{caption}</div> }
        {items.length > 0 && (
          <ul className={`sidebar-nav__list${key ? ` sidebar-nav__list_${key}` : ''}`} key={key}>
            {items.map((item) => (
              <li className={`sidebar-nav__item${item.itemClassName ? ` sidebar-nav__item_${item.itemClassName}` : ''}`} key={item.key}>
                {item.onClick ? (
                  <button type="button" className={`sidebar-nav__button ${item.isActive ? 'is-active' : ''}`} onClick={item.onClick} title={item.title}>
                    {this.renderItemInner(item)}
                  </button>
                ) : (
                  <NavLink to={item.link} activeClassName="is-active" exact={item.exact} className="sidebar-nav__link" title={item.title}>
                    {this.renderItemInner(item)}
                  </NavLink>
                )}
                {item.subList && this.renderList(item.subList)}
              </li>
            ))}
          </ul>
        )}
      </React.Fragment>
    );
  };

  render() {
    const { data } = this.props;

    return (
      <div className="sidebar-nav">
        {Array.isArray(data)
          ? this.renderList(data)
          : Object.keys(data).map((key) => this.renderList(data[key].list, key, data[key].caption))}
      </div>
    );
  }
}

SidebarNav.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.objectOf(
      PropTypes.shape({
        list: PropTypes.array.isRequired,
        caption: PropTypes.string,
      }),
    ),
  ]).isRequired,
};

export default SidebarNav;
