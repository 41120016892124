import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';

import { requestCanceler } from '../../../../../middlewares/api';

import Preloader from '../../../../UI/Preloader/Preloader';

class UsersUserLogs extends React.PureComponent {
  requestCancelers = [];

  types = {
    me: 'Вход в dashboard',
    cancelled_subscription: 'Подписка отменена',
    cancelling_subscription: 'Запрос на отмену подписки',
    login: 'Авторизация',
    register: 'Регистрация',
    unbind_oauth: 'Отвязано от',
  };

  loginMethods = {
    classic: 'E-Mail',
    google: 'Google',
    facebook: 'Facebook',
    elama: 'Elama',
    vk: 'VK',
    yandex: 'Яндекс',
  };

  componentDidMount() {
    const { logs } = this.props;

    if (!logs) this.getLogs();
  }

  componentWillUnmount() {
    this.requestCancelers.forEach((canceler) => canceler.cancelRequest());
  }

  getLogs = (page = 1) => {
    const { id, onGetUserLogs } = this.props;

    const requestGetLogsCanceler = requestCanceler();

    this.requestCancelers.push(requestGetLogsCanceler);

    onGetUserLogs(id, { limit: 20, page }, requestGetLogsCanceler.token);
  };

  loadMore = () => {
    const { logsIsFetching, logs } = this.props;

    if (logsIsFetching || !logs) return;

    this.getLogs(logs.page + 1);
  };

  renderLogType = ({ type, data }) => {
    if (!this.types[type]) return type;

    let result = this.types[type];

    if (['login', 'register'].includes(type)) {
      if (data && data.login_method) result += ` (через ${this.loginMethods[data.login_method]})`;
      else result += ` (через ${this.loginMethods.classic})`;
    }

    if (type === 'unbind_oauth') {
      result += data?.oauth_provider ? ` ${this.loginMethods[data.oauth_provider]}` : ' внешнего сервиса';
    }

    return result;
  };

  render() {
    const { logs, logsIsFetching } = this.props;

    return (
      <>
        <div className="user__logs scroll">
          {logs && logs.docs.length ? (
            <div className="user__logs-list">
              {logs.docs.map((log) => (
                <div className="user__log" key={log._id}>
                  <span className="user__log-date">{dayjs(log.date).format('DD MMMM, YYYY в HH:mm:ss')}</span>
                  <span className="user__log-type">{this.renderLogType(log)}</span>
                  <span className="user__log-ip">{`IP: ${log.remote_ip}`}</span>
                </div>
              ))}
            </div>
          ) : null}
          {logsIsFetching && <div className="user__logs-preloader"><Preloader /></div>}
          {(!logs || logs.page < logs.pages) && (
            <Button
              className="user__logs-more"
              disabled={logsIsFetching}
              variant="light"
              onClick={!logs ? () => this.getLogs() : this.loadMore}
            >
              Загрузить ещё
            </Button>
          )}
        </div>
      </>
    );
  }
}

UsersUserLogs.propTypes = {
  id: PropTypes.number,
  logs: PropTypes.object,
  logsIsFetching: PropTypes.bool,
  onGetUserLogs: PropTypes.func,
};

export default UsersUserLogs;
