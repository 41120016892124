import React, {
  memo, useCallback, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Preloader from '../../../UI/Preloader/Preloader';

import Menu from './Menu/Menu';

import withRequest from '../../../HOCs/withRequest';

import {
  createLandingMenu,
  deleteLandingMenu,
  getLandingMenus,
  updateLandingMenu,
} from '../../../../actions/landing/menus';

import sweetAlert from '../../../HOCs/sweetAlert';

import { toast } from '../../../../utils';

import { getLandingPages } from '../../../../actions';

import './LandingMenu.scss';

const LandingMenu = (props) => {
  const {
    dispatch, createCanceler, lang, onUnload,
  } = props;

  const menus = useSelector(({ landing }) => landing.menu.formDocs);

  const [isDataFetching, setIsDataFetching] = useState(true);

  const isUnmounted = useRef(false);

  useEffect(() => () => {
    isUnmounted.current = true;

    onUnload();
  }, [onUnload]);

  useEffect(() => {
    const getMenusReq = createCanceler();
    const getPagesReq = createCanceler();

    const fetchPromises = [
      dispatch(getLandingMenus({ lang }, getMenusReq.token)),
      dispatch(getLandingPages({ lang, simple_list: true }, getPagesReq.token)),
    ];

    Promise.all(fetchPromises)
      .finally(() => {
        if (!isUnmounted.current) setIsDataFetching(false);
      });
  }, [lang]); // eslint-disable-line

  const handleClickAddMenu = () => {
    sweetAlert.fire({
      cancelButtonText: 'Отмена',
      confirmButtonText: 'Добавить',
      icon: null,
      showCancelButton: true,
      title: 'Добавить меню',
      html: 'Введите название:',
      input: 'text',
      // eslint-disable-next-line
      inputValidator: (value) => {
        if (!value) return 'Название не может быть пустым';
      },
    }).then((result) => {
      if (!result.value) return;

      dispatch(createLandingMenu({ lang, name: result.value }))
        .then(() => toast('success', 'Меню успешно создано!'))
        .catch(() => toast('error', 'Ошибка создания меню. Попробуйте позже'));
    });
  };

  const handleEditMenuName = useCallback((menu) => {
    sweetAlert.fire({
      cancelButtonText: 'Отмена',
      confirmButtonText: 'Обновить',
      icon: null,
      showCancelButton: true,
      title: 'Редактировать меню',
      html: 'Введите название:',
      input: 'text',
      inputValue: menu.name,
      // eslint-disable-next-line
      inputValidator: (value) => {
        if (!value) return 'Название не может быть пустым';
      },
    }).then((result) => {
      if (!result.value) return;

      dispatch(updateLandingMenu(menu._id, { name: result.value }))
        .then(() => toast('success', 'Меню успешно обновлено!'))
        .catch(() => toast('error', 'Ошибка обновления меню. Попробуйте позже'));
    });
  }, []); // eslint-disable-line

  const handleDeleteMenu = useCallback((menu) => {
    if (menu.elements?.length > 0) {
      sweetAlert.fire({
        confirmButtonText: 'Ок',
        type: 'error',
        title: 'Ошибка',
        html: 'Нельзя удалить меню пока оно содержит элементы',
      });

      return;
    }

    sweetAlert.fire({
      cancelButtonText: 'Отмена',
      confirmButtonText: 'Да, удалить',
      icon: <FontAwesomeIcon icon={['fal', 'exclamation-triangle']} />,
      type: 'warning',
      showCancelButton: true,
      title: 'Вы уверены?',
      html: 'Вы действительно хотите удалить данное меню? Это действие нельзя будет отменить',
    }).then((result) => {
      if (!result.value) return;

      dispatch(deleteLandingMenu(menu._id))
        .then(() => toast('success', 'Меню успешно удалено!'))
        .catch(({ message }) => {
          if (message === 'menu_is_attached') return toast('error', 'Меню используется в одной из категорий');

          return toast('error', 'Произошла ошибка. Попробуйте позже');
        });
    });
  }, []); // eslint-disable-line

  if (isDataFetching) return <Preloader />;

  return (
    <div className="landing-menu">
      <div className="landing-menu__actions">
        <Button variant="success" className="landing__section-action" onClick={handleClickAddMenu}>
          Добавить меню
        </Button>
      </div>

      {menus.length > 0 && (
        <div className="landing-menu__menus">
          {menus.map((menu) => (
            <Menu
              key={menu._id}
              menu={menu}
              onEdit={handleEditMenuName}
              onDelete={handleDeleteMenu}
            />
          ))}
        </div>
      )}

      {menus.length === 0 && (
        <div className="landing__info">
          Список пуст
        </div>
      )}
    </div>
  );
};

LandingMenu.propTypes = {
  createCanceler: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  lang: PropTypes.string,
  onUnload: PropTypes.func.isRequired,
};

export default memo(withRequest(LandingMenu));
