import { connect } from 'react-redux';

import {
  RESET_OBJECT_CATEGORY_OBJECTS,
  createObjectCategoryObject,
  createObjectCategoryEmptyObject,
  deleteObjectCategoryObject,
  getObjectCategoryObjects,
  updateObjectCategoryObject,
  orderObjectCategoryObjects,
  saveObjectCategoryObjectsOrder,
} from '../../../../actions/objects/objects';

import { getObjectCategory } from '../../../../actions/objects/categories';

import Objects from './Objects';

const mapStateToProps = (state) => ({
  objectCategoryObjects: state.objectCategoryObjects,
});

const mapDispatchToProps = (dispatch) => ({
  onCreateObjectCategoryObject: (data, requestToken) => dispatch(createObjectCategoryObject(data, requestToken)),
  onCreateObjectCategoryEmptyObject: (data) => dispatch(createObjectCategoryEmptyObject(data)),
  onDeleteObjectCategoryObject: (objectID, requestToken) => dispatch(deleteObjectCategoryObject(objectID, requestToken)),
  onGetObjectCategory: (id, requestToken) => dispatch(getObjectCategory(id, requestToken)),
  onGetObjectCategoryObjects: (categoryID, requestToken) => dispatch(getObjectCategoryObjects(categoryID, requestToken)),
  onUpdateObjectCategoryObject: (data, requestToken) => dispatch(updateObjectCategoryObject(data, requestToken)),
  onOrderObjectCategoryObjects: (targetID, objectIDs) => dispatch(orderObjectCategoryObjects(targetID, objectIDs)),
  onSaveObjectCategoryObjectsOrder: (data, requestToken) => dispatch(saveObjectCategoryObjectsOrder(data, requestToken)),
  onUnload: () => dispatch({ type: RESET_OBJECT_CATEGORY_OBJECTS }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Objects);
