import PropTypes from 'prop-types';
import React, { memo, useEffect } from 'react';

import { smoothScrollTo } from '../../../../utils';

import PromocodesForm from '../PromocodesForm/PromocodesForm';

const PromocodesCreate = ({
  promocodes, history, isEditing, setViewTitle, onCreatePromocode,
}) => {
  useEffect(() => {
    setViewTitle('Создать промокод');

    smoothScrollTo(document.documentElement, 0);
  }, [setViewTitle]);

  const handleCreatePromocode = (formData) => {
    delete formData.short_name;

    onCreatePromocode(formData);
  };

  return (
    !promocodes.isFetching && promocodes.docs.length ? (
      <div className="promocodes__create">
        {/* eslint-disable-next-line */}
        <div role="button" onClick={() => history.goBack()} to="/promocodes" className="promocodes__link">
          Вернуться назад
        </div>
        <PromocodesForm
          buttonText="Создать промокод"
          disabled={isEditing}
          onSubmit={handleCreatePromocode}
        />
      </div>
    ) : null
  );
};

PromocodesCreate.propTypes = {
  history: PropTypes.object,
  promocodes: PropTypes.object,
  isEditing: PropTypes.bool,
  setViewTitle: PropTypes.func,
  onCreatePromocode: PropTypes.func,
};

export default memo(PromocodesCreate);
