import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import queryString from 'query-string';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { languages } from '../../../../constants/common';
import errorMessages from '../../../../constants/errors';

import { requestCanceler } from '../../../../middlewares/api';

import { toast } from '../../../../utils';

import { SIZE_PRESET_SCHEMA } from '../../../../constants/templates/size';

import View from '../../../Layout/View/View';

import sweetAlert from '../../../HOCs/sweetAlert';

import LanguageDropdown from '../../../Includes/LanguageDropdown/LanguageDropdown';
import TemplatesActions from '../../../Includes/Templates/TemplatesActions/TemplatesActions';
import TemplatesSubnav from '../../../Includes/Templates/TemplatesSubnav/TemplatesSubnav';

import SizesSize from './SizesSize/SizesSize';

class Sizes extends React.PureComponent {
  state = {
    categories: [],
  };

  requestCancelers = [];

  languages = Object.keys(languages);

  componentDidMount() {
    this.getSizes();
    this.getCategories();
  }

  componentDidUpdate(prevProps) {
    const { location: prevLocation } = prevProps;
    const { location } = this.props;

    if (location.search !== prevLocation.search) {
      this.getSizes();
      this.getCategories();
    }
  }

  componentWillUnmount() {
    const { onUnload } = this.props;

    this.requestCancelers.forEach((canceler) => canceler.cancelRequest());

    onUnload();
  }

  get lang() {
    const { location } = this.props;

    if (!location.search) return 'ru';

    const params = queryString.parse(location.search);

    if (params.lang && Object.keys(languages).includes(params.lang)) {
      return params.lang;
    }

    return 'ru';
  }

  getSizes = () => {
    const { onGetSizes } = this.props;

    const requestGetSizesCanceler = requestCanceler();

    this.requestCancelers.push(requestGetSizesCanceler);

    onGetSizes({ lang: this.lang }, requestGetSizesCanceler.token).catch(() => {});
  };

  getCategories = () => {
    const { onGetCategories } = this.props;

    const requestGetCategoriesCanceler = requestCanceler();

    this.requestCancelers.push(requestGetCategoriesCanceler);

    onGetCategories({ lang: this.lang }, requestGetCategoriesCanceler.token)
      .then((docs) => {
        this.setState({ categories: docs });
      })
      .catch(() => {});
  };

  handleChangeLang = (lang) => {
    const { history } = this.props;

    const queryStr = !lang || lang === 'ru' ? '/templates/sizes' : `/templates/sizes?lang=${lang}`;

    if (this.lang === lang) return;

    history.push(queryStr);
  };

  onDragEnd = (result) => {
    const { onSetSizesOrder } = this.props;

    const {
      destination, source, draggableId, type,
    } = result;

    if (!destination) return;

    if (destination.index === source.index) return;

    if (type === 'size') {
      onSetSizesOrder({
        sizeId: draggableId, // id перемещаемого размера
        currentPosition: source.index, // индекс текущего перемещаемого размера
        newPosition: destination.index, // индекс новой позиции в массиве
      });
    }
  };

  handleSaveOrder = () => {
    const { sizes, onSaveSizesOrder } = this.props;

    sweetAlert.fire({
      cancelButtonText: 'Отмена',
      confirmButtonText: 'Да',
      icon: <FontAwesomeIcon icon={['fal', 'exclamation-triangle']} />,
      type: 'warning',
      showCancelButton: true,
      title: 'Вы уверены?',
      html: 'Вы действительно хотите сохранить изменение порядка?',
    }).then((result) => {
      if (!result.value) return;

      const prepared = sizes.docs.map((item, order) => ({ _id: item._id, order }));

      const requestSaveSizesOrderCanceler = requestCanceler();

      this.requestCancelers.push(requestSaveSizesOrderCanceler);

      onSaveSizesOrder(prepared, requestSaveSizesOrderCanceler.token)
        .then(() => toast('success', 'Список успешно отсортирован'));
    });
  };

  // handleCreateSize = () => {
  //   const { onCreateSize } = this.props;

  //   sweetAlert.fire({
  //     cancelButtonText: 'Отмена',
  //     confirmButtonText: 'Добавить',
  //     focusConfirm: false,
  //     icon: null,
  //     showCancelButton: true,
  //     title: 'Добавить группу',
  //     html:
  //       'Введите короткое название и алиас:'
  //       + '<input id="swal-input-group-short-title" class="swal2-input" type="text" placeholder="Короткое название" style="margin-top: 16px !important;">'
  //       + '<input id="swal-input-slug" class="swal2-input" type="text" placeholder="Алиас (транслитом, /^[a-z0-9-]{3,30}$/" style="margin-top: 16px !important;">',
  //     preConfirm: () => {
  //       const short_title = document.getElementById('swal-input-group-short-title').value.trim();
  //       const slug = document.getElementById('swal-input-slug').value.trim();

  //       if (!short_title || !slug) return Swal.showValidationMessage('Поля не могут быть пустыми');

  //       if (!/^[a-z0-9-]{3,30}$/.test(slug)) return Swal.showValidationMessage('Алиас не соответствует паттерну');

  //       return { short_title, slug };
  //     },
  //   }).then((result) => {
  //     if (!result.value || !result.value?.short_title || !result.value?.slug) return;

  //     const requestCreateSizeCanceler = requestCanceler();

  //     this.requestCancelers.push(requestCreateSizeCanceler);

  //     onCreateSize({
  //       short_title: result.value.short_title,
  //       slug: result.value.slug,
  //       lang: queryString.parse(window.location.search)?.lang || 'ru',
  //     }, requestCreateSizeCanceler.token)
  //       .then(() => toast('success', 'Размер успешно создана'))
  //       .catch(({ message }) => toast('error', message));
  //   });
  // };

  renderActionButtons = () => {
    const { sizes } = this.props;

    const {
      isFetching, isOrdered, isSizeChanging,
    } = sizes;

    return (
      <TemplatesActions
        isFetching={isFetching}
        isOrdered={isOrdered}
        stateChanging={isSizeChanging}
        onClickSaveOrder={this.handleSaveOrder}
      />
    );
  };

  renderLanguagesDropdown = () => {
    const { sizes, location } = this.props;
    const { isFetching, isSizeChanging } = sizes;

    const defaultLang = queryString.parse(location.search)?.lang;
    const currentLang = defaultLang && this.languages.includes(defaultLang) ? defaultLang : this.languages[0];

    return (
      <LanguageDropdown
        currentLang={currentLang}
        disabled={isFetching || isSizeChanging.order}
        onChange={this.handleChangeLang}
      />
    );
  };

  render() {
    const { sizes, location } = this.props;

    const {
      docs, error, isFetching,
    } = sizes;

    const { categories } = this.state;

    return (
      <View
        errorMessage={errorMessages[error?.message]}
        isError={error && ['admin_privileges_required', 'first_request_failed'].includes(error.message)}
        isFetching={isFetching}
        viewClass="templates"
        header={(
          <>
            <TemplatesSubnav location={location} />
            {this.renderLanguagesDropdown()}
            {this.renderActionButtons()}
          </>
        )}
        content={(
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable
              droppableId="all-sizes"
              direction="vertical"
              type="size"
            >
              {(provided, snap) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="template-bunch"
                >
                  {!isFetching && docs && docs.length > 0 && docs.map((item, index) => (
                    <Draggable draggableId={String(item._id)} index={index} key={item._id}>
                      {(provided2, snapshot) => (
                        <div
                          {...provided2.draggableProps}
                          ref={provided2.innerRef}
                          className={classNames('template-bunch__item template-bunch__item_size', {
                            'template-bunch__item_dragging': snapshot.isDragging,
                          })}
                        >
                          <SizesSize data={item} categories={categories} />
                          <div className="template-bunch__item-drag" {...provided2.dragHandleProps} />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  <div style={snap.isDraggingOver ? { display: 'none' } : {}}>
                    <SizesSize data={SIZE_PRESET_SCHEMA} categories={categories} />
                  </div>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
        footer={docs.length > 10 ? this.renderActionButtons() : null}
      />
    );
  }
}

Sizes.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  sizes: PropTypes.object.isRequired,
  onCreateSize: PropTypes.func.isRequired,
  onDeleteSize: PropTypes.func.isRequired,
  onGetCategories: PropTypes.func.isRequired,
  onGetSizes: PropTypes.func.isRequired,
  onSaveSizesOrder: PropTypes.func.isRequired,
  onSetSizesOrder: PropTypes.func.isRequired,
  onUpdateSize: PropTypes.func.isRequired,
  onUnload: PropTypes.func.isRequired,
};

export default Sizes;
