import _ from 'lodash';
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { Dropdown, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import sweetAlert from '../../../../HOCs/sweetAlert';

import { requestCanceler } from '../../../../../middlewares/api';

import { toast } from '../../../../../utils';

import usePrevious, { useDidUpdateEffect, useUnmount } from '../../../../hooks';

import CheckBox from '../../../../UI/CheckBox/CheckBox';

import {
  createSize,
  deleteSize,
  getSignedUrl,
  sendFileToS3,
  updateSize,
} from '../../../../../actions';

import { SIZE_1_MB } from '../../../../../constants/common';

import { SIZE_MODES, SIZE_TYPES, SIZE_UNITS } from '../../../../../constants/templates/size';

import Preloader from '../../../../UI/Preloader/Preloader';

const MAX_FILE_SIZE = SIZE_1_MB * 0.5;

const SizesSize = ({
  categories,
  data: initialData,
}) => {
  const dispatch = useDispatch();

  const [data, setData] = useState(initialData);
  const [isUnsaved, setIsUnsaved] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const prevInitialData = usePrevious(initialData);
  const prevData = usePrevious(data);
  const prevIsUpdating = usePrevious(isUpdating);

  const [file, setFile] = useState(null);
  const [fileObjectUrl, setFileObjectUrl] = useState('');
  const [resWidth, setResWidth] = useState(typeof data?.size[0] !== 'undefined' ? data?.size[0] : 600);
  const [resHeight, setResHeight] = useState(typeof data?.size[1] !== 'undefined' ? data?.size[1] : 600);

  const fileInputRef = useRef();

  const requestCancelers = useRef([]);

  const validateUploadFile = useCallback((files) => {
    if (!files.length) return false;

    if (files[0].size > MAX_FILE_SIZE) {
      toast('error', `Размер файла не должен превышать ${MAX_FILE_SIZE / SIZE_1_MB} мб`);

      return false;
    }

    return true;
  }, []);

  const handleChange = useCallback((args) => {
    setData({ ...data, ...args });
  }, [data]);

  const handleDelete = useCallback(() => {
    sweetAlert.fire({
      cancelButtonText: 'Отмена',
      confirmButtonText: 'Да, удалить',
      icon: <FontAwesomeIcon icon={['fal', 'exclamation-triangle']} />,
      type: 'warning',
      showCancelButton: true,
      title: 'Вы уверены?',
      html: 'Вы действительно хотите удалить размер?',
    }).then((result) => {
      if (!result.value) return;

      setIsUpdating(true);

      const requestDeleteCanceler = requestCanceler();

      requestCancelers.current.push(requestDeleteCanceler);

      dispatch(deleteSize(data._id, requestDeleteCanceler.token))
        .then(() => toast('success', 'Размер удален'))
        .catch(() => setIsUpdating(false));
    });
  }, [data._id, dispatch]);

  const handleFileChange = useCallback((event) => {
    if (!validateUploadFile(event.target.files)) return;

    setFile(event.target.files[0]);
  }, [validateUploadFile]);

  const save = useCallback((additionalData) => {
    setIsUpdating(true);

    const requestSaveCanceler = requestCanceler();

    requestCancelers.current.push(requestSaveCanceler);

    if (data._id) {
      dispatch(updateSize({
        ...data,
        size: [+resWidth, +resHeight],
        ...additionalData,
      }, requestSaveCanceler.token))
        .then(() => toast('success', 'Размер обновлен'))
        .catch(({ message }) => toast('error', message))
        .finally(() => setIsUpdating(false));
    } else {
      dispatch(createSize({
        ...data,
        lang: queryString.parse(window.location.search)?.lang || 'ru',
        size: [+resWidth, +resHeight],
        ...additionalData,
      }, requestSaveCanceler.token))
        .then(() => {
          toast('success', 'Новый размер добавлен');

          setData(initialData);

          setResWidth(initialData.size[0]);
          setResHeight(initialData.size[1]);
        })
        .catch(({ message }) => toast('error', message))
        .finally(() => setIsUpdating(false));
    }
  }, [data, dispatch, initialData, resHeight, resWidth]);

  const uploadFile = useCallback((callback) => {
    const { name, type } = file;

    const requestGetSignedUrlCanceler = requestCanceler();

    requestCancelers.current.push(requestGetSignedUrlCanceler);

    dispatch(getSignedUrl({
      file_name: name,
      file_type: type,
      upload_type: 'size_preset',
    }, requestGetSignedUrlCanceler.token))
      .then((response) => {
        const requestSendToS3Canceler = requestCanceler();

        requestCancelers.current.push(requestSendToS3Canceler);

        dispatch(sendFileToS3(response.signedRequest, file, requestSendToS3Canceler.token))
          .then(() => {
            handleChange({ image_url: response.url });

            setFile(null);

            callback({ image_url: response.url });
          })
          .catch(() => toast('error', 'Не удалось загрузить файл по подписанному URL'));
      })
      .catch(() => toast('error', 'Не удалось получить подписанный URL'));
  }, [dispatch, file, handleChange]);

  const handleSubmit = useCallback(() => {
    setIsUpdating(true);

    if (file) {
      uploadFile(save);
    } else {
      save();
    }
  }, [file, save, uploadFile]);

  const applyResChangeListener = (type) => (e) => {
    const { value } = e.target;

    let resultValue = value.trim().replace(/^00+/, '0').replace(/ /g, '');

    if (!resultValue) resultValue = '0';

    resultValue = resultValue.replace(/^0(?=\d)/, '');

    // max 3 chars after comma
    if (resultValue.includes('.') && resultValue.split('.')[1].length > 3) return;

    // basic regexp to check if float value
    if (!(/^\d+(\.(\d+)?)?$/).test(resultValue) && resultValue) return;

    if (type === 'width') {
      setResWidth(resultValue);
    } else {
      setResHeight(resultValue);
    }

    handleChange({ isUnsaved: true });
  };

  useEffect(() => {
    if (file) {
      setFileObjectUrl(URL.createObjectURL(file));

      setIsUnsaved(true);
    } else {
      setFileObjectUrl('');
    }
  }, [file, handleChange]);

  useDidUpdateEffect(() => {
    if (data && !_.isEqual(prevData, data)) setIsUnsaved(true);
  }, [data, prevData]);

  useDidUpdateEffect(() => {
    if (initialData && !_.isEqual(prevInitialData, initialData)) setData(initialData);
  }, [initialData, prevInitialData]);

  useDidUpdateEffect(() => {
    if (prevIsUpdating && !isUpdating) setIsUnsaved(false);
  }, [prevIsUpdating, isUpdating]);

  useUnmount(() => {
    requestCancelers.current.forEach((canceler) => canceler.cancelRequest());
  });

  const selectedCategory = categories.find((category) => category._id === data.group_category_id);

  return (
    <div
      className={classNames('template-bunch-item template-bunch-item_size', {
        'is-updating': isUpdating,
      })}
    >
      <div className="template-bunch-item__header">
        <div className="template-bunch-item__size-image">
          <input
            accept="image/png,image/jpeg,image/svg+xml"
            disabled={isUpdating}
            name="logoFile"
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
          {(fileObjectUrl || data.image_url) && (
            <div className="template-bunch-item__size-image-media">
              <img src={fileObjectUrl || data.image_url} alt="" />
            </div>
          )}
          <div className="template-bunch-item__size-image-actions">
            {!(fileObjectUrl || data.image_url) && (
              <button
                disabled={isUpdating}
                title={(fileObjectUrl || data.image_url) ? 'Изменить' : 'Выбрать обложку...'}
                type="button"
                onClick={() => { fileInputRef.current.click(); }}
              >
                <FontAwesomeIcon icon={['fal', 'upload']} />
              </button>
            )}
            {(fileObjectUrl || data.image_url) && (
              <button
                disabled={isUpdating}
                title="Удалить иконку"
                type="button"
                onClick={() => {
                  if (data.image_url) handleChange({ image_url: '' });

                  if (fileObjectUrl) setFile(null);
                }}
              >
                <FontAwesomeIcon icon={['fal', 'times']} />
              </button>
            )}
          </div>
        </div>
        <div className="template-bunch-item__size-title">
          <Form.Control
            disabled={isUpdating}
            placeholder="Введите название размера"
            value={data.title}
            onChange={(e) => handleChange({ title: e.target.value })}
          />
        </div>

        <div className="template-bunch-item__size-show" title="Показывать размер в списке">
          <CheckBox
            checked={data?.show}
            disabled={isUpdating}
            id={`checkbox-show-${data._id}`}
            onChange={(value) => handleChange({ show: value })}
          />
        </div>

        {data.type !== 'custom' && (
          <Dropdown className="template-bunch-item__size-dropdown">
            <Dropdown.Toggle
              as="button"
              className="btn btn-light"
              disabled={isUpdating}
            >
              <span>
                {selectedCategory ? `${selectedCategory.group.short_title} - ${selectedCategory.short_title}` : 'Нет источника шаблонов'}
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                as="button"
                onSelect={() => handleChange({ group_category_id: undefined })}
              >
                Нет источника шаблонов
              </Dropdown.Item>
              {categories.map((category) => (
                <Dropdown.Item
                  as="button"
                  key={category._id}
                  onSelect={() => handleChange({ group_category_id: category._id })}
                >
                  {`${category.group.short_title} - ${category.short_title}`}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        )}

        <Dropdown className="template-bunch-item__size-dropdown">
          <Dropdown.Toggle
            as="button"
            className="btn btn-light"
            disabled={isUpdating}
          >
            {SIZE_MODES[data.mode]}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {Object.keys(SIZE_MODES).map((mode) => (
              <Dropdown.Item
                as="button"
                key={mode}
                onSelect={() => handleChange({ mode })}
              >
                {SIZE_MODES[mode]}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown className="template-bunch-item__size-dropdown">
          <Dropdown.Toggle
            as="button"
            className="btn btn-light"
            disabled={isUpdating}
          >
            {SIZE_TYPES[data.type]}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {Object.keys(SIZE_TYPES).map((type) => (
              <Dropdown.Item
                as="button"
                key={type}
                onSelect={() => handleChange({ type })}
              >
                {SIZE_TYPES[type]}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>

        {data.type !== 'custom' && (
          <>
            <div className="template-bunch-item__size-res">
              <Form.Control
                disabled={isUpdating}
                value={resWidth}
                style={{ width: '65px' }}
                onChange={applyResChangeListener('width')}
              />
              <span dangerouslySetInnerHTML={{ __html: '&nbsp;&nbsp;&nbsp;x&nbsp;&nbsp;&nbsp;' }} />
              <Form.Control
                disabled={isUpdating}
                value={resHeight}
                style={{ width: '65px' }}
                onChange={applyResChangeListener('height')}
              />
            </div>

            <Dropdown className="template-bunch-item__size-dropdown">
              <Dropdown.Toggle
                as="button"
                className="btn btn-light"
                disabled={isUpdating}
              >
                {data.units}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {SIZE_UNITS.map((units) => (
                  <Dropdown.Item
                    as="button"
                    key={units}
                    onSelect={() => handleChange({ units })}
                  >
                    {units}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </>
        )}

        <button
          className="template-bunch-item__button template-bunch-item__button_update"
          disabled={isUpdating || !isUnsaved}
          type="button"
          onClick={handleSubmit}
        >
          {isUpdating ? <Preloader medium /> : <FontAwesomeIcon icon={['fal', 'check']} />}
        </button>

        <button
          className="template-bunch-item__button template-bunch-item__button_delete"
          disabled={isUpdating}
          style={!data._id ? {
            opacity: 0,
            pointerEvents: 'none',
            visibillity: 'none',
          } : {}}
          type="button"
          onClick={handleDelete}
        >
          <FontAwesomeIcon icon={['fal', 'times']} />
        </button>
      </div>
    </div>
  );
};

SizesSize.propTypes = {
  categories: PropTypes.array.isRequired,
  data: PropTypes.object,
};

export default SizesSize;
