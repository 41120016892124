import { cloneDeep } from 'lodash';

import {
  CREATE_OBJECT_CATEGORY_OBJECT,
  CREATE_OBJECT_CATEGORY_EMPTY_OBJECT,
  DELETE_OBJECT_CATEGORY_OBJECT,
  GET_OBJECT_CATEGORY_OBJECTS,
  RESET_OBJECT_CATEGORY_OBJECTS,
  UPDATE_OBJECT_CATEGORY_OBJECT,
  GET_OBJECT_CATEGORY,
  ORDER_OBJECT_CATEGORY_OBJECTS,
  SAVE_OBJECT_CATEGORY_OBJECTS_ORDER,
} from '../../actions';

const defaultState = {
  category: {},
  docs: [],
  error: null,
  isFetching: false,
  isOrdered: false,
  isObjectChanging: {
    create: false,
    remove: null,
    update: null,
    order: false,
  },
};

function orderObjects(docs, payload) {
  const objs = cloneDeep(docs);

  const resObjs = objs.filter((item) => payload.objectIDs.includes(item._id));

  let prepared = objs.map((item) => {
    if (payload.objectIDs.includes(item._id)) {
      return {
        ...item,
        toDelete: true,
      };
    }

    return item;
  });

  let maxIndex = 0;

  payload.objectIDs.forEach((id) => {
    const i = objs.findIndex((item) => item._id === id);

    if (i > maxIndex) maxIndex = i;
  });

  const targetIndex = prepared.findIndex((item) => item._id === payload.targetID);

  prepared.splice(maxIndex > targetIndex ? targetIndex : targetIndex + 1, 0, ...resObjs);

  prepared = prepared.filter((item) => !item.toDelete);

  return prepared;
}

function returnError(response) {
  if (response.message) return { message: 'first_request_failed' };

  return { message: 'admin_privileges_required' };
}

const setIsObjectChanging = (isObjectChanging, props) => ({
  ...isObjectChanging,
  ...props,
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case `${GET_OBJECT_CATEGORY}_REQUEST`:
      return {
        ...state,
        error: null,
        category: null,
        isFetching: true,
      };
    case `${GET_OBJECT_CATEGORY_OBJECTS}_REQUEST`:
      return {
        ...state,
        docs: [],
        error: null,
        isFetching: true,
      };
    case `${CREATE_OBJECT_CATEGORY_OBJECT}_REQUEST`:
      return {
        ...state,
        error: null,
        isObjectChanging: setIsObjectChanging(state.isObjectChanging, { create: true }),
      };
    case `${DELETE_OBJECT_CATEGORY_OBJECT}_REQUEST`:
      return {
        ...state,
        error: null,
        isObjectChanging: setIsObjectChanging(state.isObjectChanging, { remove: action.payload.id }),
      };
    case `${UPDATE_OBJECT_CATEGORY_OBJECT}_REQUEST`:
      return {
        ...state,
        error: null,
        isObjectChanging: setIsObjectChanging(state.isObjectChanging, { update: action.payload.data._id }),
      };
    case `${SAVE_OBJECT_CATEGORY_OBJECTS_ORDER}_REQUEST`:
      return {
        ...state,
        isObjectChanging: setIsObjectChanging(state.isObjectChanging, { order: true }),
      };
    case ORDER_OBJECT_CATEGORY_OBJECTS:
      return {
        ...state,
        isOrdered: true,
        docs: orderObjects(state.docs, action.payload),
      };
    case SAVE_OBJECT_CATEGORY_OBJECTS_ORDER:
      return {
        ...state,
        isOrdered: false,
        isObjectChanging: setIsObjectChanging(state.isObjectChanging, { order: false }),
      };
    case GET_OBJECT_CATEGORY:
      return {
        ...state,
        category: action.response,
        isFetching: false,
        isOrdered: false,
      };
    case GET_OBJECT_CATEGORY_OBJECTS:
      return {
        ...state,
        docs: action.response,
        isFetching: false,
        isOrdered: false,
      };
    case UPDATE_OBJECT_CATEGORY_OBJECT:
      return {
        ...state,
        docs: state.docs.map((item) => {
          if (item._id === action.payload.data._id) {
            return {
              ...action.payload.data,
              ...action.response,
            };
          }

          return item;
        }),
        isFetching: false,
        isObjectChanging: setIsObjectChanging(state.isObjectChanging, { update: null }),
      };
    case DELETE_OBJECT_CATEGORY_OBJECT:
      return {
        ...state,
        docs: state.docs.filter((item) => item._id !== action.payload.id),
        isObjectChanging: setIsObjectChanging(state.isObjectChanging, { remove: null }),
      };
    case CREATE_OBJECT_CATEGORY_OBJECT:
      const { is_empty, ...data } = action.payload.data; // eslint-disable-line

      return {
        ...state,
        docs: state.docs.map((item) => {
          if (item._id === action.payload.data._id) {
            return {
              ...data,
              ...action.response,
            };
          }

          return item;
        }),
        isObjectChanging: setIsObjectChanging(state.isObjectChanging, { create: false }),
      };
    case CREATE_OBJECT_CATEGORY_EMPTY_OBJECT:
      return {
        ...state,
        docs: [
          ...state.docs,
          {
            ...action.payload.data,
          },
        ],
      };
    case `${GET_OBJECT_CATEGORY}_FAILURE`:
      return {
        ...state,
        error: returnError(action.response),
        isFetching: false,
      };
    case `${GET_OBJECT_CATEGORY_OBJECTS}_FAILURE`:
      return {
        ...state,
        error: returnError(action.response),
        isFetching: false,
      };
    case `${UPDATE_OBJECT_CATEGORY_OBJECT}_FAILURE`:
      return {
        ...state,
        isFetching: false,
        isObjectChanging: setIsObjectChanging(state.isObjectChanging, { update: null }),
      };
    case `${DELETE_OBJECT_CATEGORY_OBJECT}_FAILURE`:
      return {
        ...state,
        isObjectChanging: setIsObjectChanging(state.isObjectChanging, { remove: null }),
      };
    case `${CREATE_OBJECT_CATEGORY_OBJECT}_FAILURE`:
      return {
        ...state,
        isObjectChanging: setIsObjectChanging(state.isObjectChanging, { create: false }),
      };
    case `${SAVE_OBJECT_CATEGORY_OBJECTS_ORDER}_FAILURE`:
      return {
        ...state,
        isOrdered: false,
        isObjectChanging: setIsObjectChanging(state.isObjectChanging, { order: false }),
      };
    case RESET_OBJECT_CATEGORY_OBJECTS:
      return defaultState;
    default:
      return state;
  }
};
