import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {
  useState, useEffect, useRef, memo,
} from 'react';
import { Form } from 'react-bootstrap';

import './TagsInput.scss';

const TagsInput = ({ defaultTags = [], disabled, onChange }) => {
  const [isFocused, setIsFocused] = useState(false);
  const [tags, setTags] = useState([]);
  const [value, setValue] = useState('');

  const inputRef = useRef(null);

  useEffect(() => {
    if (defaultTags.length) setTags(defaultTags);
  }, [defaultTags, setTags]);

  const createTag = () => {
    const valueInLowerCase = value.toLowerCase();

    const isExists = tags.includes(valueInLowerCase);

    if (!isExists) {
      const newTags = [
        ...tags,
        valueInLowerCase,
      ];

      setTags(newTags);
      setValue('');

      onChange(newTags);
    }
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && value) {
      createTag();
    }
  };

  const handleRemoveTag = (tagName) => {
    const newTags = tags.filter((tag) => tag !== tagName);

    setTags(newTags);
    onChange(newTags);
  };

  return (
    // eslint-disable-next-line
    <div
      className={classNames('tags-input', { focused: isFocused, disabled, exist: tags.includes(value) })}
      onClick={() => {
        setIsFocused(true);
        inputRef.current.focus();
      }}
    >
      <div className="tags-input__tags">
        {tags.map((tag, i) => (
          // eslint-disable-next-line
          <div key={tag + i} className="tags-input__tag">
            {tag}
            {/* eslint-disable-next-line */}
            <span onClick={() => handleRemoveTag(tag)}>
              <svg height="329pt" viewBox="0 0 329.26933 329" width="329pt" xmlns="http://www.w3.org/2000/svg"><path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0" /></svg>
            </span>
          </div>
        ))}
        <Form.Control
          ref={inputRef}
          className="tags-input__field"
          disabled={disabled}
          value={value}
          name="input"
          placeholder="Новая метка"
          title="Введите название тега и нажмите Enter"
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          onBlur={() => setIsFocused(false)}
        />
      </div>
    </div>
  );
};

TagsInput.propTypes = {
  defaultTags: PropTypes.array,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default memo(TagsInput);
