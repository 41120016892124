import { connect } from 'react-redux';

import {
  RESET_PROMOCODES,
  createPromocode,
  getPromocodes,
  updatePromocode,
} from '../../../actions';

import Promocodes from './Promocodes';

const mapStateToProps = (state) => ({
  promocodes: state.promocodes,
});

const mapDispatchToProps = (dispatch) => ({
  onCreatePromocode: (data, requestToken) => dispatch(createPromocode(data, requestToken)),
  onGetPromocodes: (requestToken) => dispatch(getPromocodes(requestToken)),
  onUpdatePromocode: (id, data, requestToken) => dispatch(updatePromocode(id, data, requestToken)),
  onUnload: () => dispatch({ type: RESET_PROMOCODES }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Promocodes);
