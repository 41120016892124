export const permissions = {
  ALL: 'all',
  APICACHE: 'apicache',
  USERS: 'users',
  INVOICES: 'invoices',
  PARTNERS: 'partners',
  MAILING: 'mailing',
  TEMPLATES: 'templates',
  VIDEOS: 'videos',
  IDEAS: 'ideas',
  ELAMA: 'elama',
  TAGS: 'tags',
  LANDING: 'landing',
  OBJECTS: 'objects',
  SERVICES: 'services',
  ACCESS: 'access',
  COMPANIES: 'companies',
  SUPPORT: 'support',
};

export const permissionNames = {
  ALL: 'Полные права',
  APICACHE: 'API-кеш',
  USERS: 'Пользователи',
  INVOICES: 'Счета',
  PARTNERS: 'Партнеры',
  MAILING: 'Рассылки',
  TEMPLATES: 'Шаблоны',
  VIDEOS: 'Видео',
  IDEAS: 'Идеи',
  ELAMA: 'Elama',
  TAGS: 'Теги',
  LANDING: 'Лендинг',
  OBJECTS: 'Объекты',
  SERVICES: 'Тарифы',
  ACCESS: 'Доступ',
  COMPANIES: 'Контрагенты',
  SUPPORT: 'Тех. поддержка (доп. ограничения)',
};

export const languages = {
  ru: 'Русский',
  en: 'Английский',
};

export const userBlocks = {
  none: 'Нет',
  sharing: 'Шаринг',
};

export const videoFormats = {
  landscape: 'Горизонтальное',
  stories: 'Stories',
  square: 'Квадратное',
  portrait: 'Портретное',
  a1_portrait: 'A1 (портретное)',
  a1_landscape: 'A1 (альбомное)',
  a2_portrait: 'A2 (портретное)',
  a2_landscape: 'A2 (альбомное)',
  a3_portrait: 'A3 (портретное)',
  a3_landscape: 'A3 (альбомное)',
  a4_portrait: 'A4 (портретное)',
  a4_landscape: 'A4 (альбомное)',
  a5_portrait: 'A5 (портретное)',
  a5_landscape: 'A5 (альбомное)',
  a6_portrait: 'A6 (портретное)',
  a6_landscape: 'A6 (альбомное)',
  address_card: 'Визитка',
  custom: 'Произвольное',
};

export const variationTypes = {
  video: 'Видео',
  image: 'Изображение',
  site: 'Сайт',
};

export const variationScrollHint = {
  default: 'Стандартный',
  none: 'Выключен',
};

export const tdrNotices = {
  tdr_accepted: {
    subject: 'SUPA - Ваш шаблон #variation_id одобрен',
    message: 'Поздравляем!\n\nВаш шаблон одобрен модератором и будет опубликован на сайте SUPA.\n\nВ скором времени мы выплатим вам вознаграждение.\n\nСпасибо за участие! Ждем от вас новых шаблонов!\n\nШаблон: https://supa.ru/app/designs/create?type=template&template_id=variation_id&mode',
  },
  tdr_paid: {
    subject: 'SUPA - Ваш шаблон #variation_id оплачен',
    message: 'Ура, денежки прилетели!\n\nВам выплачено вознаграждение в размере 500 рублей!\n\nШаблон: https://supa.ru/app/designs/create?type=template&template_id=variation_id&mode',
  },
  tdr_rejected: {
    subject: 'SUPA - Ваш шаблон #variation_id отклонен',
    message: 'Сожалеем, но ваш шаблон был отклонен модератором.\n\nПричина:\n\nШаблон: https://supa.ru/app/designs/create?type=template&template_id=variation_id&mode',
  },
};

export const SIZE_1_MB = 1048576;

export const PREFIX_ACTION_TYPE_LANDING_PAGES = 'LANDING_PAGES_';
export const PREFIX_ACTION_TYPE_LANDING_CATEGORIES = 'LANDING_CATEGORIES_';
export const PREFIX_ACTION_TYPE_LANDING_MENUS = 'LANDING_MENUS_';
export const PREFIX_ACTION_TYPE_LANDING_FORM = 'LANDING_FORM_';
