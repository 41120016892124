import React, { memo, useRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Preloader from '../Preloader/Preloader';

import './CheckBox.scss';

const CheckBox = ({
  children,
  checked,
  eventKey,
  isFetching = false,
  className,
  labelClassName,
  disabled,
  id,
  onChange,
  ...props
}) => {
  const inputRef = useRef(null);

  const handleClickToggle = () => {
    onChange(inputRef.current.checked, eventKey);
  };

  return (
    <div className={`custom-checkbox${className ? ` ${className}` : ''}`}>
      <input
        {...props}
        id={id}
        ref={inputRef}
        type="checkbox"
        disabled={disabled}
        checked={checked}
        onChange={handleClickToggle}
      />
      <label htmlFor={id} className={labelClassName}>
        {isFetching
          ? <Preloader inline small />
          : <FontAwesomeIcon icon={['fas', 'check']} />}
        {children}
      </label>
    </div>
  );
};

CheckBox.defaultProps = {
  checked: false,
};

CheckBox.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  eventKey: PropTypes.string,
  labelClassName: PropTypes.string,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  isFetching: PropTypes.bool,
};

export default memo(CheckBox);
