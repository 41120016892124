import PropTypes from 'prop-types';
import React from 'react';
import { Alert } from 'react-bootstrap';

import { requestCanceler } from '../../../../middlewares/api';

import Logo from '../../../UI/Logo/Logo';
import Preloader from '../../../UI/Preloader/Preloader';

import errorMessages from '../../../../constants/errors';

class SignIn extends React.Component {
  state = {
    login: '',
    password: '',
  };

  requestSignInCanceler = null;

  componentWillUnmount() {
    const { onUnload } = this.props;

    if (this.requestSignInCanceler) this.requestSignInCanceler.cancelRequest();

    onUnload();
  }

  handleChangeInput = (event) => {
    const { name } = event.target;
    const value = event.target.value.trim();

    this.setState({
      [name]: value,
    });
  };

  handleSubmitForm = (event) => {
    event.preventDefault();

    const { onSignIn } = this.props;
    const { login, password } = this.state;

    this.requestSignInCanceler = requestCanceler();

    onSignIn({ login, password }, this.requestSignInCanceler.token).catch(() => {});
  };

  renderErrors = () => {
    const { auth } = this.props;

    return (
      <Alert variant="danger">
        {
          ['user_not_found', 'password_incorrect', 'Network Error']
            .includes(auth.error.message)
            ? errorMessages[auth.error.message]
            : errorMessages.unknown_error
        }
      </Alert>
    );
  };

  renderHeader = () => (
    <div className="auth__header">
      <div className="auth__logo">
        <Logo href="https://supa.ru/" title="SUPA" />
      </div>
      <h1 className="auth__title">Авторизуйтесь и вперед!</h1>
    </div>
  );

  renderMain = () => {
    const { login, password } = this.state;
    const { auth } = this.props;

    return (
      <div className="auth__main">
        <div className="auth__form">
          <form onSubmit={this.handleSubmitForm} className="form">
            {auth.error && this.renderErrors()}
            <input
              className="form-control"
              name="login"
              placeholder="Ваш E-Mail или логин (если есть)"
              required
              type="text"
              value={login}
              onChange={this.handleChangeInput}
            />
            <input
              className="form-control"
              name="password"
              placeholder="Ваш пароль"
              required
              type="password"
              value={password}
              onChange={this.handleChangeInput}
            />
            <button
              className="btn btn-lg btn-outline-secondary btn-block"
              disabled={auth.isFetching || !login || !password}
              type="submit"
            >
              войти
            </button>
          </form>
        </div>
      </div>
    );
  };

  renderFooter = () => {
    const { auth } = this.props;

    return (
      <div className="auth__footer">
        {auth.isFetching && (
          <div className="auth__preloader">
            <Preloader />
          </div>
        )}
      </div>
    );
  };

  render() {
    return (
      <div className="auth auth_sign-in">
        <div className="row justify-content-center">
          <div className="col-4">
            {this.renderHeader()}
            {this.renderMain()}
            {this.renderFooter()}
          </div>
        </div>
      </div>
    );
  }
}

SignIn.propTypes = {
  auth: PropTypes.object.isRequired,
  onSignIn: PropTypes.func.isRequired,
  onUnload: PropTypes.func.isRequired,
};

export default SignIn;
