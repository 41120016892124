import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import './TemplatesActions.scss';

const TemplatesActions = ({
  isFetching, isOrdered, stateChanging, type, onClickCreate, onClickSaveOrder,
}) => (
  <div className="templates-actions">
    {!isFetching && typeof onClickCreate === 'function' && (
      <Button
        disabled={stateChanging.create}
        onClick={onClickCreate}
      >
        {`Добавить ${type}`}
      </Button>
    )}
    {!isFetching && isOrdered && typeof onClickSaveOrder === 'function' && (
      <Button
        disabled={stateChanging.order}
        onClick={onClickSaveOrder}
      >
        Сохранить изменение порядка
      </Button>
    )}
  </div>
);

TemplatesActions.propTypes = {
  type: PropTypes.string,
  isFetching: PropTypes.bool,
  isOrdered: PropTypes.bool,
  stateChanging: PropTypes.shape({
    create: PropTypes.bool,
    order: PropTypes.bool,
  }),
  onClickCreate: PropTypes.func,
  onClickSaveOrder: PropTypes.func,
};

export default TemplatesActions;
