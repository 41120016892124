export const CHANGE_TEMPLATE = 'CHANGE_TEMPLATE';
export const CREATE_TEMPLATE = 'CREATE_TEMPLATE';
export const GET_TEMPLATES = 'GET_TEMPLATES';
export const GET_CATEGORY = 'GET_CATEGORY';
export const REMOVE_TEMPLATE = 'REMOVE_TEMPLATE';
export const RESET_TEMPLATES = 'RESET_TEMPLATES';
export const TEMPLATE_ADD_READY_VARIATION = 'TEMPLATE_ADD_READY_VARIATION';
export const TEMPLATE_CHANGE_READY_VARIATION = 'TEMPLATE_CHANGE_READY_VARIATION';
export const TEMPLATE_CREATE_VARIATION = 'TEMPLATE_CREATE_VARIATION';
export const TEMPLATE_UPDATE_VARIATION = 'TEMPLATE_UPDATE_VARIATION';
export const TEMPLATE_UPDATE_READY_VARIATION = 'TEMPLATE_UPDATE_READY_VARIATION';
export const TEMPLATE_GET_VARIATION = 'TEMPLATE_GET_VARIATION';
export const TEMPLATE_REMOVE_VARIATION = 'TEMPLATE_REMOVE_VARIATION';
export const TEMPLATE_SET_TDR_NOTICE = 'TEMPLATE_SET_TDR_NOTICE';
export const TEMPLATE_REMOVE_TDR_NOTICE = 'TEMPLATE_REMOVE_TDR_NOTICE';
export const TEMPLATE_SEND_TDR_NOTICE = 'TEMPLATE_SEND_TDR_NOTICE';
export const UPDATE_TEMPLATE = 'UPDATE_TEMPLATE';

/* --------------- */
/* Action creators */

/* --------------- */

export function getTemplates(params, requestToken) {
  return {
    type: GET_TEMPLATES,
    request: {
      method: 'get',
      url: '/admin/templates',
      params,
      requestToken,
    },
  };
}

export function changeTemplate(id, params) {
  return {
    type: CHANGE_TEMPLATE,
    payload: { id, params },
  };
}

export function createTemplate(data, requestToken) {
  return {
    type: CREATE_TEMPLATE,
    request: {
      method: 'post',
      url: '/admin/templates',
      data,
      requestToken,
    },
    payload: { categoryId: data.category_id },
  };
}

export function updateTemplate(data, requestToken) {
  return {
    type: UPDATE_TEMPLATE,
    request: {
      method: 'patch',
      url: `/admin/templates/${data._id}`,
      data,
      requestToken,
    },
    payload: { id: data._id },
  };
}

export function removeTemplate(id, requestToken) {
  return {
    type: REMOVE_TEMPLATE,
    request: {
      method: 'delete',
      url: `/admin/templates/${id}`,
      requestToken,
    },
    payload: { id },
  };
}

export function createVariation(template_id, video_id, type, requestToken) {
  return {
    type: TEMPLATE_CREATE_VARIATION,
    request: {
      method: 'post',
      url: '/admin/template_variations',
      data: {
        template_id,
        video_id,
        type,
      },
      requestToken,
    },
    payload: { templateId: template_id },
  };
}

export function updateVariation(variation_id, data, requestToken) {
  return {
    type: TEMPLATE_UPDATE_VARIATION,
    request: {
      method: 'put',
      url: `/admin/template_variations/${variation_id}`,
      data,
      requestToken,
    },
  };
}

export function getVariation(templateId, id, requestToken) {
  return {
    type: TEMPLATE_GET_VARIATION,
    request: {
      method: 'get',
      url: `/template_variations/${id}`,
      requestToken,
    },
  };
}

export function removeVariation(templateId, variationId, requestToken) {
  return {
    type: TEMPLATE_REMOVE_VARIATION,
    request: {
      method: 'delete',
      url: `/admin/template_variations/${variationId}`,
      requestToken,
    },
    payload: { templateId, variationId },
  };
}

export function addReadyVariation(templateId, variation) {
  return {
    type: TEMPLATE_ADD_READY_VARIATION,
    payload: { templateId, variation },
  };
}

export function updateReadyVariation(templateId, oldVariationId, newVariation) {
  return {
    type: TEMPLATE_UPDATE_READY_VARIATION,
    payload: { templateId, oldVariationId, newVariation },
  };
}

export function changeReadyVariation(templateId, variation) {
  return {
    type: TEMPLATE_CHANGE_READY_VARIATION,
    payload: { templateId, variation },
  };
}

/* --------------- */
/* Thunk creators */

/* --------------- */

function autoGetVariation(check, done) {
  let timeoutCheckVariations;

  if (!window.location.pathname.includes('templates') || window.location.pathname.split('/').length < 3) return;

  check()
    .then((response) => {
      if (response.state === 'done') {
        done(response);
      } else {
        timeoutCheckVariations = setTimeout(() => {
          autoGetVariation(check, done);
        }, 5000);
      }
    });

  // eslint-disable-next-line
  timeoutCheckVariations && clearTimeout(timeoutCheckVariations);
}

export function autoCheckVariations(templateId) {
  return (dispatch, getState) => {
    const { templates } = getState();
    const { notReadyVariations } = templates;

    if (notReadyVariations.length) {
      notReadyVariations.forEach((variation) => {
        autoGetVariation(() => dispatch(getVariation(templateId, variation.id)), (response) => {
          const template_id = templateId || response.template_id;

          dispatch(changeReadyVariation(template_id, response));
        });
      });
    }
  };
}

export function autoCheckUpdateVariations(templateId) {
  return (dispatch, getState) => {
    const { templates } = getState();
    const { notReadyUpdatingVariations } = templates;

    if (notReadyUpdatingVariations.length) {
      notReadyUpdatingVariations.forEach((variation) => {
        autoGetVariation(() => dispatch(getVariation(templateId, variation.id)), (response) => dispatch(changeReadyVariation(templateId, response)));
      });
    }
  };
}

export function setTdrNotice(templateId, notification) {
  return {
    type: TEMPLATE_SET_TDR_NOTICE,
    payload: { templateId, notification },
  };
}

export function removeTdrNotice(templateId, slug) {
  return {
    type: TEMPLATE_REMOVE_TDR_NOTICE,
    payload: { templateId, slug },
  };
}

export function sendTdrNotice(data, requestToken) {
  return {
    type: TEMPLATE_SEND_TDR_NOTICE,
    request: {
      method: 'post',
      url: '/tdr/notice',
      data,
      requestToken,
    },
    payload: { templateId: data.template_id },
  };
}
