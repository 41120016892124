import React, {
  memo, useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { toast } from '../../../../utils';

import errorMessages from '../../../../constants/errors';

import { RESET_ACCESS_IP_ADDRESSES, getAdminIPAddress, updateAdminIPAddress } from '../../../../actions';

import withRequest from '../../../HOCs/withRequest';

import View from '../../../Layout/View/View';

import AccessSubnav from '../../../Includes/Access/AccessSubnav/AccessSubnav';

import AccessIPAddressesForm from '../AccessIPAddressesForm/AccessIPAddressesForm';

const AccessIPAddressesEdit = ({ match: { params: { id } }, createCanceler, dispatch }) => {
  const addresses = useSelector(({ accessIPAddresses }) => accessIPAddresses);

  const [isDataFetching, setIsDataFetching] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    setIsDataFetching(true);

    const getIPAddressRequest = createCanceler();

    dispatch(getAdminIPAddress(id, getIPAddressRequest.token))
      .then((res) => {
        setIsDataFetching(false);

        setData({ address: res.address, description: res?.description || '' });
      })
      .catch(() => setIsDataFetching(false));
  }, []); // eslint-disable-line

  const handleEdit = useCallback((formData, successCallback) => {
    const updateIPAddressRequest = createCanceler();

    dispatch(updateAdminIPAddress(id, formData, updateIPAddressRequest.token))
      .then(() => {
        toast('success', 'IP-адрес изменён!');

        successCallback();
      })
      .catch((error) => {
        const message = errorMessages[error.message] || error.message;

        toast('error', message);
      });
  }, [createCanceler, dispatch, id]);

  useEffect(() => () => {
    dispatch({ type: RESET_ACCESS_IP_ADDRESSES });
  }, [dispatch]);

  return (
    <View
      errorMessage={errorMessages[addresses?.error?.message]}
      isError={addresses.error && (addresses.error?.message === 'ip_address_not_found' || ['admin_privileges_required'].includes(addresses.error.message))}
      isFetching={isDataFetching}
      preloaderCaption="Загружаем..."
      header={<AccessSubnav style={{ marginBottom: '32px' }} />}
      isHeaderBeforeTitle
      title="Редактировать IP-адрес"
      viewClass="ip-address-edit"
      content={data ? (
        <AccessIPAddressesForm isEditMode defaultData={data} addresses={addresses} onSubmit={handleEdit} />
      ) : null}
    />
  );
};

AccessIPAddressesEdit.propTypes = {
  match: PropTypes.object,
  createCanceler: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default memo(withRequest(withRouter(AccessIPAddressesEdit)));
