import Cookies from 'universal-cookie';

import {
  CHANGE_PASS,
  SIGN_IN,
  LOGOUT,
} from '../actions';

const cookies = new Cookies();

export function loadTokenState() {
  try {
    if (cookies.get('token')) {
      localStorage.setItem('token', cookies.get('token'));

      cookies.remove('token');
    }

    return localStorage.getItem('token');
  } catch (error) {
    return undefined;
  }
}

export default () => (next) => (action) => {
  if ([CHANGE_PASS, SIGN_IN].includes(action.type)) {
    localStorage.setItem('token', action.response.token);
  } else if (action.type === LOGOUT) {
    localStorage.removeItem('token');
  }

  next(action);
};
