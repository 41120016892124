import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';

const TemplatesTemplateResources = ({ variations }) => {
  const resources = useMemo(() => {
    const res = [];

    if (variations && variations?.length) {
      variations.forEach((variation) => {
        variation.data.slides.forEach((slide) => {
          if (slide._attribution) res.push(slide._attribution);

          slide.objects.forEach((object) => {
            if (object._attribution) res.push(object._attribution);

            if (object?.style?.background?._attribution) res.push(object.style.background._attribution);
          });
        });
      });
    }

    return res;
  }, [variations]);

  return resources.length ? (
    <div className="template__row">
      <div className="template__label">Стоковые ресурсы:</div>
      <div className="template__row-content template__tdr-resources">
        <ul>
          {resources.map((item) => (
            <li key={item}><a href={item} target="_blank" rel="noreferrer">{item}</a></li>
          ))}
        </ul>
      </div>
    </div>
  ) : null;
};

TemplatesTemplateResources.propTypes = {
  variations: PropTypes.array,
};

export default memo(TemplatesTemplateResources);
