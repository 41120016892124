import PropTypes from 'prop-types';
import React, { useState } from 'react'; // eslint-disable-line
import { Accordion } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './Spoiler.scss';

const Spoiler = ({
  className,
  collapsed,
  content,
  title,
}) => {
  const [isShow, setIsShow] = useState(!collapsed);

  return (
    <div className={`spoiler ${className}`}>
      <Accordion defaultActiveKey={!collapsed ? '0' : ''}>
        <Accordion.Toggle
          as="div"
          eventKey="0"
          className={`spoiler__header${isShow ? ' show' : ''}`}
          onClick={() => {
            setIsShow(!isShow);
          }}
        >
          {title}
          <FontAwesomeIcon icon={['far', 'chevron-down']} />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <div className="spoiler__body">
            {content}
          </div>
        </Accordion.Collapse>
      </Accordion>
    </div>
  );
};

Spoiler.defaultProps = {
  collapsed: false,
  title: 'Title',
  content: 'Content',
};

Spoiler.propTypes = {
  className: PropTypes.string,
  collapsed: PropTypes.bool,
  content: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

export default Spoiler;
