import React, { forwardRef, useEffect, useState } from 'react';
import { Dropdown as DropdownBts, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useDebounce, useClickOutside } from '../../hooks';

import './SearchDropdown.scss';

const CustomMenu = forwardRef((props, ref) => {
  const {
    children, className, 'aria-labelledby': labeledBy, placeholder, onChangeSearch,
  } = props;
  const [search, setSearch] = useState('');

  const handleChangeSearch = (e) => {
    const { value } = e.target;

    setSearch(value);
    onChangeSearch?.(value);
  };

  return (
    <div
      ref={ref}
      className={classNames('custom-menu', className)}
      aria-labelledby={labeledBy}
    >
      <div className="custom-menu__wrapper">
        <FontAwesomeIcon className="custom-menu__search-icon" icon={['fas', 'search']} />
        <Form.Control
          autoFocus
          className="custom-menu__search-input"
          placeholder={placeholder}
          value={search}
          onChange={handleChangeSearch}
        />
      </div>

      <ul className="custom-menu__list">
        {children}
      </ul>
    </div>
  );
});

const SearchDropdown = (props) => {
  const {
    className, value, disabled, onSearch, placeholder, children,
  } = props;

  const [show, setShow] = useState(false);

  const dropdownRef = useClickOutside(() => setShow(false), ['.modal']);

  const handleChange = useDebounce(onSearch, 300);

  const handleToggle = () => setShow((prev) => !prev);

  useEffect(() => {
    setShow(false);
  }, [value]);

  return (
    <div className={classNames('search-dropdown', className, { disabled })} ref={dropdownRef}>
      <DropdownBts show={show}>
        <DropdownBts.Toggle
          type="button"
          className="search-dropdown__toggle btn btn-light"
          variant="light"
          onClick={handleToggle}
        >
          <span>{value}</span>
          <FontAwesomeIcon className="search-dropdown__chevron" icon={['far', 'chevron-down']} />
        </DropdownBts.Toggle>
        <DropdownBts.Menu as={CustomMenu} onChangeSearch={handleChange} placeholder={placeholder}>
          {
            children?.length > 0
              ? children
              : <div className="search-dropdown__empty-list">Список пуст</div>
          }
        </DropdownBts.Menu>
      </DropdownBts>
    </div>
  );
};

CustomMenu.propTypes = {
  'aria-labelledby': PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChangeSearch: PropTypes.func.isRequired,
};

SearchDropdown.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
};

export default SearchDropdown;
