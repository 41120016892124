import PropTypes from 'prop-types';
import React, {
  memo, useCallback, useEffect, useRef,
} from 'react';

import { requestCanceler } from '../../../../middlewares/api';

import errorMessages from '../../../../constants/errors';

import { toast } from '../../../../utils';

import View from '../../../Layout/View/View';

import ObjectCategoriesForm from '../CategoriesForm/CategoriesForm';

const CategoriesCreate = ({
  location, objectCategories, onCreateObjectCategory, onUnload,
}) => {
  const { error, isFetching } = objectCategories;

  const requestCancelers = useRef([]);

  const handleCreate = useCallback((data, cb) => {
    if (isFetching) return;

    const request = requestCanceler();

    requestCancelers.current.push(request);

    onCreateObjectCategory(data, request.token)
      .then(() => {
        toast('success', 'Категория добавлена!');

        cb();
      })
      .catch((err) => err?.message && toast('error', errorMessages[err.message]));
  }, [isFetching, onCreateObjectCategory]);

  useEffect(() => () => {
    requestCancelers.current.forEach((canceler) => canceler.cancelRequest());

    onUnload();
  }, [onUnload]);

  return (
    <View
      errorMessage={errorMessages[error?.message]}
      isError={error && ['admin_privileges_required', 'first_request_failed'].includes(error.message)}
      viewClass="object-categories object-categories_create"
      title="Добавить категорию"
      content={(
        <ObjectCategoriesForm
          buttonText="Добавить категорию"
          disabled={isFetching}
          location={location}
          resetAfterSubmit
          onSubmit={handleCreate}
        />
      )}
    />
  );
};

CategoriesCreate.propTypes = {
  location: PropTypes.object,
  objectCategories: PropTypes.object.isRequired,
  onCreateObjectCategory: PropTypes.func.isRequired,
  onUnload: PropTypes.func.isRequired,
};

export default memo(CategoriesCreate);
