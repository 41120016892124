import { connect } from 'react-redux';

import {
  RESET_USERS,
  addVideo,
  createInvoice,
  deletePeriod,
  changePeriodDate,
  getUsers,
  getUserLogs,
  removePeriodMigrationID,
  userSignIn,
  userUnbindOauth,
  updateUser,
} from '../../../actions';

import Users from './Users';

const mapStateToProps = (state) => ({
  auth: state.auth,
  users: state.users,
});

const mapDispatchToProps = (dispatch) => ({
  onAddVideo: (data, requestToken) => dispatch(addVideo(data, requestToken)),
  onChangeUser: (id, data, requestToken) => dispatch(updateUser(id, data, requestToken)),
  onCreateInvoice: (params, requestToken) => dispatch(createInvoice(params, requestToken)),
  onDeletePeriod: (id, periodId, requestToken) => dispatch(deletePeriod(id, periodId, requestToken)),
  onChangePeriodDate: (userId, periodId, data, requestToken) => dispatch(changePeriodDate(userId, periodId, data, requestToken)),
  onGetUsers: (params, isSearch, requestToken) => dispatch(getUsers(params, isSearch, requestToken)),
  onGetTeamUsers: (params, requestToken) => dispatch(getUsers(params, requestToken)),
  onGetUserLogs: (id, params, requestToken) => dispatch(getUserLogs(id, params, requestToken)),
  onRemovePeriodMigrationID: (id, periodId, requestToken) => dispatch(removePeriodMigrationID(id, periodId, requestToken)),
  onUserSignIn: (data, requestToken) => dispatch(userSignIn(data, requestToken)),
  onUserUnbindOauth: (id, requestToken) => dispatch(userUnbindOauth(id, requestToken)),
  onUnload: () => dispatch({ type: RESET_USERS }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
