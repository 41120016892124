import PropTypes from 'prop-types';
import React, { memo, useState } from 'react';
import {
  Button, Dropdown, Tabs, Tab, Form,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { variationTypes, variationScrollHint, videoFormats } from '../../../../../../constants/common';

import FieldForm from '../../../../../Includes/FieldForm/FieldForm';

import Preloader from '../../../../../UI/Preloader/Preloader';

import TemplatesTemplatePreview from '../TemplatesTemplatePreview/TemplatesTemplatePreview';

const TemplateVariation = ({
  variation, isTemplateChanging, onRemoveVariation, onUpdateVariation,
}) => {
  const [type, setType] = useState(variation.type || 'video');
  const [scrollHint, setScrollHint] = useState(variation.scroll_hint || 'default');
  const [startPosition, setStartPosition] = useState(variation.start_position || '');

  return (
    <div className="template__variations-info">
      <TemplatesTemplatePreview alt="Превью видео" src={variation?.image} small />
      <div className="template__content">
        <div className="template__row">
          <div className="template__label">Видео:</div>
          <div className="template__row-content">
            <a href={variation?.video} target="_blank" rel="noreferrer">
              {variation?.state === 'pending' ? 'генерируется...' : variation?.video}
            </a>
          </div>
        </div>
        <div className="template__row">
          <div className="template__label">Обложка:</div>
          <div className="template__row-content">
            <a href={variation?.image} target="_blank" rel="noreferrer">
              {variation?.state === 'pending' ? 'генерируется...' : variation?.image}
            </a>
          </div>
        </div>
        <div className="template__row">
          <div className="template__label">Изменить:</div>
          <div className="template__row-content template__variations-actions">
            {variation?.state === 'pending' ? 'генерируется...' : (
              <div className="template__variations-form">
                <Dropdown className="template__type-select">
                  <Dropdown.Toggle
                    as="button"
                    className="btn btn-light"
                    disabled={isTemplateChanging.updateVariation}
                  >
                    {variationTypes[type]}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {type !== 'video' && (
                      <Dropdown.Item
                        as="button"
                        eventKey="video"
                        onSelect={(value) => setType(value)}
                      >
                        {variationTypes.video}
                      </Dropdown.Item>
                    )}
                    {type !== 'image' && (
                      <Dropdown.Item
                        as="button"
                        eventKey="image"
                        onSelect={(value) => setType(value)}
                      >
                        {variationTypes.image}
                      </Dropdown.Item>
                    )}
                    {type !== 'site' && (
                      <Dropdown.Item
                        as="button"
                        eventKey="site"
                        onSelect={(value) => setType(value)}
                      >
                        {variationTypes.site}
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
                <FieldForm
                  buttonText="Сгенерировать"
                  disabled={isTemplateChanging.updateVariation}
                  fieldType="input"
                  placeholder="ID видео"
                  onSubmit={(videoId) => {
                    onUpdateVariation(variation.id, {
                      video_id: videoId,
                      start_position: Number.parseFloat(startPosition.toString().replace(/-/g, '') || 0),
                      scroll_hint: scrollHint,
                      type,
                    });
                  }}
                />
              </div>
            )}
            <div className="template__variations-actions-btns">
              <Button
                href={`${window.SUPA_DASHBOARD_URL}/designs/create?type=template${variation.type === 'image' ? `&mode=${variation.type}` : ''}&template_id=${variation.id}&from=admin`}
                size="sm"
                target="_blank"
                variant="light"
              >
                <FontAwesomeIcon icon={['fas', 'eye']} />
              </Button>
              <Button disabled={!!isTemplateChanging.removeVariation[variation.id]} variant="light" size="sm" onClick={() => onRemoveVariation(variation.id)}>
                <FontAwesomeIcon icon={['fal', 'times']} />
              </Button>
            </div>
          </div>
        </div>
        {type === 'site' && (
          <div className="template__row">
            <div className="template__label">Параметры:</div>
            <div className="template__row-content">
              {variation?.state === 'pending' ? 'генерируется...' : (
                <>
                  <div className="field-form">
                    <Form.Control
                      as="input"
                      type="number"
                      placeholder="Стартовая позиция"
                      disabled={isTemplateChanging.updateVariation}
                      value={startPosition}
                      min={0}
                      onChange={({ target }) => setStartPosition(target.value)}
                    />
                  </div>

                  <Dropdown className="template__type-select">
                    <Dropdown.Toggle
                      as="button"
                      className="btn btn-light"
                      disabled={isTemplateChanging.updateVariation}
                    >
                      {variationScrollHint[scrollHint]}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {scrollHint !== 'default' && (
                        <Dropdown.Item
                          as="button"
                          eventKey="default"
                          onSelect={(value) => setScrollHint(value)}
                        >
                          {variationScrollHint.default}
                        </Dropdown.Item>
                      )}
                      {scrollHint !== 'none' && (
                        <Dropdown.Item
                          as="button"
                          eventKey="none"
                          onSelect={(value) => setScrollHint(value)}
                        >
                          {variationScrollHint.none}
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

TemplateVariation.propTypes = {
  variation: PropTypes.object,
  isTemplateChanging: PropTypes.object,
  onRemoveVariation: PropTypes.func,
  onUpdateVariation: PropTypes.func,
};

const TemplatesTemplateVariations = ({
  isVisible, isTemplateChanging, variations, toggleIsVisible, onRemoveVariation, onUpdateVariation,
}) => {
  const handleChangeTab = (key) => {
    const currentKey = key === isVisible ? null : key;

    toggleIsVisible(currentKey);
  };

  return (
    <div className="template__row template__variations">
      <div className="template__label">Вариации:</div>
      <Tabs activeKey={isVisible} transition={false} onSelect={handleChangeTab}>
        {variations.map((variation) => (
          <Tab
            eventKey={variation.id}
            key={variation.id}
            title={variation.state === 'done' ? videoFormats[variation.format] : (
              <>
                {videoFormats[variation.format]}
                <Preloader small />
              </>
            )}
          >
            <TemplateVariation
              variation={variation}
              isTemplateChanging={isTemplateChanging}
              onRemoveVariation={onRemoveVariation}
              onUpdateVariation={onUpdateVariation}
            />
          </Tab>
        ))}
      </Tabs>
    </div>
  );
};

TemplatesTemplateVariations.propTypes = {
  isVisible: PropTypes.string,
  isTemplateChanging: PropTypes.object,
  variations: PropTypes.array,
  toggleIsVisible: PropTypes.func,
  onRemoveVariation: PropTypes.func,
  onUpdateVariation: PropTypes.func,
};

export default memo(TemplatesTemplateVariations);
