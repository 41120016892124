import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { memo, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import usePrevious from '../../../../hooks';

import sweetAlert from '../../../../HOCs/sweetAlert';

import Preloader from '../../../../UI/Preloader/Preloader';

import CategoriesCategoryForm from './CategoriesCategoryForm';

import './CategoriesCategory.scss';

const CategoriesCategory = ({
  category, isRemoving, isUpdating, isUnsaved, lang, onRemoveCategory, onUpdateCategory,
}) => {
  const [isFormVisible, setIsFormVisible] = useState(false);

  const [title, setTitle] = useState(category.title);

  const prevCategory = usePrevious(category);

  const handleSetIsFormVisible = () => {
    if (isFormVisible && isUnsaved) {
      sweetAlert.fire({
        cancelButtonText: 'Перейти к редактированию',
        confirmButtonText: 'Свернуть без сохранения',
        icon: <FontAwesomeIcon icon={['fal', 'exclamation-triangle']} />,
        type: 'warning',
        showCancelButton: true,
        title: 'Имеются не сохраненные изменения!',
      }).then((result) => {
        if (!result.value) return;

        setIsFormVisible(false);
      });
    } else {
      setIsFormVisible(!isFormVisible);
    }
  };

  const handleRemoveCategory = () => {
    onRemoveCategory(category._id);
  };

  useEffect(() => {
    if (prevCategory && prevCategory.isUnsaved && !category.isUnsaved) {
      setTitle(category.title);
    }
  }, [category, prevCategory]);

  const linkTo = lang ? `/templates/categories/${category.id}?lang=${lang}` : `/templates/categories/${category.id}`;

  return (
    <div
      className={classNames('template-bunch-item', {
        unsaved: category.isUnsaved,
        opened: isFormVisible,
      })}
    >
      <div className="template-bunch-item__header">
        <button className="template-bunch-item__button template-bunch-item__button_title" type="button" onClick={handleSetIsFormVisible}>
          {title}
          {isFormVisible
            ? <FontAwesomeIcon icon={['far', 'chevron-down']} />
            : <FontAwesomeIcon icon={['far', 'chevron-right']} />}
        </button>
        {isUpdating && <Preloader medium />}
        <span className="template-bunch-item__count">
          <Link to={linkTo}>{`Шаблоны: ${category.count_templates || 0}`}</Link>
        </span>
        <button
          className={`template-bunch-item__button template-bunch-item__button_edit${isFormVisible ? ' is-active' : ''}`}
          type="button"
          onClick={handleSetIsFormVisible}
        >
          <FontAwesomeIcon icon={['fas', 'pencil']} />
        </button>
        <button onClick={handleRemoveCategory} disabled={isRemoving} className="template-bunch-item__button template-bunch-item__button_delete" type="button">
          <FontAwesomeIcon icon={['fal', 'times']} />
        </button>
      </div>
      {isFormVisible && (
        <div className="template-bunch-item__main">
          <CategoriesCategoryForm
            data={category}
            isUnsaved={isUnsaved}
            isUpdating={isUpdating}
            onUpdateCategory={onUpdateCategory}
          />
        </div>
      )}
    </div>
  );
};

CategoriesCategory.propTypes = {
  category: PropTypes.object,
  isRemoving: PropTypes.bool,
  isUpdating: PropTypes.bool,
  isUnsaved: PropTypes.bool,
  lang: PropTypes.string,
  onRemoveCategory: PropTypes.func,
  onUpdateCategory: PropTypes.func,
};

export default memo(CategoriesCategory);
