import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { logoutThunk } from '../../../../actions';

import Nav from '../../../Includes/Nav/Nav';

const changelogTriggerID = 'changelog-trigger';

class HeaderSecondaryNav extends React.PureComponent {
  constructor(props) {
    super(props);

    const {
      auth, exit,
    } = this.props;
    const { user } = auth;

    this.list = [
      {
        key: 'notifications',
        itemClassName: null,
        toggleID: changelogTriggerID,
        title: 'Оповещения',
        icon: <FontAwesomeIcon icon={['fas', 'bell']} />,
        condition: !user.settings.ui.disallowed_ui_elements.includes('notifications'),
      },
      {
        key: 'settings',
        itemClassName: null,
        link: '/settings',
        title: 'Настройки',
        icon: <FontAwesomeIcon icon={['fas', 'cog']} />,
        condition: !user.settings.ui.disallowed_routes.includes('/settings'),
      },
      {
        key: 'logout',
        itemClassName: null,
        title: 'Выход',
        onClick: exit,
        icon: <FontAwesomeIcon icon={['fas', 'sign-out-alt']} />,
        condition: !user.settings.ui.disallowed_ui_elements.includes('logout'),
      },
    ];
  }

  componentDidMount() {
    this.initHeadway();
  }

  initHeadway = () => {
    const HeadwayConfig = {
      selector: `#${changelogTriggerID}`,
      account: 'yBpbA7',
      trigger: `#${changelogTriggerID}`,
      enabled: true,
      position: {
        x: 'left',
      },
      translations: {
        title: 'Уведомления',
        readMore: 'Читать далее',
        labels: {
          new: 'Новость',
          improvement: 'Обновление',
          fix: 'FIX',
        },
      },
    };

    if (window.Headway) window.Headway.init(HeadwayConfig);
  };

  render() {
    return <Nav list={this.list} />;
  }
}

HeaderSecondaryNav.propTypes = {
  auth: PropTypes.object.isRequired,
  exit: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  common: state.common,
});

const mapDispatchToProps = (dispatch) => ({
  exit: () => dispatch(logoutThunk()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderSecondaryNav);
