import PropTypes from 'prop-types';
import React, {
  // useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { requestCanceler } from '../../../../middlewares/api';

import successMessages from '../../../../constants/successMessages';

import { toast } from '../../../../utils';

import sweetAlert from '../../../HOCs/sweetAlert';

import FieldForm from '../../../Includes/FieldForm/FieldForm';

// import TagMain from './TagMain/TagMain';

import './Tag.scss';

function Tag({
  isFetching,
  tag,
  onUpdate,
  onDelete,
}) {
  // const [isMainVisible, setIsMainVisible] = useState(false);

  const requestCancelers = useRef([]);

  useEffect(() => () => requestCancelers.current.forEach((canceler) => canceler.cancelRequest()), []);

  // const toggleMainVisible = () => setIsMainVisible(!isMainVisible);

  const handleUpdate = useCallback((newData) => {
    const requestUpdateTagCanceler = requestCanceler();

    requestCancelers.current.push(requestUpdateTagCanceler);

    return onUpdate(tag._id, newData, requestUpdateTagCanceler.token);
  }, [tag._id, onUpdate]);

  const showAlert = useCallback((html) => (
    sweetAlert.fire({
      cancelButtonText: 'Отмена',
      confirmButtonText: 'Да, я уверен',
      icon: <FontAwesomeIcon icon={['fal', 'exclamation-triangle']} />,
      type: 'warning',
      showCancelButton: true,
      title: 'Вы уверены?',
      html,
    })
  ), []);

  const handleDeleteTag = (html = 'Подтвердить удаление тега?', force) => {
    showAlert(html).then((result) => {
      if (!result.value) return;

      const requestDeleteTagCanceler = requestCanceler();

      requestCancelers.current.push(requestDeleteTagCanceler);

      const params = force ? { force: true } : {};

      onDelete(tag._id, params, requestDeleteTagCanceler.token)
        .then(() => toast('success', successMessages.tag_deleted))
        .catch(({ message }) => {
          if (message === 'tag_is_used') {
            handleDeleteTag('Тег используется! Вы действительно хотите выполнить удаление?', true);
          }
        });
    });
  };

  const handleUpdateName = useCallback((value) => {
    const name = value.trim().replaceAll('  ', ' ');

    if (!name) return;

    showAlert('Подтвердить изменение названия тега?').then((result) => {
      if (!result.value) return;

      handleUpdate({ name })
        .then(() => toast('success', successMessages.tag_name));
    });
  }, [showAlert, handleUpdate]);

  const handleUpdateAlias = useCallback((value) => {
    const alias = value.trim();

    if (!alias) return;
    if (alias[0] === '-' || alias[alias.length - 1] === '-') return;

    showAlert('Подтвердить изменение алиаса?').then((result) => {
      if (!result.value) return;

      handleUpdate({ alias })
        .then(() => toast('success', successMessages.tag_alias));
    });
  }, [showAlert, handleUpdate]);

  const handleValidateValue = useCallback((val) => {
    const regExp = /^[a-z]+(-[a-z]+)*-?$/g;
    const isValid = regExp.test(val);

    return isValid ? val : val.replace(/[^a-z-]/g, '').replace(/--+/g, '-');
  }, []);

  return (
    <div className="tag">
      <div className="tag__header">
        <div className="tag__forms">
          <div className="tag__form">
            <FieldForm
              buttonText={<FontAwesomeIcon icon={['fas', 'check']} />}
              disabled={isFetching}
              defaultValue={tag.name}
              fieldType="input"
              readOnly={isFetching}
              onSubmit={handleUpdateName}
            />
          </div>

          <div className="tag__form">
            <FieldForm
              buttonText={<FontAwesomeIcon icon={['fas', 'check']} />}
              disabled={isFetching}
              defaultValue={tag.alias}
              fieldType="input"
              readOnly={isFetching}
              validator={handleValidateValue}
              onSubmit={handleUpdateAlias}
            />
          </div>
        </div>

        <div className="tag__options">
          {/* <button
            className={`tag__action${isMainVisible ? ' is-active' : ''}`}
            type="button"
            onClick={toggleMainVisible}
          >
            <FontAwesomeIcon icon={['fas', 'pencil']} />
          </button> */}
          <button
            className="tag__action"
            disabled={isFetching}
            type="button"
            title="Удалить"
            onClick={() => handleDeleteTag()}
          >
            <FontAwesomeIcon icon={['fal', 'times']} />
          </button>
        </div>
      </div>
      {/* {isMainVisible && <TagMain />} */}
    </div>
  );
}

Tag.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  tag: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default Tag;
