import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { nanoid } from 'nanoid';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import View from '../../../Layout/View/View';

import { requestCanceler } from '../../../../middlewares/api';

import { languages } from '../../../../constants/common';
import errorMessages from '../../../../constants/errors';

import { toast } from '../../../../utils';

import sweetAlert from '../../../HOCs/sweetAlert';

import NoMedia from '../../../UI/NoMedia/NoMedia';

import Pagination from '../../../Includes/Pagination/Pagination';

import ObjectsObject from './ObjectsObject/ObjectsObject';

import './Objects.scss';

class ObjectCategories extends React.PureComponent {
  requestCancelers = [];

  languages = Object.keys(languages);

  state = {
    page: 1,
    limit: 10,
    orderIDs: [],
  };

  componentDidMount() {
    this.getCategories();
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;

    if (location.search !== prevProps.location.search) {
      this.getCategories();
    }
  }

  componentWillUnmount() {
    const { onUnload } = this.props;

    this.requestCancelers.forEach((canceler) => canceler.cancelRequest());

    onUnload();
  }

  getCategories = () => {
    const {
      history, location, match: { params: { id } }, onGetObjectCategory, onGetObjectCategoryObjects,
    } = this.props;

    const requestParams = {};

    if (location.search) {
      const defaultParams = queryString.parse(location.search);

      if (!defaultParams.lang || !Object.keys(languages).includes(defaultParams.lang)) {
        this.handleSearchCategories('ru');

        return;
      }

      Object.assign(requestParams, defaultParams);
    }

    const requestGetCategory = requestCanceler();

    this.requestCancelers.push(requestGetCategory);

    onGetObjectCategory(id, requestGetCategory.token)
      .then((cat) => {
        const requestGetCategoryObjects = requestCanceler();

        this.requestCancelers.push(requestGetCategoryObjects);

        onGetObjectCategoryObjects(cat._id, requestGetCategoryObjects.token)
          .catch(() => history.push('/objects/categories'));
      })
      .catch(() => history.push('/objects/categories'));
  };

  handleSearchCategories = (lang) => {
    const { history, location, objectCategoryObjects: { category } } = this.props;

    const queryStr = !lang || lang === 'ru' ? `/objects/category/${category.alias}` : `/objects/category/${category.alias}?lang=${lang}`;

    if (queryString.parse(location.search)?.lang === lang) {
      this.getCategories();
    } else {
      history.push(queryStr);
    }
  };

  handleCreateEmptyObject = () => {
    const { objectCategoryObjects: { category, docs }, onCreateObjectCategoryEmptyObject } = this.props;

    onCreateObjectCategoryEmptyObject({
      _id: nanoid(10),
      lang: category.lang,
      tags: [],
      tag_ids: [],
      order: docs.length ? docs[docs.length - 1].order + 1 : 0,
      category_id: category._id,
      name: '',
      template_variation_id: null,
      is_empty: true,
    });
  };

  handleCreate = (data) => {
    const { onCreateObjectCategoryObject } = this.props;

    const request = requestCanceler();

    this.requestCancelers.push(request);

    onCreateObjectCategoryObject(data, request.token)
      .then(() => toast('success', 'Объект создан!'))
      .catch((err) => err?.message && toast('error', errorMessages[err.message]));
  };

  handleUpdate = (data) => {
    const { onUpdateObjectCategoryObject } = this.props;

    const request = requestCanceler();

    this.requestCancelers.push(request);

    onUpdateObjectCategoryObject(data, request.token)
      .then(() => toast('success', 'Объект сохранён!'))
      .catch((err) => err?.message && toast('error', errorMessages[err.message]));
  };

  handleDelete = (data) => {
    const { onDeleteObjectCategoryObject } = this.props;

    sweetAlert.fire({
      cancelButtonText: 'Нет',
      confirmButtonText: 'Да, удалить',
      icon: <FontAwesomeIcon icon={['fal', 'exclamation-triangle']} />,
      type: 'warning',
      showCancelButton: true,
      title: 'Вы уверены?',
      html: 'Вы действительно хотите удалить объект?',
    }).then((result) => {
      if (!result.value) return;

      if (data.is_empty) {
        onDeleteObjectCategoryObject(data._id);

        toast('success', 'Объект удалён!');

        return;
      }

      const request = requestCanceler();

      this.requestCancelers.push(request);

      onDeleteObjectCategoryObject(data._id, request.token)
        .then(() => toast('success', 'Объект удалён!'))
        .catch((err) => err?.message && toast('error', errorMessages[err.message]));
    });
  };

  handleChangePage = (page) => this.setState({ page });

  handleChangeLimit = (limit) => this.setState({ limit });

  handleSetOrderID = (id) => { // eslint-disable-line
    const { objectCategoryObjects: { docs } } = this.props;
    const { orderIDs } = this.state;

    if (orderIDs.includes(id)) {
      return this.setState((prev) => ({
        ...prev,
        orderIDs: prev.orderIDs.filter((item) => item !== id),
      }));
    }

    this.setState((prev) => ({
      ...prev,
      orderIDs: [
        ...prev.orderIDs,
        id,
      ].sort((a, b) => docs.findIndex((item) => item._id === a) - docs.findIndex((item) => item._id === b)),
    }));
  };

  handleApplyOrder = (targetID) => {
    const { onOrderObjectCategoryObjects } = this.props;
    const { orderIDs } = this.state;

    if (!orderIDs.length) return;

    onOrderObjectCategoryObjects(targetID, orderIDs);

    this.setState({ orderIDs: [] });
  };

  handleSaveOrder = () => {
    const { objectCategoryObjects: { docs }, onSaveObjectCategoryObjectsOrder } = this.props;

    sweetAlert.fire({
      cancelButtonText: 'Отмена',
      confirmButtonText: 'Да',
      icon: <FontAwesomeIcon icon={['fal', 'exclamation-triangle']} />,
      type: 'warning',
      showCancelButton: true,
      title: 'Вы уверены?',
      html: 'Вы действительно хотите сохранить изменение порядка?',
    }).then((result) => {
      if (!result.value) return;

      const prepared = docs
        .filter((item) => !item.is_empty)
        .map((item, order) => ({ _id: item._id, order }));

      const request = requestCanceler();

      this.requestCancelers.push(request);

      onSaveObjectCategoryObjectsOrder(prepared, request.token)
        .then(() => toast('success', 'Список успешно отсортирован'))
        .catch((err) => err?.message && toast('error', errorMessages[err.message]));
    });
  };

  renderBackBtn = () => {
    const { objectCategoryObjects: { category } } = this.props;

    const linkTo = category ? `/objects/categories?lang=${category.lang}` : '/objects/categories';

    return (
      <Link className="object-category-objects__back" to={linkTo}>
        <FontAwesomeIcon icon={['far', 'chevron-left']} />
      </Link>
    );
  };

  renderActionButtons = (className) => {
    const {
      objectCategoryObjects: {
        error, isFetching, isObjectChanging, isOrdered,
      },
    } = this.props;

    const isDisabled = isFetching || isObjectChanging.update || isObjectChanging.remove || isObjectChanging.create || isObjectChanging.order;
    const isError = error && ['admin_privileges_required', 'first_request_failed'].includes(error.message);

    return (
      <div className={`object-category-objects__actions${className ? ` ${className}` : ''}`}>
        {!isFetching && !isError && (
          <>
            <Button
              disabled={isDisabled}
              variant="success"
              onClick={this.handleCreateEmptyObject}
            >
              Добавить объект
            </Button>
            {isOrdered && (
              <Button
                disabled={isDisabled}
                onClick={this.handleSaveOrder}
              >
                Сохранить изменение порядка
              </Button>
            )}
          </>
        )}
      </div>
    );
  };

  renderList = () => {
    const { objectCategoryObjects: { docs, isFetching, isObjectChanging } } = this.props;
    const { page, limit, orderIDs } = this.state;

    const showFromIndex = limit * (page - 1);

    const isDisabled = !!(isObjectChanging.update || isObjectChanging.remove || isObjectChanging.create || isObjectChanging.order);

    return (
      <div className="object-category-objects__list">
        {!isFetching && docs && docs.length > 0 && docs.map((item, i) => (i >= showFromIndex && i < showFromIndex + limit) && (
          <div key={item._id} className="object-category-objects-list__item">
            <ObjectsObject
              data={item}
              isDisabled={isDisabled}
              isOrdering={orderIDs.includes(item._id)}
              orderIDs={orderIDs}
              onCreate={this.handleCreate}
              onUpdate={this.handleUpdate}
              onDelete={this.handleDelete}
              onSetOrder={this.handleSetOrderID}
              onApplyOrder={this.handleApplyOrder}
            />
          </div>
        ))}
        {!isFetching && docs.length === 0 && <NoMedia icon={<FontAwesomeIcon icon={['fas', 'info-circle']} />} caption="Список пуст" />}
      </div>
    );
  };

  renderPagination = () => {
    const {
      objectCategoryObjects: {
        docs, error, isFetching, isObjectChanging,
      },
    } = this.props;
    const {
      page, limit,
    } = this.state;

    const isDisabled = isFetching || isObjectChanging.update || isObjectChanging.remove || isObjectChanging.create || isObjectChanging.order;
    const isError = error && ['admin_privileges_required', 'first_request_failed'].includes(error.message);

    return !isError && docs && docs.length > 0 ? (
      <Pagination
        className="object-category-objects__pagination"
        currentPage={page}
        disabled={isDisabled}
        pages={Math.ceil(docs.length / limit)}
        total={docs.length}
        limit={limit}
        limitDropdown
        onChange={this.handleChangePage}
        onChangeLimit={this.handleChangeLimit}
      />
    ) : null;
  };

  render() {
    const { objectCategoryObjects } = this.props;
    const { category, error, isFetching } = objectCategoryObjects;

    return (
      <View
        errorMessage={errorMessages[error?.message]}
        isError={error && ['admin_privileges_required', 'first_request_failed'].includes(error.message)}
        isFetching={isFetching}
        viewClass="object-category-objects"
        title={category?.name || ''}
        header={!isFetching ? (
          <>
            {this.renderBackBtn()}
            {this.renderActionButtons()}
          </>
        ) : null}
        content={this.renderList()}
        footer={(
          <>
            {this.renderActionButtons('object-category-objects__footer')}
            {this.renderPagination()}
          </>
        )}
      />
    );
  }
}

ObjectCategories.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  objectCategoryObjects: PropTypes.object.isRequired,
  onCreateObjectCategoryObject: PropTypes.func.isRequired,
  onCreateObjectCategoryEmptyObject: PropTypes.func.isRequired,
  onDeleteObjectCategoryObject: PropTypes.func.isRequired,
  onGetObjectCategory: PropTypes.func.isRequired,
  onGetObjectCategoryObjects: PropTypes.func.isRequired,
  onUpdateObjectCategoryObject: PropTypes.func.isRequired,
  onOrderObjectCategoryObjects: PropTypes.func.isRequired,
  onSaveObjectCategoryObjectsOrder: PropTypes.func.isRequired,
  onUnload: PropTypes.func.isRequired,
};

export default ObjectCategories;
