import React, {
  memo, useEffect, useRef, useState,
} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown } from "react-bootstrap";
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useHistory, Link } from 'react-router-dom';

import { deletePage, syncPage, updatePage } from "../../../../../actions";

import withRequest from "../../../../HOCs/withRequest";
import sweetAlert from '../../../../HOCs/sweetAlert';

import TildaURL from '../../icons/tilda.svg';

import { toast } from "../../../../../utils";

import './Page.scss';

const Page = memo((props) => {
  const {
    isFetching, data, category, dispatch, createCanceler,
  } = props;
  const history = useHistory();

  const [isPageReloading, setIsPageReloading] = useState(false);
  const [isPageUpdating, setIsPageUpdating] = useState(false);

  const isUmounted = useRef();

  const handleClickEditPage = () => history.push(`/landing/pages/${data._id}/edit`);

  const handleClickShowPage = () => {
    const domain = data.lang === 'ru' ? window.SUPA_LANDING_URL : window.SUPA_LANDING_EN_URL;

    const link = category ? `${domain}/${category.alias}/${data.alias}` : `${domain}/${data.alias}`;

    window.open(link);
  };

  const handleSelectRevision = (revision) => {
    if (revision._id === data.current_revision_id) return;

    dispatch(updatePage(data._id, { current_revision_id: revision._id }))
      .then(() => toast('success', 'Ревизия успешно изменена!'))
      .catch(() => toast('error', 'Ошибка обновления ревизии. Попробуйте позже'));
  };

  const handleClickRefresh = () => {
    const request = createCanceler();

    setIsPageReloading(true);

    dispatch(syncPage(data._id, request.token))
      .catch(() => toast('error', 'Ошибка получения данных страницы. Попробуйте позже'))
      .finally(() => {
        if (!isUmounted.current) setIsPageReloading(false);
      });
  };

  const handleClickDeletePage = (pge) => {
    sweetAlert.fire({
      cancelButtonText: 'Отмена',
      confirmButtonText: 'Да, удалить',
      icon: <FontAwesomeIcon icon={['fal', 'exclamation-triangle']} />,
      type: 'warning',
      showCancelButton: true,
      title: 'Вы уверены?',
      html: 'Вы действительно хотите удалить данную страницу? Это действие нельзя будет отменить',
    }).then((result) => {
      if (!result.value) return;

      dispatch(deletePage(data._id))
        .then(() => toast('success', 'Страница успешно удалена!'))
        .catch(() => toast('error', 'Произошла ошибка. Попробуйте позже'));
    });
  };

  const getCurrentPagePath = () => {
    if (data.is_main) return '/';

    return category ? `/${category.alias}/${data.alias}` : `/${data.alias}`;
  };

  const getCurrentRevisionTitle = () => {
    const curRev = data.revisions?.find((r) => r._id === data.current_revision_id);

    if (!curRev) return 'Выберите ревизию...';

    return `${dayjs(curRev.date).format('DD.MM.YYYY HH:mm:ss')} - текущая`;
  };

  const getCurrentRevisionIcon = () => {
    const curRev = data.revisions?.find((r) => r._id === data.current_revision_id);

    if (!curRev) return null

    switch (curRev.data_provider) {
      case 'tilda':
        return <img src={TildaURL} alt="tilda-logo" />;
      default:
        return null
    }
  };

  const getCurrentRevisionDateClass = () => {
    const index = data.revisions?.findIndex((r) => r._id === data.current_revision_id);

    if (index <= 0) return '';

    return ' landing-page__revision_old';
  };

  const handleClickToggleStatus = () => {
    setIsPageUpdating(true);

    dispatch(updatePage(data._id, { status: data.status === 'public' ? 'stage_only' : 'public' }))
      .then(() => toast('success', 'Статус страницы успешно изменен!'))
      .catch(() => toast('error', 'Ошибка обновления статуса. Попробуйте позже'))
      .finally(() => {
        if (!isUmounted.current) setIsPageUpdating(false);
      });
  };

  useEffect(() => () => {
    isUmounted.current = true;
  }, []);

  return (
    <div className="landing-page" key={data._id}>
      <div className={`landing-page__title${data.is_main ? ' landing-page__title_blue' : ''}`}>
        <FontAwesomeIcon icon={["fal", "file-alt"]} />
        <div className="landing-page__info">
          <div className="landing-page__name-wrapper">
            <button
              className="landing-page__name"
              type="button"
              onClick={handleClickEditPage}
            >
              {data.name}
            </button>
            <button
              type="button"
              className="landing__category-action"
              onClick={handleClickShowPage}
            >
              <FontAwesomeIcon className="landing-page__eye-icon" icon={["fas", "eye"]} />
            </button>
          </div>
          <span className="landing-page__path">{getCurrentPagePath()}</span>
        </div>
      </div>
      <div className="landing-page__actions">
        <span className={`landing-page__revision${getCurrentRevisionDateClass()}`}>
          Ревизия:
        </span>
        <Dropdown className="landing__dropdown">
          <Dropdown.Toggle
            as="button"
            type="button"
            className="btn btn-light"
            disabled={isFetching || isPageUpdating}
          >
            {getCurrentRevisionIcon()}
            {getCurrentRevisionTitle()}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {data.revisions?.map((revision) => (
              <Dropdown.Item
                key={revision._id}
                active={data.current_revision_id === revision._id}
                as="button"
                type="button"
                onClick={() => handleSelectRevision(revision)}
              >
                {`${dayjs(revision.date).format("DD.MM.YYYY HH:mm:ss")}${
                  data.current_revision_id === revision._id ? " - текущая" : ""
                }`}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <button
          data-action="sync"
          disabled={isFetching || isPageUpdating}
          type="button"
          className="landing__category-action"
          onClick={handleClickRefresh}
        >
          <FontAwesomeIcon icon={["fas", "sync"]} spin={isPageReloading}/>
        </button>
        <button
          data-action="status"
          disabled={isFetching || isPageUpdating}
          type="button"
          className={`landing__category-action${data.status === 'stage_only' ? ' landing__category-action_highlighted' : ''}`}
          onClick={handleClickToggleStatus}
        >
          <FontAwesomeIcon icon={['fas', 'flask']} />
        </button>
        <Link
          to={`/landing/pages/${data._id}/edit`}
          data-action="edit"
          className="landing__category-action"
          disabled={isFetching || isPageUpdating}
        >
          <FontAwesomeIcon icon={["fas", "pen"]} />
        </Link>
        <button
          data-action="delete"
          disabled={isFetching || isPageUpdating}
          type="button"
          className="landing__category-action"
          onClick={handleClickDeletePage}
        >
          <FontAwesomeIcon icon={["far", "times"]} />
        </button>
      </div>
    </div>
  );
});

Page.propTypes = {
  category: PropTypes.object,
  data: PropTypes.object.isRequired,
  createCanceler: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
};

export default withRequest(Page);
