import classNames from 'classnames';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './PromocodesPromocode.scss';

const PromocodesPromocode = ({ promocode }) => {
  const dateToday = new Date();

  const checkIsActive = (date) => {
    const isoDateToday = new Date(dateToday.getTime() - (dateToday.getTimezoneOffset() * 60000)).toISOString();

    return date < isoDateToday;
  };

  return (
    <div className={classNames('promocode', { active: checkIsActive(promocode.promocode_to) })}>
      <div className="promocode__info">
        <div className="promocode__status"> </div>
        <Link className="promocode__name" to={`/promocodes/edit/${promocode.id}`}>
          {`${promocode.promocode} (${dayjs(promocode.promocode_from).format('DD.MM.YYYY')} - ${dayjs(promocode.promocode_to).format('DD.MM.YYYY')}) - `}
          <span>{`${promocode.sum} ${promocode.currency}`}</span>
        </Link>
        <div className="promocode__service">{promocode.name}</div>
      </div>
      <Link className="btn btn-success promocode__button" to={`/promocodes/edit/${promocode.id}`}>
        <FontAwesomeIcon icon={['fas', 'edit']} />
      </Link>
    </div>
  );
};

PromocodesPromocode.propTypes = {
  promocode: PropTypes.object,
};

export default memo(PromocodesPromocode);
