import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import View from '../../Layout/View/View';

import SidebarNav from '../../Includes/SidebarNav/SidebarNav';
import ProfileContainer from './Profile/ProfileContainer';

class Settings extends React.Component {
  renderSidebar = () => {
    const list = [
      {
        key: 'profile',
        link: '/settings/profile',
        title: 'Профиль',
        icon: <FontAwesomeIcon icon={['fas', 'heart']} />,
      },
    ];

    return (
      <SidebarNav data={list} />
    );
  };

  render() {
    return (
      <View
        title="Настройки"
        sidebar={this.renderSidebar()}
        content={(
          <Switch>
            <Route exact path="/settings/profile" component={ProfileContainer} />

            <Redirect to="/settings/profile" />
          </Switch>
        )}
      />
    );
  }
}

export default Settings;
