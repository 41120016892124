import React, {
  memo, useCallback, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import dayjs from 'dayjs';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import { diff } from 'deep-object-diff';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import errorMessages from '../../../../constants/errors';

import { downloadFile, toast } from '../../../../utils';

import {
  getCompanies, getCompanyBlank, deleteCompany, RESET_COMPANIES,
} from '../../../../actions';

import usePrevious from '../../../hooks';

import withRequest from '../../../HOCs/withRequest';
import sweetAlert from '../../../HOCs/sweetAlert';

import View from '../../../Layout/View/View';

import SearchForm from '../../../Includes/SearchForm/SearchForm';

import NoMedia from '../../../UI/NoMedia/NoMedia';
import LimitListHint from '../../../UI/LimitListHint/LimitListHint';

import CompaniesActPopup from '../CompaniesActPopup/CompaniesActPopup';
import CompaniesCompany from '../CompaniesCompany/CompaniesCompany';

const LIMIT_PER_PAGE = 20;
const SEARCH_TYPES = {
  name: 'названию',
  inn: 'ИНН',
  login: 'логину',
  id: 'ID',
};

const getActDate = () => ({
  from: new Date(Date.UTC(new Date().getUTCFullYear() - 1, 0, 1, 0, 0, 0)),
  to: new Date(Date.UTC(new Date().getUTCFullYear() - 1, 11, 31, 0, 0, 0)),
});

const Companies = ({
  history, location, createCanceler, dispatch,
}) => {
  const companies = useSelector(({ companies: companiesStore }) => companiesStore);
  const authUser = useSelector(({ auth }) => auth.user);

  const prevLocation = usePrevious(location);

  const [isUpdateSearchType, setIsUpdateSearchType] = useState(false);
  const actDate = useRef(getActDate());

  const searchType = useRef(queryString.parse(location.search)?.search_type || 'name');
  const searchQuery = useRef(queryString.parse(location.search)?.query || '');

  const changeURL = useCallback(() => {
    const params = queryString.parse(location.search);
    const diffParams = diff({ search_type: params.search_type || 'name', query: params.query || '' }, { search_type: searchType.current, query: searchQuery.current });

    if (Object.keys(diffParams).length === 0) return;

    const queryStr = searchQuery.current
      ? `/companies?query=${searchQuery.current}&search_type=${searchType.current}`
      : '/companies';

    history.push(queryStr);
  }, [location.search, history]);

  const onGetCompanies = useCallback((page = 1, isSearch = false) => {
    const requestGetCompaniesCanceler = createCanceler();

    dispatch(getCompanies({
      page,
      limit: LIMIT_PER_PAGE,
      ...(searchQuery.current && {
        query: searchQuery.current,
        search_type: searchType.current,
      }),
    }, isSearch, requestGetCompaniesCanceler.token));
  }, [createCanceler, dispatch]);

  const handleChangeSearchType = useCallback((type) => {
    searchType.current = type;

    setIsUpdateSearchType(!isUpdateSearchType);
  }, [isUpdateSearchType]);

  const handleSearch = (query) => {
    searchQuery.current = query || '';

    changeURL();

    return null;
  };

  const loadMore = () => {
    if (companies.isFetching) return;

    onGetCompanies(companies.page + 1);
  };

  const handleDelete = useCallback((id) => {
    sweetAlert.fire({
      cancelButtonText: 'Отмена',
      confirmButtonText: 'Удалить',
      icon: <FontAwesomeIcon icon={['fal', 'times-circle']} />,
      showCancelButton: true,
      type: 'error',
      html: 'Чтобы удалить контрагента напишите текст "<b>DELETE ME</b>" и нажмите "Удалить":',
      input: 'text',
      inputValue: '',
      inputValidator: (text) => {
        if (text.trim() !== 'DELETE ME') return 'Некорректный текст';

        return false;
      },
      inputAttributes: {
        maxlength: 9,
      },
    }).then((result) => {
      if (!result.value) return;

      const requestDeleteCompanyCanceler = createCanceler();

      dispatch(deleteCompany(id, requestDeleteCompanyCanceler.token))
        .then(() => {
          toast('success', 'Контрагент удалён!');
        })
        .catch((error) => {
          const message = errorMessages[error.message] || error.message || 'Ошибка';

          toast('error', message);
        });
    });
  }, [createCanceler, dispatch]);

  const handleCreateAct = useCallback((id, companyName, format, cb) => {
    sweetAlert.fire({
      customClasses: {
        htmlContainer: 'companies-popup',
      },
      cancelButtonText: 'Отмена',
      confirmButtonText: 'Создать акт сверки',
      icon: <FontAwesomeIcon icon={['fal', 'question-circle']} />,
      showCancelButton: true,
      type: 'info',
      title: 'Укажите период акта сверки',
      html: (
        <CompaniesActPopup
          defaultDate={actDate.current}
          onChange={(val) => {
            actDate.current = val;
          }}
        />
      ),
    }).then((result) => { // eslint-disable-line
      if (!result.value) {
        actDate.current = getActDate();

        cb();

        return;
      }

      const dateFrom = new Date(actDate.current.from);
      const dateTo = new Date(actDate.current.to);

      dispatch(getCompanyBlank({
        id,
        format,
        from: new Date(Date.UTC(dateFrom.getUTCFullYear(), dateFrom.getUTCMonth(), dateFrom.getUTCDate(), 0, 0, 0)).toISOString(),
        to: new Date(Date.UTC(dateTo.getUTCFullYear(), dateTo.getUTCMonth(), dateTo.getUTCDate(), 0, 0, 0)).toISOString(),
      }))
        .then((blob) => {
          downloadFile(blob, `Акт сверки для ${companyName} от ${dayjs(dateTo).format('DD.MM.YYYY')}.${format}`);

          toast('success', `Акт сверки ${format.toUpperCase()} успешно создан!`);

          actDate.current = getActDate();

          cb();
        })
        .catch((error) => {
          const message = errorMessages[error.message] || error?.message || 'Ошибка';

          toast('error', message);

          actDate.current = getActDate();

          cb();
        });
    });
  }, [dispatch]);

  useEffect(() => {
    if (location.search !== prevLocation?.search) {
      const { query, search_type } = queryString.parse(location.search);

      searchType.current = search_type || 'name';
      searchQuery.current = query || '';

      onGetCompanies(1, !!searchQuery.current);
    }
  }, [location, prevLocation, onGetCompanies]);

  useEffect(() => () => {
    dispatch({ type: RESET_COMPANIES });
  }, [dispatch]);

  const renderHeader = () => (
    <div className="companies__header">
      <Link to="/companies/create" className="btn btn-success">Создать контрагента</Link>
      <div className="companies__filters">
        <div className="companies__dropdown">
          <span>Поиск по:</span>
          <Dropdown>
            <Dropdown.Toggle as="button" className="btn btn-light" disabled={companies.isFetching}>
              {SEARCH_TYPES[searchType.current]}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {Object.keys(SEARCH_TYPES).map((type) => (
                <Dropdown.Item
                  as="button"
                  disabled={type === searchType.current}
                  eventKey={type}
                  key={type}
                  onSelect={handleChangeSearchType}
                >
                  {SEARCH_TYPES[type]}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="companies__search">
          <SearchForm
            className="companies__search-form"
            defaultValue={searchQuery.current}
            disabled={companies.isFetching}
            onSearch={handleSearch}
            placeholder="введите запрос"
          />
        </div>
      </div>
    </div>
  );

  const renderList = () => (
    <InfiniteScroll
      dataLength={companies.docs.length}
      hasMore={companies.page < companies.pages}
      loader={null}
      next={loadMore}
      style={{ overflow: 'visible' }}
    >
      {companies.docs.length > 0 && (
        <div className="companies__list">
          <>
            {companies.docs.map((item) => (
              <CompaniesCompany key={item.id} item={item} onDelete={handleDelete} onCreateAct={handleCreateAct} />
            ))}
            {authUser.admin_permissions.includes('support') && companies.docs.length === companies.limit && (
              <LimitListHint num={companies.limit} />
            )}
          </>
        </div>
      )}
      {!companies.docs.length && !companies.isFetching && (
        <div className="companies__no-media">
          <NoMedia
            caption="Ничего не найдено"
            icon={<FontAwesomeIcon icon={['fas', 'exclamation-circle']} />}
          />
        </div>
      )}
    </InfiniteScroll>
  );

  return (
    <View
      errorMessage={errorMessages[companies.error?.message]}
      isError={companies.error && ['admin_privileges_required', 'first_request_failed'].includes(companies.error.message)}
      isFetching={companies.isFetching}
      header={renderHeader()}
      preloaderCaption="Загружаем..."
      title="Контрагенты"
      viewClass="companies"
      content={renderList()}
    />
  );
};

Companies.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  createCanceler: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default memo(withRequest(Companies));
