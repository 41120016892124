import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import queryString from 'query-string';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SweetAlert2 from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { languages } from '../../../../constants/common';
import errorMessages from '../../../../constants/errors';

import { requestCanceler } from '../../../../middlewares/api';

import { toast } from '../../../../utils';

import View from '../../../Layout/View/View';

import sweetAlert from '../../../HOCs/sweetAlert';

import LanguageDropdown from '../../../Includes/LanguageDropdown/LanguageDropdown';
import TemplatesActions from '../../../Includes/Templates/TemplatesActions/TemplatesActions';
import TemplatesSubnav from '../../../Includes/Templates/TemplatesSubnav/TemplatesSubnav';

import GroupsGroup from './GroupsGroup/GroupsGroup';

// import './Groups.scss';

const Swal = withReactContent(SweetAlert2);

class Groups extends React.PureComponent {
  requestCancelers = [];

  languages = Object.keys(languages);

  componentDidMount() {
    this.getGroups();
    this.getCategories();
  }

  componentDidUpdate(prevProps) {
    const { location: prevLocation } = prevProps;
    const { location } = this.props;

    if (location.search !== prevLocation.search) {
      this.getGroups();
      this.getCategories();
    }
  }

  componentWillUnmount() {
    const { onUnload } = this.props;

    this.requestCancelers.forEach((canceler) => canceler.cancelRequest());

    onUnload();
  }

  get lang() {
    const { location } = this.props;

    if (!location.search) return 'ru';

    const params = queryString.parse(location.search);

    if (params.lang && Object.keys(languages).includes(params.lang)) {
      return params.lang;
    }

    return 'ru';
  }

  getGroups = () => {
    const { onGetGroups } = this.props;

    const requestGetGroupsCanceler = requestCanceler();

    this.requestCancelers.push(requestGetGroupsCanceler);

    onGetGroups({ lang: this.lang }, requestGetGroupsCanceler.token).catch(() => {});
  };

  getCategories = () => {
    const { onGetCategories } = this.props;

    const requestGetCategoriesCanceler = requestCanceler();

    this.requestCancelers.push(requestGetCategoriesCanceler);

    onGetCategories({ all: 1, service: 1, lang: this.lang }, false, requestGetCategoriesCanceler.token).catch(() => {});
  };

  handleChangeLang = (lang) => {
    const { history } = this.props;

    const queryStr = !lang || lang === 'ru' ? '/templates/groups' : `/templates/groups?lang=${lang}`;

    if (this.lang === lang) return;

    history.push(queryStr);
  };

  onDragEnd = (result) => {
    const { onSetGroupsOrder } = this.props;

    const {
      destination, source, draggableId, type,
    } = result;

    if (!destination) return;

    if (destination.index === source.index) return;

    if (type === 'group') {
      onSetGroupsOrder({
        groupId: draggableId, // id перемещаемой группы
        currentPosition: source.index, // индекс текущей перемещаемой группы
        newPosition: destination.index, // индекс новой позиции в массиве
      });
    }
  };

  handleSaveGroupsOrder = () => {
    const { groups, onSaveGroupsOrder } = this.props;

    sweetAlert.fire({
      cancelButtonText: 'Отмена',
      confirmButtonText: 'Да',
      icon: <FontAwesomeIcon icon={['fal', 'exclamation-triangle']} />,
      type: 'warning',
      showCancelButton: true,
      title: 'Вы уверены?',
      html: 'Вы действительно хотите сохранить изменение порядка?',
    }).then((result) => {
      if (!result.value) return;

      const prepared = groups.docs.map((item, order) => ({ _id: item._id, order }));

      const requestSaveGroupsOrderCanceler = requestCanceler();

      this.requestCancelers.push(requestSaveGroupsOrderCanceler);

      onSaveGroupsOrder(prepared, requestSaveGroupsOrderCanceler.token)
        .then(() => toast('success', 'Список успешно отсортирован'));
    });
  };

  handleCreateGroup = () => {
    const { onCreateGroup } = this.props;

    sweetAlert.fire({
      cancelButtonText: 'Отмена',
      confirmButtonText: 'Добавить',
      focusConfirm: false,
      icon: null,
      showCancelButton: true,
      title: 'Добавить группу',
      html:
        'Введите короткое название и алиас:'
        + '<input id="swal-input-group-short-title" class="swal2-input" type="text" placeholder="Короткое название" style="margin-top: 16px !important;">'
        + '<input id="swal-input-slug" class="swal2-input" type="text" placeholder="Алиас (транслитом, /^[a-z0-9-]{3,30}$/" style="margin-top: 16px !important;">',
      preConfirm: () => {
        const short_title = document.getElementById('swal-input-group-short-title').value.trim();
        const slug = document.getElementById('swal-input-slug').value.trim();

        if (!short_title || !slug) return Swal.showValidationMessage('Поля не могут быть пустыми');

        if (!/^[a-z0-9-]{3,30}$/.test(slug)) return Swal.showValidationMessage('Алиас не соответствует паттерну');

        return { short_title, slug };
      },
    }).then((result) => {
      if (!result.value || !result.value?.short_title || !result.value?.slug) return;

      const requestCreateGroupCanceler = requestCanceler();

      this.requestCancelers.push(requestCreateGroupCanceler);

      onCreateGroup({
        short_title: result.value.short_title,
        slug: result.value.slug,
        lang: queryString.parse(window.location.search)?.lang || 'ru',
      }, requestCreateGroupCanceler.token)
        .then(() => toast('success', 'Группа успешно создана'))
        .catch(({ message }) => toast('error', message));
    });
  };

  renderActionButtons = () => {
    const { groups } = this.props;
    const {
      isFetching, isOrdered, isGroupChanging,
    } = groups;

    return (
      <TemplatesActions
        isFetching={isFetching}
        isOrdered={isOrdered}
        stateChanging={isGroupChanging}
        type="группу"
        onClickCreate={this.handleCreateGroup}
        onClickSaveOrder={this.handleSaveGroupsOrder}
      />
    );
  };

  renderLanguagesDropdown = () => {
    const { groups, location } = this.props;
    const { isFetching, isGroupChanging } = groups;

    const defaultLang = queryString.parse(location.search)?.lang;
    const currentLang = defaultLang && this.languages.includes(defaultLang) ? defaultLang : this.languages[0];

    return (
      <LanguageDropdown
        currentLang={currentLang}
        disabled={isFetching || isGroupChanging.order}
        onChange={this.handleChangeLang}
      />
    );
  };

  render() {
    const { groups, groupsCategories, location } = this.props;

    const {
      docs, error, isFetching, isGroupChanging,
    } = groups;

    return (
      <View
        errorMessage={errorMessages[error?.message]}
        isError={error && ['admin_privileges_required', 'first_request_failed'].includes(error.message)}
        isFetching={isFetching}
        viewClass="templates"
        header={(
          <>
            <TemplatesSubnav location={location} />
            {this.renderLanguagesDropdown()}
            {this.renderActionButtons()}
          </>
        )}
        content={(
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable
              droppableId="all-groups"
              direction="vertical"
              type="group"
            >
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="template-bunch"
                >
                  {!isFetching && docs && docs.length > 0 && docs.map((item, index) => (
                    <Draggable draggableId={String(item._id)} index={index} key={item._id}>
                      {(provided2, snapshot) => (
                        <div
                          {...provided2.draggableProps}
                          ref={provided2.innerRef}
                          className={classNames('template-bunch__item', {
                            'template-bunch__item_dragging': snapshot.isDragging,
                          })}
                        >
                          <GroupsGroup
                            group={item}
                            groupCategories={groupsCategories[item._id]}
                            isDeleting={item._id === isGroupChanging.remove}
                            isUpdating={item._id === isGroupChanging.update}
                            lang={this.lang}
                          />
                          <div className="template-bunch__item-drag" {...provided2.dragHandleProps} />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
        footer={docs.length > 10 ? this.renderActionButtons() : null}
      />
    );
  }
}

Groups.propTypes = {
  groups: PropTypes.object.isRequired,
  groupsCategories: PropTypes.object.isRequired,
  history: PropTypes.object,
  location: PropTypes.object,
  onCreateGroup: PropTypes.func.isRequired,
  onGetCategories: PropTypes.func.isRequired,
  onGetGroups: PropTypes.func.isRequired,
  onSetGroupsOrder: PropTypes.func.isRequired,
  onSaveGroupsOrder: PropTypes.func.isRequired,
  onUnload: PropTypes.func.isRequired,
};

export default Groups;
