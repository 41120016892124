import { connect } from 'react-redux';

import {
  createInvoice,
  deleteService,
  getServices,
  RESET_SERVICES,
} from '../../../actions';

import Services from './Services';

const mapStateToProps = (state) => ({
  services: state.services,
});

const mapDispatchToProps = (dispatch) => ({
  onCreateInvoice: (params, requestToken) => dispatch(createInvoice(params, requestToken)),
  onDeleteService: (id, requestToken) => dispatch(deleteService(id, requestToken)),
  onGetServices: (params, isSearch, requestToken) => dispatch(getServices(params, isSearch, requestToken)),
  onUnload: () => dispatch({ type: RESET_SERVICES }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Services);
