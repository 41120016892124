import React, { useState } from 'react';
import PropTypes from 'prop-types';

import DatePicker from '../../../UI/DatePicker/DatePicker';

const InvoiceDatePopup = ({ defaultDate, description, onChange }) => {
  const [payDate, setPayDate] = useState(new Date(defaultDate));

  return (
    <div className="invoice-popup__datepicker">
      <span>{description || ''}</span>
      <DatePicker
        value={payDate}
        onChange={(val) => {
          onChange(val);

          setPayDate(val);
        }}
      />
    </div>
  );
};

InvoiceDatePopup.propTypes = {
  defaultDate: PropTypes.string.isRequired,
  description: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default InvoiceDatePopup;
