const subscriptionMessages = {
  Active: 'Подключена',
  PastDue: 'Не удалось списать деньги, но попытаемся еще раз...',
  Unactive: 'Не подключена',
  Rejected: 'Аннулированна. Недостаточно средств на карте',
  Cancelling: 'Отменяется...',
  Cancelled: 'Не подключена',
};

export default subscriptionMessages;
