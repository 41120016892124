import {
  APP_LOAD,
  VIEW_LOAD,
  VIEW_UNLOAD,
} from '../actions';

const defaultState = {
  appLoaded: false,
  pageClass: null,
  viewName: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case APP_LOAD:
      return {
        ...state,
        appLoaded: true,
      };
    case VIEW_LOAD:
      return {
        ...state,
        pageClass: action.payload.pageClass || action.payload.name || null,
        viewName: action.payload.name || null,
      };
    case VIEW_UNLOAD:
      return {
        ...state,
        pageClass: null,
        viewName: null,
      };
    default:
      return state;
  }
};
