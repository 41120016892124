import PropTypes from 'prop-types';
import React, { memo, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { languages } from '../../../../constants/common';

import NoMedia from '../../../UI/NoMedia/NoMedia';

import IdeasIdea from '../IdeasIdea/IdeasIdea';

const langs = Object.keys(languages);

const IdeasList = ({
  ideas, location, onLoadMore, onSearchIdeas, setViewTitle,
}) => {
  useEffect(() => {
    setViewTitle('Идеи постов');
  }, [setViewTitle]);

  const defaultLang = queryString.parse(location.search)?.lang;

  const renderLanguagesNav = () => {
    const currentLang = defaultLang && langs.includes(defaultLang) ? defaultLang : langs[0];

    return (
      <div className="ideas__lang">
        {langs.map((lang) => (
          // eslint-disable-next-line
          <div
            key={lang}
            className={`ideas__lang-item${lang === currentLang ? ' is-active' : ''}`}
            onClick={() => onSearchIdeas(lang)}
          >
            {lang}
          </div>
        ))}
      </div>
    );
  };

  return (
    <InfiniteScroll
      dataLength={ideas.docs.length}
      hasMore={ideas.page < ideas.pages}
      loader={null}
      next={onLoadMore}
      style={{ overflow: 'visible' }}
    >
      <div className="ideas__list">
        <Link to={`/ideas/create${defaultLang ? `?lang=${defaultLang}` : ''}`} className="ideas__link">Создать идею</Link>
        {renderLanguagesNav()}
        {ideas.docs.length > 0 && typeof ideas.total === 'number' && ideas.docs.map((idea, index) => (
          <div className="ideas__list-idea" key={idea._id}>
            <IdeasIdea
              data={idea}
              index={ideas.total - index}
            />
          </div>
        ))}
      </div>
      {!ideas.docs.length && !ideas.isFetching && (
        <div className="ideas__no-media">
          <NoMedia
            caption="Ничего не найдено"
            icon={<FontAwesomeIcon icon={['fas', 'exclamation-circle']} />}
          />
        </div>
      )}
    </InfiniteScroll>
  );
};

IdeasList.propTypes = {
  location: PropTypes.object,
  ideas: PropTypes.shape({
    docs: PropTypes.array,
    page: PropTypes.number,
    pages: PropTypes.number,
    total: PropTypes.number,
    isFetching: PropTypes.bool,
  }),
  setViewTitle: PropTypes.func.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  onSearchIdeas: PropTypes.func.isRequired,
};

export default memo(IdeasList);
