import PropTypes from 'prop-types';
import React, { useState, memo } from 'react';
import queryString from 'query-string';
import { Form } from 'react-bootstrap';

import SearchForm from '../../../Includes/SearchForm/SearchForm';

function UsersSearch({ onSearch }) {
  const [searchType, setSearchType] = useState(queryString.parse(window.location.search)?.search_type || 'name');

  const onChangeSearchType = ({ target }) => setSearchType(target.dataset.type);

  const handleSearch = (query) => onSearch({ query, search_type: searchType });

  return (
    <div className="tags__search">
      <div className="tags__search-options">
        <span>Поиск по:</span>
        <Form.Check
          checked={searchType === 'name'}
          className="form-check-custom"
          data-type="name"
          id="tags__search-name"
          inline
          label="Названию"
          name="group1"
          type="radio"
          onChange={onChangeSearchType}
        />
        <Form.Check
          checked={searchType === 'alias'}
          className="form-check-custom"
          data-type="alias"
          id="tags__search-email"
          inline
          label="Алиасу"
          name="group1"
          type="radio"
          onChange={onChangeSearchType}
        />
      </div>

      <SearchForm
        className="users__search-form"
        defaultValue={queryString.parse(window.location.search)?.search}
        placeholder={`Поиск по ${searchType === 'name' ? 'названию' : 'алиасу'}`}
        onSearch={handleSearch}
      />
    </div>
  );
}

UsersSearch.propTypes = {
  onSearch: PropTypes.func.isRequired,
};

export default memo(UsersSearch);
