import PropTypes from 'prop-types';
import React, { memo, useState, useRef } from 'react';
import { Button, Form } from 'react-bootstrap';

import Preloader from '../../../UI/Preloader/Preloader';

const MailingUnsubscribeTab = ({ isFetching, onUnsubscribeList }) => {
  const [file, setFile] = useState(null);
  const [unsubscribedCount, setUnsubscribedCount] = useState(null);

  const fileRef = useRef(null);

  const resetFile = () => {
    setFile(null);

    fileRef.current.value = '';
  };

  const handleLoadFile = ({ target }) => {
    if (!target.files.length) {
      resetFile();

      setUnsubscribedCount(null);

      return;
    }

    setFile(target.files[0]);
  };

  const handleUnsubscribeList = () => {
    onUnsubscribeList(file, (count = null) => {
      resetFile();

      setUnsubscribedCount(count);
    });
  };

  return (
    <Form className={`fileloader mailing__fileloader ${isFetching ? 'disabled' : ''}`}>
      <div className="mailing__fileloader-form">
        <Form.File
          custom
          id="custom-file"
        >
          <Form.File.Input
            accept=".txt,.csv"
            disabled={isFetching}
            ref={fileRef}
            onChange={handleLoadFile}
          />
          <Form.File.Label data-browse="обзор">Из файла</Form.File.Label>
        </Form.File>
        <Button disabled={!file || isFetching} onClick={handleUnsubscribeList}>
          Отписать
        </Button>
        {isFetching && <Preloader medium />}
      </div>
      {file?.name && (
        <div className="fileloader__file">
          <span>Выбран файл:&nbsp;</span>
          {file.name}
        </div>
      )}
      {!file && Number.isInteger(unsubscribedCount) && (
        <div className="fileloader__file">
          <span>Отписано:&nbsp;</span>
          {unsubscribedCount}
        </div>
      )}
    </Form>
  );
};

MailingUnsubscribeTab.propTypes = {
  isFetching: PropTypes.bool,
  onUnsubscribeList: PropTypes.func,
};

export default memo(MailingUnsubscribeTab);
