export const LIMIT_PER_PAGE = 10;

export const SEARCH_TYPES = {
  id: 'ID тарифа',
  text: 'Тексту',
};

export const SORT_TYPES = {
  asc: 'от старых к новым',
  desc: 'от новых к старым',
};

export const DAYS = {
  1: '1 день',
  7: '1 неделя',
  30: '1 месяц',
  90: '3 месяца',
  365: '1 год',
};

export const CURRENCIES = {
  RUB: 'RUB',
  USD: 'USD',
};

export const PLANS = {
  plan: 'plan',
  old_plan: 'old_plan',
  '': 'null',
};

export const GROUPS = {
  individual: 'individual',
  premium_main: 'premium_main',
  plus_main: 'plus_main',
  team: 'team',
};

export const RANGES = {
  individual: 'индивидуальный (3xxxxxx)',
  premium_main: 'премиальный (1xxxx)',
  plus_main: 'премиальный (1xxxx)',
  team: 'премиальный (1xxxx)',
};
