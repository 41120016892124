import { useRef, useState } from 'react';

import { useEvent } from '../hooks';

export default function useScrollFix() {
  const [isEmptyList, setIsEmptyList] = useState(false);

  const scrollRef = useRef(null);

  const scrollCallback = useEvent((fetchingResult, loadMoreCallback) => {
    if (fetchingResult?.page === 1 && fetchingResult?.docs?.length === 0) setIsEmptyList(true);

    if (!scrollRef?.current) return;

    const { height: LIST_HEIGHT } = scrollRef.current.getBoundingClientRect();

    if (fetchingResult.page + 1 <= fetchingResult.pages && LIST_HEIGHT < window.innerHeight) { // scroll fix
      loadMoreCallback(fetchingResult.page + 1);
    }
  }, []);

  return [scrollRef, scrollCallback, isEmptyList];
}
