export const CREATE_GROUP_CATEGORY = 'CREATE_GROUP_CATEGORY';
export const CHANGE_GROUP_CATEGORY = 'CHANGE_GROUP_CATEGORY';
export const DELETE_GROUP_CATEGORY = 'DELETE_GROUP_CATEGORY';
export const GET_ALL_GROUP_CATEGORIES = 'GET_ALL_GROUP_CATEGORIES';
export const GET_GROUP_CATEGORIES = 'GET_GROUP_CATEGORIES';
export const ORDER_GROUP_CATEGORY = 'ORDER_GROUP_CATEGORY';
export const RESET_GROUP_CATEGORIES = 'RESET_GROUP_CATEGORIES';
export const SAVE_GROUP_CATEGORIES_ORDER = 'SAVE_GROUP_CATEGORIES_ORDER';
export const UPDATE_GROUP_CATEGORY = 'UPDATE_GROUP_CATEGORY';

/* --------------- */
/* Action creators */

/* --------------- */

export function getAllCategories(params, requestToken) {
  return {
    type: GET_ALL_GROUP_CATEGORIES,
    request: {
      method: 'get',
      url: '/admin/template_groups/categories',
      params,
      requestToken,
    },
  };
}

export function getGroupCategories(groupId, requestToken) {
  return {
    type: GET_GROUP_CATEGORIES,
    request: {
      method: 'get',
      url: `/admin/template_groups/${groupId}/categories`,
      requestToken,
    },
    payload: { groupId },
  };
}

export function setGroupCategoriesOrder(moveData) {
  return {
    type: ORDER_GROUP_CATEGORY,
    payload: moveData,
  };
}

export function saveGroupCategoriesOrder(groupId, data, requestToken) {
  return {
    type: SAVE_GROUP_CATEGORIES_ORDER,
    request: {
      method: 'post',
      url: `/admin/template_groups/${groupId}/categories/order`,
      data,
      requestToken,
    },
    payload: { groupId },
  };
}

export function createGroupCategory(groupId, data, requestToken) {
  return {
    type: CREATE_GROUP_CATEGORY,
    request: {
      method: 'post',
      url: `/admin/template_groups/${groupId}/categories`,
      data,
      requestToken,
    },
    payload: { groupId },
  };
}

export function deleteGroupCategory(groupId, categoryId, requestToken) {
  return {
    type: DELETE_GROUP_CATEGORY,
    request: {
      method: 'delete',
      url: `/admin/template_groups/${groupId}/categories/${categoryId}`,
      requestToken,
    },
    payload: { groupId, categoryId },
  };
}

export function changeGroupCategory(groupId, categoryId, data) {
  return {
    type: CHANGE_GROUP_CATEGORY,
    payload: { groupId, categoryId, data },
  };
}

export function updateGroupCategory(groupId, data, requestToken) {
  return {
    type: UPDATE_GROUP_CATEGORY,
    request: {
      method: 'patch',
      url: `/admin/template_groups/${groupId}/categories/${data._id}`,
      data,
      requestToken,
    },
    payload: { groupId, categoryId: data._id },
  };
}
