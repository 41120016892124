export const CREATE_SERVICE = 'CREATE_SERVICE';
export const DELETE_SERVICE = 'DELETE_SERVICE';
export const GET_SERVICE = 'GET_SERVICE';
export const GET_SERVICES = 'GET_SERVICES';
export const RESET_SERVICES = 'RESET_SERVICES';
export const UPDATE_SERVICE = 'UPDATE_SERVICE';

/* --------------- */
/* Action creators */

/* --------------- */

export function getService(id, requestToken) {
  return {
    type: GET_SERVICE,
    request: {
      method: 'get',
      url: `/admin/services/${id}`,
      requestToken,
    },
  };
}

export function getServices(params, isSearch, requestToken) {
  return {
    type: GET_SERVICES,
    request: {
      method: 'get',
      url: '/admin/services',
      requestToken,
      params,
    },
    payload: { isSearch },
  };
}

export function createService(data, requestToken) {
  return {
    type: CREATE_SERVICE,
    request: {
      method: 'post',
      url: '/admin/services',
      requestToken,
      data,
    },
  };
}

export function updateService(id, data, requestToken) {
  return {
    type: UPDATE_SERVICE,
    request: {
      method: 'patch',
      url: `/admin/services/${id}`,
      requestToken,
      data,
    },
  };
}

export function deleteService(id, requestToken) {
  return {
    type: DELETE_SERVICE,
    request: {
      method: 'delete',
      url: `/admin/services/${id}`,
      requestToken,
    },
    payload: { id },
  };
}
