import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import queryString from 'query-string';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { languages } from '../../../../constants/common';
import errorMessages from '../../../../constants/errors';

import { requestCanceler } from '../../../../middlewares/api';

import { toast } from '../../../../utils';

import View from '../../../Layout/View/View';

import sweetAlert from '../../../HOCs/sweetAlert';

import LanguageDropdown from '../../../Includes/LanguageDropdown/LanguageDropdown';
import TemplatesActions from '../../../Includes/Templates/TemplatesActions/TemplatesActions';
import TemplatesSubnav from '../../../Includes/Templates/TemplatesSubnav/TemplatesSubnav';

import CategoriesCategory from './CategoriesCategory/CategoriesCategory';

// import './Categories.scss';

class Categories extends React.PureComponent {
  requestCancelers = [];

  languages = Object.keys(languages);

  componentDidMount() {
    this.getCategories({});
  }

  componentDidUpdate(prevProps) {
    const { categories, location } = this.props;
    const { error } = categories;

    if (location.search !== prevProps.location.search) {
      this.getCategories();
    }

    if (error && error.message && error !== prevProps.categories.error) {
      const message = ['Network Error', 'permission_denied', 'server_error', 'incorrect_query'].includes(error.message)
        ? errorMessages[error.message]
        : error.message;

      toast('error', message);
    }

    if (error && error.errors && error.errors.length) {
      toast('error', error.errors.join('<br /><br />'));
    }
  }

  componentWillUnmount() {
    const { onUnload } = this.props;

    this.requestCancelers.forEach((canceler) => canceler.cancelRequest());

    onUnload();
  }

  getCategories = () => {
    const { location, onGetCategories } = this.props;

    const requestParams = {};

    if (location.search) {
      const defaultParams = queryString.parse(location.search);

      if (defaultParams.lang && !Object.keys(languages).includes(defaultParams.lang)) {
        this.handleSearchCategories('ru');

        return;
      }

      Object.assign(requestParams, defaultParams);
    }

    const requestGetCategoriesCanceler = requestCanceler();

    this.requestCancelers.push(requestGetCategoriesCanceler);

    onGetCategories(requestParams, requestGetCategoriesCanceler.token).catch(() => {});
  };

  handleSearchCategories = (lang) => {
    const { history, location } = this.props;

    const queryStr = !lang || lang === 'ru' ? '/templates/categories' : `/templates/categories?lang=${lang}`;

    if (queryString.parse(location.search)?.lang === lang) {
      this.getCategories();
    } else {
      history.push(queryStr);
    }
  };

  onDragEnd = (result) => {
    const { onSetCategoriesOrder } = this.props;

    const {
      destination, source, draggableId, type,
    } = result;

    if (!destination) return;
    if (destination.index === source.index) return;

    if (type === 'category') {
      onSetCategoriesOrder({
        categoryId: draggableId, // id перемещаемой категории
        currentPosition: source.index, // индекс текущей перемещаемой категории
        newPosition: destination.index, // индекс новой позиции в массиве
      });
    }
  };

  handleSaveCategoriesOrder = () => {
    const { categories, onSaveCategoriesOrder } = this.props;

    sweetAlert.fire({
      cancelButtonText: 'Отмена',
      confirmButtonText: 'Да',
      icon: <FontAwesomeIcon icon={['fal', 'exclamation-triangle']} />,
      type: 'warning',
      showCancelButton: true,
      title: 'Вы уверены?',
      html: 'Вы действительно хотите сохранить изменение порядка?',
    }).then((result) => {
      if (!result.value) return;

      const prepared = categories.docs.map((item, order) => ({ _id: item._id, order }));

      const requestSaveCategoriesOrderCanceler = requestCanceler();

      this.requestCancelers.push(requestSaveCategoriesOrderCanceler);

      onSaveCategoriesOrder(prepared, requestSaveCategoriesOrderCanceler.token)
        .then(() => toast('success', 'Список успешно отсортирован'));
    });
  };

  handleCreateCategory = () => {
    const { onCreateCategory } = this.props;

    sweetAlert.fire({
      cancelButtonText: 'Отмена',
      confirmButtonText: 'Добавить',
      icon: null,
      showCancelButton: true,
      title: 'Добавить категорию',
      html: 'Введите название:',
      input: 'text',
      // eslint-disable-next-line
      inputValidator: (value) => {
        if (!value) return 'Название не может быть пустым';
      },
    }).then((result) => {
      if (!result.value) return;

      const requestCreateCategoryCanceler = requestCanceler();

      this.requestCancelers.push(requestCreateCategoryCanceler);

      onCreateCategory({
        title: result.value,
        lang: queryString.parse(window.location.search)?.lang || 'ru',
      }, requestCreateCategoryCanceler.token)
        .then(() => toast('success', 'Категория успешно создана'))
        .catch(() => {});
    });
  };

  handleRemoveCategory = (id) => {
    const { onRemoveCategory } = this.props;

    sweetAlert.fire({
      cancelButtonText: 'Отмена',
      confirmButtonText: 'Да, удалить',
      icon: <FontAwesomeIcon icon={['fal', 'exclamation-triangle']} />,
      type: 'warning',
      showCancelButton: true,
      title: 'Вы уверены?',
      html: 'Вы действительно хотите удалить категорию?',
    }).then((result) => {
      if (!result.value) return;

      const requestRemoveCategoryCanceler = requestCanceler();

      this.requestCancelers.push(requestRemoveCategoryCanceler);

      onRemoveCategory(id, requestRemoveCategoryCanceler.token)
        .then(() => toast('success', 'Категория удалена'));
    });
  };

  handleUpdateCategory = (data) => {
    const { onUpdateCategory } = this.props;

    if (!data.title) {
      toast('error', 'Укажите название категории');

      return;
    }

    const requestUpdateCategoryCanceler = requestCanceler();

    this.requestCancelers.push(requestUpdateCategoryCanceler);

    const updatedData = {
      ...data,
    };

    onUpdateCategory(updatedData, requestUpdateCategoryCanceler.token)
      .then(() => toast('success', 'Категория обновлена'))
      .catch(() => {});
  };

  renderActionButtons = () => {
    const { categories } = this.props;
    const {
      isFetching, isOrdered, isCategoryChanging,
    } = categories;

    return (
      <TemplatesActions
        isFetching={isFetching}
        isOrdered={isOrdered}
        stateChanging={isCategoryChanging}
        type="категорию"
        onClickCreate={this.handleCreateCategory}
        onClickSaveOrder={this.handleSaveCategoriesOrder}
      />
    );
  };

  renderLanguagesDropdown = () => {
    const { categories, location } = this.props;
    const { isFetching, isCategoryChanging } = categories;

    const defaultLang = queryString.parse(location.search)?.lang;
    const currentLang = defaultLang && this.languages.includes(defaultLang) ? defaultLang : this.languages[0];

    return (
      <LanguageDropdown
        currentLang={currentLang}
        disabled={isFetching || isCategoryChanging.order}
        onChange={this.handleSearchCategories}
      />
    );
  };

  render() {
    const { categories, location } = this.props;
    const {
      docs, error, isFetching, isCategoryChanging,
    } = categories;

    return (
      <View
        errorMessage={errorMessages[error?.message]}
        isError={error && ['admin_privileges_required', 'first_request_failed'].includes(error.message)}
        isFetching={isFetching}
        viewClass="templates"
        header={(
          <>
            <TemplatesSubnav />
            {this.renderLanguagesDropdown()}
            {this.renderActionButtons()}
          </>
        )}
        content={(
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable
              droppableId="all-categories"
              direction="vertical"
              type="category"
            >
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="template-bunch"
                >
                  {!isFetching && docs && docs.length > 0 && docs.map((item, index) => (
                    <Draggable draggableId={String(item.id)} index={index} key={item._id}>
                      {(provided2, snapshot) => (
                        <div
                          {...provided2.draggableProps}
                          ref={provided2.innerRef}
                          className={classNames('template-bunch__item', {
                            'template-bunch__item_dragging': snapshot.isDragging,
                          })}
                        >
                          <CategoriesCategory
                            category={item}
                            isRemoving={item.id === isCategoryChanging.remove}
                            isUpdating={item.id === isCategoryChanging.update}
                            isUnsaved={item?.isUnsaved}
                            lang={queryString.parse(location.search)?.lang}
                            onRemoveCategory={this.handleRemoveCategory}
                            onUpdateCategory={this.handleUpdateCategory}
                          />
                          <div className="template-bunch__item-drag" {...provided2.dragHandleProps} />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
        footer={this.renderActionButtons()}
      />
    );
  }
}

Categories.propTypes = {
  categories: PropTypes.object.isRequired,
  history: PropTypes.object,
  location: PropTypes.object,
  onCreateCategory: PropTypes.func.isRequired,
  onGetCategories: PropTypes.func.isRequired,
  onRemoveCategory: PropTypes.func.isRequired,
  onSetCategoriesOrder: PropTypes.func.isRequired,
  onSaveCategoriesOrder: PropTypes.func.isRequired,
  onUpdateCategory: PropTypes.func.isRequired,
  onUnload: PropTypes.func.isRequired,
};

export default Categories;
