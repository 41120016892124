const cacheGroups = [
  'template-categories',
  'templates',
  'template-sizes',
  'icons',
  'icon',
  'sounds',
  'sound-hires',
  'sound-categories',
  'sound-category-previews',
  'illustration-styles',
  'illustrations',
  'illustration-hires',
  'photo-objects-category-previews',
  'photo-objects',
  'gifs',
  'images-unsplash',
  'images-pixabay',
  'images-pexels',
  'videos-pixabay',
  'videos-pexels',
  'videos-storyblocks',
  'lottie',
  'landing-routes',
  'landing-menus',
  'landing-page-data',
];

export default cacheGroups;
