import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

class LinkDuo extends React.PureComponent {
  parseTo = (to) => {
    const parser = document.createElement('a');

    parser.href = to;

    return parser;
  };

  isInternal = (to) => {
    if (to.indexOf('://') === -1) return true;

    const toLocation = this.parseTo(to);

    return window.location.hostname === toLocation.hostname;
  };

  isService = (to) => to.indexOf('mailto:') === 0 || to.indexOf('tel:') === 0;

  render() {
    const {
      activeClassName,
      to,
      children,
      exact,
      ...props
    } = this.props;

    if (!to) {
      return (
        <button type="button" {...props}>{children}</button>
      );
    }

    const isInternal = this.isInternal(to);
    const isService = this.isService(to);

    return isInternal && !isService
      ? (
        <NavLink
          to={to}
          exact={exact}
          activeClassName={activeClassName}
          {...props}
        >
          {children}
        </NavLink>
      )
      : <a href={to} rel="noopener noreferrer" target="_blank" {...props}>{children}</a>;
  }
}

LinkDuo.propTypes = {
  exact: PropTypes.bool,
  children: PropTypes.node,
  activeClassName: PropTypes.string,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.func,
  ]),
};

export default LinkDuo;
