import { RESET_MAILING, DOWNLOAD_MAILING_LIST, UNSUBSCRIBE_MAILING_LIST } from '../actions';

const defaultState = {
  isFetching: false,
  error: null,
};

function returnError(response) {
  if (response.message) return response;

  return { message: 'admin_privileges_required' };
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case `${DOWNLOAD_MAILING_LIST}_REQUEST`:
    case `${UNSUBSCRIBE_MAILING_LIST}_REQUEST`:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case DOWNLOAD_MAILING_LIST:
    case UNSUBSCRIBE_MAILING_LIST:
      return {
        ...state,
        isFetching: false,
      };
    case `${DOWNLOAD_MAILING_LIST}_FAILURE`:
    case `${UNSUBSCRIBE_MAILING_LIST}_FAILURE`:
      return {
        ...state,
        isFetching: false,
        error: returnError(action.response),
      };
    case RESET_MAILING:
      return defaultState;
    default:
      return state;
  }
};
