import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Button } from 'react-bootstrap';

import usePrevious from '../../hooks';

import './SearchForm.scss';

function SearchForm({
  className = '',
  placeholder = '',
  defaultValue,
  disabled,
  onChange,
  onSearch,
}) {
  const [query, setQuery] = useState(defaultValue || '');

  const prevDefaultValue = usePrevious(defaultValue);

  useEffect(() => {
    if (defaultValue !== prevDefaultValue) setQuery(defaultValue);
  }, [defaultValue, prevDefaultValue, query]);

  const onChangeQuery = ({ target }) => { // eslint-disable-line
    if (onChange && typeof onChange === 'function') return onChange(target.value);

    setQuery(target.value);
  };

  const onSearchHandler = (e) => {
    e.preventDefault();

    onSearch(query.trim());
  };

  return (
    <Form className={`search-form ${String(className)}`} onSubmit={onSearchHandler}>
      <Form.Control type="text" placeholder={placeholder} value={query} disabled={disabled} onChange={onChangeQuery} />
      <Button variant="light" size="sm" type="submit" disabled={disabled}>поиск</Button>
    </Form>
  );
}

SearchForm.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onSearch: PropTypes.func.isRequired,
};

export default SearchForm;
