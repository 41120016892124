import { connect } from 'react-redux';

import {
  createService,
  RESET_SERVICES,
} from '../../../../actions';

import ServicesCreate from './ServicesCreate';

const mapStateToProps = (state) => ({
  services: state.services,
});

const mapDispatchToProps = (dispatch) => ({
  onCreateService: (params, requestToken) => dispatch(createService(params, requestToken)),
  onUnload: () => dispatch({ type: RESET_SERVICES }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ServicesCreate);
