import PropTypes from 'prop-types';
import React, { memo, useEffect } from 'react';
import { Link } from 'react-router-dom';

import PromocodesPromocode from '../PromocodesPromocode/PromocodesPromocode';

const PromocodesList = ({ promocodes, setViewTitle }) => {
  useEffect(() => {
    setViewTitle('Промокоды');
  }, [setViewTitle]);

  return (
    <div className="promocodes__list">
      <Link to="/promocodes/create" className="promocodes__link">Создать промокод</Link>
      {promocodes.docs.length ? promocodes.docs.map((item) => (
        <div className="promocodes__promocode" key={item._id}>
          <PromocodesPromocode promocode={item} />
        </div>
      )) : null}
    </div>
  );
};

PromocodesList.propTypes = {
  promocodes: PropTypes.object.isRequired,
  setViewTitle: PropTypes.func.isRequired,
};

export default memo(PromocodesList);
