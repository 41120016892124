import React from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader';
import { Redirect, Switch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToastContainer } from 'react-toastify';

import './App.scss';

import { isPermissionAccept } from '../../utils';
import { permissions } from '../../constants/common';

import RequireAuthRoute from '../HOCs/RequireAuthRoute';
import NoRequireAuthRoute from '../HOCs/NoRequireAuthRoute';

import SignInContainer from '../Views/Auth/SignIn/SignInContainer';
import Settings from '../Views/Settings/Settings';
import ElamaUsersContainer from '../Views/Elama/Users/UsersContainer';
import Elama from '../Views/Partners/Elama/ElamaContainer';
import UsersContainer from '../Views/Users/UsersContainer';
import InvoicesContainer from '../Views/Invoices/InvoicesContainer';
import Referral from '../Views/Partners/ReferralProgram/PartnersContainer';
import MailingContainer from '../Views/Mailing/MailingContainer';
import PromocodesContainer from '../Views/Promocodes/PromocodesContainer';
import IdeasContainer from '../Views/Ideas/IdeasContainer';
import TagsContainer from '../Views/Tags/TagsContainer';

import TemplatesGroupsContainer from '../Views/Templates/Groups/GroupsContainer';
import TemplatesSizesContainer from '../Views/Templates/Sizes/SizesContainer';
import TemplatesCategoriesContainer from '../Views/Templates/Categories/CategoriesContainer';
import TemplatesCategory from '../Views/Templates/Templates/TemplatesContainer';

// import VideosContainer from '../Views/Videos/VideosContainer';
import ApicacheContainer from '../Views/Apicache/ApicacheContainer';
import LandingContainer from '../Views/Landing/LandingContainer';

import ObjectCategoriesContainer from '../Views/Objects/Categories/CategoriesContainer';
import ObjectCategoriesCreateContainer from '../Views/Objects/CategoriesCreate/CategoriesCreateContainer';
import ObjectCategoriesEditContainer from '../Views/Objects/CategoriesEdit/CategoriesEditContainer';

import ObjectCategoryObjectsContainer from '../Views/Objects/Objects/ObjectsContainer';

import ServicesContainer from '../Views/Services/ServicesContainer';
import ServicesCreateContainer from '../Views/Services/ServicesCreate/ServicesCreateContainer';
import ServicesEditContainer from '../Views/Services/ServicesEdit/ServicesEditContainer';

import AccessIPAddresses from '../Views/Access/AccessIPAddresses/AccessIPAddresses';
import AccessIPAddressesEdit from '../Views/Access/AccessIPAddressesEdit/AccessIPAddressesEdit';
import AccessIPAddressesCreate from '../Views/Access/AccessIPAddressesCreate/AccessIPAddressesCreate';
import AccessAdmins from '../Views/Access/AccessAdmins/AccessAdmins';
import AccessAdminsEdit from '../Views/Access/AccessAdminsEdit/AccessAdminsEdit';
import AccessAdminsCreate from '../Views/Access/AccessAdminsCreate/AccessAdminsCreate';

import Companies from '../Views/Companies/Companies/Companies';
import CompaniesCreate from '../Views/Companies/CompaniesCreate/CompaniesCreate';
import CompaniesEdit from '../Views/Companies/CompaniesEdit/CompaniesEdit';

import Header from '../Layout/Header/Header';
import Footer from '../Layout/Footer/Footer';

function ToastCloseButton({ closeToast }) {
  return <FontAwesomeIcon icon={['fal', 'times']} onClick={closeToast} />;
}

ToastCloseButton.propTypes = {
  closeToast: PropTypes.func,
};

class App extends React.PureComponent {
  componentDidMount() {
    const { onLoad } = this.props;

    onLoad();

    this.removeAppLoader();
  }

  removeAppLoader = () => {
    const elem = document.getElementById('app-loader');

    if (!elem) return;

    elem.classList.add('removing');

    setTimeout(() => {
      elem.outerHTML = '';
    }, 250);
  };

  render() {
    const { auth: { user }, common } = this.props;

    let defaultRedirect = '/users';

    if (user && user.admin_permissions && user.admin_permissions.length) {
      defaultRedirect = user.admin_permissions[0] !== 'all'
        ? `/${user.admin_permissions[0]}`
        : defaultRedirect;
    }

    return (
      <div id="page" className={`page${common.pageClass ? ` page_${common.pageClass}` : ''}`}>
        {user && (
          <div className="page__header">
            <div className="container">
              <Header />
            </div>
          </div>
        )}
        <div className="page__main">
          <div className="container">
            <div className="main">
              <Switch>
                <RequireAuthRoute exact path="/apicache" component={ApicacheContainer} condition={isPermissionAccept(user, permissions.APICACHE)} defaultRedirect={defaultRedirect} />
                <RequireAuthRoute exact path="/elama" component={ElamaUsersContainer} condition={isPermissionAccept(user, permissions.ELAMA)} defaultRedirect={defaultRedirect} />
                <RequireAuthRoute exact path="/users" component={UsersContainer} condition={isPermissionAccept(user, permissions.USERS)} defaultRedirect={defaultRedirect} />
                <RequireAuthRoute exact path="/invoices" component={InvoicesContainer} condition={isPermissionAccept(user, permissions.INVOICES)} defaultRedirect={defaultRedirect} />
                <RequireAuthRoute exact path="/partners/referral" component={Referral} condition={isPermissionAccept(user, permissions.PARTNERS)} defaultRedirect={defaultRedirect} />
                <RequireAuthRoute exact path="/partners/elama" component={Elama} condition={isPermissionAccept(user, permissions.PARTNERS)} defaultRedirect={defaultRedirect} />
                <RequireAuthRoute exact path="/mailing" component={MailingContainer} condition={isPermissionAccept(user, permissions.MAILING)} defaultRedirect={defaultRedirect} />
                <RequireAuthRoute path="/promocodes" component={PromocodesContainer} condition={isPermissionAccept(user, permissions.SERVICES)} defaultRedirect={defaultRedirect} />
                <RequireAuthRoute path="/ideas" component={IdeasContainer} condition={isPermissionAccept(user, permissions.IDEAS)} defaultRedirect={defaultRedirect} />
                <RequireAuthRoute path="/tags" component={TagsContainer} condition={isPermissionAccept(user, permissions.TAGS)} defaultRedirect={defaultRedirect} />
                <RequireAuthRoute path="/landing" component={LandingContainer} condition={isPermissionAccept(user, permissions.LANDING)} defaultRedirect={defaultRedirect} />

                <RequireAuthRoute path="/templates/categories/:categoryId" component={TemplatesCategory} condition={isPermissionAccept(user, permissions.TEMPLATES)} defaultRedirect={defaultRedirect} />
                <RequireAuthRoute path="/templates/categories" component={TemplatesCategoriesContainer} condition={isPermissionAccept(user, permissions.TEMPLATES)} defaultRedirect={defaultRedirect} />
                <RequireAuthRoute path="/templates/groups" component={TemplatesGroupsContainer} condition={isPermissionAccept(user, permissions.TEMPLATES)} defaultRedirect={defaultRedirect} />
                <RequireAuthRoute path="/templates/sizes" component={TemplatesSizesContainer} condition={isPermissionAccept(user, permissions.TEMPLATES)} defaultRedirect={defaultRedirect} />

                <Redirect path="/templates" to="/templates/categories" />
                <Redirect path="/partners" to="/partners/referral" />

                <RequireAuthRoute path="/objects/categories/create" component={ObjectCategoriesCreateContainer} condition={isPermissionAccept(user, permissions.OBJECTS)} defaultRedirect={defaultRedirect} />
                <RequireAuthRoute path="/objects/categories/edit/:id" component={ObjectCategoriesEditContainer} condition={isPermissionAccept(user, permissions.OBJECTS)} defaultRedirect={defaultRedirect} />
                <RequireAuthRoute path="/objects/categories" component={ObjectCategoriesContainer} condition={isPermissionAccept(user, permissions.OBJECTS)} defaultRedirect={defaultRedirect} />
                <RequireAuthRoute path="/objects/category/:id" component={ObjectCategoryObjectsContainer} condition={isPermissionAccept(user, permissions.OBJECTS)} defaultRedirect={defaultRedirect} />

                <Redirect path="/objects" to="/objects/categories" />

                <RequireAuthRoute path="/services/create" component={ServicesCreateContainer} condition={isPermissionAccept(user, permissions.SERVICES)} defaultRedirect={defaultRedirect} />
                <RequireAuthRoute path="/services/edit/:id" component={ServicesEditContainer} condition={isPermissionAccept(user, permissions.SERVICES)} defaultRedirect={defaultRedirect} />
                <RequireAuthRoute path="/services" component={ServicesContainer} condition={isPermissionAccept(user, permissions.SERVICES)} defaultRedirect={defaultRedirect} />

                <RequireAuthRoute path="/access/ip-addresses/create" component={AccessIPAddressesCreate} condition={isPermissionAccept(user, permissions.ACCESS)} defaultRedirect={defaultRedirect} />
                <RequireAuthRoute path="/access/ip-addresses/edit/:id" component={AccessIPAddressesEdit} condition={isPermissionAccept(user, permissions.ACCESS)} defaultRedirect={defaultRedirect} />
                <RequireAuthRoute path="/access/ip-addresses" component={AccessIPAddresses} condition={isPermissionAccept(user, permissions.ACCESS)} defaultRedirect={defaultRedirect} />
                <RequireAuthRoute path="/access/admins/create" component={AccessAdminsCreate} condition={isPermissionAccept(user, permissions.ACCESS)} defaultRedirect={defaultRedirect} />
                <RequireAuthRoute path="/access/admins/edit/:id" component={AccessAdminsEdit} condition={isPermissionAccept(user, permissions.ACCESS)} defaultRedirect={defaultRedirect} />
                <RequireAuthRoute path="/access/admins" component={AccessAdmins} condition={isPermissionAccept(user, permissions.ACCESS)} defaultRedirect={defaultRedirect} />
                <Redirect path="/access" to="/access/ip-addresses" />

                <RequireAuthRoute path="/companies/create" component={CompaniesCreate} condition={isPermissionAccept(user, permissions.COMPANIES)} defaultRedirect={defaultRedirect} />
                <RequireAuthRoute path="/companies/edit/:id" component={CompaniesEdit} condition={isPermissionAccept(user, permissions.COMPANIES)} defaultRedirect={defaultRedirect} />
                <RequireAuthRoute path="/companies" component={Companies} condition={isPermissionAccept(user, permissions.COMPANIES)} defaultRedirect={defaultRedirect} />
                <Redirect path="/companies" to="/companies" />

                {/* <RequireAuthRoute exact path={['/videos', '/videos/:userId']} component={VideosContainer} condition={isPermissionAccept(user, permissions.VIDEOS)} defaultRedirect={defaultRedirect} /> */}
                <RequireAuthRoute path="/settings" component={Settings} defaultRedirect={defaultRedirect} />

                <NoRequireAuthRoute exact path="/login" component={SignInContainer} />

                <Redirect to={defaultRedirect} />
              </Switch>
            </div>
          </div>
        </div>
        {user && !user.settings.ui.disallowed_ui_elements.includes('footer') && (
          <div className="page__footer">
            <div className="container">
              <Footer />
            </div>
          </div>
        )}
        <ToastContainer
          autoClose={false}
          closeButton={<ToastCloseButton />}
          hideProgressBar={false}
        />
      </div>
    );
  }
}

App.propTypes = {
  auth: PropTypes.object.isRequired,
  common: PropTypes.object.isRequired,
  onLoad: PropTypes.func.isRequired,
};

export default hot(module)(App);
