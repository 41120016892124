import PropTypes from 'prop-types';
import React from 'react';
import queryString from 'query-string';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown } from 'react-bootstrap';

import { requestCanceler } from '../../../middlewares/api';

import { toast } from '../../../utils';

import { GET_INVOICES } from '../../../actions';

import errorMessages from '../../../constants/errors';

import View from '../../Layout/View/View';

import SearchForm from '../../Includes/SearchForm/SearchForm';

import NoMedia from '../../UI/NoMedia/NoMedia';
import LimitListHint from '../../UI/LimitListHint/LimitListHint';

import CheckBox from '../../UI/CheckBox/CheckBox';

import InvoicesInvoice from './InvoicesInvoice/InvoicesInvoice';

import './Invoices.scss';

export const SEARCH_TYPES = {
  id: 'ID',
  email: 'E-mail',
  service_id: 'ID тарифа',
  company_inn: 'ИНН',
};

class Invoices extends React.PureComponent {
  requestCancelers = [];

  limit = 30;

  state = {
    defaultSearchData: {
      search_type: queryString.parse(window.location.search)?.search_type || 'id',
      query: queryString.parse(window.location.search)?.query || '',
      paid_only: queryString.parse(window.location.search)?.paid_only || '',
    },
  };

  componentDidMount() {
    const { location: { search }, invoices } = this.props;

    const isSearch = queryString.parse(search)?.search;

    if (Boolean(isSearch) || !invoices.docs.length) this.getInvoices({ page: 1 });

    window.addEventListener('online', this.checkOnlineState);
  }

  componentDidUpdate(prevProps) {
    const { location, invoices } = this.props;
    const { error } = invoices;

    if (location.search !== prevProps.location.search) {
      this.getInvoices({ isSearch: true });
    }

    if (error && error.message && error !== prevProps.invoices.error) {
      const message = ['Network Error', 'invoice_not_exist, service_group_not_match', 'admin_privileges_required'].includes(error.message)
        ? errorMessages[error.message]
        : error.message;

      toast('error', message);
    }
  }

  componentWillUnmount() {
    const { onUnload } = this.props;

    this.requestCancelers.forEach((canceler) => canceler.cancelRequest());

    window.removeEventListener('online', this.checkOnlineState);

    onUnload();
  }

  getInvoices = ({ page = 1, isSearch = false }) => {
    const { onGetInvoices, location } = this.props;

    const requestParams = { limit: this.limit, page };

    if (location.search) {
      Object.assign(requestParams, queryString.parse(location.search));
    }

    const requestGetInvoicesCanceler = requestCanceler();

    this.requestCancelers.push(requestGetInvoicesCanceler);

    onGetInvoices(requestParams, isSearch, requestGetInvoicesCanceler.token).catch(() => {});
  };

  loadMore = () => {
    const { invoices } = this.props;

    if (invoices.isFetching) return;

    this.getInvoices({ page: invoices.page + 1 });
  };

  handleSearchInvoices = () => {
    const { history, location } = this.props;
    const { query, search_type, paid_only } = this.state.defaultSearchData;

    let path = '/invoices';

    const params = [];

    if (paid_only) params.push('paid_only=true');

    if (query) {
      params.push(`query=${query}`);
      params.push(`search_type=${search_type}`);
    }

    if (params.length) {
      path = `${path}?${params.join('&')}`;
    }

    if (
      queryString.parse(location.search)?.query === query
      && queryString.parse(location.search)?.search_type === search_type
      && queryString.parse(location.search)?.paid_only === paid_only
    ) {
      this.getInvoices({ isSearch: true });
    } else {
      history.push(path);
    }
  };

  handleClickSearchInvoices = ({ query, search_type }) => {
    this.setState({ defaultSearchData: { query, search_type } });
  };

  checkOnlineState = () => {
    const { lastActionType } = this.props.invoices;

    if (navigator.onLine && lastActionType === `${GET_INVOICES}_REQUEST`) this.loadMore();
  };

  handleChangePaidOnly = (value) => {
    const { query, search_type } = this.state.defaultSearchData;

    this.setState({
      defaultSearchData: {
        query,
        search_type,
        paid_only: value,
      },
    });
  };

  render() {
    const { defaultSearchData } = this.state;

    const {
      auth: { user: authUser },
      invoices,
      onGetInvoiceBlank,
      onPayInvoice,
      onToggleBonusInvoice,
      onUpdateInvoicePayDate,
      onUpdateInvoiceDate,
      onUpdateInvoiceCloseDate,
      onUpdateInvoiceCompany,
    } = this.props;

    const { error, isFetching } = invoices;

    return (
      <View
        errorMessage={errorMessages[error?.message]}
        viewClass="invoices"
        title="Счета"
        isError={error && ['admin_privileges_required', 'first_request_failed'].includes(error.message)}
        isFetching={isFetching}
        content={(
          <>
            <div className="invoices__search">
              <span>Поиск по:</span>
              <div className="invoices__dropdown">
                <Dropdown>
                  <Dropdown.Toggle as="button" className="btn btn-light" disabled={isFetching}>
                    {SEARCH_TYPES[defaultSearchData.search_type]}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {Object.keys(SEARCH_TYPES).map((type) => (
                      <Dropdown.Item
                        as="button"
                        disabled={type === defaultSearchData.search_type}
                        eventKey={type}
                        key={type}
                        onSelect={(val) => this.setState({ defaultSearchData: { ...defaultSearchData, search_type: val } })}
                      >
                        {SEARCH_TYPES[type]}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <SearchForm
                className="invoices__search-form"
                disabled={isFetching}
                defaultValue={defaultSearchData.query}
                onChange={(val) => this.setState({ defaultSearchData: { ...defaultSearchData, query: val } })}
                onSearch={this.handleSearchInvoices}
              />
              <Link to="/companies" className="btn btn-success invoices__link-btn">Контрагенты</Link>
              <CheckBox
                className="invoices__search-form-paid"
                checked={!!defaultSearchData.paid_only}
                id="invoices-search-form-paid"
                isFetching={false}
                onChange={this.handleChangePaidOnly}
              >
                Только оплаченные
              </CheckBox>
            </div>
            <InfiniteScroll
              dataLength={invoices.docs.length}
              hasMore={invoices.page < invoices.pages}
              loader={null}
              next={this.loadMore}
              style={{ overflow: 'visible' }}
            >
              {invoices.docs.length > 0 && (
                <>
                  <div className="invoices__list">
                    {invoices.docs.map((invoice) => (
                      <div className="invoices__list-invoice" key={invoice.id + invoice._id}>
                        <InvoicesInvoice
                          data={invoice}
                          onClickGetInvoiceBlank={onGetInvoiceBlank}
                          onClickPay={onPayInvoice}
                          onClickBonus={onToggleBonusInvoice}
                          onClickSearchInvoices={this.handleClickSearchInvoices}
                          onClickUpdatePayDate={onUpdateInvoicePayDate}
                          onClickUpdateDate={onUpdateInvoiceDate}
                          onClickUpdateCloseDate={onUpdateInvoiceCloseDate}
                          onClickUpdateComapany={onUpdateInvoiceCompany}
                        />
                      </div>
                    ))}
                  </div>
                  {authUser.admin_permissions.includes('support') && invoices.docs.length === invoices.limit && (
                    <LimitListHint num={invoices.limit} />
                  )}
                </>
              )}
              {!invoices.docs.length && !isFetching && (
                <div className="invoices__no-media">
                  <NoMedia
                    caption="Ничего не найдено"
                    icon={<FontAwesomeIcon icon={['fas', 'exclamation-circle']} />}
                  />
                </div>
              )}
            </InfiniteScroll>
          </>
        )}
      />
    );
  }
}

Invoices.propTypes = {
  auth: PropTypes.object.isRequired,
  history: PropTypes.object,
  invoices: PropTypes.object.isRequired,
  location: PropTypes.object,
  onGetInvoices: PropTypes.func.isRequired,
  onGetInvoiceBlank: PropTypes.func.isRequired,
  onPayInvoice: PropTypes.func.isRequired,
  onToggleBonusInvoice: PropTypes.func.isRequired,
  onUpdateInvoicePayDate: PropTypes.func.isRequired,
  onUpdateInvoiceDate: PropTypes.func.isRequired,
  onUpdateInvoiceCloseDate: PropTypes.func.isRequired,
  onUpdateInvoiceCompany: PropTypes.func.isRequired,
  onUnload: PropTypes.func.isRequired,
};

export default Invoices;
