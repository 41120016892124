import { connect } from 'react-redux';

import {
  RESET_USERS,
  elamaUserSignIn,
  getElamaUsers,
} from '../../../../actions';

import Users from './Users';

const mapStateToProps = (state) => ({
  users: state.users,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUsers: (params, isSearch, requestToken) => dispatch(getElamaUsers(params, isSearch, requestToken)),
  onUserSignIn: (data, requestToken) => dispatch(elamaUserSignIn(data, requestToken)),
  onUnload: () => dispatch({ type: RESET_USERS }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
