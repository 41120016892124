import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import React, {
  memo, useCallback, useEffect, useRef, useState,
} from 'react';
import { Link } from 'react-router-dom';
import { Button, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import errorMessages from '../../../../constants/errors';

import { downloadFile, toast } from '../../../../utils';

import { requestCanceler } from '../../../../middlewares/api';

import sweetAlert from '../../../HOCs/sweetAlert';

import CheckBox from '../../../UI/CheckBox/CheckBox';
import Preloader from '../../../UI/Preloader/Preloader';

import InvoiceDatePopup from '../InvoiceDatePopup/InvoiceDatePopup';

import './InvoicesInvoice.scss';

const BLANK_NAMES = {
  bill: 'Счёт',
  act: 'Акт',
  actsverki: 'Акт сверки',
};

function InvoicesInvoice({
  data,
  onClickGetInvoiceBlank,
  onClickPay,
  onClickBonus,
  onClickUpdatePayDate,
  onClickUpdateDate,
  onClickUpdateCloseDate,
  onClickUpdateComapany,
  onClickSearchInvoices,
}) {
  const [isSettingBonus, setIsSettingBonus] = useState(false);
  const [isPay, setIsPay] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const [isPDFBillFetching, setIsPDFBillFetching] = useState(false);
  const [isXLSXBillFetching, setIsXLSXBillFetching] = useState(false);
  const [isPDFActFetching, setIsPDFActFetching] = useState(false);
  const [isXLSXActFetching, setIsXLSXActFetching] = useState(false);

  const createDate = useRef(data.date);
  const payDate = useRef(data.pay_date);
  const closeDate = useRef(data?.close_date || data.lastPeriod?.to || new Date());
  const actDate = useRef(data.close_date || data?.lastPeriod?.to);

  const requestCancelers = useRef([]);

  const status = {
    paid: 'Оплачен',
    failed: 'Оплата не удалась',
  };

  useEffect(() => () => requestCancelers.current.forEach((canceler) => canceler.cancelRequest()), []);

  function handleClickPay() {
    if (data.status === 'paid') return;

    sweetAlert.fire({
      cancelButtonText: 'Отмена',
      confirmButtonText: 'Да, я уверен',
      icon: <FontAwesomeIcon icon={['fal', 'exclamation-triangle']} />,
      showCancelButton: true,
      type: 'warning',
      title: 'Вы уверены?',
      html: 'Отметить счет, как оплаченный?',
    }).then((result) => {
      if (!result.value) return;

      setIsPay(true);

      const requestPayCanceler = requestCanceler();

      requestCancelers.current.push(requestPayCanceler);

      onClickPay(data.id, requestPayCanceler.token)
        .then((res) => {
          payDate.current = res.invoice.pay_date;

          if (res.invoice.lastPeriod) {
            actDate.current = res.invoice.lastPeriod.to;
            closeDate.current = res.invoice.lastPeriod.to;
          }
        })
        .finally(() => {
          setIsPay(false);
        });
    });
  }

  function handleClickBonus(bonus) {
    setIsSettingBonus(true);

    const requestToggleBonusCanceler = requestCanceler();

    requestCancelers.current.push(requestToggleBonusCanceler);

    onClickBonus(data.id, bonus, requestToggleBonusCanceler.token)
      .finally(() => {
        setIsSettingBonus(false);
      });
  }

  function handleClickUpdatePayDate() {
    if (isUpdating) return;

    sweetAlert.fire({
      customClasses: {
        htmlContainer: 'invoice-popup',
      },
      cancelButtonText: 'Отмена',
      confirmButtonText: 'Изменить дату',
      icon: <FontAwesomeIcon icon={['fal', 'question-circle']} />,
      showCancelButton: true,
      type: 'info',
      title: 'Укажите дату оплаты счета',
      html: (
        <InvoiceDatePopup
          defaultDate={payDate.current}
          description="Дата оплаты счета:"
          onChange={(val) => {
            payDate.current = val;
          }}
        />
      ),
    }).then((result) => {
      if (!result.value) return;

      setIsUpdating(true);

      const currentDate = new Date(data.pay_date);
      const date = new Date(payDate.current);

      const requestUpdatePayDateCanceler = requestCanceler();

      requestCancelers.current.push(requestUpdatePayDateCanceler);

      onClickUpdatePayDate({
        id: data.id,
        pay_date: new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), currentDate.getUTCHours(), currentDate.getUTCMinutes(), currentDate.getUTCSeconds())).toISOString(),
      }, requestUpdatePayDateCanceler.token)
        .then(() => {
          toast('success', 'Дата оплаты счёта успешно изменена!');

          setIsUpdating(false);
        })
        .catch((error) => {
          payDate.current = data.pay_date;

          const message = errorMessages[error.message] || error?.message || 'Ошибка';

          toast('error', message);

          setIsUpdating(false);
        });
    });
  }

  function handleClickUpdateDate() {
    if (isUpdating) return;

    sweetAlert.fire({
      customClasses: {
        htmlContainer: 'invoice-popup',
      },
      cancelButtonText: 'Отмена',
      confirmButtonText: 'Изменить дату',
      icon: <FontAwesomeIcon icon={['fal', 'question-circle']} />,
      showCancelButton: true,
      type: 'info',
      title: 'Укажите дату создания счета',
      html: (
        <InvoiceDatePopup
          defaultDate={createDate.current}
          description="Дата создания счета:"
          onChange={(val) => {
            createDate.current = val;
          }}
        />
      ),
    }).then((result) => {
      if (!result.value) return;

      setIsUpdating(true);

      const currentDate = new Date(data.date);
      const date = new Date(createDate.current);

      const requestUpdateDateCanceler = requestCanceler();

      requestCancelers.current.push(requestUpdateDateCanceler);

      onClickUpdateDate({
        id: data.id,
        date: new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), currentDate.getUTCHours(), currentDate.getUTCMinutes(), currentDate.getUTCSeconds())).toISOString(),
      }, requestUpdateDateCanceler.token)
        .then(() => {
          toast('success', 'Дата создания счёта успешно изменена!');

          setIsUpdating(false);
        })
        .catch((error) => {
          createDate.current = data.date;

          const message = errorMessages[error.message] || error?.message || 'Ошибка';

          toast('error', message);

          setIsUpdating(false);
        });
    });
  }

  function handleClickUpdateCloseDate() {
    if (isUpdating) return;

    sweetAlert.fire({
      customClasses: {
        htmlContainer: 'invoice-popup',
      },
      cancelButtonText: 'Отмена',
      confirmButtonText: 'Изменить дату',
      icon: <FontAwesomeIcon icon={['fal', 'question-circle']} />,
      showCancelButton: true,
      type: 'info',
      title: 'Укажите дату закрытия счета',
      html: (
        <InvoiceDatePopup
          defaultDate={closeDate.current}
          description="Дата закрытия счета:"
          onChange={(val) => {
            closeDate.current = val;
          }}
        />
      ),
    }).then((result) => {
      if (!result.value) return;

      setIsUpdating(true);

      const currentDate = new Date();
      const date = new Date(closeDate.current);

      const requestUpdateCloseDateCanceler = requestCanceler();

      requestCancelers.current.push(requestUpdateCloseDateCanceler);

      onClickUpdateCloseDate({
        id: data.id,
        close_date: new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), currentDate.getUTCHours(), currentDate.getUTCMinutes(), currentDate.getUTCSeconds())).toISOString(),
      }, requestUpdateCloseDateCanceler.token)
        .then((res) => {
          toast('success', 'Дата закрытия счёта успешно изменена!');

          actDate.current = new Date(res.close_date);

          setIsUpdating(false);
        })
        .catch((error) => {
          closeDate.current = data?.close_date || data.lastPeriod?.to || new Date();

          const message = errorMessages[error.message] || error?.message || 'Ошибка';

          toast('error', message);

          setIsUpdating(false);
        });
    });
  }

  function handleClickUpdateCompany(companyID) {
    if (isUpdating) return;

    setIsUpdating(true);

    const requestUpdateCompanyCanceler = requestCanceler();

    requestCancelers.current.push(requestUpdateCompanyCanceler);

    onClickUpdateComapany({ id: data.id, company_id: companyID }, requestUpdateCompanyCanceler.token)
      .then(() => {
        toast('success', 'Контрагент успешно изменен!');

        setIsUpdating(false);
      })
      .catch((error) => {
        const message = errorMessages[error.message] || error?.message || 'Ошибка';

        toast('error', message);

        setIsUpdating(false);
      });
  }

  const handleGetInvoiceBlank = useCallback((format, type, date, setIsFetching) => {
    setIsFetching(true);

    const requestGetInvoiceBillBlankCanceler = requestCanceler();

    requestCancelers.current.push(requestGetInvoiceBillBlankCanceler);

    onClickGetInvoiceBlank({
      id: data.id,
      format,
      type,
      ...date && { date },
    }, requestGetInvoiceBillBlankCanceler.token)
      .then((blob) => {
        downloadFile(blob, `${BLANK_NAMES[type]} №${type === 'act' ? `${data.id}-1` : data.id} для ${data.company.full_name}.${format}`);

        toast('success', `${BLANK_NAMES[type]} ${format.toUpperCase()} успешно создан!`);

        setIsFetching(false);
      })
      .catch((error) => {
        const message = errorMessages[error.message] || error?.message || 'Ошибка';

        toast('error', message);

        setIsFetching(false);
      });
  }, [data.id, onClickGetInvoiceBlank, data?.company?.full_name]);

  const handleClickGetInvoiceXLSXBillBlank = useCallback(() => handleGetInvoiceBlank('xlsx', 'bill', null, setIsXLSXBillFetching), [handleGetInvoiceBlank]);

  const handleClickGetInvoicePDFBillBlank = useCallback(() => handleGetInvoiceBlank('pdf', 'bill', null, setIsPDFBillFetching), [handleGetInvoiceBlank]);

  const handleGetInvoiceActBlank = (format, setIsFetching) => {
    sweetAlert.fire({
      customClasses: {
        htmlContainer: 'invoice-popup',
      },
      cancelButtonText: 'Отмена',
      confirmButtonText: 'Создать акт',
      icon: <FontAwesomeIcon icon={['fal', 'question-circle']} />,
      showCancelButton: true,
      type: 'info',
      title: 'Укажите дату акта',
      html: (
        <InvoiceDatePopup
          defaultDate={actDate.current}
          description="Дата акта:"
          onChange={(val) => {
            actDate.current = val;
          }}
        />
      ),
    }).then((result) => {
      if (!result.value) {
        actDate.current = data.close_date || data.lastPeriod.to;

        return;
      }

      const date = new Date(actDate.current);

      handleGetInvoiceBlank(format, 'act', new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0)).toISOString(), setIsFetching);

      actDate.current = data.close_date || data.lastPeriod.to;
    });
  };

  const handleClickGetInvoiceXLSXActBlank = () => handleGetInvoiceActBlank('xlsx', setIsXLSXActFetching);

  const handleClickGetInvoicePDFActBlank = () => handleGetInvoiceActBlank('pdf', setIsPDFActFetching);

  const handleClickSearchUserInvoices = () => data.user?.email && onClickSearchInvoices({ search_type: 'email', query: data.user.email });

  const handleClickSearchCompanyInvoices = () => data?.company && onClickSearchInvoices({ search_type: 'company_inn', query: data.company.inn });

  return (
    <div className="invoice">
      <div className="invoice__id">
        {`#${data.id}`}
      </div>
      <div className="invoice__service-name">
        <Link className="" to={`/services?query=${data.service_id}&search_type=id&sort=desc`}>{data.service?.name}</Link>
      </div>
      <div className="invoice__user-email" title={data.user?.email}>
        <Link to={`/users?search=${data.user?.email}&search_type=full_email`}>{data.user?.email}</Link>
      </div>
      <div className="invoice__companies">
        <Dropdown>
          <Dropdown.Toggle as="button" className="btn" disabled={isSettingBonus || isPay || isUpdating}>
            <span>{data?.company?.full_name || 'Нет контрагента'}</span>
            <FontAwesomeIcon icon={['far', 'chevron-down']} />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              as="button"
              disabled={!data?.company_id}
              eventKey={0}
              key={0}
              onSelect={() => handleClickUpdateCompany(null)}
            >
              Нет контрагента
            </Dropdown.Item>
            {data?.companies?.length > 0 && data.companies.map((company) => (
              <Dropdown.Item
                as="button"
                disabled={data?.company_id === company.id}
                eventKey={company.id}
                key={company.id}
                onSelect={() => handleClickUpdateCompany(company.id)}
              >
                {company.full_name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="invoice__date">
        <div className="invoice__date-info">
          <span>Создан</span>
          <span className="invoice__date-edit-btn" disabled={isUpdating} role="button" onClick={handleClickUpdateDate}>
            {dayjs(data.date).format('DD.MM.YYYY')}
          </span>
        </div>
      </div>
      <div className="invoice__status">
        {data.status !== 'paid' && (
          <Button
            className="invoice__button"
            disabled={isPay}
            size="sm"
            variant="light"
            onClick={handleClickPay}
          >
            Оплатить
          </Button>
        )}
        <div className="invoice__status-info">
          <span className={`invoice__status_${data.status}`}>{status[data.status]}</span>
          {/* eslint-disable-next-line */}
          {data.status === 'paid' && (
            <span className="invoice__status-edit-btn" disabled={isUpdating} role="button" onClick={handleClickUpdatePayDate}>
              {dayjs(data.pay_date).format('DD.MM.YYYY')}
            </span>
          )}
        </div>
      </div>
      {data.status === 'paid' ? (
        <div className={`invoice__date${!data.close_date && !data.lastPeriod ? ' invoice__date_none' : ''}${(data.close_date || data.lastPeriod) ? ` ${new Date() < new Date((data.close_date || data.lastPeriod.to)) ? 'invoice__date_standing' : 'invoice__date_outdated'}` : ''}`}>
          <div className="invoice__date-info">
            <span
              className={!data.close_date && !data.lastPeriod ? 'invoice__date-edit-btn' : ''}
              disabled={isUpdating}
              role="button"
              onClick={!data.close_date && !data.lastPeriod ? handleClickUpdateCloseDate : () => {}}
            >
              Закрытие
            </span>
            {(data.close_date || data.lastPeriod) && (
              <span className="invoice__date-edit-btn" disabled={isUpdating} role="button" onClick={handleClickUpdateCloseDate}>
                {dayjs(data.close_date || data.lastPeriod.to).format('DD.MM.YYYY')}
              </span>
            )}
          </div>
        </div>
      ) : <div className="invoice__date" />}
      <CheckBox
        className="invoice__checkbox"
        checked={!!data?.bonus}
        disabled={isSettingBonus}
        id={String(data.id)}
        isFetching={isSettingBonus}
        onChange={handleClickBonus}
      >
        Бонус
      </CheckBox>
      <div className="invoice__actions">
        <Dropdown>
          <Dropdown.Toggle as="button" className="btn" disabled={false}>
            <FontAwesomeIcon icon={['fas', 'ellipsis-h']} />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {data.user?.email && (
              <Link to={`/users?search=${data.user.email}&search_type=full_email`}>
                <Dropdown.Item as="button" disabled={false} onClick={() => {}}>
                  Найти пользователя
                </Dropdown.Item>
              </Link>
            )}
            {data?.company && (
              <Link to={`/companies?query=${data.company.inn}&search_type=inn`}>
                <Dropdown.Item as="button" disabled={false} onClick={() => {}}>
                  Найти контрагента
                </Dropdown.Item>
              </Link>
            )}
            {data.user?.email && (
              <Link to={`/invoices?query=${data.user.email}&search_type=email`}>
                <Dropdown.Item as="button" disabled={false} onClick={handleClickSearchUserInvoices}>
                  Найти все счета пользователя
                </Dropdown.Item>
              </Link>
            )}
            {data?.company && (
              <Link to={`/invoices?query=${data.company.inn}&search_type=company_inn`}>
                <Dropdown.Item as="button" disabled={false} onClick={handleClickSearchCompanyInvoices}>
                  Найти все счета контрагента
                </Dropdown.Item>
              </Link>
            )}
            <Link to={`/services?query=${data.service_id}&search_type=id&sort=desc`}>
              <Dropdown.Item as="button" disabled={false} onClick={() => {}}>
                Найти тариф
              </Dropdown.Item>
            </Link>
            {data?.company && (
              <>
                <Dropdown.Item as="button" disabled={isPDFBillFetching} onClick={handleClickGetInvoicePDFBillBlank}>
                  Cчет в PDF
                  {isPDFBillFetching && <Preloader small />}
                </Dropdown.Item>
                <Dropdown.Item as="button" disabled={isXLSXBillFetching} onClick={handleClickGetInvoiceXLSXBillBlank}>
                  Cчет в XLSX
                  {isXLSXBillFetching && <Preloader small />}
                </Dropdown.Item>
                {(data?.close_date || data?.lastPeriod) && data.status === 'paid' && (
                  <>
                    <Dropdown.Item as="button" disabled={isPDFActFetching} onClick={handleClickGetInvoicePDFActBlank}>
                      Акт в PDF
                      {isPDFActFetching && <Preloader small />}
                    </Dropdown.Item>
                    <Dropdown.Item as="button" disabled={isXLSXActFetching} onClick={handleClickGetInvoiceXLSXActBlank}>
                      Акт в XLSX
                      {isXLSXActFetching && <Preloader small />}
                    </Dropdown.Item>
                  </>
                )}
              </>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
}

InvoicesInvoice.propTypes = {
  data: PropTypes.object,
  onClickGetInvoiceBlank: PropTypes.func,
  onClickPay: PropTypes.func,
  onClickBonus: PropTypes.func,
  onClickUpdatePayDate: PropTypes.func,
  onClickUpdateDate: PropTypes.func,
  onClickUpdateCloseDate: PropTypes.func,
  onClickUpdateComapany: PropTypes.func,
  onClickSearchInvoices: PropTypes.func,
};

export default memo(InvoicesInvoice);
