export const ADD_GROUP_CATEGORIES_COUNT = 'ADD_GROUP_CATEGORIES_COUNT';
export const CREATE_GROUP = 'CREATE_GROUP';
export const CHANGE_GROUP = 'CHANGE_GROUP';
export const DELETE_GROUP = 'DELETE_GROUP';
export const GET_GROUPS = 'GET_GROUPS';
export const ORDER_GROUP = 'ORDER_GROUP';
export const RESET_GROUPS = 'RESET_GROUPS';
export const SAVE_GROUPS_ORDER = 'SAVE_GROPS_ORDER';
export const UPDATE_GROUP = 'UPDATE_GROUP';

/* --------------- */
/* Action creators */

/* --------------- */

export function getGroups(params, requestToken) {
  return {
    type: GET_GROUPS,
    request: {
      method: 'get',
      url: '/admin/template_groups',
      params,
      requestToken,
    },
  };
}

export function setGroupsOrder(moveData) {
  return {
    type: ORDER_GROUP,
    payload: moveData,
  };
}

export function saveGroupsOrder(data, requestToken) {
  return {
    type: SAVE_GROUPS_ORDER,
    request: {
      method: 'post',
      url: '/admin/template_groups/order',
      data,
      requestToken,
    },
  };
}

export function createGroup(data, requestToken) {
  return {
    type: CREATE_GROUP,
    request: {
      method: 'post',
      url: '/admin/template_groups',
      data,
      requestToken,
    },
  };
}

export function deleteGroup(id, requestToken) {
  return {
    type: DELETE_GROUP,
    request: {
      method: 'delete',
      url: `/admin/template_groups/${id}`,
      requestToken,
    },
    payload: { id },
  };
}

export function changeGroup(id, data) {
  return {
    type: CHANGE_GROUP,
    payload: { id, data },
  };
}

export function addGroupCategoriesCount(id, count, force) {
  return {
    type: ADD_GROUP_CATEGORIES_COUNT,
    payload: { id, count, force },
  };
}

export function updateGroup(data, requestToken) {
  return {
    type: UPDATE_GROUP,
    request: {
      method: 'patch',
      url: `/admin/template_groups/${data._id}`,
      data,
      requestToken,
    },
    payload: { id: data._id },
  };
}
