import PropTypes from 'prop-types';
import React, { memo, useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';

import './FieldForm.scss';

const FieldForm = ({
  buttonText,
  disabled,
  disabledByDefault = true,
  disabledPrevValueComparison = false,
  defaultValue = '',
  fieldType = 'textarea',
  inputType = 'text',
  placeholder = '',
  readOnly,
  resetOn = false,
  validator,
  onSubmit = () => {},
}) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    if (!resetOn) return;

    if (typeof resetOn === 'boolean') {
      setValue('');
    } else {
      setValue(resetOn);
    }
  }, [resetOn]);

  const handleChange = (e) => {
    let { value: val } = e.target;

    if (typeof validator === 'function') val = validator(val);

    setValue(val);
  };

  const handleClick = () => {
    if (value !== defaultValue || !disabledByDefault) {
      onSubmit(value.replace(/(<([^>]+)>)/ig, ''));
    }
  };

  return (
    <div className="field-form">
      <Form.Control
        as={fieldType}
        type={inputType}
        placeholder={placeholder}
        readOnly={readOnly}
        value={value}
        onChange={handleChange}
      />
      {buttonText && (
        <Button
          disabled={disabled || (!value && disabledByDefault) || (!disabledPrevValueComparison && value === defaultValue)}
          variant="light"
          onClick={handleClick}
        >
          {buttonText}
        </Button>
      )}
    </div>
  );
};

FieldForm.propTypes = {
  buttonText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  disabledByDefault: PropTypes.bool,
  disabledPrevValueComparison: PropTypes.bool,
  fieldType: PropTypes.string,
  inputType: PropTypes.string,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  resetOn: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  validator: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default memo(FieldForm);
