import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import errorMessages from '../../../constants/errors';
import { languages as langs } from '../../../constants/common';

import View from '../../Layout/View/View';

import LandingSubnav from '../../Includes/Landing/LandingSubnav/LandingSubnav';
import LanguageDropdown from '../../Includes/LanguageDropdown/LanguageDropdown';

import LandingPages from './LandingPages/LandingPages';
import LandingMenu from './LandingMenu/LandingMenu';
import PageFormCreate from './LandingPages/PageFormCreate/PageFormCreate';
import PageFormEdit from './LandingPages/PageFormEdit/PageFormEdit';
import CategoryFormCreate from './LandingPages/CategoryFormCreate/CategoryFormCreate';
import CategoryFormEdit from './LandingPages/CategoryFormEdit/CategoryFormEdit';

import './Landing.scss';

const languages = Object.keys(langs);

const Landing = (props) => {
  const {
    error,
    isAnyFormVisible,
    isFetching,
    location,
    onUnload,
  } = props;

  const defaultLang = queryString.parse(location.search)?.lang;
  const currentLang = defaultLang && languages.includes(defaultLang) ? defaultLang : languages[0];

  const [lang, setLang] = useState(currentLang);

  const unload = useCallback(() => onUnload(), []); // eslint-disable-line

  useEffect(() => () => {
    unload();
  }, [unload]);

  const handleChangeLanguage = useCallback((val) => { setLang(val); }, []);

  const renderLanguagesDropdown = () => (
    <LanguageDropdown
      currentLang={lang}
      disabled={isFetching || isAnyFormVisible}
      onChange={handleChangeLanguage}
    />
  );

  return (
    <View
      errorMessage={errorMessages[error?.message]}
      isError={error && ['admin_privileges_required'].includes(error.message)}
      viewClass="landing"
      header={(
        <>
          <LandingSubnav />
          {renderLanguagesDropdown()}
        </>
      )}
      content={(
        <div className="landing">
          <Switch>
            <Route exact path="/landing/pages" render={() => <LandingPages lang={lang} onUnload={unload} />} />
            <Route exact path="/landing/pages/:id/edit" render={() => <PageFormEdit lang={lang} />} />
            <Route exact path="/landing/pages/create" render={() => <PageFormCreate lang={lang} />} />
            <Route exact path="/landing/categories/create" render={() => <CategoryFormCreate lang={lang} />} />
            <Route exact path="/landing/categories/:id/edit" render={() => <CategoryFormEdit lang={lang} />} />
            <Route exact path="/landing/menu" render={() => <LandingMenu lang={lang} onUnload={unload} />} />
            <Redirect to="/landing/pages" />
          </Switch>
        </div>
      )}
    />
  );
};

Landing.propTypes = {
  error: PropTypes.object,
  isAnyFormVisible: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  onUnload: PropTypes.func.isRequired,
};

export default Landing;
