import {
  CREATE_PAGE,
  DELETE_PAGE,
  GET_PAGE,
  SYNC_PAGE,
  GET_LANDING_PAGES,
  UPDATE_PAGE,
} from '../../../actions';

import { PREFIX_ACTION_TYPE_LANDING_PAGES } from '../../../constants/common';

function returnError(response) {
  if (response.message || response.errors) return response;

  return { message: 'admin_privileges_required' };
}

const getFullType = (type) => PREFIX_ACTION_TYPE_LANDING_PAGES + type;

export default (state, action) => {
  switch (action.type) {
    case `${getFullType(GET_LANDING_PAGES)}_REQUEST`:
    case `${getFullType(DELETE_PAGE)}_REQUEST`:
    case `${getFullType(UPDATE_PAGE)}_REQUEST`:
    case `${getFullType(CREATE_PAGE)}_REQUEST`:
    case `${getFullType(GET_PAGE)}_REQUEST`:
    case `${getFullType(SYNC_PAGE)}_REQUEST`:
      return {
        ...state,
        error: null,
        isFetching: true,
      };
    case getFullType(GET_PAGE):
      return {
        ...state,
        isFetching: false,
      };
    case getFullType(GET_LANDING_PAGES):
      return {
        ...state,
        pages: { ...state.pages, docs: action.response },
        isFetching: false,
      };
    case getFullType(CREATE_PAGE):
      return {
        ...state,
        pages: {
          ...state.pages,
          docs: [action.response, ...state.pages.docs],
        },
        isFetching: false,
      };
    case getFullType(DELETE_PAGE):
      return {
        ...state,
        pages: {
          ...state.pages,
          docs: state.pages.docs.filter((p) => p._id !== action.payload.id),
        },
        isFetching: false,
      };
    case getFullType(UPDATE_PAGE):
    case getFullType(SYNC_PAGE):
      return {
        ...state,
        pages: {
          ...state.pages,
          docs: state.pages.docs.map((page) => {
            if (page._id !== action.payload.id) return page;

            return { ...page, ...action.response };
          }),
        },
        isFetching: false,
      };
    case `${getFullType(GET_LANDING_PAGES)}_FAILURE`:
    case `${getFullType(CREATE_PAGE)}_FAILURE`:
    case `${getFullType(DELETE_PAGE)}DELETE_PAGE`:
    case `${getFullType(UPDATE_PAGE)}_FAILURE`:
    case `${getFullType(GET_PAGE)}_FAILURE`:
    case `${getFullType(SYNC_PAGE)}_FAILURE`:
      return {
        ...state,
        error: returnError(action.response),
        isFetching: false,
      };
    default:
      return state;
  }
};
