import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, {
  memo,
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { requestCanceler } from '../../../../../middlewares/api';

import { toast } from '../../../../../utils';

import successMessages from '../../../../../constants/successMessages';
import subscriptionMessages from '../../../../../constants/subscriptions';
import { languages, userBlocks } from '../../../../../constants/common';

import sweetAlert from '../../../../HOCs/sweetAlert';

import FieldForm from '../../../../Includes/FieldForm/FieldForm';

import UsersUserLogs from '../UsersUserLogs/UsersUserLogs';
import UsersUserPeriod from '../UsersUserPeriod/UsersUserPeriod';

function UsersUserMain({
  user,
  handleChangeUser,
  onAddVideo,
  onChangePeriodDate,
  onDeletePeriod,
  onGetUserLogs,
  onRemovePeriodMigrationID,
}) {
  const [resetVideoFieldValue, setResetVideoFieldValue] = useState(false);
  const [isUserChanging, setIsUserChanging] = useState({
    comment: false,
    currency: false,
    lang: false,
    block: false,
    periods: false,
    videos: false,
  });

  const requestCancelers = useRef([]);

  useEffect(() => () => requestCancelers.current.forEach((canceler) => canceler.cancelRequest()), []);

  const handleIsUserChanging = (props) => setIsUserChanging((prev) => ({ ...prev, ...props }));

  const handleAddComment = useCallback((comment) => {
    handleIsUserChanging({ comment: true });

    handleChangeUser({ comment })
      .then(() => toast('success', successMessages.comment))
      .finally(() => handleIsUserChanging({ comment: false }));
  }, [handleChangeUser]);

  const handleChangeCurrency = useCallback((curr) => {
    handleIsUserChanging({ currency: true });

    handleChangeUser({ currency: curr })
      .then(() => toast('success', successMessages.currency))
      .finally(() => handleIsUserChanging({ currency: false }));
  }, [handleChangeUser]);

  const handleChangeLang = useCallback((lang) => {
    handleIsUserChanging({ lang: true });

    handleChangeUser({ lang })
      .then(() => toast('success', successMessages.lang))
      .finally(() => handleIsUserChanging({ lang: false }));
  }, [handleChangeUser]);

  const handleChangeBlock = useCallback((block) => {
    handleIsUserChanging({ block: true });

    handleChangeUser({ block })
      .then(() => toast('success', successMessages.block))
      .finally(() => handleIsUserChanging({ block: false }));
  }, [handleChangeUser]);

  const handleAddVideo = useCallback((videos) => {
    handleIsUserChanging({ videos: true });

    let transfer_keys_list;

    try {
      const arr = videos.trim().split(/\r\n|\r|\n/g);

      transfer_keys_list = arr.map((item) => {
        const values = item.trim().split(':').map((id) => id.trim());

        if (!values[0] || !values[1]) throw new Error('invalid data');

        const reg = new RegExp(/^\d+$/);

        if (!reg.test(values[0]) || !reg.test(values[1])) throw new Error('invalid data');

        const user_id = Number.parseInt(values[0], 10);
        const video_id = Number.parseInt(values[1], 10);

        return { user_id, video_id };
      });
    } catch (error) {
      handleIsUserChanging({ videos: false });
      return toast('error', 'Запись не соответствует формату');
    }

    const requestAddVideoCanceler = requestCanceler();

    requestCancelers.current.push(requestAddVideoCanceler);

    return onAddVideo({ to_user: user.id, transfer_keys_list }, requestAddVideoCanceler.token)
      .then(() => {
        toast('success', `Перенос роликов успешно завершен для пользователя #${user.id}`, false);
        setResetVideoFieldValue(true);
      })
      .catch(() => setResetVideoFieldValue(false))
      .finally(() => handleIsUserChanging({ videos: false }));
  }, [user.id, onAddVideo]);

  const handleChangeTdrLimit = useCallback((value) => {
    handleIsUserChanging({ tdr_limit: true });

    const newTdrLimit = !value ? 0 : parseInt(value, 10);

    handleChangeUser({ tdr_limit: newTdrLimit })
      .then(() => toast('success', `TDR лимит изменен на «${newTdrLimit}»!`))
      .finally(() => handleIsUserChanging({ tdr_limit: false }));
  }, [handleChangeUser]);

  const handleDeletePeriod = (periodId) => {
    sweetAlert.fire({
      cancelButtonText: 'Отмена',
      confirmButtonText: 'Удалить',
      icon: <FontAwesomeIcon icon={['fal', 'exclamation-triangle']} />,
      type: 'warning',
      showCancelButton: true,
      title: 'Вы уверены?',
      html: 'Вы действительно хотите удалить период?',
    }).then((result) => {
      if (!result.value) return;

      handleIsUserChanging({ periods: true });

      const requestDeletePeriodCanceler = requestCanceler();

      requestCancelers.current.push(requestDeletePeriodCanceler);

      onDeletePeriod(user.id, periodId, requestDeletePeriodCanceler.token)
        .then(() => toast('success', successMessages.period_deleted))
        .finally(() => handleIsUserChanging({ periods: false }));
    });
  };

  const handleChangePeriodDate = (periodID, data) => {
    let html = '';

    if (data.from) {
      const from = dayjs(data.from).format('DD.MM.YYYY, HH:mm:ss');

      html = `Установить дату начала периода на<br /> ${from}`;
    }

    if (data.to) {
      const to = dayjs(data.to).format('DD.MM.YYYY, HH:mm:ss');

      html = `Установить дату окончания периода на<br /> ${to}`;
    }

    sweetAlert.fire({
      cancelButtonText: 'Отмена',
      confirmButtonText: 'Изменить',
      icon: <FontAwesomeIcon icon={['fal', 'exclamation-triangle']} />,
      type: 'warning',
      showCancelButton: true,
      title: 'Внимание!',
      html,
    }).then((result) => {
      if (!result.value) return;

      handleIsUserChanging({ periods: true });

      const requestChangePeriodDatePeriodCanceler = requestCanceler();

      requestCancelers.current.push(requestChangePeriodDatePeriodCanceler);

      onChangePeriodDate(user.id, periodID, data, requestChangePeriodDatePeriodCanceler.token)
        .then(() => toast('success', successMessages.period_date_was_changed))
        .finally(() => handleIsUserChanging({ periods: false }));
    });
  };

  const handleRemovePeriodMigrationID = (periodId) => {
    sweetAlert.fire({
      cancelButtonText: 'Отмена',
      confirmButtonText: 'Удалить',
      icon: <FontAwesomeIcon icon={['fal', 'exclamation-triangle']} />,
      type: 'warning',
      showCancelButton: true,
      title: 'Внимание!',
      html: 'Вы действительно хотите удалить запись о миграции?',
    }).then((result) => {
      if (!result.value) return;

      handleIsUserChanging({ periods: true });

      const requestRemovePeriodMigrationIDCanceler = requestCanceler();

      requestCancelers.current.push(requestRemovePeriodMigrationIDCanceler);

      onRemovePeriodMigrationID(user.id, periodId, requestRemovePeriodMigrationIDCanceler.token)
        .then(() => toast('success', successMessages.period_migration_id_deleted))
        .finally(() => handleIsUserChanging({ periods: false }));
    });
  };

  const handleCancelSubscription = useCallback((e) => {
    e.preventDefault();

    sweetAlert.fire({
      cancelButtonText: 'Нет',
      confirmButtonText: 'Да, я уверен',
      icon: <FontAwesomeIcon icon={['fal', 'exclamation-triangle']} />,
      type: 'warning',
      showCancelButton: true,
      title: 'Вы уверены?',
      html: 'Подтвердить отмену подписки?',
    }).then((result) => {
      if (!result.value) return;

      handleChangeUser({ 'subscription.state': 'Cancelled' })
        .then(() => toast('success', successMessages.subscription_cancelled))
        .catch(() => {});
    });
  }, [handleChangeUser]);

  return (
    <div className="user__main">
      {!user.team && (
        <>
          <div className="user__main-row">
            <div className="user__main-column">E-Mail подтвержден:</div>
            <div className="user__main-column">{user.confirmed ? 'Да' : 'Нет'}</div>
          </div>
          <div className="user__main-row">
            <div className="user__main-column">Попыток подтверждения:</div>
            <div className="user__main-column">{user.confirm_attempts}</div>
          </div>
        </>
      )}
      <div className="user__main-row">
        <div className="user__main-column">IP регистрации:</div>
        <div className="user__main-column">{user.remote_ip}</div>
      </div>
      <div className="user__main-row">
        <div className="user__main-column">Дата регистрации:</div>
        <div className="user__main-column">{user.date && dayjs(user.date).format('DD.MM.YYYY, HH:mm:ss')}</div>
      </div>
      <div className="user__main-row">
        <div className="user__main-column">Комментарий:</div>
        <div className="user__main-column">
          <FieldForm
            buttonText="Сохранить"
            disabled={isUserChanging.comment}
            defaultValue={user?.comment}
            disabledByDefault={false}
            placeholder="В целях безопасности HTML теги будут вырезаны"
            onSubmit={handleAddComment}
          />
        </div>
      </div>
      {!user.team && user.teams && user.teams.length ? (
        <div className="user__main-row">
          <div className="user__main-column">Команды:</div>
          <div className="user__main-column user__teams">
            {user.teams.map((team) => (
              <Link className="btn btn-light" to={`/users?team_id=${team._id}`} key={team._id}>
                {`Команда: ${team.name}`}
              </Link>
            ))}
          </div>
        </div>
      ) : null}
      {user.periods && user.periods.length ? (
        <div className="user__main-row">
          <div className="user__main-column">Периоды:</div>
          <div className="user__main-column user__periods">
            {user.periods.map((period, index) => (
              <UsersUserPeriod
                disabled={isUserChanging.periods}
                period={period}
                key={period._id}
                hideDateChange={user.periods.length - 1 !== index || ['Active', 'Activating'].includes(user?.subscription?.state)}
                onChangePeriodDate={handleChangePeriodDate}
                onDeletePeriod={handleDeletePeriod}
                onRemovePeriodMigrationID={handleRemovePeriodMigrationID}
              />
            ))}
          </div>
        </div>
      ) : null}
      {user?.companies?.length > 0 ? (
        <div className="user__main-row">
          <div className="user__main-column">Контрагенты:</div>
          <div className="user__main-column user__companies">
            {user.companies.map((company) => (
              <div key={company.id} className="user__company">
                <Link to={`/companies?query=${company.inn}&search_type=inn`}>{company.full_name}</Link>
              </div>
            ))}
          </div>
        </div>
      ) : null}
      <div className="user__main-row">
        <div className="user__main-column">Видеоролики:</div>
        <div className="user__main-column"><Link to={`/videos/${user.id}`}>посмотреть</Link></div>
      </div>
      {!user.team && (
        <>
          <div className="user__main-row">
            <div className="user__main-column">Подписка:</div>
            <div className="user__main-column user__subscription">
              {Object.keys(subscriptionMessages).includes(user?.subscription?.state) ? `${subscriptionMessages[user?.subscription?.state]} (${user?.subscription?.state})` : user?.subscription?.state}
              {user?.subscription?.state === 'Cancelling' && (
                // eslint-disable-next-line
                <a href="#" onClick={handleCancelSubscription} className="link">Отменить принудительно</a>
              )}
            </div>
          </div>
          <div className="user__main-row">
            <div className="user__main-column">Валюта:</div>
            <div className="user__main-column">
              <Dropdown>
                <Dropdown.Toggle as="button" className="btn btn-light">
                  {user.currency}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {['RUB', 'USD'].map((curr) => (
                    user.currency !== curr ? (
                      <Dropdown.Item
                        as="button"
                        disabled={isUserChanging.currency}
                        eventKey={curr}
                        key={curr}
                        onSelect={handleChangeCurrency}
                      >
                        {curr}
                      </Dropdown.Item>
                    ) : null
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </>
      )}
      <div className="user__main-row">
        <div className="user__main-column">Язык:</div>
        <div className="user__main-column">
          <Dropdown>
            <Dropdown.Toggle as="button" className="btn btn-light">
              {user.lang && languages[user.lang]}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {Object.keys(languages).map((lang) => (
                user.lang !== lang ? (
                  <Dropdown.Item
                    as="button"
                    disabled={isUserChanging.lang}
                    eventKey={lang}
                    key={lang}
                    onSelect={handleChangeLang}
                  >
                    {languages[lang]}
                  </Dropdown.Item>
                ) : null
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      {!user.team && (
        <div className="user__main-row">
          <div className="user__main-column">Блокировка:</div>
          <div className="user__main-column">
            <Dropdown>
              <Dropdown.Toggle as="button" className="btn btn-light">
                {user.block ? userBlocks[user.block] : userBlocks.none}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {Object.keys(userBlocks).map((block) => (
                  user.block !== block ? (
                    <Dropdown.Item
                      as="button"
                      disabled={isUserChanging.block}
                      eventKey={block}
                      key={block}
                      onSelect={handleChangeBlock}
                    >
                      {userBlocks[block]}
                    </Dropdown.Item>
                  ) : null
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      )}
      <div className="user__main-row">
        <div className="user__main-column">Добавить видео:</div>
        <div className="user__main-column">
          <FieldForm
            buttonText="Добавить видео"
            disabled={isUserChanging.videos}
            placeholder="<id юзера>:<id видео>&#10;<id юзера>:<id видео>"
            resetOn={resetVideoFieldValue}
            onSubmit={handleAddVideo}
          />
        </div>
      </div>
      {!user.team && (
        <>
          <div className="user__main-row">
            <div className="user__main-column">TDR лимит:</div>
            <div className="user__main-column user__tdr-limit">
              <FieldForm
                buttonText="Обновить"
                disabled={isUserChanging.tdr_limit}
                defaultValue={String(user?.tdr_limit)}
                fieldType="input"
                inputType="number"
                onSubmit={handleChangeTdrLimit}
              />
            </div>
          </div>
        </>
      )}
      <div className="user__main-row">
        <div className="user__main-column">Лог юзера:</div>
        <div className="user__main-column">
          <UsersUserLogs
            id={user.id}
            logs={user?.logs}
            logsIsFetching={user.logsIsFetching}
            onGetUserLogs={onGetUserLogs}
          />
        </div>
      </div>
    </div>
  );
}

UsersUserMain.propTypes = {
  user: PropTypes.object,
  handleChangeUser: PropTypes.func,
  onAddVideo: PropTypes.func,
  onChangePeriodDate: PropTypes.func,
  onDeletePeriod: PropTypes.func,
  onGetUserLogs: PropTypes.func,
  onRemovePeriodMigrationID: PropTypes.func,
};

export default memo(UsersUserMain);
