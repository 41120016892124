import { PREFIX_ACTION_TYPE_LANDING_CATEGORIES } from '../../constants/common';

export const GET_LANDING_PAGE_CATEGORIES = 'GET_LANDING_PAGE_CATEGORIES';
export const GET_PAGE_CATEGORY = 'GET_PAGE_CATEGORY';
export const CREATE_PAGE_CATEGORY = 'CREATE_PAGE_CATEGORY';
export const DELETE_PAGE_CATEGORY = 'DELETE_PAGE_CATEGORY';
export const UPDATE_PAGE_CATEGORY = 'UPDATE_PAGE_CATEGORY';

export function getLandingPageCategories(params, requestToken) {
  return {
    type: PREFIX_ACTION_TYPE_LANDING_CATEGORIES + GET_LANDING_PAGE_CATEGORIES,
    request: {
      method: 'get',
      url: '/admin/landing/page_categories',
      params,
      requestToken,
    },
  };
}

export function getPageCategory(id, requestToken) {
  return {
    type: PREFIX_ACTION_TYPE_LANDING_CATEGORIES + GET_PAGE_CATEGORY,
    request: {
      method: 'get',
      url: `/admin/landing/page_categories/${id}`,
      requestToken,
    },
  };
}

export function createPageCategory(data) {
  return {
    type: PREFIX_ACTION_TYPE_LANDING_CATEGORIES + CREATE_PAGE_CATEGORY,
    request: {
      method: 'post',
      url: '/admin/landing/page_categories',
      data,
    },
  };
}

export function deletePageCategory(id) {
  return {
    type: PREFIX_ACTION_TYPE_LANDING_CATEGORIES + DELETE_PAGE_CATEGORY,
    request: {
      method: 'delete',
      url: `/admin/landing/page_categories/${id}`,
    },
    payload: { id },
  };
}

export function updatePageCategory(id, data) {
  return {
    type: PREFIX_ACTION_TYPE_LANDING_CATEGORIES + UPDATE_PAGE_CATEGORY,
    request: {
      method: 'patch',
      url: `/admin/landing/page_categories/${id}`,
      data,
    },
    payload: { id },
  };
}
