import React, {
  memo, useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { toast } from '../../../../utils';

import errorMessages from '../../../../constants/errors';

import { getAdminUser, updateAdminPrivileges } from '../../../../actions';

import withRequest from '../../../HOCs/withRequest';

import View from '../../../Layout/View/View';

import AccessSubnav from '../../../Includes/Access/AccessSubnav/AccessSubnav';

import AccessAdminsForm from '../AccessAdminsForm/AccessAdminsForm';

const AccessAdminsEdit = ({ match: { params: { id } }, createCanceler, dispatch }) => {
  const admins = useSelector(({ accessAdmins }) => accessAdmins);

  const [isDataFetching, setIsDataFetching] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    setIsDataFetching(true);

    const getUserRequest = createCanceler();

    dispatch(getAdminUser(id, getUserRequest.token))
      .then((res) => {
        setIsDataFetching(false);

        setData({ email: res.email, name: res?.name || '', admin_permissions: res?.admin_permissions || [] });
      })
      .catch(() => setIsDataFetching(false));
  }, []); // eslint-disable-line

  const handleEdit = useCallback((formData, successCallback) => {
    const updateUserPrivRequest = createCanceler();

    dispatch(updateAdminPrivileges(id, formData, updateUserPrivRequest.token))
      .then(() => {
        toast('success', 'Администратор изменён!');

        successCallback();
      })
      .catch((error) => {
        const message = errorMessages[error.message] || error.message;

        toast('error', message);
      });
  }, [id, createCanceler, dispatch]);

  return (
    <View
      errorMessage={errorMessages[admins?.error?.message]}
      isError={admins.error && (admins.error?.message === 'user_not_found' || ['admin_privileges_required'].includes(admins.error.message))}
      isFetching={isDataFetching}
      preloaderCaption="Загружаем..."
      header={<AccessSubnav />}
      isHeaderBeforeTitle
      title="Редактировать администратора"
      viewClass="admin-edit"
      content={data ? (
        <AccessAdminsForm isEditMode defaultData={data} userID={id} admins={admins} onSubmit={handleEdit} />
      ) : null}
    />
  );
};

AccessAdminsEdit.propTypes = {
  match: PropTypes.object,
  createCanceler: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default memo(withRequest(withRouter(AccessAdminsEdit)));
