import React from 'react';
import { NavLink } from 'react-router-dom';

const PartnersNav = () => (
  <ul className="partners__nav">
    <li className="partners__nav-item">
      <NavLink to="/partners/referral" activeClassName="is-active">
        Реферальная программа
      </NavLink>
    </li>
    <li className="partners__nav-item">
      <NavLink to="/partners/elama" activeClassName="is-active">
        eLama
      </NavLink>
    </li>
  </ul>
);

export default PartnersNav;
