import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import React, { memo, useState } from 'react';
import { Button, Dropdown, Form } from 'react-bootstrap';

import { toast } from '../../../../utils';

import { languages } from '../../../../constants/common';

import CheckBox from '../../../UI/CheckBox/CheckBox';
import Preloader from '../../../UI/Preloader/Preloader';

const CategoriesForm = ({
  buttonText,
  data,
  disabled,
  location,
  resetAfterSubmit,
  onSubmit = () => {},
}) => {
  const [formData, setFormData] = useState(
    data?._id
      ? {
        name: data?.name,
        alias: data?.alias,
        lang: data?.lang,
        resize: data?.resize,
      }
      : {
        name: '',
        alias: '',
        lang: queryString.parse(location.search)?.lang || 'ru',
        resize: true,
      },
  );

  const handleChangeFormData = (props) => {
    setFormData((prev) => ({ ...prev, ...props }));
  };

  const handleChangeName = (value) => {
    handleChangeFormData({ name: value });
  };

  const handleChangeAlias = (value) => {
    handleChangeFormData({ alias: value });
  };

  const handleChangeResize = (value) => {
    handleChangeFormData({ resize: value });
  };

  const handleChangeLang = (value) => {
    handleChangeFormData({ lang: value });
  };

  const handleSubmit = () => { // eslint-disable-line
    if (!formData.name.trim()) return toast('error', 'Некорректное название');
    if (!formData.alias.trim()) return toast('error', 'Некорректный алиас');

    const prepared = { ...formData };

    // eslint-disable-next-line
    if (prepared.__v) delete prepared.__v;

    onSubmit(prepared, () => {
      if (resetAfterSubmit) {
        handleChangeFormData({ lang: formData.lang, name: '', alias: '' });
      }
    });
  };

  return (
    <div className="object-categories__form">
      <div className="object-categories__form-row object-categories__form-lang">
        <Dropdown>
          <Dropdown.Toggle as="button" className="btn btn-light" disabled={disabled}>
            {formData.lang && languages[formData.lang]}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {Object.keys(languages).map((lang) => (
              formData.lang !== lang ? (
                <Dropdown.Item
                  as="button"
                  eventKey={lang}
                  key={lang}
                  onSelect={handleChangeLang}
                >
                  {languages[lang]}
                </Dropdown.Item>
              ) : null
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="object-categories__form-row object-categories__form-name">
        <div className="object-categories__form-label">Название:</div>
        <Form.Control
          disabled={disabled}
          value={formData.name}
          onChange={(e) => handleChangeName(e.target.value)}
        />
      </div>
      <div className="object-categories__form-row object-categories__form-alias">
        <div className="object-categories__form-label">Алиас:</div>
        <Form.Control
          disabled={disabled}
          value={formData.alias}
          onChange={(e) => handleChangeAlias(e.target.value)}
        />
      </div>
      <div className="object-categories__form-row object-categories__form-alias">
        <div className="object-categories__form-label">Resize:</div>
        <CheckBox
          className="invoice__checkbox"
          checked={formData.resize}
          disabled={disabled}
          id="resize"
          isFetching={false}
          onChange={handleChangeResize}
        />
      </div>
      <div className="object-categories__form-row object-categories__form-submit">
        <Button
          className="btn-success"
          disabled={disabled || !formData.name.trim() || !formData.alias.trim()}
          onClick={handleSubmit}
        >
          {buttonText}
          {disabled && <Preloader inline small caption="" />}
        </Button>
        <span className="object-categories__link">
          <Link to={`/objects/categories${formData.lang && formData.lang !== 'ru' ? `?lang=${formData.lang}` : ''}`}>
            Назад
          </Link>
        </span>
      </div>
    </div>
  );
};

CategoriesForm.propTypes = {
  buttonText: PropTypes.string,
  data: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    alias: PropTypes.string,
    lang: PropTypes.string,
    resize: PropTypes.bool,
  }),
  disabled: PropTypes.bool,
  location: PropTypes.object,
  resetAfterSubmit: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default memo(CategoriesForm);
