import React, { memo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { toast } from '../../../../utils';

import errorMessages from '../../../../constants/errors';

import { RESET_ACCESS_IP_ADDRESSES, createAdminIPAddress } from '../../../../actions';

import withRequest from '../../../HOCs/withRequest';

import View from '../../../Layout/View/View';

import AccessSubnav from '../../../Includes/Access/AccessSubnav/AccessSubnav';

import AccessIPAddressesForm from '../AccessIPAddressesForm/AccessIPAddressesForm';

const AccessIPAddressesCreate = ({ createCanceler, dispatch }) => {
  const addresses = useSelector(({ accessIPAddresses }) => accessIPAddresses);

  const handleCreate = useCallback((formData, successCallback) => {
    const createIPAddressRequest = createCanceler();

    dispatch(createAdminIPAddress(formData, createIPAddressRequest.token))
      .then(() => {
        toast('success', 'IP-адрес создан!');

        successCallback();
      })
      .catch((error) => {
        const message = errorMessages[error.message] || error.message;

        toast('error', message);
      });
  }, [createCanceler, dispatch]);

  useEffect(() => () => {
    dispatch({ type: RESET_ACCESS_IP_ADDRESSES });
  }, [dispatch]);

  return (
    <View
      errorMessage={errorMessages[addresses.error?.message]}
      isError={addresses.error && ['admin_privileges_required'].includes(addresses.error.message)}
      isFetching={false}
      preloaderCaption="Загружаем..."
      header={<AccessSubnav />}
      isHeaderBeforeTitle
      title="Создать IP-адрес"
      viewClass="ip-address-create"
      content={<AccessIPAddressesForm addresses={addresses} onSubmit={handleCreate} />}
    />
  );
};

AccessIPAddressesCreate.propTypes = {
  createCanceler: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default memo(withRequest(AccessIPAddressesCreate));
