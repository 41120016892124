import React, {
  memo, useCallback, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import { diff } from 'deep-object-diff';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import {
  addElementToMenu,
  cancelMenuChanges,
  changeMenuElementLevel,
  changeMenuElementsOrder,
  copyMenuElement,
  deleteMenuElement,
  updateMenuElement,
  updateMenuElements,
} from '../../../../../actions';

import withRequest from '../../../../HOCs/withRequest';

import { isURLValid, toast } from '../../../../../utils';

import MenuElement from '../MenuElement/MenuElement';

import './Menu.scss';

const MENUS_WITH_DISABLED_LEVELS = ['header'];

const Menu = ({
  menu, dispatch, onEdit, onDelete,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const isFetching = useSelector(({ landing }) => landing.isFetching);
  const initMenu = useSelector(({ landing }) => landing.menu.docs.find((m) => m._id === menu._id));

  const isMenuModified = useMemo(() => !isEqual(initMenu, menu), [initMenu, menu]);

  const handleToggleCollapse = () => setIsCollapsed((prev) => !prev);

  const handleClickAddElement = (e) => {
    e.stopPropagation();

    setIsCollapsed(false);

    dispatch(addElementToMenu(menu._id));
  };

  const handleClickSaveMenu = () => {
    const elementsData = menu.elements.map((el) => {
      const existEl = initMenu.elements?.find((elem) => elem._id === el._id);

      if (!existEl) {
        const { _id, menu_id, ...newData } = el;

        return { id: _id, ...newData };
      }

      const updatedData = diff(existEl, el);

      if (isEqual(updatedData, {})) return null;

      return {
        _id: existEl._id,
        ...updatedData,
        ...'parent_element_id' in updatedData && typeof updatedData.parent_element_id === 'undefined' && { parent_element_id: '' },
      };
    }).filter(Boolean);

    const deletedElements = initMenu.elements?.filter((el) => !menu.elements.find((e) => e._id === el._id));

    elementsData.push(...deletedElements.map((el) => ({ _id: el._id, deleted: true })));

    dispatch(updateMenuElements(menu._id, elementsData))
      .then(() => {
        toast('success', 'Элементы меню успешно обновлены!');
      })
      .catch(() => toast('error', 'Произошла ошибка. Попробуйте позже'));
  };

  const handleClickCancel = () => dispatch(cancelMenuChanges(menu._id));

  const handleClickEditMenu = (e) => {
    e.stopPropagation();

    onEdit(menu);
  };

  const handleClickDeleteMenu = (e) => {
    e.stopPropagation();

    onDelete(menu);
  };

  const handleChangeElement = useCallback((id, data) => {
    dispatch(updateMenuElement(menu._id, id, data));
  }, [menu._id]); // eslint-disable-line

  const handleCopyElement = useCallback((id) => {
    dispatch(copyMenuElement(menu._id, id));
  }, [menu._id]); // eslint-disable-line

  const handleDeleteElement = useCallback((id) => {
    dispatch(deleteMenuElement(menu._id, id));
  }, [menu._id]); // eslint-disable-line

  const handleChangeElementLevel = useCallback((id) => {
    dispatch(changeMenuElementLevel(menu._id, id));
  }, [menu._id]); // eslint-disable-line

  const handleDragEnd = (result) => {
    const {
      destination, source, draggableId, type,
    } = result;

    if (!destination || destination.index === source.index || type !== 'menu-element') return;

    dispatch(changeMenuElementsOrder(menu._id, {
      elementId: draggableId,
      currentPosition: source.index,
      newPosition: destination.index,
    }));
  };

  const isSaveBtnDisabled = menu.elements?.some((el) => (
    !el.name
    || (el.action === 'menu' && !el.action_menu_id)
    || (el.action === 'page' && !el.action_page_id)
    || (el.action === 'url' && !isURLValid(el.action_url))
  ));

  return (
    <div className="menu landing__category">
      <div
        className="landing__category-header"
        role="button"
        tabIndex={0}
        onClick={handleToggleCollapse}
      >
        <div className="landing__category-name">
          <span>{menu.name}</span>
          <FontAwesomeIcon
            icon={['far', 'chevron-right']}
            style={{ transform: `rotate(${isCollapsed ? '0' : '90'}deg)` }}
          />
        </div>
        <div className="landing__category-actions">
          <button disabled={isFetching} type="button" className="landing__category-action" onClick={handleClickAddElement}>
            <FontAwesomeIcon icon={['fas', 'plus']} />
          </button>
          <button disabled={isFetching} type="button" className="landing__category-action" onClick={handleClickEditMenu}>
            <FontAwesomeIcon icon={['fas', 'pen']} />
          </button>
          <button disabled={isFetching} type="button" className="landing__category-action" onClick={handleClickDeleteMenu}>
            <FontAwesomeIcon icon={['far', 'times']} />
          </button>
        </div>
      </div>

      {!isCollapsed && (
        <div className="landing__category-body">
          {menu.elements?.length === 0 && <div className="landing__info">В этом меню еще нет пунктов</div>}
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable
              droppableId="all-elements"
              direction="vertical"
              type="menu-element"
            >
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {menu.elements?.map((el, index) => (
                    <Draggable draggableId={el._id} index={index} key={el._id}>
                      {(provided2) => (
                        <div
                          {...provided2.draggableProps}
                          ref={provided2.innerRef}
                          className={`menu__element-wrapper${el.parent_element_id ? ' menu__element-wrapper_nested' : ''}`}
                        >
                          <MenuElement
                            element={el}
                            dragHandleProps={provided2.dragHandleProps}
                            isLevelDisabled={MENUS_WITH_DISABLED_LEVELS.includes(menu.name)}
                            isFetching={isFetching}
                            onChange={handleChangeElement}
                            onCopy={handleCopyElement}
                            onChangeLevel={handleChangeElementLevel}
                            onDelete={handleDeleteElement}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          {isMenuModified && (
            <>
              <Button
                disabled={isFetching || isSaveBtnDisabled}
                className="landing__section-action"
                variant="success"
                onClick={handleClickSaveMenu}
              >
                Сохранить меню
              </Button>
              <Button
                disabled={isFetching}
                className="landing__section-action"
                variant="light"
                onClick={handleClickCancel}
              >
                Отменить
              </Button>
            </>
          )}
        </div>
      )}
    </div>
  );
};

Menu.propTypes = {
  menu: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default memo(withRequest(Menu));
