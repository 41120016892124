import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';

import errorMessages from '../../../../constants/errors';

import './Profile.scss';

class Profile extends React.Component {
  defaultState = {
    oldPassword: '',
    newPassword: '',
    newPasswordRepeat: '',
    passwordNotMatch: false,
  };

  state = { ...this.defaultState };

  componentWillUnmount() {
    const { onUnload } = this.props;

    onUnload();
  }

  handleChangeInput = (event) => {
    const { name } = event.target;
    const value = event.target.value.trim();

    this.setState({
      [name]: value,
    });
  };

  handleSubmitForm = (event) => {
    event.preventDefault();

    const { changePassword } = this.props;
    const { oldPassword, newPassword, newPasswordRepeat } = this.state;

    if (newPassword !== newPasswordRepeat) {
      this.setState({
        passwordNotMatch: true,
      });

      return;
    }

    this.setState({
      passwordNotMatch: false,
    });

    changePassword({ new_password: newPassword, old_password: oldPassword }).then(() => {
      this.setState(this.defaultState);
    }).catch(() => {});
  };

  renderErrors = () => {
    const { profile } = this.props;
    const { passwordNotMatch } = this.state;

    const error = passwordNotMatch ? 'password_not_match' : profile.error.message;

    return (
      <Alert variant="danger">
        {
          ['password_not_match', 'old_password_wrong', 'account_blocked', 'Network Error']
            .includes(error)
            ? errorMessages[error]
            : errorMessages.unknown_error
        }
      </Alert>
    );
  };

  renderProfileInfo() {
    const { auth } = this.props;
    const { user } = auth;

    return (
      <>
        {!user.team && (
          <>
            <h2 className="profile__title">Личные данные</h2>
            <div className="profile__content">
              <strong>E-mail:</strong>
              {user.email}
            </div>
          </>
        )}
        {user.team && user.login && (
          <div className="profile__content">
            <strong>Логин:</strong>
            {user.login}
          </div>
        )}
      </>
    );
  }

  renderChangePassword() {
    const {
      oldPassword,
      newPassword,
      newPasswordRepeat,
      passwordNotMatch,
    } = this.state;
    const { profile } = this.props;
    const { response } = profile;

    return (
      <>
        <h2 className="profile__title">Изменение пароля</h2>
        <div className="profile__content">
          <div className="profile__form">
            <form onSubmit={this.handleSubmitForm} className="form">
              {(profile.error || passwordNotMatch) && this.renderErrors()}
              {response && response.message === 'success' && <Alert variant="success">Пароль изменен</Alert>}
              <input
                name="oldPassword"
                className="form-control"
                type="password"
                placeholder="Старый пароль"
                value={oldPassword}
                required
                onChange={this.handleChangeInput}
              />
              <input
                name="newPassword"
                className="form-control"
                type="password"
                placeholder="Новый пароль"
                value={newPassword}
                required
                onChange={this.handleChangeInput}
              />
              <input
                name="newPasswordRepeat"
                className="form-control"
                type="password"
                placeholder="Новый пароль (еще раз)"
                value={newPasswordRepeat}
                required
                onChange={this.handleChangeInput}
              />
              <button
                className="btn btn-primary"
                type="submit"
                disabled={profile.isFetching || !oldPassword || !newPassword || !newPasswordRepeat}
              >
                Изменить пароль
              </button>
            </form>
          </div>
        </div>
      </>
    );
  }

  render() {
    return (
      <div className="profile">
        {this.renderProfileInfo()}
        {this.renderChangePassword()}
      </div>
    );
  }
}

Profile.propTypes = {
  auth: PropTypes.object.isRequired,
  changePassword: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  onUnload: PropTypes.func.isRequired,
};

export default Profile;
