import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';

import { tdrNotices } from '../../../../../../constants/common';

import sweetAlert from '../../../../../HOCs/sweetAlert';

const TdrNoticeModal = ({ type, template, onConfirm }) => {
  const [formData, setFormData] = useState({
    subject: tdrNotices[type].subject.replace(/variation_id/gi, String(template?.variations[0]?.id)),
    message: tdrNotices[type].message
      .replace(/variation_id/gi, String(template?.variations[0]?.id))
      .replace(/&mode/gi, template?.variations[0].type && template?.variations[0].type === 'image' ? '&mode=image' : ''),
  });

  const handleChangeFormData = ({ target }) => {
    setFormData((prev) => ({ ...prev, [target.dataset.type]: target.value }));
  };

  // eslint-disable-next-line
  const handleConfirm = () => {
    const notification = {
      subject: formData.subject,
      message: formData.message,
      type,
    };

    onConfirm(template.id, notification);
  };

  return (
    <div className="template__notice-modal-content">
      <Form.Control
        data-type="subject"
        value={formData.subject}
        onChange={handleChangeFormData}
      />
      <Form.Control
        as="textarea"
        data-type="message"
        value={formData.message}
        onChange={handleChangeFormData}
      />
      <div className="template__notice-modal-actions">
        <Button onClick={handleConfirm}>Сохранить</Button>
        <Button variant="warning" onClick={() => sweetAlert.close()}>Не уведомлять</Button>
      </div>
    </div>
  );
};

TdrNoticeModal.propTypes = {
  type: PropTypes.string,
  template: PropTypes.object,
  onConfirm: PropTypes.func,
};

export default TdrNoticeModal;
