import { connect } from 'react-redux';

import {
  RESET_INVOICES,
  getInvoices,
  getInvoiceBlank,
  payInvoice,
  toggleBonusInvoice,
  updateInvoicePayDate,
  updateInvoiceDate,
  updateInvoiceCloseDate,
  updateInvoiceCompany,
} from '../../../actions/invoices';

import Invoices from './Invoices';

const mapStateToProps = (state) => ({
  auth: state.auth,
  invoices: state.invoices,
});

const mapDispatchToProps = (dispatch) => ({
  onGetInvoices: (params, isSearch, requestToken) => dispatch(getInvoices(params, isSearch, requestToken)),
  onGetInvoiceBlank: (params, requestToken) => dispatch(getInvoiceBlank(params, requestToken)),
  onPayInvoice: (id, requestToken) => dispatch(payInvoice(id, requestToken)),
  onToggleBonusInvoice: (id, bonus, requestToken) => dispatch(toggleBonusInvoice(id, bonus, requestToken)),
  onUpdateInvoicePayDate: (data, requestToken) => dispatch(updateInvoicePayDate(data, requestToken)),
  onUpdateInvoiceDate: (data, requestToken) => dispatch(updateInvoiceDate(data, requestToken)),
  onUpdateInvoiceCloseDate: (data, requestToken) => dispatch(updateInvoiceCloseDate(data, requestToken)),
  onUpdateInvoiceCompany: (data, requestToken) => dispatch(updateInvoiceCompany(data, requestToken)),
  onUnload: () => dispatch({ type: RESET_INVOICES }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Invoices);
