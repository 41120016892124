export const CLEAR_CACHE = 'CLEAR_CACHE';
export const CLEAR_ALL_CACHE = 'CLEAR_ALL_CACHE';
export const GET_CACHED_GROUPS = 'GET_CACHED_GROUPS';

/* --------------- */
/* Action creators */

/* --------------- */

export function clearCache(data, requestToken) {
  return {
    type: CLEAR_CACHE,
    request: {
      method: 'post',
      url: '/admin/apicache/clear',
      data,
      requestToken,
    },
  };
}

export function clearAllCache(requestToken) {
  return {
    type: CLEAR_ALL_CACHE,
    request: {
      method: 'post',
      url: '/admin/apicache/clear/all',
      requestToken,
    },
  };
}
