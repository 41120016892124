import { RESET_LANDING } from '../../actions';

import {
  PREFIX_ACTION_TYPE_LANDING_CATEGORIES,
  PREFIX_ACTION_TYPE_LANDING_FORM,
  PREFIX_ACTION_TYPE_LANDING_MENUS,
  PREFIX_ACTION_TYPE_LANDING_PAGES,
} from '../../constants/common';

import FormCases, { defaultFormState } from './landing-state/form';
import PagesCases from './landing-state/pages';
import CategoriesCases from './landing-state/categories';
import MenusCases from './landing-state/menus';

const defaultState = {
  error: null,
  isFetching: false,
  form: defaultFormState,
  isFormVisible: false,
  categories: {
    docs: [],
  },
  pages: {
    docs: [],
  },
  menu: {
    formDocs: [], // [{ ...menuData, elements: [] }] - current changing data, use when working with elements in menu
    docs: [], // [{ ...menuData, elements: [] }] - initial data from server
  },
};

const landingState = (state = defaultState, action) => {
  if (action.type.indexOf(PREFIX_ACTION_TYPE_LANDING_PAGES) !== -1) {
    return PagesCases(state, action);
  }

  if (action.type.indexOf(PREFIX_ACTION_TYPE_LANDING_CATEGORIES) !== -1) {
    return CategoriesCases(state, action);
  }

  if (action.type.indexOf(PREFIX_ACTION_TYPE_LANDING_MENUS) !== -1) {
    return MenusCases(state, action);
  }

  if (action.type.indexOf(PREFIX_ACTION_TYPE_LANDING_FORM) !== -1) {
    return FormCases(state, action);
  }

  switch (action.type) {
    case RESET_LANDING:
      return defaultState;
    default:
      return state;
  }
};

export default landingState;
