import {
  GET_PARTNERS,
  RESET_PARTNERS,
  MAKE_PARTNER_PAYOUT,
  UPDATE_USER,
} from '../actions';

const defaultState = {
  docs: [],
  error: null,
  isFetching: false,
  page: 0,
  pages: 1,
};

function returnError(response, state) {
  if (response.message && state.page === 0) return { message: 'first_request_failed' };
  if (response.message) return response;

  return { message: 'admin_privileges_required' };
}

function updatePartner(state, action) {
  return state.docs.map((item) => {
    if (item.user.id !== action.response.id) return item;

    return {
      ...item,
      user: action.response,
    };
  });
}

function makePayout(state, action) {
  return state.docs.map((item) => {
    if (item.user.id !== action.payload.userId) return item;

    return {
      ...item,
      sum_avail: item.sum_avail - action.payload.sum,
      sum_payout: item.sum_payout + action.payload.sum,
    };
  });
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case `${GET_PARTNERS}_REQUEST`:
      return {
        ...state,
        docs: action.payload.isSearch ? [] : state.docs,
        error: null,
        isFetching: true,
      };
    case `${UPDATE_USER}_REQUEST`:
    case `${MAKE_PARTNER_PAYOUT}_REQUEST`:
      return {
        ...state,
        error: null,
      };
    case GET_PARTNERS:
      return {
        ...state,
        docs: action.response,
        isFetching: false,
      };
    case UPDATE_USER:
      return {
        ...state,
        docs: updatePartner(state, action),
      };
    case MAKE_PARTNER_PAYOUT:
      return {
        ...state,
        docs: makePayout(state, action),
      };
    case `${GET_PARTNERS}_FAILURE`:
      return {
        ...state,
        error: returnError(action.response, state),
        isFetching: false,
      };
    case `${UPDATE_USER}_FAILURE`:
    case `${MAKE_PARTNER_PAYOUT}_FAILURE`:
      return {
        ...state,
        error: action.response,
      };
    case RESET_PARTNERS:
      return defaultState;
    default:
      return state;
  }
};
