import dayjs from 'dayjs';
import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Dropdown, Tooltip, OverlayTrigger } from 'react-bootstrap';

import { copyToClipboard } from '../../../../../utils';

import DateTimePicker from '../../../../UI/DateTimePicker/DateTimePicker';

const UsersUserPeriod = memo(({
  disabled,
  period,
  hideDateChange,
  onDeletePeriod,
  onChangePeriodDate,
  onRemovePeriodMigrationID,
}) => {
  const [isOpenDatePickerFrom, setIsOpenDatePickerFrom] = useState(false);
  const [isOpenDatePickerTo, setIsOpenDatePickerTo] = useState(false);

  const now = new Date().getTime();
  const to = new Date(period.to).getTime();

  const handleDeletePeriod = () => onDeletePeriod(period.id);
  const handleRemovePeriodMigrationID = () => onRemovePeriodMigrationID(period.id);

  const handleChangePeriodDataFrom = (value) => {
    onChangePeriodDate(period.id, {
      from: value.getTime(),
    });
  };

  const handleChangePeriodDataEnd = (value) => {
    onChangePeriodDate(period.id, {
      to: value.getTime(),
    });
  };

  const periodFrom = dayjs(period.from).format('DD.MM.YYYY');
  const periodTo = dayjs(period.to).format('DD.MM.YYYY');

  return (
    <div className={`user__period period ${now < to ? 'active' : ''}`}>
      <Dropdown>
        <OverlayTrigger
          placement="top"
          overlay={(
            <Tooltip id="tooltip-bottom">
              {period.service.name}
            </Tooltip>
          )}
        >
          <Dropdown.Toggle as="button" className="btn">
            {period.service.group === 'individual' ? 'Индивидуальный ' : ''}
            {period.service.promocode ? 'Промокод ' : ''}
            {periodFrom}
            &nbsp;-&nbsp;
            {periodTo}
            {period.migration_service_id && ` (${period.migration_service_id})`}
          </Dropdown.Toggle>
        </OverlayTrigger>
        <Dropdown.Menu>
          <Link to={`/services?query=${period.service_id}&search_type=id&sort=desc`}>
            <Dropdown.Item as="button" disabled={disabled} onClick={() => {}}>
              Посмотреть тариф
            </Dropdown.Item>
          </Link>
          <Dropdown.Item as="button" disabled={disabled} onClick={() => copyToClipboard(periodFrom.trim())}>
            Копировать начало периода
          </Dropdown.Item>
          <Dropdown.Item as="button" disabled={disabled} onClick={() => copyToClipboard(periodTo.trim())}>
            Копировать конец периода
          </Dropdown.Item>
          <Dropdown.Item as="button" disabled={disabled} onClick={handleDeletePeriod}>
            Удалить период
          </Dropdown.Item>
          {period.migration_service_id && (
            <Dropdown.Item as="button" disabled={disabled} onClick={handleRemovePeriodMigrationID}>
              Удалить запись о миграции
            </Dropdown.Item>
          )}
          {!hideDateChange && (
            <Dropdown.Item as="button" disabled={disabled} onClick={() => setIsOpenDatePickerFrom(true)}>
              Изменить начало периода
            </Dropdown.Item>
          )}
          {!hideDateChange && (
            <Dropdown.Item as="button" disabled={disabled} onClick={() => setIsOpenDatePickerTo(true)}>
              Изменить окончание периода
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
      {!hideDateChange && isOpenDatePickerFrom && (
        <DateTimePicker
          inline
          // minDate={new Date(period.from)}
          maxDate={new Date(period.to)}
          selected={new Date(period.from)}
          timeInputLabel="Время:"
          showTimeInput
          showPreviousMonths
          onChange={handleChangePeriodDataFrom}
          onOutsideClick={() => setIsOpenDatePickerFrom(false)}
        />
      )}
      {!hideDateChange && isOpenDatePickerTo && (
        <DateTimePicker
          inline
          minDate={new Date(period.from)}
          selected={new Date(period.to)}
          timeInputLabel="Время:"
          showTimeInput
          onChange={handleChangePeriodDataEnd}
          onOutsideClick={() => setIsOpenDatePickerTo(false)}
        />
      )}
    </div>
  );
});

UsersUserPeriod.propTypes = {
  disabled: PropTypes.bool,
  period: PropTypes.object,
  hideDateChange: PropTypes.bool,
  onDeletePeriod: PropTypes.func,
  onChangePeriodDate: PropTypes.func,
  onRemovePeriodMigrationID: PropTypes.func,
};

export default UsersUserPeriod;
