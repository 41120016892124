import {
  CHANGE_PASS,
  GET_USER,
  LOGOUT,
  SIGN_IN,
  UPDATE_ADMIN_PRIV,
} from '../actions';

export const authDefaultState = {
  error: null,
  isFetching: false,
  user: null,
  token: null,
  response: null,
};

export default (state = authDefaultState, action) => {
  switch (action.type) {
    case GET_USER:
      return {
        ...state,
        plan: Object.keys(action.response.active_services)[0] || 'free',
        error: null,
        isFetching: false,
        user: action.response,
        response: null,
      };
    case `${GET_USER}_REQUEST`:
    case `${SIGN_IN}_REQUEST`:
      return {
        ...state,
        error: null,
        isFetching: true,
        response: null,
      };
    case CHANGE_PASS:
      return {
        ...state,
        token: action.response.token,
      };
    case SIGN_IN:
      return {
        ...state,
        error: null,
        isFetching: false,
        token: action.response.token,
        response: action.response,
      };
    case UPDATE_ADMIN_PRIV:
      return {
        ...state,
        user: {
          ...state.user,
          ...(state.user.id === +action.payload.id && { name: action.payload.name }),
        },
      };
    case `${SIGN_IN}_PAGE_UNLOADED`:
      return {
        ...state,
        error: null,
        isFetching: false,
        response: null,
      };
    case `${GET_USER}_FAILURE`:
    case `${SIGN_IN}_FAILURE`:
      return {
        ...state,
        error: action.response,
        isFetching: false,
        response: null,
      };
    case LOGOUT:
      return authDefaultState;
    default:
      return state;
  }
};
