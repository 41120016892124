import PropTypes from 'prop-types';
import React, { memo, useCallback, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Dropdown, Form } from 'react-bootstrap';
import cloneDeep from 'lodash/cloneDeep';

import CheckBox from '../../../UI/CheckBox/CheckBox';
import Spoiler from '../../../UI/Spoiler/Spoiler';

import {
  CURRENCIES, DAYS, GROUPS, PLANS, RANGES,
} from '../../../../constants/services';

import { smoothScrollTo } from '../../../../utils';

import { useCachedListener } from '../../../hooks';

const RENDER_API_FIELDS = [
  { label: 'Потоков:', name: 'threads' },
  { label: 'В очереди:', name: 'queue_limit' },
  { label: 'Запросов в минуту:', name: 'requests_per_minute_limit' },
];

const DEFAULT_FORM_DATA = {
  name: 'Тарифный план "Индивидуальный" для <email>',
  names: {
    ru: 'Тарифный план "Индивидуальный" для <email>',
    en: 'Individual plan for <email>',
  },
  days: 365,
  sum: 0,
  currency: 'RUB',
  type: 'plan',
  group: 'individual',
  comment: '',
  features: {
    seats: 10,
    render_api: {
      threads: 1,
      queue_limit: 30,
      requests_per_minute_limit: 50,
    },
  },
  discount_percent: 0,
  id: '',
  fastspring_plan_id: '',
};

const ServicesForm = ({
  defaultData, history, isEditMode, isHasPaidInvoices, services, onSubmit,
}) => {
  const [formData, setFormData] = useState(defaultData || DEFAULT_FORM_DATA);
  const [isRenderApiActive, setIsRenderApiActive] = useState(!!defaultData?.features?.render_api || !isEditMode);
  const [isSeats, setIsSeats] = useState(!!defaultData?.features?.seats || (isEditMode ? false : true)); // eslint-disable-line
  const [isNextID, setIsNextID] = useState(true);
  const [validFields, setValidFields] = useState({
    name_ru: true,
    name_en: true,
    comment: true,
  });

  const handleChangeData = (value) => setFormData({ ...formData, ...value });

  const handleFormSubmit = () => {
    const resultData = cloneDeep(formData);

    if (!resultData.id || resultData.id === '') delete resultData.id;
    if (resultData.sum === '') resultData.sum = 0;
    if (!isSeats && typeof resultData.features === 'object') resultData.features = { ...resultData.features, seats: 0 };
    if (!isRenderApiActive && typeof resultData.features === 'object') delete resultData.features.render_api;

    if (resultData.features?.render_api) {
      const { threads, queue_limit, requests_per_minute_limit } = resultData.features.render_api;

      resultData.features.render_api.threads = threads || DEFAULT_FORM_DATA.features.render_api.threads;
      resultData.features.render_api.queue_limit = queue_limit || DEFAULT_FORM_DATA.features.render_api.queue_limit;
      resultData.features.render_api.requests_per_minute_limit = requests_per_minute_limit || DEFAULT_FORM_DATA.features.render_api.requests_per_minute_limit;
    }

    if (isRenderApiActive && !resultData.features?.render_api) {
      resultData.features = {
        ...typeof resultData.features === 'object' ? resultData.features : {},
        render_api: DEFAULT_FORM_DATA.features.render_api,
      };
    }

    onSubmit(resultData);
  };

  const isNumberInvalid = (val) => !val || Number.isNaN(val) || val < 0;

  const cachedUpdateRenderAPI = useCachedListener((prop, e) => {
    const val = +e.target.value;

    const isInvalid = isNumberInvalid(val);

    setFormData({
      ...formData,
      features: {
        ...formData.features,
        render_api: { ...formData.features.render_api, [prop]: isInvalid ? 1 : Math.round(val) },
      },
    });
  });

  const handleToggleRenderApi = useCallback(() => setIsRenderApiActive(!isRenderApiActive), [isRenderApiActive]);

  return (
    <div className="service-form">
      <div className="service-form__row">
        <div className="service-form__label">Название (ru):</div>
        <Form.Control
          disabled={services.isFetching}
          placeholder=""
          value={formData.names.ru}
          isInvalid={!validFields.name_ru}
          onChange={(e) => {
            const isInvalid = !e.target.value;

            setValidFields({ ...validFields, name_ru: !isInvalid });

            handleChangeData({ names: { ...formData.names, ru: e.target.value }, name: e.target.value });
          }}
        />
      </div>
      <div className="service-form__row">
        <div className="service-form__label">Название (en):</div>
        <Form.Control
          disabled={services.isFetching}
          placeholder=""
          value={formData.names.en}
          isInvalid={!validFields.name_en}
          onChange={(e) => {
            const isInvalid = !e.target.value;

            setValidFields({ ...validFields, name_en: !isInvalid });

            handleChangeData({ names: { ...formData.names, en: e.target.value } });
          }}
        />
      </div>
      <div className="service-form__row">
        <div className="service-form__label">Период:</div>
        <Dropdown>
          <Dropdown.Toggle as="button" className="btn btn-light" disabled={isHasPaidInvoices || services.isFetching}>
            {DAYS[formData.days]}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {Object.keys(DAYS).map((type) => (
              <Dropdown.Item
                as="button"
                disabled={type === formData.days}
                eventKey={type}
                key={type}
                onSelect={(val) => handleChangeData({ days: +val })}
              >
                {DAYS[type]}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="service-form__row service-form__currency">
        <div className="service-form__label">Стоимость:</div>
        <Form.Control
          disabled={isHasPaidInvoices || services.isFetching}
          placeholder="0"
          value={String(formData.sum || '')}
          onChange={(e) => { // eslint-disable-line
            const value = +e.target.value;

            if (Number.isNaN(value) || value < 0) return handleChangeData({ sum: formData.sum });

            handleChangeData({ sum: value });
          }}
        />
        <Dropdown>
          <Dropdown.Toggle as="button" className="btn btn-light" disabled={isHasPaidInvoices || services.isFetching}>
            {CURRENCIES[formData.currency]}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {Object.keys(CURRENCIES).map((type) => (
              <Dropdown.Item
                as="button"
                disabled={type === formData.currency}
                eventKey={type}
                key={type}
                onSelect={(val) => handleChangeData({ currency: val })}
              >
                {CURRENCIES[type]}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="service-form__row">
        <div className="service-form__label">Комментарий:</div>
        <Form.Control
          disabled={services.isFetching}
          placeholder=""
          value={formData.comment || ''}
          isInvalid={!validFields.comment}
          onChange={(e) => {
            const isInvalid = e.target.value.length > 300;

            setValidFields({ ...validFields, comment: !isInvalid });

            handleChangeData({ comment: e.target.value });
          }}
        />
      </div>
      <Spoiler
        className="service-form__spoiler"
        collapsed={false}
        title="Возможности"
        content={(
          <>
            <div className="service-form__row service-form__team">
              <div className="service-form__label">
                <CheckBox
                  className="service-form__checkbox"
                  checked={isSeats}
                  disabled={isHasPaidInvoices || services.isFetching}
                  id="team"
                  isFetching={false}
                  onChange={() => {
                    setIsSeats(!isSeats);

                    if (!isSeats === false) handleChangeData({ features: { ...formData?.features, seats: isEditMode ? defaultData?.features?.seats || '' : 0 } });
                  }}
                >
                  Команда:
                </CheckBox>
              </div>
              <Form.Control
                disabled={isHasPaidInvoices || !isSeats || services.isFetching}
                placeholder="0"
                value={String(formData?.features?.seats || '')}
                onChange={(e) => { // eslint-disable-line
                  const value = +e.target.value;

                  if (Number.isNaN(value) || value < 0) return handleChangeData({ features: { ...formData?.features, seats: 0 } });

                  handleChangeData({ features: { ...formData?.features, seats: value } });
                }}
              />
              <span>участников</span>
            </div>
            <div className="service-form__column">
              <div className="service-form__label">
                <CheckBox
                  className="service-form__checkbox"
                  checked={isRenderApiActive}
                  disabled={isHasPaidInvoices || services.isFetching}
                  id="render-api"
                  isFetching={false}
                  onChange={handleToggleRenderApi}
                >
                  Render API:
                </CheckBox>
              </div>
              <div className="service-form__column-block">
                {RENDER_API_FIELDS.map(({ name, label }) => {
                  const fieldVal = formData?.features?.render_api?.[name] || DEFAULT_FORM_DATA.features.render_api[name];

                  return (
                    <div className="service-form__row" key={name}>
                      <div className="service-form__label">{label}</div>
                      <Form.Control
                        disabled={isHasPaidInvoices || !isRenderApiActive || services.isFetching}
                        type="number"
                        min={1}
                        value={fieldVal}
                        isInvalid={!fieldVal}
                        onChange={cachedUpdateRenderAPI(name)}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        )}
      />
      <Spoiler
        className="service-form__spoiler"
        collapsed
        title="Служебные параметры"
        content={(
          <>
            <div className="service-form__row service-form__id">
              <div className="service-form__label">id:</div>
              <Form.Control
                disabled={isHasPaidInvoices || (!isEditMode && isNextID) || services.isFetching}
                placeholder=""
                value={String(formData.id)}
                onChange={(e) => { // eslint-disable-line
                  const value = +e.target.value;

                  if (Number.isNaN(value) || value < 0) return handleChangeData({ id: formData.id });

                  handleChangeData({ id: value });
                }}
              />
              {!isEditMode && (
                <CheckBox
                  className="service-form__checkbox"
                  checked={isNextID}
                  disabled={isHasPaidInvoices || services.isFetching}
                  id="next-id"
                  isFetching={false}
                  onChange={() => {
                    setIsNextID(!isNextID);

                    if (!isNextID === true) handleChangeData({ id: isEditMode ? defaultData.id : '' });
                  }}
                >
                  {`Следующий ${RANGES[formData.group]}`}
                </CheckBox>
              )}
            </div>
            <div className="service-form__row service-form__plan">
              <div className="service-form__label">type:</div>
              <Dropdown>
                <Dropdown.Toggle as="button" className="btn btn-light" disabled={isHasPaidInvoices || services.isFetching}>
                  {PLANS[formData.type]}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {Object.keys(PLANS).map((type) => (
                    <Dropdown.Item
                      as="button"
                      disabled={type === formData.type}
                      eventKey={type}
                      key={type}
                      onSelect={(val) => handleChangeData({ type: val })}
                    >
                      {PLANS[type]}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="service-form__row service-form__discount">
              <div className="service-form__label">discount_percent:</div>
              <Form.Control
                disabled={isHasPaidInvoices || services.isFetching}
                placeholder="0"
                value={String(formData.discount_percent || '')}
                onChange={(e) => { // eslint-disable-line
                  const value = +e.target.value;

                  if (Number.isNaN(value) || value < 0 || value > 100) return handleChangeData({ discount_percent: formData.discount_percent });

                  handleChangeData({ discount_percent: value });
                }}
              />
            </div>
            <div className="service-form__row service-form__group">
              <div className="service-form__label">group:</div>
              <Dropdown>
                <Dropdown.Toggle as="button" className="btn btn-light" disabled={isHasPaidInvoices || services.isFetching}>
                  {GROUPS[formData.group]}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {Object.keys(GROUPS).map((type) => (
                    <Dropdown.Item
                      as="button"
                      disabled={type === formData.group}
                      eventKey={type}
                      key={type}
                      onSelect={(val) => handleChangeData({ group: val })}
                    >
                      {GROUPS[type]}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="service-form__row service-form__fastspring">
              <div className="service-form__label">fastspring_plan_id:</div>
              <Form.Control
                disabled={isHasPaidInvoices || services.isFetching}
                placeholder=""
                value={formData.fastspring_plan_id || ''}
                onChange={(e) => handleChangeData({ fastspring_plan_id: e.target.value })}
              />
            </div>
          </>
        )}
      />
      <div className="service-form__footer">
        <Button className="service-form__submit-btn" onClick={handleFormSubmit} variant="success" size="sm" disabled={services.isFetching || Object.values(validFields).some((value) => !value)}>
          {isEditMode ? 'Изменить тариф' : 'Создать тариф'}
        </Button>
        <div // eslint-disable-line
          role="button"
          onClick={() => {
            smoothScrollTo(document.documentElement, 0);

            history.goBack();
          }}
          to="/services"
          className="service-form__link"
        >
          Назад
        </div>
      </div>
    </div>
  );
};

ServicesForm.propTypes = {
  defaultData: PropTypes.object,
  history: PropTypes.object,
  isEditMode: PropTypes.bool,
  isHasPaidInvoices: PropTypes.bool,
  services: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default memo(withRouter(ServicesForm));
