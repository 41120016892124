import PropTypes from 'prop-types';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import queryString from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';

import { requestCanceler } from '../../../../middlewares/api';

import { copyToClipboard, toast } from '../../../../utils';

import { GET_USERS } from '../../../../actions';

import errorMessages from '../../../../constants/errors';

import successMessages from '../../../../constants/successMessages';

import View from '../../../Layout/View/View';

import NoMedia from '../../../UI/NoMedia/NoMedia';

import UsersSearch from '../../Users/UsersSearch/UsersSearch';

class Users extends React.PureComponent {
  state = {
    signin: [],
  };

  requestCancelers = [];

  limit = 30;

  componentDidMount() {
    const { location: { search }, users } = this.props;

    const isSearch = queryString.parse(search)?.search;

    if (Boolean(isSearch) || !users.docs.length) this.getUsers({ page: 1 });

    window.addEventListener('online', this.checkOnlineState);
  }

  componentDidUpdate(prevProps) {
    const { location, users } = this.props;
    const { error } = users;

    if (location.search !== prevProps.location.search) this.getUsers({ isSearch: true });

    if (error && error !== prevProps.users.error) {
      let msg;
      if (['Network Error', 'user_not_found', 'email_incorrect', 'email_exist', 'permission_denied', 'server_error', 'admin_privileges_required'].includes(error.message)) {
        msg = errorMessages[error.message];
      } else if (error.message === 'incorrect_data' && error.errors) {
        msg = (
          <>
            {error.errors.map((item) => (
              <div key={item.video_id}>
                {`Видео #${item.video_id} не принадлежит пользователю #${item.user_id} или не существует`}
                <br />
              </div>
            ))}
          </>
        );
      } else {
        msg = error.message;
      }

      toast('error', msg);
    }
  }

  componentWillUnmount() {
    const { onUnload } = this.props;

    this.requestCancelers.forEach((canceler) => canceler.cancelRequest());

    window.removeEventListener('online', this.checkOnlineState);

    onUnload();
  }

  getUsers = ({ page = 1, isSearch = false }) => {
    const { onGetUsers, location } = this.props;

    const requestParams = { limit: this.limit, page };

    if (location.search) {
      Object.assign(requestParams, queryString.parse(location.search));
    }

    const requestGetUsersCanceler = requestCanceler();

    this.requestCancelers.push(requestGetUsersCanceler);

    onGetUsers(requestParams, isSearch, requestGetUsersCanceler.token).catch(() => {});
  };

  loadMore = () => {
    const { users } = this.props;

    if (users.isFetching) return;

    this.getUsers({ page: users.page + 1 });
  };

  handleSearchUsers = ({ query, search_type }) => {
    const { history, location } = this.props;

    const queryStr = query ? `/elama?search=${query}&search_type=${search_type}` : '/elama';

    if (
      queryString.parse(location.search)?.search === query
      && queryString.parse(location.search)?.search_type === search_type
    ) {
      this.getUsers({ isSearch: true });
    } else {
      history.push(queryStr);
    }
  };

  checkOnlineState = () => {
    const { lastActionType } = this.props.users;

    if (navigator.onLine && lastActionType === `${GET_USERS}_REQUEST`) this.loadMore();
  };

  handleClickUserSignIn = (data) => {
    const { onUserSignIn } = this.props;

    const { signin } = this.state;

    this.setState({
      signin: !signin.includes(data.id) ? [signin, data.id] : signin,
    });

    const requestUserSignInCanceler = requestCanceler();

    this.requestCancelers.push(requestUserSignInCanceler);

    onUserSignIn({ elama_user_id: data.oauth_provider_userid }, requestUserSignInCanceler.token)
      .then(({ url }) => copyToClipboard(url))
      .then(() => toast('success', successMessages.signin))
      .finally(() => this.setState({
        signin: signin.filter((id) => id !== data.id),
      }));
  };

  render() {
    const { users } = this.props;

    const { error, isFetching } = users;

    const { signin } = this.state;

    return (
      <View
        errorMessage={errorMessages[error?.message]}
        isError={error && ['admin_privileges_required', 'first_request_failed'].includes(error.message)}
        isFetching={isFetching}
        title="Пользователи eLama"
        viewClass="users"
        content={(
          <>
            <UsersSearch onSearch={this.handleSearchUsers} isElama />
            <InfiniteScroll
              dataLength={users.docs.length}
              hasMore={users.page < users.pages}
              loader={null}
              next={this.loadMore}
              style={{ overflow: 'visible' }}
            >
              {users.docs.length > 0 && (
                <div className="users__list">
                  <div className="user__header">
                    <div className="user__id">Elama ID</div>
                    <div className="user__email">
                      <strong>Контактный E-Mail</strong>
                    </div>
                    <div className="user__id">SUPA ID</div>
                  </div>
                  {users.docs.map((user) => (
                    <div className="users__list-user" key={user._id}>
                      <div className="user">
                        <div className="user__header">
                          <div className="user__id">{`#${user.oauth_provider_userid}`}</div>
                          <div className="user__email">
                            {user.oauth_provider_email}
                          </div>
                          <div className="user__id">{`#${user.id}`}</div>
                          <div className="user__options">
                            <Button
                              disabled={signin.includes(user.id)}
                              size="sm"
                              variant="info"
                              onClick={() => this.handleClickUserSignIn(user)}
                            >
                              <FontAwesomeIcon icon={['fas', 'sign-in-alt']} />
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {!users.docs.length && !isFetching && (
                <div className="users__no-media">
                  <NoMedia
                    caption="Пользователей не найдено"
                    icon={<FontAwesomeIcon icon={['fas', 'exclamation-circle']} />}
                  />
                </div>
              )}
            </InfiniteScroll>
          </>
        )}
      />
    );
  }
}

Users.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  onGetUsers: PropTypes.func.isRequired,
  onUserSignIn: PropTypes.func.isRequired,
  onUnload: PropTypes.func.isRequired,
  users: PropTypes.object.isRequired,
};

export default Users;
