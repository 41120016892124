import PropTypes from 'prop-types';
import React from 'react';

import View from '../../../Layout/View/View';

import ServicesForm from '../ServicesForm/ServicesForm';

import { requestCanceler } from '../../../../middlewares/api';

import errorMessages from '../../../../constants/errors';

import { smoothScrollTo, toast } from '../../../../utils';

class ServicesEdit extends React.PureComponent {
  state = {
    data: null,
  };

  requestCancelers = [];

  componentDidMount() {
    const { match: { params: { id } }, onGetService } = this.props;

    const requestGetServiceCanceler = requestCanceler();

    this.requestCancelers.push(requestGetServiceCanceler);

    onGetService(id, requestGetServiceCanceler.token)
      .then((res) => this.setState({ data: res }));
  }

  componentDidUpdate(prevProps) {
    const { services: { error } } = this.props;

    if (error && error !== prevProps.services.error) {
      let message = errorMessages[error.message] || error.message;

      if (error.message === 'service_already_exists' && error.params) {
        message = errorMessages[error.message]
          .replace('(id)', error.params.id)
          .replace('(freeID)', error.params.freeID || '');
      }

      toast('error', message);
    }
  }

  componentWillUnmount() {
    const { onUnload } = this.props;

    this.requestCancelers.forEach((canceler) => canceler.cancelRequest());

    onUnload();
  }

  handleUpdate = (data) => {
    const { history, match: { params: { id } }, onUpdateService } = this.props;

    const requestUpdateServiceCanceler = requestCanceler();

    this.requestCancelers.push(requestUpdateServiceCanceler);

    onUpdateService(id, data, requestUpdateServiceCanceler.token)
      .then(() => {
        toast('success', 'Тариф изменён!');

        smoothScrollTo(document.documentElement, 0);

        history.push('/services?sort=desc&group=individual');
      });
  };

  render() {
    const { services } = this.props;
    const { data } = this.state;

    return (
      <View
        errorMessage={errorMessages[services?.error?.message]}
        isError={services.error && services.error?.message === 'service_not_found'}
        isFetching={services.isFetching}
        preloaderCaption="Загружаем..."
        title="Изменить тариф"
        viewClass="service-edit"
        content={data ? (
          <ServicesForm defaultData={data} isEditMode isHasPaidInvoices={data?.has_paid_invoices} services={services} onSubmit={this.handleUpdate} />
        ) : null}
      />
    );
  }
}

ServicesEdit.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  services: PropTypes.object.isRequired,
  onGetService: PropTypes.func.isRequired,
  onUpdateService: PropTypes.func.isRequired,
  onUnload: PropTypes.func.isRequired,
};

export default ServicesEdit;
