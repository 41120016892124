import {
  CHANGE_TEMPLATE,
  CREATE_TEMPLATE,
  GET_TEMPLATES,
  REMOVE_TEMPLATE,
  RESET_TEMPLATES,
  TEMPLATE_ADD_READY_VARIATION,
  TEMPLATE_CHANGE_READY_VARIATION,
  TEMPLATE_CREATE_VARIATION,
  TEMPLATE_REMOVE_VARIATION,
  TEMPLATE_UPDATE_VARIATION,
  TEMPLATE_UPDATE_READY_VARIATION,
  TEMPLATE_SET_TDR_NOTICE,
  TEMPLATE_REMOVE_TDR_NOTICE,
  TEMPLATE_SEND_TDR_NOTICE,
  UPDATE_TEMPLATE,
} from '../../actions';

const defaultState = {
  docs: [],
  error: null,
  isFetching: false,
  lastActionType: null,
  page: 0,
  pages: 1,
  limit: 20,
  isTemplateChanging: {
    create: null,
    remove: {},
    update: {},
    createVariation: false,
    removeVariation: {},
    updateVariation: false,
  },
  notReadyVariations: [],
  notReadyUpdatingVariations: [],
};

function returnError(response, state) {
  if (response.message && state.page === 0) return { message: 'first_request_failed' };
  if (response.message) return response;

  return { message: 'admin_privileges_required' };
}

const setIsTemplateChanging = (isTemplateChanging, props) => ({
  ...isTemplateChanging,
  ...props,
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case `${GET_TEMPLATES}_REQUEST`:
      return {
        ...state,
        docs: [],
        error: null,
        isFetching: true,
        lastActionType: action.type,
      };
    case `${CREATE_TEMPLATE}_REQUEST`:
      return {
        ...state,
        error: null,
        isTemplateChanging: setIsTemplateChanging(state.isTemplateChanging, { create: true }),
      };
    case `${UPDATE_TEMPLATE}_REQUEST`:
      return {
        ...state,
        error: null,
        isTemplateChanging: setIsTemplateChanging(state.isTemplateChanging, { update: { ...state.isTemplateChanging.update, [action.payload.id]: true } }),
      };
    case `${REMOVE_TEMPLATE}_REQUEST`:
      return {
        ...state,
        error: null,
        isTemplateChanging: setIsTemplateChanging(state.isTemplateChanging, { remove: { ...state.isTemplateChanging.remove, [action.payload.id]: true } }),
      };
    case `${TEMPLATE_CREATE_VARIATION}_REQUEST`:
      return {
        ...state,
        error: null,
        isTemplateChanging: setIsTemplateChanging(state.isTemplateChanging, { createVariation: true }),
      };
    case `${TEMPLATE_UPDATE_VARIATION}_REQUEST`:
      return {
        ...state,
        error: null,
        isTemplateChanging: setIsTemplateChanging(state.isTemplateChanging, { updateVariation: true }),
      };
    case `${TEMPLATE_REMOVE_VARIATION}_REQUEST`:
      return {
        ...state,
        error: null,
        isTemplateChanging: setIsTemplateChanging(state.isTemplateChanging, { removeVariation: { ...state.isTemplateChanging.removeVariation, [action.payload.variationId]: true } }),
      };
    case `${TEMPLATE_SEND_TDR_NOTICE}_REQUEST`:
      return {
        ...state,
        error: null,
      };
    case GET_TEMPLATES:
      // eslint-disable-next-line
      const notReady = [];

      action.response.docs.forEach((item) => {
        item.variations.forEach((variation) => {
          if (variation.state !== 'done') notReady.push(variation);
        });
      });

      // eslint-disable-next-line
      const docs = action.response.docs.map((tpl) => ({ ...tpl, categories: tpl.categories.map((cat) => ({ ...cat, selected: true }))}))

      return {
        ...state,
        ...action.response,
        docs: action.response.page === 1
          ? docs
          : [...state.docs, ...docs],
        isFetching: false,
        lastActionType: null,
        notReadyVariations: [...state.notReadyVariations, ...notReady],
      };
    case CHANGE_TEMPLATE:
      return {
        ...state,
        docs: state.docs.map((item) => {
          if (item.id !== action.payload.id) return item;
          return {
            ...item,
            ...action.payload.params,
            isUnsaved: true,
          };
        }),
      };
    case CREATE_TEMPLATE:
      return {
        ...state,
        docs: [
          {
            ...action.response,
            categories: action.response.categories.map((cat) => ({ ...cat, selected: true })),
          },
          ...state.docs,
        ],
        isTemplateChanging: setIsTemplateChanging(state.isTemplateChanging, { create: false }),
      };
    case UPDATE_TEMPLATE:
      return {
        ...state,
        docs: state.docs.map((item) => {
          if (item._id !== action.payload.id) return item;

          return {
            ...action.response,
            categories: action.response.categories.map((cat) => ({ ...cat, selected: true })),
            variations: item?.variations ? item.variations : [],
          };
        }),
        isTemplateChanging: setIsTemplateChanging(state.isTemplateChanging, { update: { ...state.isTemplateChanging.update, [action.payload.id]: false } }),
      };
    case REMOVE_TEMPLATE:
      return {
        ...state,
        docs: state.docs.filter((item) => item._id !== action.payload.id),
        isTemplateChanging: setIsTemplateChanging(state.isTemplateChanging, { remove: { ...state.isTemplateChanging.remove, [action.payload.id]: false } }),
      };
    case TEMPLATE_ADD_READY_VARIATION:
      return {
        ...state,
        docs: state.docs.map((item) => {
          if (item.id !== action.payload.templateId) return item;
          return {
            ...item,
            variations: item?.variations ? [...item.variations, action.payload.variation] : [action.payload.variation],
          };
        }),
        notReadyVariations: [...state.notReadyVariations, action.payload.variation],
      };
    case TEMPLATE_UPDATE_READY_VARIATION:
      return {
        ...state,
        docs: state.docs.map((item) => {
          if (item.id !== action.payload.templateId) return item;

          return {
            ...item,
            variations: item.variations.map((variation) => {
              if (variation.id !== action.payload.oldVariationId) return variation;

              return action.payload.newVariation;
            }),
          };
        }),
        notReadyUpdatingVariations: [...state.notReadyUpdatingVariations, action.payload.newVariation],
      };
    case TEMPLATE_CHANGE_READY_VARIATION:
      return {
        ...state,
        docs: state.docs.map((item) => {
          if (item.id !== action.payload.templateId) return item;

          return {
            ...item,
            variations: item.variations.map((variation) => {
              if (variation._id !== action.payload.variation._id) return variation;

              return action.payload.variation;
            }),
          };
        }),
        notReadyVariations: state.notReadyVariations.filter((item) => item.id !== action.payload.variation.id),
      };
    case TEMPLATE_CREATE_VARIATION:
      return {
        ...state,
        isTemplateChanging: setIsTemplateChanging(state.isTemplateChanging, { createVariation: false }),
      };
    case TEMPLATE_UPDATE_VARIATION:
      return {
        ...state,
        isTemplateChanging: setIsTemplateChanging(state.isTemplateChanging, { updateVariation: false }),
      };
    case TEMPLATE_REMOVE_VARIATION:
      return {
        ...state,
        docs: state.docs.map((item) => {
          if (item.id !== action.payload.templateId) return item;

          return {
            ...item,
            variations: item.variations.filter((variation) => variation.id !== action.payload.variationId),
          };
        }),
        isTemplateChanging: setIsTemplateChanging(state.isTemplateChanging, { removeVariation: { ...state.isTemplateChanging.removeVariation, [action.payload.variationId]: false } }),
      };
    case TEMPLATE_SET_TDR_NOTICE:
      return {
        ...state,
        docs: state.docs.map((item) => {
          if (item.id !== action.payload.templateId) return item;

          return {
            ...item,
            notifications: item?.notifications ? [...item.notifications, action.payload.notification] : [action.payload.notification],
          };
        }),
      };
    case TEMPLATE_REMOVE_TDR_NOTICE:
      return {
        ...state,
        docs: state.docs.map((item) => {
          if (item.id !== action.payload.templateId) return item;

          return {
            ...item,
            notifications: item?.notifications ? item.notifications.filter((notification) => notification.type !== action.payload.slug) : [],
          };
        }),
      };
    case TEMPLATE_SEND_TDR_NOTICE:
      return {
        ...state,
        docs: state.docs.map((item) => {
          if (item.id !== action.payload.templateId) return item;

          return {
            ...item,
            notifications: [],
          };
        }),
      };
    case `${GET_TEMPLATES}_FAILURE`:
      return {
        ...state,
        error: returnError(action.response, state),
        isFetching: false,
      };
    case `${CREATE_TEMPLATE}_FAILURE`:
      return {
        ...state,
        error: action.response,
        isTemplateChanging: setIsTemplateChanging(state.isTemplateChanging, { create: false }),
      };
    case `${UPDATE_TEMPLATE}_FAILURE`:
      return {
        ...state,
        error: action.response,
        isTemplateChanging: setIsTemplateChanging(state.isTemplateChanging, { update: { ...state.isTemplateChanging.update, [action.payload.id]: false } }),
      };
    case `${REMOVE_TEMPLATE}_FAILURE`:
      return {
        ...state,
        error: action.response,
        isTemplateChanging: setIsTemplateChanging(state.isTemplateChanging, { remove: { ...state.isTemplateChanging.remove, [action.payload.id]: false } }),
      };
    case `${TEMPLATE_CREATE_VARIATION}_FAILURE`:
      return {
        ...state,
        error: action.response,
        isTemplateChanging: setIsTemplateChanging(state.isTemplateChanging, { createVariation: false }),
      };
    case `${TEMPLATE_UPDATE_VARIATION}_FAILURE`:
      return {
        ...state,
        error: action.response,
        isTemplateChanging: setIsTemplateChanging(state.isTemplateChanging, { updateVariation: false }),
      };
    case `${TEMPLATE_REMOVE_VARIATION}_FAILURE`:
      return {
        ...state,
        error: action.response,
        isTemplateChanging: setIsTemplateChanging(state.isTemplateChanging, { removeVariation: { ...state.isTemplateChanging.removeVariation, [action.payload.variationId]: false } }),
      };
    case `${TEMPLATE_SEND_TDR_NOTICE}_FAILURE`:
      return {
        ...state,
        error: action.response,
      };
    case RESET_TEMPLATES:
      return defaultState;
    default:
      return state;
  }
};
