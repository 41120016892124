import PropTypes from 'prop-types';
import React, { memo, useState } from 'react';
import { nanoid } from 'nanoid';
import { Button, Dropdown, Form } from 'react-bootstrap';

import CheckBox from '../../../UI/CheckBox/CheckBox';

import MailingFilter from '../MailingFilter/MailingFilter';

import { validateEmail } from '../../../../utils/index';

const MailingCreateListTab = ({
  isFetching, filterActions, filterLists, onDownloadList,
}) => {
  const [state, setState] = useState({
    extraFilters: {
      lang: 'ru',
      removeMailRuUsers: true,
    },
    filters: [],
    email: '',
  });

  const handleAddFilter = () => {
    setState((prev) => ({
      ...prev,
      filters: [
        ...prev.filters,
        { action: 'include', list: 'all' },
      ],
    }));
  };

  const handleRemoveFilter = (index) => {
    setState((prev) => ({
      ...prev,
      filters: prev.filters.filter((item, i) => i !== index),
    }));
  };

  const handleChangeExtraFilters = (props) => {
    setState((prev) => ({
      ...prev,
      extraFilters: {
        ...prev.extraFilters,
        ...props,
      },
    }));
  };

  const handleChangeFilter = (index, data) => {
    setState((prev) => ({
      ...prev,
      filters: prev.filters.map((item, i) => {
        if (i !== index) return item;

        return { ...item, ...data };
      }),
    }));
  };

  const handleChangeEmail = (value) => {
    setState((prev) => ({
      ...prev,
      email: value,
    }));
  };

  const handleDownloadList = () => {
    onDownloadList({ ...state });

    handleChangeEmail('');
  };

  return (
    <>
      {state.filters.length ? state.filters.map((filter, index) => (
        <MailingFilter
          action={filter.action}
          filterActions={filterActions}
          filterLists={filterLists}
          key={nanoid()}
          list={filter.list}
          index={index}
          onChangeFilter={handleChangeFilter}
          onRemoveFilter={handleRemoveFilter}
        />
      )) : null}
      {/* eslint-disable-next-line */}
      <div className="mailing__add-filter" onClick={handleAddFilter} role="button">
        Добавить фильтр
      </div>
      {state.filters.length ? (
        <>
          <div className="mailing__extra">
            <div className="mailing__extra-filter">
              <span>Язык пользователей:</span>
              <Dropdown>
                <Dropdown.Toggle as="button" className="btn btn-light">
                  {state.extraFilters.lang === 'ru' ? 'Русский' : 'Английский'}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    as="button"
                    eventKey={state.extraFilters.lang === 'ru' ? 'en' : 'ru'}
                    onSelect={(lang) => handleChangeExtraFilters({ lang })}
                  >
                    {state.extraFilters.lang === 'ru' ? 'Английский' : 'Русский'}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="mailing__extra-filter">
              <span>Удалить адреса mail.ru:</span>
              <CheckBox
                className="mailing__checkbox"
                checked={state.extraFilters.removeMailRuUsers}
                id="mailing"
                onChange={(removeMailRuUsers) => handleChangeExtraFilters({ removeMailRuUsers })}
              />
            </div>
          </div>
          <Form.Control
            className="mailing__email"
            placeholder="Введите E-Mail, куда следует отправить список"
            type="text"
            value={state.email}
            onChange={(event) => handleChangeEmail(event.target.value.toLowerCase())}
          />
          <Button className="mailing__download" disabled={isFetching || !state.email || !validateEmail(state.email.trim())} onClick={handleDownloadList}>
            Сформировать список и отправить на E-Mail
          </Button>
        </>
      ) : null}
    </>
  );
};

MailingCreateListTab.propTypes = {
  filterActions: PropTypes.object.isRequired,
  filterLists: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onDownloadList: PropTypes.func.isRequired,
};

export default memo(MailingCreateListTab);
