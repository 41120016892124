import { GET_VIDEOS, RESET_VIDEOS } from '../actions';

const defaultState = {
  docs: [],
  error: null,
  isFetching: false,
};

function returnError(response) {
  if (response.message) return { message: 'first_request_failed' };

  return { message: 'admin_privileges_required' };
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case `${GET_VIDEOS}_REQUEST`:
      return {
        ...state,
        docs: [],
        error: null,
        isFetching: true,
      };
    case GET_VIDEOS:
      return {
        ...state,
        docs: action.response,
        isFetching: false,
      };
    case `${GET_VIDEOS}_FAILURE`:
      return {
        ...state,
        error: returnError(action.response),
        isFetching: false,
      };
    case RESET_VIDEOS:
      return defaultState;
    default:
      return state;
  }
};
