import React, {
  memo, useEffect, useMemo, useRef, useState,
} from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { getLandingPageCategories, getLandingPages } from '../../../../actions';

import Preloader from '../../../UI/Preloader/Preloader';

import withRequest from '../../../HOCs/withRequest';

import PageCategory from './PageCategory/PageCategory';
import Page from './Page/Page';

import './LandingPages.scss';

const LandingPages = memo((props) => {
  const {
    dispatch, createCanceler, lang, onUnload,
  } = props;
  const history = useHistory();

  const categories = useSelector(({ landing }) => landing.categories.docs);
  const pages = useSelector(({ landing }) => landing.pages.docs);

  const pageWithoutCategories = useMemo(() => pages.filter((p) => !p.category_id), [pages]);

  const [isDataFetching, setIsDataFetching] = useState(true);

  const isUnmounted = useRef(false);

  useEffect(() => () => {
    isUnmounted.current = true;

    onUnload();
  }, [onUnload]);

  useEffect(() => {
    const getPagesReq = createCanceler();
    const getPageCategoriesReq = createCanceler();

    const fetchPromises = [
      dispatch(getLandingPages({ lang }, getPagesReq.token)),
      dispatch(getLandingPageCategories({ lang }, getPageCategoriesReq.token)),
    ];

    Promise.all(fetchPromises)
      .finally(() => {
        if (!isUnmounted.current) setIsDataFetching(false);
      });
  }, [lang]); // eslint-disable-line

  const handleClickAddCategory = () => history.push('/landing/categories/create');

  const handleClickAddPage = () => history.push('/landing/pages/create');

  if (isDataFetching) return <Preloader />;

  return (
    <div className="landing-pages">
      <div className="landing-pages__actions">
        <Button variant="warning" className="landing__section-action" onClick={handleClickAddCategory}>
          Добавить категорию
        </Button>
        <Button
          className="landing__section-action"
          variant="success"
          onClick={handleClickAddPage}
        >
          Добавить страницу
        </Button>
      </div>

      {pageWithoutCategories.length > 0 && (
        <div className="landing-pages__pages">
          {pageWithoutCategories.map((page) => (
            <Page key={page._id} data={page} />
          ))}
        </div>
      )}

      {categories.length > 0 && (
        <div className="landing-pages__categories">
          {categories.map((category) => (
            <PageCategory key={category._id} category={category} />
          ))}
        </div>
      )}

      {categories.length === 0 && pageWithoutCategories.length === 0 && (
        <div className="landing__info">
          Список пуст
        </div>
      )}
    </div>
  );
});

LandingPages.propTypes = {
  createCanceler: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  lang: PropTypes.string,
  onUnload: PropTypes.func.isRequired,
};

export default withRequest(LandingPages);
