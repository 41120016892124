import React, {
  memo, useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import errorMessages from '../../../../constants/errors';

import { toast } from '../../../../utils';

import {
  getCompany, createCompanyComment, deleteCompanyComment, updateCompany,
} from '../../../../actions';

import withRequest from '../../../HOCs/withRequest';

import View from '../../../Layout/View/View';

import CompaniesForm from '../CompaniesForm/CompaniesForm';

const CompaniesEdit = ({ match: { params: { id } }, createCanceler, dispatch }) => {
  const companies = useSelector(({ companies: companiesStore }) => companiesStore);

  const [isDataFetching, setIsDataFetching] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    setIsDataFetching(true);

    const getCompanyRequest = createCanceler();

    dispatch(getCompany(id, getCompanyRequest.token))
      .then((res) => {
        setIsDataFetching(false);

        setData(res);
      })
      .catch(() => setIsDataFetching(false));
  }, []); // eslint-disable-line

  const handleDeleteCompanyComment = useCallback((commentID) => {
    const deleteCompanyCommentRequestCanceler = createCanceler();

    dispatch(deleteCompanyComment(id, commentID, deleteCompanyCommentRequestCanceler.token))
      .then(() => {
        toast('success', 'Комментарий удалён!');

        setData({
          ...data,
          comments: data.comments.filter((comment) => comment._id !== commentID),
        });
      })
      .catch((error) => {
        const message = errorMessages[error.message] || error.message;

        toast('error', message);
      });
  }, [createCanceler, dispatch, data, id]);

  const handleCreateCompanyComment = useCallback((commentText, successCallback) => {
    const createCompanyCommentRequestCanceler = createCanceler();

    dispatch(createCompanyComment(id, commentText, createCompanyCommentRequestCanceler.token))
      .then((res) => {
        toast('success', 'Комментарий создан!');

        setData({
          ...data,
          comments: res,
        });

        successCallback();
      })
      .catch((error) => {
        const message = errorMessages[error.message] || error.message;

        toast('error', message);
      });
  }, [createCanceler, dispatch, data, id]);

  const handleEdit = useCallback((formData, successCallback) => {
    const editCompanyRequestCanceler = createCanceler();

    dispatch(updateCompany(id, formData, editCompanyRequestCanceler.token))
      .then(() => {
        toast('success', 'Контрагент успешно изменён!');

        successCallback();
      })
      .catch((error) => {
        const message = errorMessages[error.message] || error.message;

        toast('error', message);
      });
  }, [createCanceler, dispatch, id]);

  return (
    <View
      isFetching={isDataFetching}
      preloaderCaption="Загружаем..."
      title="Редактировать контрагента"
      viewClass="company-edit"
      content={data ? (
        <CompaniesForm
          isEditMode
          defaultData={data}
          comments={data?.comments}
          companies={companies}
          onCreateCompanyComment={handleCreateCompanyComment}
          onDeleteCompanyComment={handleDeleteCompanyComment}
          onSubmit={handleEdit}
        />
      ) : null}
    />
  );
};

CompaniesEdit.propTypes = {
  match: PropTypes.object.isRequired,
  createCanceler: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  // onUnload: PropTypes.func.isRequired,
};

export default memo(withRequest(CompaniesEdit));
