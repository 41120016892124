import {
  CREATE_PAGE_CATEGORY,
  DELETE_PAGE_CATEGORY,
  GET_LANDING_PAGE_CATEGORIES,
  GET_PAGE_CATEGORY,
  UPDATE_PAGE_CATEGORY,
} from '../../../actions';

import { PREFIX_ACTION_TYPE_LANDING_CATEGORIES } from '../../../constants/common';

function returnError(response) {
  if (response.message || response.errors) return response;

  return { message: 'admin_privileges_required' };
}

const getFullType = (type) => PREFIX_ACTION_TYPE_LANDING_CATEGORIES + type;

export default (state, action) => {
  switch (action.type) {
    case `${getFullType(GET_LANDING_PAGE_CATEGORIES)}_REQUEST`:
    case `${getFullType(GET_PAGE_CATEGORY)}_REQUEST`:
    case `${getFullType(CREATE_PAGE_CATEGORY)}_REQUEST`:
    case `${getFullType(DELETE_PAGE_CATEGORY)}_REQUEST`:
    case `${getFullType(UPDATE_PAGE_CATEGORY)}_REQUEST`:
      return {
        ...state,
        error: null,
        isFetching: true,
      };
    case getFullType(GET_PAGE_CATEGORY):
      return {
        ...state,
        isFetching: false,
      };
    case getFullType(GET_LANDING_PAGE_CATEGORIES):
      return {
        ...state,
        categories: { ...state.categories, docs: action.response },
        isFetching: false,
      };
    case getFullType(CREATE_PAGE_CATEGORY):
      return {
        ...state,
        categories: {
          ...state.categories,
          docs: [action.response, ...state.categories.docs],
        },
        isFetching: false,
      };
    case getFullType(DELETE_PAGE_CATEGORY):
      return {
        ...state,
        categories: {
          ...state.categories,
          docs: state.categories.docs.filter((c) => c._id !== action.payload.id),
        },
        isFetching: false,
      };
    case getFullType(UPDATE_PAGE_CATEGORY):
      return {
        ...state,
        categories: {
          ...state.categories,
          docs: state.categories.docs.map((category) => {
            if (category._id !== action.payload.id) return category;

            return action.response;
          }),
        },
        isFetching: false,
      };
    case `${getFullType(GET_PAGE_CATEGORY)}_FAILURE`:
    case `${getFullType(GET_LANDING_PAGE_CATEGORIES)}_FAILURE`:
    case `${getFullType(CREATE_PAGE_CATEGORY)}_FAILURE`:
    case `${getFullType(DELETE_PAGE_CATEGORY)}_FAILURE`:
    case `${getFullType(UPDATE_PAGE_CATEGORY)}_FAILURE`:
      return {
        ...state,
        error: returnError(action.response),
        isFetching: false,
      };
    default:
      return state;
  }
};
