export const GET_IDEA = 'GET_IDEA';
export const GET_IDEAS = 'GET_IDEAS';
export const CREATE_IDEA = 'CREATE_IDEA';
export const UPDATE_IDEA = 'UPDATE_IDEA';
export const RESET_IDEAS = 'RESET_IDEAS';

/* --------------- */
/* Action creators */

/* --------------- */

export function getIdeas(params, isSearch, requestToken) {
  return {
    type: GET_IDEAS,
    request: {
      method: 'get',
      url: '/ideas',
      params,
      requestToken,
    },
    payload: { isSearch },
  };
}

export function getIdea(id, requestToken) {
  return {
    type: GET_IDEA,
    request: {
      method: 'get',
      url: `/admin/ideas/${id}`,
      requestToken,
    },
  };
}

export function updateIdea(slug, data, requestToken) {
  return {
    type: UPDATE_IDEA,
    request: {
      method: 'patch',
      url: `/admin/ideas/${slug}`,
      data,
      requestToken,
    },
    payload: { slug, data },
  };
}

export function createIdea(data, requestToken) {
  return {
    type: CREATE_IDEA,
    request: {
      method: 'post',
      url: '/admin/ideas',
      data,
      requestToken,
    },
  };
}
