import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

const TemplatesSubnav = () => {
  const location = useLocation();

  return (
    <div className="subnav">
      <NavLink activeClassName="is-active" to={`/templates/categories${location ? location.search : ''}`}>
        Категории
      </NavLink>
      <NavLink activeClassName="is-active" to={`/templates/groups${location ? location.search : ''}`}>
        Группы
      </NavLink>
      <NavLink activeClassName="is-active" to={`/templates/sizes${location ? location.search : ''}`}>
        Размеры
      </NavLink>
    </div>
  );
};

export default TemplatesSubnav;
