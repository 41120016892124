export const GET_VIDEOS = 'GET_VIDEOS';
export const RESET_VIDEOS = 'RESET_VIDEOS';

/* --------------- */
/* Action creators */

/* --------------- */

export function getVideos(requestToken, userId) {
  return {
    type: GET_VIDEOS,
    request: {
      method: 'get',
      url: '/admin/videos',
      params: userId ? { user_id: userId } : {},
      requestToken,
    },
  };
}
