const successMessages = {
  // users
  email: 'E-mail пользователя успешно изменен!',
  confirm_attempts: 'Попытки подтверждения E-mail успешно сброшены!',
  invoice_creating: 'Счет создан! Чтобы увидеть созданный счет, перейдите в раздел "Счета".',
  signin: 'Ссылка на профиль скопирована в буфер обмена!',
  comment: 'Комментарий сохранен!',
  currency: 'Валюта изменена!',
  lang: 'Язык изменен!',
  block: 'Блокировка изменена!',
  period_deleted: 'Период удален!',
  period_date_was_changed: 'Дата периода изменена',
  period_migration_id_deleted: 'Запись о миграции удалена!',
  subscription_cancelled: 'Статус подписки успешно изменен!',
  tag_name: 'Название тега успешно изменено!',
  tag_alias: 'Алиас тега успешно изменен!',
  tag_deleted: 'Тег успешно удален!',
  unbind_oauth: 'Метод авторизации успешно отвязан!',
};

export default successMessages;
