import {
  CREATE_IDEA,
  GET_IDEA,
  GET_IDEAS,
  RESET_IDEAS,
  UPDATE_IDEA,
} from '../actions';

const defaultState = {
  error: null,
  isFetching: false,
  docs: [],
  lastActionType: null,
  page: 0,
  pages: 1,
  total: 0,
  editedIdea: null,
  editedIdeaIsFetching: false,
};

function returnError(response, state) {
  if (response.message && state.page === 0) return { message: 'first_request_failed' };
  if (response.message) return response;

  return { message: 'admin_privileges_required' };
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case `${GET_IDEAS}_REQUEST`:
      return {
        ...state,
        docs: action.payload.isSearch ? [] : state.docs,
        error: null,
        isFetching: true,
        lastActionType: action.type,
      };
    case `${GET_IDEA}_REQUEST`:
      return {
        ...state,
        error: null,
        editedIdeaIsFetching: true,
        lastActionType: null,
        editedIdea: null,
      };
    case `${CREATE_IDEA}_REQUEST`:
    case `${UPDATE_IDEA}_REQUEST`:
      return {
        ...state,
        error: null,
      };
    case GET_IDEAS:
      return {
        ...state,
        ...action.response,
        docs: action.response.page === 1
          ? action.response.docs
          : [...state.docs, ...action.response.docs],
        isFetching: false,
        lastActionType: null,
      };
    case GET_IDEA:
      return {
        ...state,
        editedIdea: action.response,
        editedIdeaIsFetching: false,
      };
    case UPDATE_IDEA:
      return {
        ...state,
        docs: state.docs.map((item) => {
          if (item.slug !== action.payload.slug) return item;

          return {
            ...item,
            ...action.response,
          };
        }),
      };
    case CREATE_IDEA:
      return {
        ...state,
        docs: [action.response, ...state.docs],
        total: state.total + 1,
      };
    case `${GET_IDEAS}_FAILURE`:
      return {
        ...state,
        error: returnError(action.response, state),
        isFetching: false,
      };
    case `${GET_IDEA}_FAILURE`:
      return {
        ...state,
        error: action.response,
        editedIdeaIsFetching: false,
      };
    case `${CREATE_IDEA}_FAILURE`:
    case `${UPDATE_IDEA}_FAILURE`:
      return {
        ...state,
        error: action.response,
      };
    case RESET_IDEAS:
      return defaultState;
    default:
      return state;
  }
};
