import {
  CHANGE_GROUP_CATEGORY,
  CREATE_GROUP_CATEGORY,
  GET_GROUP_CATEGORIES,
  DELETE_GROUP_CATEGORY,
  ORDER_GROUP_CATEGORY,
  SAVE_GROUP_CATEGORIES_ORDER,
  RESET_GROUP_CATEGORIES,
  UPDATE_GROUP_CATEGORY,
} from '../../actions';

const defaultState = {};

function returnError(response) {
  if (response.message) return { message: 'first_request_failed' };

  return { message: 'admin_privileges_required' };
}

const setIsGroupChanging = (isGroupChanging, props) => ({
  ...isGroupChanging,
  ...props,
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case `${GET_GROUP_CATEGORIES}_REQUEST`:
      return {
        ...state,
        [action.payload.groupId]: {
          ...state[action.payload.groupId],
          docs: [],
          error: null,
          isFetching: true,
        },
      };
    case `${SAVE_GROUP_CATEGORIES_ORDER}_REQUEST`:
      return {
        ...state,
        [action.payload.groupId]: {
          ...state[action.payload.groupId],
          error: null,
          isGroupChanging: setIsGroupChanging(state.isGroupChanging, { order: true }),
        },
      };
    case `${CREATE_GROUP_CATEGORY}_REQUEST`:
      return {
        ...state,
        [action.payload.groupId]: {
          ...state[action.payload.groupId],
          error: null,
          isGroupChanging: setIsGroupChanging(state.isGroupChanging, { create: true }),
        },
      };
    case `${DELETE_GROUP_CATEGORY}_REQUEST`:
      return {
        ...state,
        [action.payload.groupId]: {
          ...state[action.payload.groupId],
          error: null,
          isGroupChanging: setIsGroupChanging(state.isGroupChanging, { remove: action.payload.id }),
        },
      };
    case `${UPDATE_GROUP_CATEGORY}_REQUEST`:
      return {
        ...state,
        [action.payload.groupId]: {
          ...state[action.payload.groupId],
          error: null,
          isGroupChanging: setIsGroupChanging(state.isGroupChanging, { update: action.payload.id }),
        },
      };
    case SAVE_GROUP_CATEGORIES_ORDER:
      return {
        ...state,
        [action.payload.groupId]: {
          ...state[action.payload.groupId],
          isOrdered: false,
          isGroupChanging: setIsGroupChanging(state.isGroupChanging, { order: false }),
        },
      };
    case GET_GROUP_CATEGORIES:
      return {
        ...state,
        [action.payload.groupId]: {
          ...state[action.payload.groupId],
          docs: action.response,
          isFetching: false,
          isOrdered: false,
        },
      };
    case ORDER_GROUP_CATEGORY:
      // eslint-disable-next-line
      const newDocs = state[action.payload.groupId].docs;

      newDocs.splice(action.payload.newPosition, 0, newDocs.splice(action.payload.currentPosition, 1)[0]);

      return {
        ...state,
        [action.payload.groupId]: {
          ...state[action.payload.groupId],
          docs: newDocs,
          isOrdered: true,
        },
      };
    case CREATE_GROUP_CATEGORY:
      return {
        ...state,
        [action.payload.groupId]: {
          ...state[action.payload.groupId],
          docs: [
            ...state[action.payload.groupId].docs,
            action.response,
          ],
          isGroupChanging: setIsGroupChanging(state.isGroupChanging, { create: false }),
        },
      };
    case DELETE_GROUP_CATEGORY:
      return {
        ...state,
        [action.payload.groupId]: {
          ...state[action.payload.groupId],
          docs: state[action.payload.groupId].docs.filter((item) => item._id !== action.payload.categoryId),
          isGroupChanging: setIsGroupChanging(state.isGroupChanging, { remove: null }),
        },
      };
    case CHANGE_GROUP_CATEGORY:
      return {
        ...state,
        [action.payload.groupId]: {
          ...state[action.payload.groupId],
          docs: state[action.payload.groupId].docs.map((item) => {
            if (item._id !== action.payload.categoryId) return item;

            return {
              ...item,
              ...action.payload.data,
              isUnsaved: true,
            };
          }),
        },
      };
    case UPDATE_GROUP_CATEGORY:
      return {
        ...state,
        [action.payload.groupId]: {
          ...state[action.payload.groupId],
          docs: state[action.payload.groupId].docs.map((item) => {
            if (item._id !== action.payload.categoryId) return item;

            return action.response;
          }),
          isGroupChanging: setIsGroupChanging(state.isGroupChanging, { update: null }),
        },
      };
    case `${GET_GROUP_CATEGORIES}_FAILURE`:
      return {
        ...state,
        [action.payload.groupId]: {
          ...state[action.payload.groupId],
          error: returnError(action.response),
          isFetching: false,
        },
      };
    case `${SAVE_GROUP_CATEGORIES_ORDER}_FAILURE`:
      return {
        ...state,
        [action.payload.groupId]: {
          ...state[action.payload.groupId],
          error: action.response,
          isGroupChanging: setIsGroupChanging(state.isGroupChanging, { order: false }),
        },
      };
    case `${CREATE_GROUP_CATEGORY}_FAILURE`:
      return {
        ...state,
        [action.payload.groupId]: {
          ...state[action.payload.groupId],
          error: action.response,
          isGroupChanging: setIsGroupChanging(state.isGroupChanging, { create: false }),
        },
      };
    case `${DELETE_GROUP_CATEGORY}_FAILURE`:
      return {
        ...state,
        [action.payload.groupId]: {
          ...state[action.payload.groupId],
          error: action.response,
          isGroupChanging: setIsGroupChanging(state.isGroupChanging, { remove: null }),
        },
      };
    case `${UPDATE_GROUP_CATEGORY}_FAILURE`:
      return {
        ...state,
        [action.payload.groupId]: {
          ...state[action.payload.groupId],
          error: action.response,
          isGroupChanging: setIsGroupChanging(state.isGroupChanging, { update: null }),
        },
      };
    case RESET_GROUP_CATEGORIES:
      return {
        ...state,
        [action.payload.groupId]: undefined,
      };
    default:
      return state;
  }
};
