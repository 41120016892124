import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Link } from 'react-router-dom';

const TemplatesBreadcrumbs = ({ category, lang }) => {
  const url = lang ? `?lang=${lang}` : '';

  return (
    <nav className="templates__breadcrumbs">
      <ol>
        <li><Link to={`/templates/categories${url}`}>Категории</Link></li>
        <li>/</li>
        {category && <li className="templates__breadcrumbs_active">{`${category.title} (${category.count_templates})`}</li>}
      </ol>
    </nav>
  );
};

TemplatesBreadcrumbs.propTypes = {
  category: PropTypes.object,
  lang: PropTypes.string,
};

export default memo(TemplatesBreadcrumbs);
