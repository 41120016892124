import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';

import { languages } from '../../../constants/common';

import './LanguageDropdown.scss';

const LanguageDropdown = memo(({
  className, currentLang, disabled, onChange,
}) => (
  <div className={`language-dropdown${className ? ` ${className}` : ''}`}>
    <span>Язык:</span>
    <Dropdown>
      <Dropdown.Toggle as="button" className="btn btn-light" disabled={disabled}>
        {languages[currentLang]}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {Object.keys(languages).map((lang) => lang !== currentLang && (
          <Dropdown.Item
            as="button"
            eventKey={lang}
            key={lang}
            onSelect={onChange}
          >
            {languages[lang]}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  </div>
));

LanguageDropdown.propTypes = {
  currentLang: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default LanguageDropdown;
