import { connect } from 'react-redux';

import {
  RESET_MAILING,
  downloadList,
  unsubscribeList,
} from '../../../actions';

import Mailing from './Mailing';

const mapStateToProps = (state) => ({
  mailing: state.mailing,
});

const mapDispatchToProps = (dispatch) => ({
  onDownloadList: (data, requestToken) => dispatch(downloadList(data, requestToken)),
  onUnsubscribeList: (data, requestToken) => dispatch(unsubscribeList(data, requestToken)),
  onUnload: () => dispatch({ type: RESET_MAILING }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Mailing);
