import PropTypes from 'prop-types';
import React, {
  memo,
  useState,
  useEffect,
  useRef,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { requestCanceler } from '../../../../../middlewares/api';

import successMessages from '../../../../../constants/successMessages';

import { toast } from '../../../../../utils';

import sweetAlert from '../../../../HOCs/sweetAlert';

import FieldForm from '../../../../Includes/FieldForm/FieldForm';

import CommentIcon from '../../../../UI/CommentIcon/CommentIcon';

import './PartnersPartner.scss';

const PartnersPartner = ({
  partner,
  readyToPay,
  onChangeUser,
  onMakePayout,
}) => {
  const [isShowCommentForm, setIsShowCommentForm] = useState(false);
  const [isShowRequisites, setIsShowRequisites] = useState(false);
  const [isPartnerChanging, setIsPartnerChanging] = useState({
    comment: false,
    makePayout: false,
  });

  const requestCancelers = useRef([]);

  useEffect(() => () => requestCancelers.current.forEach((canceler) => canceler.cancelRequest()), []);

  const toggleShowCommentForm = () => setIsShowCommentForm(!isShowCommentForm);
  const toggleShowRequisites = () => setIsShowRequisites(!isShowRequisites);

  const handleIsPartnerChanging = (newProps) => setIsPartnerChanging((prev) => ({ ...prev, ...newProps }));

  const handleChangeComment = (comment) => {
    handleIsPartnerChanging({ comment: true });

    const requestChangeUserCanceler = requestCanceler();

    requestCancelers.current.push(requestChangeUserCanceler);

    onChangeUser(partner.user.id, { comment }, requestChangeUserCanceler.token)
      .then(() => toast('success', successMessages.comment))
      .finally(() => handleIsPartnerChanging({ comment: false }));
  };

  const handleMakePayout = (sum) => {
    sweetAlert.fire({
      cancelButtonText: 'Отмена',
      confirmButtonText: 'Подтвердить',
      icon: <FontAwesomeIcon icon={['fal', 'exclamation-triangle']} />,
      type: 'warning',
      showCancelButton: true,
      title: 'Вы уверены?',
      html: 'Подтвердить выплату?',
    }).then((result) => {
      if (!result.value) return;

      handleIsPartnerChanging({ makePayout: true });

      const requestMakePayoutCanceler = requestCanceler();

      requestCancelers.current.push(requestMakePayoutCanceler);

      onMakePayout(partner.user.id, +sum, requestMakePayoutCanceler.token)
        .then(() => toast('success', 'Успешно выплачено!'))
        .finally(() => handleIsPartnerChanging({ makePayout: false }));
    });
  };

  return (
    <>
      <div className={`partner ${readyToPay ? 'partner_readyToPay' : ''}`}>
        <div className={`partner__email ${isShowCommentForm ? 'active' : ''}`}>
          {/* eslint-disable-next-line */}
          <span role="button" onClick={toggleShowCommentForm}>{partner.user.email}</span>
          <CommentIcon comment={partner.user?.comment} />
        </div>
        <div className="partner__sum-avail">{partner.sum_avail}</div>
        <div className="partner__sum-ready">{partner.sum_ready}</div>
        <div className="partner__sum-payout">{partner.sum_payout}</div>
        <div className={`partner__requisites ${isShowRequisites ? 'active' : ''}`}>
          {partner.payout_method !== 'bank'
            ? partner.payout_method_info
            : ( // eslint-disable-next-line
              <span role="button" onClick={toggleShowRequisites}>Банк</span>
            )}
        </div>
        <div className="partner__pay-form">
          {partner.sum_avail < partner.payout_min
            ? 'Недоступно'
            : (
              <FieldForm
                buttonText="OK"
                defaultValue={String(partner.sum_avail)}
                disabled={isPartnerChanging.makePayout}
                disabledByDefault={false}
                disabledPrevValueComparison
                fieldType="input"
                inputType="number"
                resetOn={String(partner.sum_avail)}
                onSubmit={handleMakePayout}
              />
            )}
        </div>
      </div>
      {isShowCommentForm ? (
        <div className="partner__form">
          <FieldForm
            buttonText="Сохранить"
            defaultValue={partner.user?.comment}
            disabled={isPartnerChanging.comment}
            disabledByDefault={false}
            disabledPrevValueComparison
            placeholder="В целях безопасности HTML теги будут вырезаны"
            onSubmit={handleChangeComment}
          />
        </div>
      ) : null}
      {isShowRequisites && partner.payout_method === 'bank' ? (
        <div className="partner__form partner__payout-form">
          <FieldForm
            defaultValue={partner.payout_method_info}
            disabledPrevValueComparison
            readOnly
          />
        </div>
      ) : null}
    </>
  );
};

PartnersPartner.propTypes = {
  partner: PropTypes.object,
  readyToPay: PropTypes.bool,
  onChangeUser: PropTypes.func,
  onMakePayout: PropTypes.func,
};

export default memo(PartnersPartner);
