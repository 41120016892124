import PropTypes from 'prop-types';
import React, { memo, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { smoothScrollTo } from '../../../../utils';

import IdeasForm from '../IdeasForm/IdeasForm';

const IdeasCreate = ({
  ideas, history, match: { params: { id } }, location, isEditing, setViewTitle, onGetIdea, onUpdateIdea,
}) => {
  useEffect(() => {
    setViewTitle('Редактировать идею');

    smoothScrollTo(document.documentElement, 0);

    onGetIdea(id)
      .catch(() => history.push('/ideas'));
  }, [id, history, onGetIdea, setViewTitle]);

  const handleUpdateIdea = (formData) => {
    onUpdateIdea(id, formData);
  };

  return (
    // eslint-disable-next-line
    !ideas.isFetching && ideas.editedIdea ? (
      <div className="ideas__create">
        <Link
          to={`/ideas${ideas.editedIdea.lang && ideas.editedIdea.lang !== 'ru' ? `?lang=${ideas.editedIdea.lang}` : ''}`}
          className="ideas__link"
        >
          Вернуться назад
        </Link>
        <IdeasForm
          buttonText="Сохранить"
          data={ideas.editedIdea}
          disabled={isEditing}
          location={location}
          onSubmit={handleUpdateIdea}
        />
      </div>
    ) : null
  );
};

IdeasCreate.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  ideas: PropTypes.shape({
    editedIdea: PropTypes.shape({
      _id: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      templates: PropTypes.array,
      lang: PropTypes.string,
    }),
    isFetching: PropTypes.bool,
  }),
  isEditing: PropTypes.bool,
  location: PropTypes.object,
  setViewTitle: PropTypes.func,
  onGetIdea: PropTypes.func,
  onUpdateIdea: PropTypes.func,
};

export default memo(IdeasCreate);
