import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Dropdown, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MailingFilter = ({
  action, index, list, filterActions, filterLists, onChangeFilter, onRemoveFilter,
}) => {
  const handleChangeFilter = (value) => {
    onChangeFilter(index, value);
  };

  const handleRemoveFilter = () => {
    onRemoveFilter(index);
  };

  return (
    <div className="mailing__filter">
      <Dropdown className="mailing__dropdown">
        <Dropdown.Toggle as="button" disabled={index === 0} className="btn btn-light">
          {filterActions[action]}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {Object.entries(filterActions).map((item) => (
            <Dropdown.Item
              as="button"
              eventKey={item[0]}
              onSelect={(value) => handleChangeFilter({ action: value })}
              key={item[0]}
            >
              {item[1]}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown className="mailing__dropdown">
        <Dropdown.Toggle as="button" className="btn btn-light">
          {filterLists[list]}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {Object.entries(filterLists).map((item) => (
            <Dropdown.Item
              as="button"
              eventKey={item[0]}
              onSelect={(value) => handleChangeFilter({ list: value })}
              key={item[0]}
            >
              {item[1]}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <Button className="mailing__filter-remove" onClick={handleRemoveFilter}>
        <FontAwesomeIcon icon={['fal', 'times']} />
      </Button>
    </div>
  );
};

MailingFilter.propTypes = {
  action: PropTypes.string,
  filterActions: PropTypes.object,
  filterLists: PropTypes.object,
  index: PropTypes.number,
  list: PropTypes.string,
  onChangeFilter: PropTypes.func,
  onRemoveFilter: PropTypes.func,
};

export default memo(MailingFilter);
