import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { pluralize } from '../../../../utils';

function IdeasIdea({ data, index }) {
  return (
    <div className="idea">
      <div className="idea__info">
        <Link className="idea__title" to={`/ideas/${data._id}/edit${data.lang === 'ru' ? '' : `?lang=${data.lang}`}`}>
          <span>{`Идея ${index}: `}</span>
          {data.title}
        </Link>
        <div className="idea__templates-count">
          {data.description ? 'Есть описание, ' : 'Нет описания, '}
          {data.templates && data.templates.length && (
            <>
              {data.templates.length}
              {` ${pluralize(data.templates.length, ['шаблон', 'шаблона', 'шаблонов'])}`}
            </>
          )}
        </div>
      </div>
      <div className="idea__actions">
        <Link className="btn btn-success promocode__button" to={`/ideas/${data._id}/edit${data.lang === 'ru' ? '' : `?lang=${data.lang}`}`}>
          <FontAwesomeIcon icon={['fas', 'edit']} />
        </Link>
      </div>
    </div>
  );
}

IdeasIdea.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.string,
    title: PropTypes.string,
    slug: PropTypes.string,
    description: PropTypes.string,
    templates: PropTypes.array,
    lang: PropTypes.string,
  }),
  index: PropTypes.number,
};

export default memo(IdeasIdea);
