import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {
  memo, useCallback, useEffect, useRef, useState,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dropdown, Form } from 'react-bootstrap';

import { FROM_SCRATCH_TYPES, UNIT_TYPES } from '../../../../../../constants/templates/category';

import {
  changeGroupCategory, getAllCategories, getSignedUrl, sendFileToS3, updateGroupCategory,
} from '../../../../../../actions';

import sweetAlert from '../../../../../HOCs/sweetAlert';

import { requestCanceler } from '../../../../../../middlewares/api';

import { toast } from '../../../../../../utils';

import { SIZE_1_MB } from '../../../../../../constants/common';

import usePrevious, { useDidUpdateEffect } from '../../../../../hooks';

import {
  GROUP_CATEGORY_DISPLAYS,
  GROUP_CATEGORY_SIZES,
  GROUP_CATEGORY_SOURCES,
  GROUP_CATEGORY_TYPES,
} from '../../../../../../constants/templates/group-category';

import CheckBox from '../../../../../UI/CheckBox/CheckBox';
import Preloader from '../../../../../UI/Preloader/Preloader';

import DropdownMenuSearch from '../../../../../Includes/DropdownMenuSearch/DropdownMenuSearch';
import TagsInput from '../../../../../Includes/TagsInput/TagsInput';

const MAX_FILE_SIZE = SIZE_1_MB * 1;

const GroupsGroupCategory = ({
  data,
  groupId,
  lang,
  onDeleteGroupCategory,
}) => {
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [allCategories, setAllCategories] = useState([]);

  const [file, setFile] = useState(null);
  const [fileObjectUrl, setFileObjectUrl] = useState('');
  const [groupCategoryWidth, setGroupCategoryWidth] = useState(typeof data?.from_scratch.size[0] !== 'undefined' ? data?.from_scratch.size[0] : 600);
  const [groupCategoryHeight, setGroupCategoryHeight] = useState(typeof data?.from_scratch.size[1] !== 'undefined' ? data?.from_scratch.size[1] : 600);

  const prevIsFormVisible = usePrevious(isFormVisible);

  const requestCancelers = useRef([]);

  const fileInputRef = useRef();

  const activeGroupCategory = allCategories.find((groupCategory) => groupCategory._id === data.group_category_ref_id);

  const {
    categories: { docs: categories },
    groups: { docs: groups },
  } = useSelector((store) => store);

  const dispatch = useDispatch();

  const validateUploadFile = useCallback((files) => {
    if (!files.length) return false;

    if (files[0].size > MAX_FILE_SIZE) {
      toast('error', `Размер файла не должен превышать ${MAX_FILE_SIZE / SIZE_1_MB} мб`);

      return false;
    }

    return true;
  }, []);

  const handleSetIsFormVisible = () => {
    if (isFormVisible && data.isUnsaved) {
      sweetAlert.fire({
        cancelButtonText: 'Перейти к редактированию',
        confirmButtonText: 'Свернуть без сохранения',
        icon: <FontAwesomeIcon icon={['fal', 'exclamation-triangle']} />,
        type: 'warning',
        showCancelButton: true,
        title: 'Имеются не сохраненные изменения!',
      }).then((result) => {
        if (!result.value) return;

        setIsFormVisible(false);
      });
    } else {
      setIsFormVisible(!isFormVisible);
    }
  };

  const handleChangeGroupCategory = useCallback((changedData) => {
    dispatch(changeGroupCategory(groupId, data._id, changedData));
  }, [dispatch, groupId, data._id]);

  const handleDeleteGroupCategory = useCallback(() => {
    onDeleteGroupCategory(groupId, data._id);
  }, [data._id, groupId, onDeleteGroupCategory]);

  const uploadFileCover = useCallback((callback) => {
    const { name, type } = file;

    const requestGetSignedUrlCanceler = requestCanceler();

    requestCancelers.current.push({ key: 'get_sign_url_cover', canceler: requestGetSignedUrlCanceler });

    dispatch(getSignedUrl({ file_name: name, file_type: type, upload_type: 'template_category_cover' }, requestGetSignedUrlCanceler.token))
      .then((response) => {
        const requestSendToS3Canceler = requestCanceler();

        requestCancelers.current.push({ key: 'send_to_s3_cover', canceler: requestSendToS3Canceler });

        dispatch(sendFileToS3(response.signedRequest, file, requestSendToS3Canceler.token))
          .then(() => {
            handleChangeGroupCategory({ cover_url: response.url });

            setFile(null);

            callback({ cover_url: response.url });
          })
          .catch(() => toast('error', 'Не удалось загрузить файл по подписанному URL'));
      })
      .catch(() => toast('error', 'Не удалось получить подписанный URL'));
  }, [dispatch, file, handleChangeGroupCategory]);

  const updateCategory = (additionalData) => {
    const requestUpdateGroupCategoryCanceler = requestCanceler();

    requestCancelers.current.push({ key: 'update_group_category', canceler: requestUpdateGroupCategoryCanceler });

    dispatch(updateGroupCategory(groupId, {
      ...data,
      from_scratch: {
        ...data.from_scratch,
        size: [+groupCategoryWidth, +groupCategoryHeight],
      },
      ...additionalData,
    }, requestUpdateGroupCategoryCanceler.token))
      .then(() => toast('success', 'Категория обновлена'))
      .catch(({ message }) => toast('error', message))
      .finally(() => setIsUpdating(false));
  };

  const submitUpdateCategory = async () => {
    if (!data.short_title) {
      toast('error', 'Укажите короткое название категории');

      return;
    }

    setIsUpdating(true);

    if (file) {
      uploadFileCover(updateCategory);
    } else {
      updateCategory();
    }
  };

  const handleFileChange = useCallback((event) => {
    if (!validateUploadFile(event.target.files)) return;

    setFile(event.target.files[0]);
  }, [validateUploadFile]);

  const applySizeChangeListener = (type) => (e) => {
    const { value } = e.target;

    let resultValue = value.trim().replace(/^00+/, '0').replace(/ /g, '');

    if (!resultValue) resultValue = '0';

    resultValue = resultValue.replace(/^0(?=\d)/, '');

    // max 3 chars after comma
    if (resultValue.includes('.') && resultValue.split('.')[1].length > 3) return;

    // basic regexp to check if float value
    if (!(/^\d+(\.(\d+)?)?$/).test(resultValue) && resultValue) return;

    if (type === 'width') {
      setGroupCategoryWidth(resultValue);
    } else {
      setGroupCategoryHeight(resultValue);
    }

    handleChangeGroupCategory({ isUnsaved: true });
  };

  const renderCoverMedia = () => {
    let coverExt = '';

    if (file) {
      coverExt = file.name.substr(file.name.lastIndexOf('.') + 1);
    } else {
      coverExt = data.cover_url.substr(data.cover_url.lastIndexOf('.') + 1);
    }

    if (['jpg', 'jpeg', 'png'].includes(coverExt)) return <img src={fileObjectUrl || data.cover_url} alt="" />;

    if (['mp4'].includes(coverExt)) {
      return (
        <video playsInline muted loop controls>
          <source type="video/mp4" src={fileObjectUrl || data.cover_url} />
        </video>
      );
    }

    return null;
  };

  const renderHeader = () => (
    <>
      <button className="template-bunch-item__button template-bunch-item__button_title" type="button" onClick={handleSetIsFormVisible}>
        {data.short_title}
        {data.in_spotlight && <FontAwesomeIcon icon={['fas', 'star']} />}
      </button>
      {isUpdating && <Preloader medium />}
      <button
        className={`template-bunch-item__button template-bunch-item__button_edit${isFormVisible ? ' is-active' : ''}`}
        type="button"
        onClick={handleSetIsFormVisible}
      >
        <FontAwesomeIcon icon={['fas', 'pencil']} />
      </button>
      <button onClick={handleDeleteGroupCategory} disabled={isUpdating} className="template-bunch-item__button template-bunch-item__button_delete" type="button">
        <FontAwesomeIcon icon={['fal', 'times']} />
      </button>
    </>
  );

  const renderMain = () => (
    <>
      <div className="template-bunch-item__form-row template-bunch-item__form-title">
        <div className="template-bunch-item__form-label">Короткое название:</div>
        <Form.Control
          disabled={isUpdating}
          value={data.short_title}
          onChange={(e) => handleChangeGroupCategory({ short_title: e.target.value })}
        />
      </div>
      <div className="template-bunch-item__form-row template-bunch-item__form-alias">
        <div className="template-bunch-item__form-label">Алиас:</div>
        <Form.Control
          disabled={isUpdating}
          value={data.slug}
          onChange={(e) => handleChangeGroupCategory({ slug: e.target.value })}
        />
      </div>
      <div className="template-bunch-item__form-row">
        <div className="template-bunch-item__form-label">Отображение:</div>
        {Object.keys(GROUP_CATEGORY_DISPLAYS).map((display) => (
          <CheckBox
            checked={data[display]}
            disabled={isUpdating}
            id={display + data._id}
            key={display + data._id}
            onChange={(value) => {
              handleChangeGroupCategory({ [display]: value });
            }}
          >
            {GROUP_CATEGORY_DISPLAYS[display]}
            {display === 'in_spotlight' && <FontAwesomeIcon icon={['fas', 'star']} />}
          </CheckBox>
        ))}
      </div>
      <div className="template-bunch-item__form-row template-bunch-item__form-title">
        <div className="template-bunch-item__form-label">Название:</div>
        <Form.Control
          disabled={isUpdating}
          value={data.title}
          onChange={(e) => handleChangeGroupCategory({ title: e.target.value })}
        />
      </div>
      <div className="template-bunch-item__form-row">
        <div className="template-bunch-item__form-label">Описание:</div>
        <Form.Control
          disabled={isUpdating}
          value={data?.description || ''}
          onChange={(e) => handleChangeGroupCategory({ description: e.target.value })}
        />
      </div>
      <div className="template-bunch-item__form-row">
        <div className="template-bunch-item__form-label">SEO Title:</div>
        <Form.Control
          className="template-bunch-item__form-background-field"
          disabled={isUpdating}
          value={data?.seo?.title || ''}
          onChange={(e) => handleChangeGroupCategory({ seo: { ...(data.seo ? data.seo : {}), title: e.target.value } })}
        />
      </div>
      <div className="template-bunch-item__form-row">
        <div className="template-bunch-item__form-label">SEO Description:</div>
        <Form.Control
          className="template-bunch-item__form-background-field"
          disabled={isUpdating}
          value={data?.seo?.description || ''}
          onChange={(e) => handleChangeGroupCategory({ seo: { ...(data.seo ? data.seo : {}), description: e.target.value } })}
        />
      </div>
      <div className="template-bunch-item__form-row">
        <div className="template-bunch-item__form-label">SEO Keywords:</div>
        <Form.Control
          className="template-bunch-item__form-background-field"
          disabled={isUpdating}
          value={data?.seo?.keywords || ''}
          onChange={(e) => handleChangeGroupCategory({ seo: { ...(data.seo ? data.seo : {}), keywords: e.target.value } })}
        />
      </div>
      <div className="template-bunch-item__form-row">
        <div className="template-bunch-item__form-label">Обложка:</div>
        <div className="template-bunch-item__category-cover">
          <input
            accept="image/png,image/jpeg,.mp4"
            name="logoFile"
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
          {(fileObjectUrl || data.cover_url) && (
            <div className="template-bunch-item__category-cover-media">
              {renderCoverMedia()}
            </div>
          )}
          <div className="template-bunch-item__category-cover-actions">
            <button type="button" onClick={() => { fileInputRef.current.click(); }}>
              {(fileObjectUrl || data.cover_url) ? 'Изменить' : 'Выбрать обложку...' }
            </button>
            {(fileObjectUrl || data.cover_url) && (
              <button
                type="button"
                onClick={() => {
                  if (data.cover_url) handleChangeGroupCategory({ cover_url: '' });

                  if (fileObjectUrl) setFile(null);
                }}
              >
                Удалить
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="template-bunch-item__form-row">
        <div className="template-bunch-item__form-label">Источник:</div>

        <Dropdown className="template-bunch-item__category-dropdown">
          <Dropdown.Toggle
            as="button"
            className="btn btn-light"
            disabled={isUpdating}
          >
            {GROUP_CATEGORY_SOURCES[data.source]}
          </Dropdown.Toggle>
          <Dropdown.Menu as={DropdownMenuSearch}>
            {Object.keys(GROUP_CATEGORY_SOURCES).map((source) => (
              <Dropdown.Item
                active={data.source === source}
                as="button"
                key={source}
                onSelect={() => handleChangeGroupCategory({ source })}
              >
                {GROUP_CATEGORY_SOURCES[source]}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>

        {data.source === 'category' && (
          <>
            <Dropdown className="template-bunch-item__category-dropdown">
              <Dropdown.Toggle
                as="button"
                className="btn btn-light"
                disabled={isUpdating}
              >
                {categories.find((category) => category._id === data.category_id)?.title || 'Выберите категорию...'}
              </Dropdown.Toggle>
              <Dropdown.Menu as={DropdownMenuSearch}>
                {categories.map((category) => (
                  <Dropdown.Item
                    as="button"
                    active={category._id === data.category_id}
                    key={category._id}
                    onSelect={() => handleChangeGroupCategory({ category_id: category._id })}
                  >
                    {category.title}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown className="template-bunch-item__category-dropdown">
              <Dropdown.Toggle
                as="button"
                className="btn btn-light"
                disabled={isUpdating}
              >
                {GROUP_CATEGORY_TYPES[data.type]}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {Object.keys(GROUP_CATEGORY_TYPES).map((type) => (
                  <Dropdown.Item
                    active={data.type === type}
                    as="button"
                    key={type}
                    onSelect={() => handleChangeGroupCategory({ type })}
                  >
                    {GROUP_CATEGORY_TYPES[type]}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown className="template-bunch-item__category-dropdown">
              <Dropdown.Toggle
                as="button"
                className="btn btn-light"
                disabled={isUpdating}
              >
                {GROUP_CATEGORY_SIZES[data.size]}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {Object.keys(GROUP_CATEGORY_SIZES).map((size) => (
                  <Dropdown.Item
                    active={data.size === size}
                    as="button"
                    key={size}
                    onSelect={() => handleChangeGroupCategory({ size })}
                  >
                    {GROUP_CATEGORY_SIZES[size]}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </>
        )}

        {data.source === 'group_ref' && (
          <Dropdown className="template-bunch-item__category-dropdown">
            <Dropdown.Toggle
              as="button"
              className="btn btn-light"
              disabled={isUpdating}
            >
              {groups.find((group) => group._id === data.group_ref_id)?.short_title || 'Выберите группу...'}
            </Dropdown.Toggle>
            <Dropdown.Menu as={DropdownMenuSearch}>
              {groups.map((group) => (
                <Dropdown.Item
                  as="button"
                  active={group._id === data.group_ref_id}
                  key={group._id}
                  onSelect={() => handleChangeGroupCategory({ group_ref_id: group._id })}
                >
                  {group.short_title}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        )}

        {data.source === 'group_category_ref' && (
          <Dropdown className="template-bunch-item__category-dropdown">
            <Dropdown.Toggle
              as="button"
              className="btn btn-light"
              disabled={isUpdating}
            >
              {activeGroupCategory ? `${activeGroupCategory.group.short_title} - ${activeGroupCategory.short_title}` : 'Выберите категорию группы...'}
            </Dropdown.Toggle>
            <Dropdown.Menu as={DropdownMenuSearch}>
              {allCategories.map((groupCategory) => (
                groupCategory._id !== data._id ? (
                  <Dropdown.Item
                    as="button"
                    active={groupCategory._id === data.group_category_ref_id}
                    key={groupCategory._id}
                    onSelect={() => handleChangeGroupCategory({ group_category_ref_id: groupCategory._id })}
                  >
                    {`${groupCategory.group.short_title} - ${groupCategory.short_title}`}
                  </Dropdown.Item>
                ) : null
              ))}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>
      <div className="template-bunch-item__form-row">
        <div className="template-bunch-item__form-label">Популярные темы:</div>
        <TagsInput
          defaultTags={data.topics}
          disabled={isUpdating}
          onChange={(topics) => handleChangeGroupCategory({ topics })}
        />
      </div>
      <div className="template-bunch-item__form-row">
        <div className="template-bunch-item__form-label">Теги:</div>
        <TagsInput
          defaultTags={data.tags}
          disabled={isUpdating}
          onChange={(tags) => handleChangeGroupCategory({ tags })}
        />
      </div>
      <div className="template-bunch-item__form-row">
        <CheckBox
          checked={data?.from_scratch?.enabled}
          disabled={isUpdating}
          id={`${data._id}-from-scratch`}
          onChange={(value) => handleChangeGroupCategory({ from_scratch: { ...data.from_scratch, enabled: value } })}
        >
          <span style={{ fontWeight: '400' }}>Пустой макет</span>
        </CheckBox>
      </div>
      <fieldset className="template-bunch-item__form-fieldset">
        <div className="template-bunch-item__form-row">
          <div className="template-bunch-item__form-label">Размер:</div>
          <Form.Control
            disabled={isUpdating}
            value={groupCategoryWidth}
            style={{ width: '65px' }}
            onChange={applySizeChangeListener('width')}
          />
          <span dangerouslySetInnerHTML={{ __html: '&nbsp;&nbsp;&nbsp;x&nbsp;&nbsp;&nbsp;' }} />
          <Form.Control
            disabled={isUpdating}
            value={groupCategoryHeight}
            style={{ width: '65px' }}
            onChange={applySizeChangeListener('height')}
          />
          <Dropdown className="template-bunch-item__category-dropdown template-bunch-item__category-dropdown_units">
            <Dropdown.Toggle
              as="button"
              className="btn btn-light"
              disabled={isUpdating}
            >
              {UNIT_TYPES.find((item) => item.value === (data?.from_scratch?.units || 'px')).name}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {UNIT_TYPES.map(({ value, name }) => (
                <Dropdown.Item
                  as="button"
                  key={value}
                  onSelect={() => handleChangeGroupCategory({ from_scratch: { ...data.from_scratch, units: value } })}
                >
                  {name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="template-bunch-item__form-row">
          <div className="template-bunch-item__form-label">Тип:</div>
          <Dropdown className="template-bunch-item__category-dropdown">
            <Dropdown.Toggle
              as="button"
              className="btn btn-light"
              disabled={isUpdating}
            >
              {FROM_SCRATCH_TYPES[data?.from_scratch.type] || 'Видео'}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {Object.keys(FROM_SCRATCH_TYPES).map((type) => (
                <Dropdown.Item
                  as="button"
                  key={type}
                  onSelect={() => handleChangeGroupCategory({ from_scratch: { ...data.from_scratch, type } })}
                >
                  {FROM_SCRATCH_TYPES[type]}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="template-bunch-item__form-row">
          <div className="template-bunch-item__form-label">Название:</div>
          <Form.Control
            disabled={isUpdating}
            value={data?.from_scratch?.title || ''}
            onChange={(e) => handleChangeGroupCategory({ from_scratch: { ...data.from_scratch, title: e.target.value } })}
          />
        </div>
        <div className="template-bunch-item__form-row">
          <div className="template-bunch-item__form-label">Цвет:</div>
          <Form.Control
            disabled={isUpdating}
            value={data?.from_scratch.color}
            placeholder="#333333"
            style={{ width: '112px' }}
            onChange={(e) => handleChangeGroupCategory({ from_scratch: { ...data.from_scratch, color: e.target.value } })}
          />
        </div>
      </fieldset>
      <div className="template-bunch-item__form-row">
        <Button disabled={isUpdating || !data.isUnsaved} variant="success" onClick={submitUpdateCategory}>
          Сохранить
        </Button>
        {isUpdating && <Preloader medium />}
      </div>
    </>
  );

  useEffect(() => {
    if (file) {
      setFileObjectUrl(URL.createObjectURL(file));

      handleChangeGroupCategory({ isUnsaved: true });
    } else {
      setFileObjectUrl('');
    }
  }, [file, handleChangeGroupCategory]);

  useDidUpdateEffect(() => {
    if (!prevIsFormVisible && isFormVisible) {
      dispatch(getAllCategories({ lang }))
        .then((result) => {
          setAllCategories(result);
        })
        .catch(() => toast('error', 'Не удалось получить категории групп'));
    }
  }, [isFormVisible, prevIsFormVisible]);

  return (
    <div
      className={classNames('template-bunch-item__category', {
        unsaved: data.isUnsaved,
        opened: isFormVisible,
      })}
    >
      <div className="template-bunch-item__header">
        {renderHeader()}
      </div>
      {isFormVisible && (
        <div className="template-bunch-item__main">
          {renderMain()}
        </div>
      )}
    </div>
  );
};

GroupsGroupCategory.propTypes = {
  data: PropTypes.object.isRequired,
  groupId: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
  onDeleteGroupCategory: PropTypes.func.isRequired,
};

export default memo(GroupsGroupCategory);
