import {
  CHANGE_SIZE,
  CREATE_SIZE,
  GET_SIZES,
  DELETE_SIZE,
  ORDER_SIZE,
  SAVE_SIZES_ORDER,
  RESET_SIZES,
  UPDATE_SIZE,
} from '../../actions';

const defaultState = {
  docs: [],
  error: null,
  isFetching: false,
  isOrdered: false,
  isSizeChanging: {
    create: false,
    order: false,
    remove: null,
    update: null,
  },
};

function returnError(response) {
  if (response.message) return { message: 'first_request_failed' };

  return { message: 'admin_privileges_required' };
}

const setIsSizeChanging = (isSizeChanging, props) => ({
  ...isSizeChanging,
  ...props,
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case `${GET_SIZES}_REQUEST`:
      return {
        ...state,
        docs: [],
        error: null,
        isFetching: true,
      };
    case `${SAVE_SIZES_ORDER}_REQUEST`:
      return {
        ...state,
        error: null,
        // isSizeChanging: setIsSizeChanging(state.isSizeChanging, { order: true }),
      };
    case `${CREATE_SIZE}_REQUEST`:
      return {
        ...state,
        error: null,
        // isSizeChanging: setIsSizeChanging(state.isSizeChanging, { create: true }),
      };
    case `${DELETE_SIZE}_REQUEST`:
      return {
        ...state,
        error: null,
        // isSizeChanging: setIsSizeChanging(state.isSizeChanging, { remove: action.payload.id }),
      };
    case `${UPDATE_SIZE}_REQUEST`:
      return {
        ...state,
        error: null,
        // isSizeChanging: setIsSizeChanging(state.isSizeChanging, { update: action.payload.id }),
      };
    case SAVE_SIZES_ORDER:
      return {
        ...state,
        isOrdered: false,
        // isSizeChanging: setIsSizeChanging(state.isSizeChanging, { order: false }),
      };
    case GET_SIZES:
      return {
        ...state,
        docs: action.response,
        isFetching: false,
        isOrdered: false,
      };
    case ORDER_SIZE:
      // eslint-disable-next-line
      const newDocs = state.docs;

      newDocs.splice(action.payload.newPosition, 0, newDocs.splice(action.payload.currentPosition, 1)[0]);

      return {
        ...state,
        docs: newDocs,
        isOrdered: true,
      };
    case CREATE_SIZE:
      return {
        ...state,
        docs: [
          ...state.docs,
          action.response,
        ],
        // isSizeChanging: setIsSizeChanging(state.isSizeChanging, { create: false }),
      };
    case DELETE_SIZE:
      return {
        ...state,
        docs: state.docs.filter((item) => item._id !== action.payload.id),
        // isSizeChanging: setIsSizeChanging(state.isSizeChanging, { remove: null }),
      };
    case CHANGE_SIZE:
      return {
        ...state,
        docs: state.docs.map((item) => {
          if (item._id !== action.payload.id) return item;

          return {
            ...item,
            ...action.payload.data,
            isUnsaved: true,
          };
        }),
      };
    case UPDATE_SIZE:
      return {
        ...state,
        docs: state.docs.map((item) => {
          if (item._id !== action.payload.id) return item;

          return action.response;
        }),
        isSizeChanging: setIsSizeChanging(state.isSizeChanging, { update: null }),
      };
    case `${GET_SIZES}_FAILURE`:
      return {
        ...state,
        error: returnError(action.response),
        isFetching: false,
      };
    case `${SAVE_SIZES_ORDER}_FAILURE`:
      return {
        ...state,
        error: action.response,
        // isSizeChanging: setIsSizeChanging(state.isSizeChanging, { order: false }),
      };
    case `${CREATE_SIZE}_FAILURE`:
      return {
        ...state,
        error: action.response,
        // isSizeChanging: setIsSizeChanging(state.isSizeChanging, { create: false }),
      };
    case `${DELETE_SIZE}_FAILURE`:
      return {
        ...state,
        error: action.response,
        // isSizeChanging: setIsSizeChanging(state.isSizeChanging, { remove: null }),
      };
    case `${UPDATE_SIZE}_FAILURE`:
      return {
        ...state,
        error: action.response,
        // isSizeChanging: setIsSizeChanging(state.isSizeChanging, { update: null }),
      };
    case RESET_SIZES:
      return defaultState;
    default:
      return state;
  }
};
