import PropTypes from 'prop-types';
import React, {
  memo, useCallback, useEffect, useRef,
} from 'react';

import { requestCanceler } from '../../../../middlewares/api';

import errorMessages from '../../../../constants/errors';

import { toast } from '../../../../utils';

import View from '../../../Layout/View/View';

import Preloader from '../../../UI/Preloader/Preloader';

import ObjectCategoriesForm from '../CategoriesForm/CategoriesForm';

const CategoriesEdit = ({
  history, location, match: { params: { id } }, objectCategories, onGetObjectCategory, onUpdateObjectCategory, onUnload,
}) => {
  const {
    editedObjectCategory, editedObjectCategoryIsFetching, error, isCategoryChanging,
  } = objectCategories;

  const requestCancelers = useRef([]);

  const handleEdit = useCallback((data) => {
    if (editedObjectCategoryIsFetching || !editedObjectCategory) return;

    const request = requestCanceler();

    requestCancelers.current.push(request);

    onUpdateObjectCategory({ ...data, _id: editedObjectCategory._id }, request.token)
      .then(() => toast('success', 'Категория обновлена!'))
      .catch((err) => err?.message && toast('error', errorMessages[err.message]));
  }, [editedObjectCategory, editedObjectCategoryIsFetching, onUpdateObjectCategory]);

  useEffect(() => {
    const request = requestCanceler();

    requestCancelers.current.push(request);

    onGetObjectCategory(id, request.token)
      .catch(() => history.push('/objects/categories'));
  }, [id, history, location.search, onGetObjectCategory]);

  useEffect(() => () => {
    requestCancelers.current.forEach((canceler) => canceler.cancelRequest());

    onUnload();
  }, [onUnload]);

  return (
    <View
      errorMessage={errorMessages[error?.message]}
      isError={error && ['admin_privileges_required', 'first_request_failed'].includes(error.message)}
      viewClass="object-categories object-categories_edit"
      title="Редактировать категорию"
      content={editedObjectCategoryIsFetching ? (
        <div>
          <Preloader caption="Загрузка..." />
        </div>
      ) : (
        <ObjectCategoriesForm
          buttonText="Сохранить"
          data={editedObjectCategory}
          disabled={isCategoryChanging.update}
          location={location}
          onSubmit={handleEdit}
        />
      )}
    />
  );
};

CategoriesEdit.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  objectCategories: PropTypes.object.isRequired,
  onGetObjectCategory: PropTypes.func.isRequired,
  onUpdateObjectCategory: PropTypes.func.isRequired,
  onUnload: PropTypes.func.isRequired,
};

export default memo(CategoriesEdit);
