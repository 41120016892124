export const APP_LOAD = 'APP_LOAD';
export const VIEW_LOAD = 'VIEW_LOAD';
export const VIEW_UNLOAD = 'VIEW_UNLOAD';
export const GET_SIGNED_URL = 'GET_SIGNED_URL';
export const SEND_FILE_TO_S3 = 'SEND_FILE_TO_S3';

/* --------------- */
/* Action creators */

/* --------------- */

export function appLoad() {
  return {
    type: APP_LOAD,
  };
}

export function getSignedUrl(params, requestToken) {
  return {
    type: GET_SIGNED_URL,
    request: {
      method: 'get',
      url: '/sign_s3',
      params,
      requestToken,
    },
  };
}

export function sendFileToS3(url, data, requestToken) {
  return {
    type: SEND_FILE_TO_S3,
    request: {
      method: 'put',
      headers: {
        'Content-Type': data.type,
        'Cache-Control': 'max-age=0, no-cache',
      },
      url,
      data,
      directUrl: true,
      requestToken,
    },
  };
}
