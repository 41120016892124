import React from 'react';
import PropTypes from 'prop-types';

import './LimitListHint.scss';

const LimitListHint = ({ num }) => (
  <p className="limit-list-hint">{`Вывод ограничен ${num} записями. Пожалуйста, уточните поисковый запрос.`}</p>
);

LimitListHint.propTypes = {
  num: PropTypes.number.isRequired,
};

export default LimitListHint;
