import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Preloader from '../../../../UI/Preloader/Preloader';

const CategoriesCategory = ({
  category, isRemoving, isUpdating, onClickEdit, onDeleteCategory,
}) => {
  const handleDeleteCategory = () => onDeleteCategory(category._id);

  const handleClickEditCategory = () => onClickEdit(category._id);

  const linkTo = `/objects/category/${category._id}`;

  return (
    <div
      className={classNames('template-bunch-item template-bunch-item_object-category', {
        unsaved: category.isUnsaved,
      })}
    >
      <div className="template-bunch-item__header">
        <span className="template-bunch-item__link-title">
          <Link to={linkTo}>{category.name}</Link>
        </span>
        {isUpdating && <Preloader medium />}
        <span className="template-bunch-item__count">
          <Link to={linkTo}>{`Объекты: ${category.count_objects || 0}`}</Link>
        </span>
        <button
          className="template-bunch-item__button template-bunch-item__button_edit"
          type="button"
          onClick={handleClickEditCategory}
        >
          <FontAwesomeIcon icon={['fas', 'pencil']} />
        </button>
        <button onClick={handleDeleteCategory} disabled={isRemoving} className="template-bunch-item__button template-bunch-item__button_delete" type="button">
          <FontAwesomeIcon icon={['fal', 'times']} />
        </button>
      </div>
    </div>
  );
};

CategoriesCategory.propTypes = {
  category: PropTypes.object,
  isRemoving: PropTypes.bool,
  isUpdating: PropTypes.bool,
  onClickEdit: PropTypes.func.isRequired,
  onDeleteCategory: PropTypes.func,
};

export default memo(CategoriesCategory);
