import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import cloneDeep from 'lodash/cloneDeep';

import { smoothScrollTo } from '../../../../utils';

const AccessAdminIPAddressForm = ({
  defaultData, history, isEditMode, addresses, onSubmit,
}) => {
  const user = useSelector(({ auth }) => auth.user);

  const [formData, setFormData] = useState(defaultData || {
    address: '',
    description: '',
  });
  const [validFields, setValidFields] = useState({
    address: !!defaultData?.address,
    description: true,
  });
  const [pristineFields, setPristineFields] = useState({
    address: true,
    description: true,
  });

  const goBack = useCallback(() => {
    smoothScrollTo(document.documentElement, 0);

    history.push('/access/ip-addresses');
  }, [history]);

  const handleChangeData = useCallback((value) => setFormData({ ...formData, ...value }), [formData]);

  const handleChangeAddress = useCallback((e) => {
    if (isEditMode && user?.remote_ip === defaultData?.address) return;

    const isInvalid = !e.target.value || !/^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$|^::1$/.test(e.target.value);

    setPristineFields({ ...pristineFields, address: false });

    setValidFields({ ...validFields, address: !isInvalid });

    handleChangeData({ address: e.target.value });
  }, [handleChangeData, pristineFields, validFields, defaultData?.address, isEditMode, user?.remote_ip]);

  const handleChangeDescription = useCallback((e) => {
    const isInvalid = e.target.value.length > 300;

    setPristineFields({ ...pristineFields, description: false });

    setValidFields({ ...validFields, description: !isInvalid });

    handleChangeData({ description: e.target.value });
  }, [handleChangeData, pristineFields, validFields]);

  const handleFormSubmit = useCallback(() => {
    const resultData = cloneDeep(formData);

    onSubmit(resultData, goBack);
  }, [formData, goBack, onSubmit]);

  return (
    <div className="ip-address-form">
      <div className="ip-address-form__row ip-address-form__address">
        <div className="ip-address-form__label">IP-адрес:</div>
        <Form.Control
          disabled={addresses.isFetching}
          readOnly={isEditMode && user?.remote_ip === defaultData?.address}
          placeholder=""
          value={formData.address}
          isInvalid={!validFields.address && !pristineFields.address}
          onChange={handleChangeAddress}
        />
      </div>
      <div className="ip-address-form__row">
        <div className="ip-address-form__label">Описание:</div>
        <Form.Control
          disabled={addresses.isFetching}
          placeholder=""
          value={formData.description || ''}
          isInvalid={!validFields.description}
          onChange={handleChangeDescription}
        />
      </div>
      <div className="ip-address-form__footer">
        <Button className="ip-address-form__submit-btn" onClick={handleFormSubmit} variant="success" size="sm" disabled={addresses.isFetching || Object.values(validFields).some((value) => !value)}>
          {isEditMode ? 'Изменить адрес' : 'Создать адрес'}
        </Button>
        <div // eslint-disable-line
          role="button"
          onClick={goBack}
          className="ip-address-form__link"
        >
          Назад
        </div>
      </div>
    </div>
  );
};

AccessAdminIPAddressForm.propTypes = {
  defaultData: PropTypes.object,
  history: PropTypes.object,
  isEditMode: PropTypes.bool,
  addresses: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default withRouter(AccessAdminIPAddressForm);
