import React from 'react';

import Nav from '../../../Includes/Nav/Nav';

class FooterNav extends React.PureComponent {
  list = [
    {
      key: 'terms',
      itemClassName: null,
      link: 'https://supa.ru/terms',
      text: 'Публичная оферта',
      target: '_blank',
    },
  ];

  render() {
    return <Nav list={this.list} />;
  }
}

export default FooterNav;
