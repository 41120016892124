export const SIZE_TYPES = {
  fixed: 'фикс.',
  custom: 'свой',
};

export const SIZE_MODES = {
  image: 'изобр.',
  video: 'видео',
};

export const SIZE_UNITS = ['px', 'cm', 'mm', 'in'];

export const SIZE_PRESET_SCHEMA = {
  mode: 'video',
  size: [600, 600],
  type: 'fixed',
  units: 'px',
  title: '',
};
