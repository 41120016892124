import { connect } from 'react-redux';

import {
  downloadElamaUsers,
} from '../../../../actions';

import Elama from './Elama';

const mapDispatchToProps = (dispatch) => ({
  onDownload: (data, requestToken) => dispatch(downloadElamaUsers(data, requestToken)),
});

export default connect(null, mapDispatchToProps)(Elama);
