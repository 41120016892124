import PropTypes from 'prop-types';
import React, {
  memo,
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import {
  Button,
  Dropdown,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { requestCanceler } from '../../../../middlewares/api';

import successMessages from '../../../../constants/successMessages';

import { copyToClipboard, toast } from '../../../../utils';

import sweetAlert from '../../../HOCs/sweetAlert';

import FieldForm from '../../../Includes/FieldForm/FieldForm';

import CommentIcon from '../../../UI/CommentIcon/CommentIcon';

import UsersUserMain from './UsersUserMain/UsersUserMain';

import './UsersUser.scss';

const AUTH_METHODS = {
  google: 'Google',
  facebook: 'Facebook',
  elama: 'Elama',
  vk: 'VK',
  yandex: 'Яндекс',
};

function UsersUser({
  services,
  user,
  onAddVideo,
  onChangeUser,
  onCreateInvoice,
  onChangePeriodDate,
  onDeletePeriod,
  onGetUserLogs,
  onRemovePeriodMigrationID,
  onUserSignIn,
  onUserUnbindOauth,
}) {
  const [isMainVisible, setIsMainVisible] = useState(false);
  const [isUserChanging, setIsUserChanging] = useState({
    email: false,
    confirmed: false,
    confirmAttempts: false,
    invoiceCreating: false,
    signin: false,
    unbindOauth: false,
  });

  const requestCancelers = useRef([]);

  useEffect(() => () => requestCancelers.current.forEach((canceler) => canceler.cancelRequest()), []);

  const toggleMainVisible = () => setIsMainVisible(!isMainVisible);

  const handleIsUserChanging = (props) => setIsUserChanging((prev) => ({ ...prev, ...props }));

  const handleChangeUser = useCallback((newData) => {
    const requestChangeUserCanceler = requestCanceler();

    requestCancelers.current.push(requestChangeUserCanceler);

    return onChangeUser(user.id, newData, requestChangeUserCanceler.token);
  }, [user.id, onChangeUser]);

  const handleUpdateEmail = useCallback((email) => {
    if (!email.match(/.+@.+\..+/i)) return undefined;

    if (user.oauth_provider) {
      return sweetAlert.fire({
        confirmButtonText: 'Ок',
        type: 'error',
        title: 'Ошибка',
        html: `Изменить E-Mail нельзя!<br />Сначала необходимо отвязать<br />пользователя от ${AUTH_METHODS[user.oauth_provider]}`,
      });
    }

    return sweetAlert.fire({
      cancelButtonText: 'Отмена',
      confirmButtonText: 'Да, я уверен',
      icon: <FontAwesomeIcon icon={['fal', 'exclamation-triangle']} />,
      type: 'warning',
      showCancelButton: true,
      title: 'Вы уверены?',
      html: 'Подтвердить изменение E-mail пользователя?',
    }).then((result) => {
      if (!result.value) return;

      handleIsUserChanging({ email: true });

      handleChangeUser({ email: email.trim() })
        .then(() => toast('success', successMessages.email))
        .finally(() => handleIsUserChanging({ email: false }));
    });
  }, [handleChangeUser, user.oauth_provider]);

  const handleToggleConfirmEmail = useCallback(() => {
    handleIsUserChanging({ confirmed: true });

    handleChangeUser({ confirmed: !user.confirmed })
      .then((res) => toast('success', `Статус E-mail изменен на «${res.confirmed ? 'подтвержден' : 'не подтвержден'}»`))
      .finally(() => handleIsUserChanging({ confirmed: false }));
  }, [user.confirmed, handleChangeUser]);

  const handleResetConfirmAttempts = useCallback(() => {
    handleIsUserChanging({ confirmAttempts: true });

    handleChangeUser({ confirm_attempts: 0 })
      .then(() => toast('success', successMessages.confirm_attempts))
      .finally(() => handleIsUserChanging({ confirmAttempts: false }));
  }, [handleChangeUser]);

  const handleUnbindOauth = useCallback(() => {
    sweetAlert.fire({
      cancelButtonText: 'Отмена',
      confirmButtonText: 'Отвязать',
      icon: <FontAwesomeIcon icon={['fal', 'exclamation-triangle']} />,
      showCancelButton: true,
      type: 'warning',
      title: 'Вы уверены?',
      html: 'Для подтверждения напишите текст<br /> "<b>UNBIND AUTH</b>" и нажмите "Отвязать":',
      input: 'text',
      inputValue: '',
      inputValidator: (text) => {
        if (text.trim() !== 'UNBIND AUTH') return 'Некорректный текст';

        return false;
      },
      inputAttributes: {
        maxlength: 11,
      },
    }).then((result) => {
      if (!result.value) return;

      handleIsUserChanging({ unbindOauth: true });

      const requestUserUnbindOauthCanceler = requestCanceler();

      requestCancelers.current.push(requestUserUnbindOauthCanceler);

      onUserUnbindOauth(user.id, requestUserUnbindOauthCanceler.token)
        .then(() => toast('success', successMessages.unbind_oauth))
        .finally(() => handleIsUserChanging({ unbindOauth: false }));
    });
  }, [onUserUnbindOauth, user.id]);

  const handleCreateInvoice = useCallback(({ target }) => {
    handleIsUserChanging({ invoiceCreating: true });

    const requestCreateInvoiceCanceler = requestCanceler();

    requestCancelers.current.push(requestCreateInvoiceCanceler);

    onCreateInvoice({ user_id: user.id, service_id: +target.dataset.service }, requestCreateInvoiceCanceler.token)
      .then(() => toast('success', successMessages.invoice_creating))
      .finally(() => handleIsUserChanging({ invoiceCreating: false }));
  }, [user.id, onCreateInvoice]);

  const handleUserSignIn = useCallback(() => {
    handleIsUserChanging({ signin: true });

    const requestUserSignInCanceler = requestCanceler();

    requestCancelers.current.push(requestUserSignInCanceler);

    onUserSignIn({ login: user.email }, requestUserSignInCanceler.token)
      .then(({ url }) => copyToClipboard(url))
      .then(() => toast('success', successMessages.signin))
      .finally(() => handleIsUserChanging({ signin: false }));
  }, [user.email, onUserSignIn]);

  return (
    <div className="user">
      <div className="user__header">
        <div className="user__id">{`#${user.id}`}</div>
        <div className="user__email">
          <FieldForm
            buttonText={<FontAwesomeIcon icon={['fas', 'check']} />}
            disabled={isUserChanging.email}
            defaultValue={user.email}
            fieldType="input"
            readOnly={isUserChanging.email}
            onSubmit={handleUpdateEmail}
          />
        </div>
        <div className="user__dropdown">
          <Dropdown>
            <Dropdown.Toggle as="button" className="btn btn-light">Действие...</Dropdown.Toggle>
            <Dropdown.Menu>
              {services.map((item) => (
                item.text
                  ? (
                    <Dropdown.Item
                      as="button"
                      disabled={isUserChanging.invoiceCreating}
                      data-service={item.key}
                      key={item.key}
                      onClick={handleCreateInvoice}
                    >
                      {item.text}
                    </Dropdown.Item>
                  )
                  : <Dropdown.Divider key={item.key} />
              ))}
              <Dropdown.Item
                as="button"
                disabled={isUserChanging.confirmed}
                onClick={handleToggleConfirmEmail}
              >
                {user.confirmed ? 'Отменить подтверждение E-Mail' : 'Подтвердить E-Mail'}
              </Dropdown.Item>
              <Dropdown.Item
                as="button"
                disabled={isUserChanging.confirmAttempts}
                onClick={handleResetConfirmAttempts}
              >
                Сбросить попытки подтверждения E-Mail
              </Dropdown.Item>
              {user.oauth_provider && (
                <>
                  <Dropdown.Divider />
                  <Dropdown.Item
                    as="button"
                    disabled={!user.oauth_provider || isUserChanging.unbindOauth}
                    onClick={handleUnbindOauth}
                  >
                    {`Отвязать аккаунт от ${AUTH_METHODS[user.oauth_provider]}`}
                  </Dropdown.Item>
                </>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="user__comment">
          <CommentIcon comment={user?.comment} />
        </div>
        {user.login && (
          <div className="user__login">
            Логин:&nbsp;
            <strong>{user.login}</strong>
          </div>
        )}
        <div className="user__lang">
          Язык:&nbsp;
          <span>{user.lang}</span>
        </div>
        <div className="user__options">
          <Button variant="info" size="sm" disabled={isUserChanging.signin} onClick={handleUserSignIn}>
            <FontAwesomeIcon icon={['fas', 'sign-in-alt']} />
          </Button>
          <Button variant="success" size="sm" active={isMainVisible} onClick={toggleMainVisible}>
            <FontAwesomeIcon icon={['fas', 'info']} />
          </Button>
        </div>
      </div>
      {isMainVisible && (
        <UsersUserMain
          user={user}
          handleChangeUser={handleChangeUser}
          onAddVideo={onAddVideo}
          onDeletePeriod={onDeletePeriod}
          onChangePeriodDate={onChangePeriodDate}
          onGetUserLogs={onGetUserLogs}
          onRemovePeriodMigrationID={onRemovePeriodMigrationID}
        />
      )}
    </div>
  );
}

UsersUser.propTypes = {
  services: PropTypes.array,
  user: PropTypes.object,
  onAddVideo: PropTypes.func,
  onChangeUser: PropTypes.func,
  onChangePeriodDate: PropTypes.func,
  onCreateInvoice: PropTypes.func,
  onDeletePeriod: PropTypes.func,
  onGetUserLogs: PropTypes.func,
  onRemovePeriodMigrationID: PropTypes.func,
  onUserSignIn: PropTypes.func,
  onUserUnbindOauth: PropTypes.func,
};

export default memo(UsersUser);
