import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CheckBox from '../../../../../UI/CheckBox/CheckBox';
import Preloader from '../../../../../UI/Preloader/Preloader';

const TemplatesTemplateCategoriesSelect = ({
  allCategories, categories, disabled, templateId, onChange,
}) => {
  const checkSelected = (templateCategories, currentCategory) => {
    const finded = templateCategories.find((cat) => cat.category_id === currentCategory.id);
    const selected = Boolean(finded) && finded?.selected;

    return selected;
  };

  return (
    <div className={classNames('template__categories-list', { disabled })}>
      {allCategories.length ? allCategories.map((item) => (
        <CheckBox
          checked={checkSelected(categories, item)}
          key={templateId + item._id}
          id={templateId + item._id}
          onChange={(value) => onChange(value, { order: item.order, category_id: item.id, _id: item._id }, item.type)}
        >
          {item.title}
        </CheckBox>
      )) : <Preloader medium />}
    </div>
  );
};

TemplatesTemplateCategoriesSelect.propTypes = {
  allCategories: PropTypes.array,
  categories: PropTypes.array,
  disabled: PropTypes.bool,
  templateId: PropTypes.number,
  onChange: PropTypes.func,
};

const TemplatesTemplateCategories = ({
  allCategories, categories, disabled, isVisible, templateId, toggleIsVisible, onChange,
}) => {
  const selectedCategories = useMemo(() => allCategories.length && allCategories.map((item) => (
    categories.find((cat) => cat.category_id === item.id && cat?.selected) ? (
      <div className="template__categories-selected" key={item.id}>{item.title}</div>
    ) : null
  )), [allCategories, categories]);

  return (
    <div className="template__row template__categories">
      <div className="template__categories-content">
        <div className="template__label">Категории:</div>
        <div className="template__row-content">
          <div className="template__categories-actions">
            {selectedCategories && selectedCategories.length ? selectedCategories : <Preloader small />}
            <Button active={isVisible} variant="light" size="sm" onClick={toggleIsVisible}>
              <FontAwesomeIcon icon={['fas', 'pencil']} />
            </Button>
          </div>
        </div>
      </div>
      {isVisible ? (
        <TemplatesTemplateCategoriesSelect
          disabled={disabled}
          allCategories={allCategories}
          categories={categories}
          templateId={templateId}
          onChange={onChange}
        />
      ) : null}
    </div>
  );
};

TemplatesTemplateCategories.propTypes = {
  allCategories: PropTypes.array,
  categories: PropTypes.array,
  disabled: PropTypes.bool,
  isVisible: PropTypes.bool,
  templateId: PropTypes.number,
  toggleIsVisible: PropTypes.func,
  onChange: PropTypes.func,
};

export default memo(TemplatesTemplateCategories);
