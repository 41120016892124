import React, {
  memo, useCallback, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Dropdown, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Preloader from '../../../../UI/Preloader/Preloader';
import CheckBox from '../../../../UI/CheckBox/CheckBox';
import SearchDropdown from '../../../../UI/SearchDropdown/SearchDropdown';

import './PageFormContent.scss';

const PageFormContent = memo((props) => {
  const {
    isFormDisabled,
    isFetching,
    isPagesFetching,
    data,
    mode,
    providerProjects,
    categories,
    projectPages,
    onSubmit,
    onSyncProviderPages,
    onUpdate,
  } = props;

  const [search, setSearch] = useState('');

  const dropdownItems = useMemo(() => {
    const items = projectPages.filter((page) => {
      const itemText = page.title?.toLowerCase() || '';

      return itemText.includes(search.trim().toLowerCase());
    }) || [];

    return search.trim() ? items : items.reverse();
  }, [search, projectPages]);

  const handleChangeAlias = (value) => {
    const regExp = /^[a-z0-9]+(-[a-z0-9]+)*-?$/g;
    const isValid = regExp.test(value);

    if (!isValid && value) return;

    onUpdate({ alias: value.trim() });
  };

  const handleToggleIsMain = useCallback((state) => onUpdate({ is_main: state }), [onUpdate]);

  const handleSelectProviderProject = (id) => () => (
    onUpdate({
      data_provider_resource: { page_id: '', project_id: id },
    })
  );

  const handleSelectProviderPage = (id) => () => (
    onUpdate({
      data_provider_resource: { ...data.data_provider_resource, page_id: id },
    })
  );

  const handleClickEditPage = () => (
    window.open(`https://tilda.cc/page/?pageid=${data?.data_provider_resource?.page_id}&projectid=${data?.data_provider_resource?.project_id}`, '_blank')
  );

  const handleChooseCategory = (id) => () => onUpdate({ category_id: id });
  const handleChooseStatus = (status) => () => onUpdate({ status });
  const handleChangeSeo = (field, value) => onUpdate({ seo: { ...data.seo, [field]: value } });

  return (
    <form className="page-form landing-form" onSubmit={onSubmit}>
      <h3 className="landing-form__title">{`${mode === 'create' ? 'Добавить' : 'Обновить'} страницу`}</h3>
      <div className="landing-form__fields">

        <div className="landing-form__field">
          <div className="landing-form__label" title="Название">Название:</div>
          <div className="landing-form__value" data-name="name">
            <Form.Control
              disabled={isFetching}
              value={data.name}
              onChange={(e) => onUpdate({ name: e.target.value })}
            />
          </div>
        </div>

        <div className="landing-form__field">
          <div className="landing-form__label" title="Алиас">Алиас:</div>
          <div className="landing-form__value" data-name="alias">
            <Form.Control
              disabled={isFetching || !!data.is_main}
              value={data.is_main ? '' : data.alias}
              onChange={(e) => handleChangeAlias(e.target.value)}
            />
            <CheckBox
              checked={!!data.is_main}
              disabled={isFetching}
              className="flat landing-form__checkbox"
              id="main-page-checkbox"
              onChange={handleToggleIsMain}
            >
              Главная страница
            </CheckBox>
          </div>
        </div>

        <div className="landing-form__field">
          <div className="landing-form__label" title="Источник данных">Источник данных:</div>
          <div className="landing-form__value landing-form__value_multiple" data-name="provider">
            <Dropdown className="landing__dropdown" data-name="provider">
              <Dropdown.Toggle
                as="button"
                type="button"
                className="btn btn-light"
                disabled={isFetching || isPagesFetching}
              >
                Tilda
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item active as="button" type="button">
                  Tilda
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown className="landing__dropdown" data-name="project">
              <Dropdown.Toggle
                as="button"
                type="button"
                className="btn btn-light"
                disabled={isFetching || isPagesFetching}
              >
                {providerProjects?.find((p) => p.id === data?.data_provider_resource?.project_id)?.title || 'Выберите проект...'}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {!providerProjects?.length && (
                  <Dropdown.Item
                    as="button"
                    type="button"
                    style={{ opacity: 0.5, pointerEvents: 'none' }}
                  >
                    Список пуст
                  </Dropdown.Item>
                )}
                {providerProjects && providerProjects?.length > 0 && providerProjects.map((project) => (
                  <Dropdown.Item
                    key={project.id}
                    as="button"
                    type="button"
                    onClick={handleSelectProviderProject(project.id)}
                  >
                    {project.title}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>

            <SearchDropdown
              className="page-form__search-dropdown"
              placeholder="Найти раздел"
              value={projectPages?.find((p) => p.id === data?.data_provider_resource?.page_id)?.title || 'Выберите страницу...'}
              onSearch={setSearch}
            >
              {dropdownItems.map((page) => (
                <li className="page-form__search-dropdown-item" key={page.id}>
                  <button onClick={handleSelectProviderPage(page.id)} type="button">
                    {page.title}
                  </button>
                </li>
              ))}
            </SearchDropdown>

            <button
              type="button"
              className="page-form__btn"
              disabled={isFetching || isPagesFetching}
              onClick={onSyncProviderPages}
            >
              <FontAwesomeIcon icon={['fas', 'sync-alt']} />
            </button>
            <button type="button" className="page-form__btn" onClick={handleClickEditPage}>
              <FontAwesomeIcon icon={['fas', 'pen']} />
            </button>
          </div>
        </div>

        <div className="landing-form__field">
          <div className="landing-form__label" title="Категория">Категория:</div>
          <div className="landing-form__value" data-name="category">
            <Dropdown className="landing__dropdown">
              <Dropdown.Toggle
                as="button"
                type="button"
                className="btn btn-light"
                disabled={isFetching || !!data.is_main}
              >
                {
                  data.is_main
                    ? 'Без категории'
                    : (categories.find((category) => category._id === data?.category_id)?.name || 'Без категории')
                }
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  as="button"
                  type="button"
                  onClick={handleChooseCategory('')}
                >
                  Без категории
                </Dropdown.Item>
                {categories.map((category) => (
                  <Dropdown.Item
                    key={category._id}
                    as="button"
                    type="button"
                    onClick={handleChooseCategory(category._id)}
                  >
                    {category.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        <div className="landing-form__field">
          <div className="landing-form__label" title="Статус">Статус:</div>
          <div className="landing-form__value" data-name="status">
            <Dropdown className="landing__dropdown">
              <Dropdown.Toggle
                as="button"
                type="button"
                className="btn btn-light"
                disabled={isFetching}
              >
                {data.status === 'public' ? 'Доступна публично' : 'Только на stage'}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  active={data.status === 'public'}
                  as="button"
                  type="button"
                  onClick={handleChooseStatus('public')}
                >
                  Доступна публично
                </Dropdown.Item>
                <Dropdown.Item
                  active={data.status === 'stage_only'}
                  as="button"
                  type="button"
                  onClick={handleChooseStatus('stage_only')}
                >
                  Только на stage
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        <div className="landing-form__field">
          <div className="landing-form__label" title="SEO Title">SEO Title:</div>
          <div className="landing-form__value" data-name="seo-title">
            <Form.Control
              disabled={isFetching}
              value={data.seo?.title || ''}
              onChange={(e) => handleChangeSeo('title', e.target.value)}
            />
          </div>
        </div>

        <div className="landing-form__field">
          <div className="landing-form__label" title="SEO Description">SEO Description:</div>
          <div className="landing-form__value" data-name="seo-description">
            <Form.Control
              disabled={isFetching}
              value={data.seo?.description || ''}
              onChange={(e) => handleChangeSeo('description', e.target.value)}
            />
          </div>
        </div>

        <div className="landing-form__field">
          <div className="landing-form__label" title="SEO Keywords">SEO Keywords:</div>
          <div className="landing-form__value" data-name="seo-keywords">
            <Form.Control
              disabled={isFetching}
              value={data.seo?.keywords || ''}
              onChange={(e) => handleChangeSeo('keywords', e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="landing-form__actions">
        <Button
          disabled={isFormDisabled}
          variant="success"
          className="landing-form__action"
          type="submit"
        >
          {`${mode === 'create' ? 'Добавить' : 'Обновить'} страницу`}
        </Button>
        <Link to="/landing/pages" className="landing-form__action landing-form__action_link">
          Назад
        </Link>

        {(isFetching || isPagesFetching) && <Preloader medium />}
      </div>
    </form>
  );
});

PageFormContent.propTypes = {
  isFormDisabled: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isPagesFetching: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  providerProjects: PropTypes.array,
  projectPages: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSyncProviderPages: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default PageFormContent;
