import React from 'react';
import PropTypes from 'prop-types';

import './NoMedia.scss';

function NoMedia({
  action,
  caption,
  className,
  icon,
  inline,
}) {
  return (
    <div className={`no-media${inline ? ' no-media_inline' : ''}${className ? ` ${className}` : ''}`}>
      <div className="no-media__icon">{icon}</div>
      {caption && <div className="no-media__caption" dangerouslySetInnerHTML={{ __html: caption }} />}
      {action && (
        <div className="no-media__action">
          <button className={`btn btn-${action.style || 'success'}`} type="button" onClick={() => action.onClick()}>{action.caption}</button>
        </div>
      )}
    </div>
  );
}

NoMedia.propTypes = {
  action: PropTypes.oneOfType([
    PropTypes.shape({
      caption: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    }),
    PropTypes.bool,
  ]),
  caption: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.element.isRequired,
  inline: PropTypes.bool,
};

export default NoMedia;
