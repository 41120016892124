import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { s3Replace } from '../../../../../../utils';

const TemplatesTemplatePreview = ({ alt, small = false, src }) => (
  <div className={classNames('template__preview', { template__preview_broken: !src, template__preview_small: small })}>
    <img
      alt={alt}
      src={s3Replace(src)}
    />
    {!src && <FontAwesomeIcon icon={['fas', 'image']} />}
  </div>
);

TemplatesTemplatePreview.propTypes = {
  alt: PropTypes.string,
  small: PropTypes.bool,
  src: PropTypes.string,
};

export default TemplatesTemplatePreview;
