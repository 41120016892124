import React, {
  memo, useCallback, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  createPageCategory,
  getLandingMenus,
  initLandingForm,
  resetLandingForm,
  updateLandingForm,
} from '../../../../../actions';

import withRequest from '../../../../HOCs/withRequest';

import { toast } from '../../../../../utils';

import CategoryFormContent from '../CategoryFormContent/CategoryFormContent';

const CategoryFormCreate = memo((props) => {
  const { dispatch, createCanceler, lang } = props;

  const history = useHistory();

  const data = useSelector(({ landing }) => landing.form);
  const isFetching = useSelector(({ landing }) => landing.isFetching);

  const [isMenusFetching, setIsMenusFetching] = useState(false);
  const [menus, setMenus] = useState([]);

  const isUnmounted = useRef(false);

  const handleUpdateForm = useCallback((updateData) => dispatch(updateLandingForm(updateData)), [dispatch]);

  const fetchMenus = useCallback(() => {
    const request = createCanceler();

    setIsMenusFetching(true);

    dispatch(getLandingMenus({ simple_list: true, lang }, request.token))
      .then((response) => setMenus(response))
      .finally(() => {
        if (!isUnmounted.current) setIsMenusFetching(false);
      });
  }, [createCanceler, dispatch, lang]);

  const handleSubmitForm = useCallback((e) => {
    e.preventDefault();

    const {
      side_menu_id, name, alias, show_headers, show_page_navigation,
    } = data;

    // eslint-disable-next-line
    dispatch(createPageCategory({ side_menu_id, show_headers, show_page_navigation, name, alias, lang }))
      .then(() => {
        toast('success', 'Категория успешно добавлена!');

        history.push('/landing/pages');
      })
      .catch(({ message }) => {
        if (message === 'alias_already_exist') return toast('error', 'Категория с таким алиасом уже существует');

        return toast('error', 'Ошибка добавления категории');
      });
  }, [data, dispatch, history, lang]);

  useEffect(() => {
    dispatch(initLandingForm());

    return () => {
      isUnmounted.current = true;

      dispatch(resetLandingForm());
    };
  }, [dispatch]);

  useEffect(() => {
    fetchMenus();
  }, [fetchMenus]);

  const isFormDisabled = isFetching || isMenusFetching || !data?.name || !data?.alias;

  return (
    <CategoryFormContent
      isFormDisabled={isFormDisabled}
      isFetching={isFetching}
      isMenusFetching={isMenusFetching}
      data={data}
      mode="create"
      menus={menus}
      onSubmit={handleSubmitForm}
      onUpdate={handleUpdateForm}
    />
  );
});

CategoryFormCreate.propTypes = {
  createCanceler: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  lang: PropTypes.string,
};

export default withRequest(CategoryFormCreate);
