import PropTypes from 'prop-types';
import React from 'react';
import queryString from 'query-string';
import { Switch, Route } from 'react-router-dom';

import errorMessages from '../../../constants/errors';

import { requestCanceler } from '../../../middlewares/api';

import { toast } from '../../../utils';

import { GET_IDEAS } from '../../../actions';

import View from '../../Layout/View/View';

import IdeasList from './IdeasList/IdeasList';
import IdeasCreate from './IdeasCreate/IdeasCreate';
import IdeasEdit from './IdeasEdit/IdeasEdit';

import './Ideas.scss';

class Ideas extends React.PureComponent {
  limit = 12;

  requestCancelers = [];

  state = {
    isIdeaChanging: false,
    viewTitle: 'Идеи постов',
  };

  componentDidMount() {
    const { location: { search }, ideas } = this.props;

    const isSearch = queryString.parse(search)?.search;

    if (Boolean(isSearch) || !ideas.docs.length) this.getIdeas({ page: 1 });

    window.addEventListener('online', this.checkOnlineState);
  }

  componentDidUpdate(prevProps) {
    const { ideas, location } = this.props;
    const { error } = ideas;

    if (location !== prevProps.location) {
      this.getIdeas({ isSearch: true });
    }

    if (error && error.message && error !== prevProps.ideas.error) {
      let message = ['Network Error', 'idea_not_exist', 'admin_privileges_required', 'data_incorrect', 'slug_exist'].includes(error.message)
        ? errorMessages[error.message]
        : error.message;

      message = ['idea_template_not_exist', 'idea_templates_not_exist'].includes(error.message)
        ? errorMessages[error.message]
          .replace('template_ids', error.template_ids.join(', '))
          .replace('lang', error.lang)
        : message;

      toast('error', message);
    }
  }

  componentWillUnmount() {
    const { onUnload } = this.props;

    this.requestCancelers.forEach((canceler) => canceler.cancelRequest());

    window.removeEventListener('online', this.checkOnlineState);

    onUnload();
  }

  getIdeas = ({ page = 1, isSearch = false }) => {
    const { location, onGetIdeas } = this.props;

    const requestParams = { limit: this.limit, lang: 'ru', page };

    if (location.search) {
      Object.assign(requestParams, queryString.parse(location.search));
    }

    const requestGetInvoicesCanceler = requestCanceler();

    this.requestCancelers.push(requestGetInvoicesCanceler);

    onGetIdeas(requestParams, isSearch, requestGetInvoicesCanceler.token).catch(() => {});
  };

  loadMore = () => {
    const { ideas } = this.props;

    if (ideas.isFetching) return;

    this.getIdeas({ page: ideas.page + 1 });
  };

  handleSearchIdeas = (lang) => {
    const { history, location } = this.props;

    const queryStr = !lang || lang === 'ru' ? '/ideas' : `/ideas?lang=${lang}`;

    if (queryString.parse(location.search)?.lang === lang) {
      this.getIdeas({ isSearch: true });
    } else {
      history.push(queryStr);
    }
  };

  checkOnlineState = () => {
    const { lastActionType } = this.props.ideas;

    if (navigator.onLine && lastActionType === `${GET_IDEAS}_REQUEST`) this.loadMore();
  };

  handleUpdateIdea = (id, formData) => {
    const { history, onUpdateIdea } = this.props;

    this.handleIsIdeaChanging(true);

    const requestUpdateIdeaCanceler = requestCanceler();

    this.requestCancelers.push(requestUpdateIdeaCanceler);

    onUpdateIdea(id, formData, requestUpdateIdeaCanceler.token)
      .then(() => {
        toast('success', 'Идея обновлена');

        history.push(formData.lang === 'ru' ? '/ideas' : `/ideas?lang=${formData.lang}`);
      })
      .finally(() => this.handleIsIdeaChanging(false));
  };

  handleCreateIdea = (formData) => {
    const { history, onCreateIdea } = this.props;

    this.handleIsIdeaChanging(true);

    const requestCreateIdeaCanceler = requestCanceler();

    this.requestCancelers.push(requestCreateIdeaCanceler);

    onCreateIdea(formData, requestCreateIdeaCanceler.token)
      .then(() => {
        toast('success', 'Идея создана');

        history.push(formData.lang === 'ru' ? '/ideas' : `/ideas?lang=${formData.lang}`);
      })
      .finally(() => this.handleIsIdeaChanging(false));
  };

  handleIsIdeaChanging = (isIdeaChanging) => this.setState({ isIdeaChanging });

  setViewTitle = (viewTitle) => this.setState({ viewTitle });

  render() {
    const { ideas, onGetIdea } = this.props;
    const { error, isFetching, editedIdeaIsFetching } = ideas;

    const { isIdeaChanging, viewTitle } = this.state;

    return (
      <View
        errorMessage={errorMessages[error?.message]}
        isError={error && ['admin_privileges_required', 'first_request_failed'].includes(error.message)}
        isFetching={isFetching || editedIdeaIsFetching}
        preloaderCaption="Загружаем..."
        title={viewTitle}
        viewClass="ideas"
        content={(
          <Switch>
            <Route
              exact
              path="/ideas"
              // eslint-disable-next-line
              render={(props) => (
                <IdeasList
                  ideas={ideas}
                  location={props.location}
                  setViewTitle={this.setViewTitle}
                  onLoadMore={this.loadMore}
                  onSearchIdeas={this.handleSearchIdeas}
                />
              )}
            />
            <Route
              exact
              path="/ideas/create"
              render={(props) => (
                <IdeasCreate
                  {...props}
                  isEditing={isIdeaChanging}
                  ideas={ideas}
                  setViewTitle={this.setViewTitle}
                  onCreateIdea={this.handleCreateIdea}
                />
              )}
            />
            <Route
              exact
              path="/ideas/:id/edit"
              render={(props) => (
                <IdeasEdit
                  {...props}
                  isEditing={isIdeaChanging}
                  ideas={ideas}
                  setViewTitle={this.setViewTitle}
                  onGetIdea={onGetIdea}
                  onUpdateIdea={this.handleUpdateIdea}
                />
              )}
            />
          </Switch>
        )}
      />
    );
  }
}

Ideas.propTypes = {
  history: PropTypes.object,
  ideas: PropTypes.shape({
    docs: PropTypes.array,
    error: PropTypes.object,
    editedIdeaIsFetching: PropTypes.bool,
    lastActionType: PropTypes.string,
    page: PropTypes.number,
    pages: PropTypes.number,
    total: PropTypes.number,
    isFetching: PropTypes.bool,
  }),
  location: PropTypes.object,
  onCreateIdea: PropTypes.func.isRequired,
  onGetIdea: PropTypes.func.isRequired,
  onGetIdeas: PropTypes.func.isRequired,
  onUpdateIdea: PropTypes.func.isRequired,
  onUnload: PropTypes.func.isRequired,
};

export default Ideas;
