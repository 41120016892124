import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { toast } from '../../../../utils';

import errorMessages from '../../../../constants/errors';

import { addAdminPrivileges } from '../../../../actions';

import withRequest from '../../../HOCs/withRequest';

import View from '../../../Layout/View/View';

import AccessSubnav from '../../../Includes/Access/AccessSubnav/AccessSubnav';

import AccessAdminsForm from '../AccessAdminsForm/AccessAdminsForm';

const AccessAdminsCreate = ({ createCanceler, dispatch }) => {
  const admins = useSelector(({ accessAdmins }) => accessAdmins);

  const handleCreate = useCallback((formData, successCallback) => {
    const createAdminRequest = createCanceler();

    dispatch(addAdminPrivileges(formData, createAdminRequest.token))
      .then(() => {
        toast('success', 'Администратор добавлен!');

        successCallback();
      })
      .catch((error) => {
        const message = errorMessages[error.message] || error.message;

        toast('error', message);
      });
  }, [createCanceler, dispatch]);

  return (
    <View
      errorMessage={errorMessages[admins.error?.message]}
      isError={admins.error && ['admin_privileges_required'].includes(admins.error.message)}
      isFetching={false}
      preloaderCaption="Загружаем..."
      header={<AccessSubnav />}
      isHeaderBeforeTitle
      title="Добавить администратора"
      viewClass="admin-create"
      content={<AccessAdminsForm admins={admins} onSubmit={handleCreate} />}
    />
  );
};

AccessAdminsCreate.propTypes = {
  createCanceler: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default memo(withRequest(AccessAdminsCreate));
