import cloneDeep from 'lodash/cloneDeep';

import {
  ADD_ELEMENT_TO_MENU,
  DELETE_MENU_ELEMENT,
  DELETE_LANDING_MENU,
  CANCEL_MENU_CHANGES,
  CHANGE_MENU_ELEMENTS_ORDER,
  CHANGE_MENU_ELEMENT_LEVEL,
  COPY_MENU_ELEMENT,
  GET_LANDING_MENUS,
  CREATE_LANDING_MENU,
  UPDATE_MENU_ELEMENT,
  UPDATE_LANDING_MENU_ELEMENTS,
  UPDATE_LANDING_MENU,
} from '../../../actions';

import { PREFIX_ACTION_TYPE_LANDING_MENUS } from '../../../constants/common';

import { generateId } from '../../../utils';

const EMPTY_MENU_ELEMENT = { name: '', action: 'none', stage_only: false };

function returnError(response) {
  if (response.message || response.errors) return response;

  return { message: 'admin_privileges_required' };
}

const getFullType = (type) => PREFIX_ACTION_TYPE_LANDING_MENUS + type;

const getClosestWithoutParentAbove = (elements, curOrder, ignoreOrder) => {
  let el;

  for (let i = curOrder - 1; i >= 0; i -= 1) {
    if (elements[i] && !elements[i].parent_element_id && elements[i].order !== ignoreOrder) {
      el = elements[i];

      break;
    }
  }

  return el;
};

// We use mutation of original array for purpose
const invalidateLevels = (elements) => {
  const invalidatedElements = elements;

  invalidatedElements.forEach((el, i) => {
    if (!el.parent_element_id) return;

    if (i === 0) {
      el.parent_element_id = undefined;

      return;
    }

    const closest = getClosestWithoutParentAbove(invalidatedElements, el.order);

    if (closest) el.parent_element_id = closest._id;
  });

  return invalidatedElements;
};

export default (state, action) => {
  switch (action.type) {
    case getFullType(ADD_ELEMENT_TO_MENU):
      return {
        ...state,
        menu: {
          ...state.menu,
          formDocs: state.menu.formDocs.map((doc) => {
            if (doc._id !== action.payload.id) return doc;

            // Add fake temp element here
            return {
              ...doc,
              elements: [
                ...doc.elements,
                {
                  ...EMPTY_MENU_ELEMENT,
                  menu_id: doc._id,
                  order: doc.elements.length,
                  _id: generateId(),
                },
              ],
            };
          }),
        },
      };
    case getFullType(CANCEL_MENU_CHANGES):
      return {
        ...state,
        menu: {
          ...state.menu,
          formDocs: state.menu.formDocs.map((menu) => {
            if (menu._id !== action.payload.id) return menu;

            const initMenu = state.menu.docs.find((m) => m._id === action.payload.id);

            return cloneDeep(initMenu);
          }),
        },
      };
    case getFullType(UPDATE_MENU_ELEMENT):
      return {
        ...state,
        menu: {
          ...state.menu,
          formDocs: state.menu.formDocs.map((menu) => {
            if (menu._id !== action.payload.menuId) return menu;

            return {
              ...menu,
              elements: menu.elements.map((el) => {
                if (el._id !== action.payload.id) return el;

                return { ...el, ...action.payload.data };
              }),
            };
          }),
        },
      };
    case getFullType(DELETE_MENU_ELEMENT):
      return {
        ...state,
        menu: {
          ...state.menu,
          formDocs: state.menu.formDocs.map((menu) => {
            if (menu._id !== action.payload.menuId) return menu;

            return {
              ...menu,
              elements: invalidateLevels(menu.elements
                .filter((el) => el._id !== action.payload.id)
                .map((doc, order) => ({ ...doc, order }))),
            };
          }),
        },
      };
    case getFullType(CHANGE_MENU_ELEMENTS_ORDER):
      return {
        ...state,
        menu: {
          ...state.menu,
          formDocs: state.menu.formDocs.map((menu) => {
            if (menu._id !== action.payload.id) return menu;

            const newDocs = menu.elements;

            newDocs.splice(action.payload.data.newPosition, 0, newDocs.splice(action.payload.data.currentPosition, 1)[0]);

            return { ...menu, elements: invalidateLevels(newDocs.map((doc, order) => ({ ...doc, order }))) };
          }),
        },
      };
    case getFullType(CHANGE_MENU_ELEMENT_LEVEL):
      return {
        ...state,
        menu: {
          ...state.menu,
          formDocs: state.menu.formDocs.map((menu) => {
            if (menu._id !== action.payload.menuId) return menu;

            return {
              ...menu,
              elements: invalidateLevels(menu.elements.map((el) => {
                if (el._id !== action.payload.id) return el;

                // set "fake_id" because invalidateLevels function will replace it with correct ID
                return { ...el, parent_element_id: el.parent_element_id ? undefined : 'fake_id' };
              })),
            };
          }),
        },
      };
    case getFullType(COPY_MENU_ELEMENT):
      return {
        ...state,
        menu: {
          ...state.menu,
          formDocs: state.menu.formDocs.map((menu) => {
            if (menu._id !== action.payload.menuId) return menu;

            const copiedElemInd = menu.elements.findIndex((el) => el._id === action.payload.id);

            menu.elements.splice(copiedElemInd, 0, { ...menu.elements[copiedElemInd], _id: generateId() });

            return {
              ...menu,
              elements: invalidateLevels(menu.elements.map((el, i) => ({ ...el, order: i }))),
            };
          }),
        },
      };
    case `${getFullType(GET_LANDING_MENUS)}_REQUEST`:
    case `${getFullType(CREATE_LANDING_MENU)}_REQUEST`:
    case `${getFullType(DELETE_LANDING_MENU)}_REQUEST`:
    case `${getFullType(UPDATE_LANDING_MENU_ELEMENTS)}_REQUEST`:
    case `${getFullType(UPDATE_LANDING_MENU)}_REQUEST`:
      return {
        ...state,
        error: null,
        isFetching: true,
      };
    case getFullType(GET_LANDING_MENUS):
      return {
        ...state,
        menu: { ...state.menu, docs: action.response, formDocs: cloneDeep(action.response) },
        isFetching: false,
      };
    case getFullType(CREATE_LANDING_MENU):
      return {
        ...state,
        menu: {
          ...state.menu,
          docs: [action.response, ...state.menu.docs],
          formDocs: [action.response, ...state.menu.formDocs],
        },
        isFetching: false,
      };
    case getFullType(UPDATE_LANDING_MENU_ELEMENTS):
      return {
        ...state,
        menu: {
          ...state.menu,
          formDocs: state.menu.formDocs.map((menu) => {
            if (menu._id !== action.payload.id) return menu;

            return { ...menu, elements: cloneDeep(action.response) };
          }),
          docs: state.menu.docs.map((menu) => {
            if (menu._id !== action.payload.id) return menu;

            return { ...menu, elements: cloneDeep(action.response) };
          }),
        },
        isFetching: false,
      };
    case getFullType(UPDATE_LANDING_MENU):
      return {
        ...state,
        menu: {
          ...state.menu,
          formDocs: state.menu.formDocs.map((menu) => {
            if (menu._id !== action.payload.id) return menu;

            return { ...menu, ...action.response };
          }),
          docs: state.menu.docs.map((menu) => {
            if (menu._id !== action.payload.id) return menu;

            return { ...menu, ...action.response };
          }),
        },
        isFetching: false,
      };
    case getFullType(DELETE_LANDING_MENU):
      return {
        ...state,
        menu: {
          ...state.menu,
          formDocs: state.menu.formDocs.filter((m) => m._id !== action.payload.id),
          docs: state.menu.docs.filter((m) => m._id !== action.payload.id),
        },
        isFetching: false,
      };
    case `${getFullType(GET_LANDING_MENUS)}_FAILURE`:
    case `${getFullType(CREATE_LANDING_MENU)}_FAILURE`:
    case `${getFullType(DELETE_LANDING_MENU)}_FAILURE`:
    case `${getFullType(UPDATE_LANDING_MENU_ELEMENTS)}_FAILURE`:
    case `${getFullType(UPDATE_LANDING_MENU)}_FAILURE`:
      return {
        ...state,
        error: returnError(action.response),
        isFetching: false,
      };
    default:
      return state;
  }
};
