import PropTypes from 'prop-types';
import React, { useState, memo } from 'react';
import queryString from 'query-string';
import { Form } from 'react-bootstrap';

import SearchForm from '../../../../Includes/SearchForm/SearchForm';

function TemplatesSearch({
  onSearchTemplates,
}) {
  const [searchType, setSearchType] = useState(queryString.parse(window.location.search)?.search_type || 'variation_id');

  const onChangeSearchType = ({ target }) => {
    setSearchType(target.dataset.type);
  };

  const onSearch = (query) => {
    onSearchTemplates({ query, search_type: searchType });
  };

  return (
    <div className="templates__search">
      <div className="templates__search-options">
        <span>Поиск по:</span>
        <Form.Check
          checked={searchType === 'variation_id'}
          className="form-check-custom"
          data-type="variation_id"
          id="templates__search-id"
          inline
          label="id вариации"
          name="group1"
          type="radio"
          onChange={onChangeSearchType}
        />
        <Form.Check
          checked={searchType === 'tdr'}
          className="form-check-custom"
          data-type="tdr"
          id="templates__search-tdr"
          inline
          label="автору"
          name="group1"
          type="radio"
          onChange={onChangeSearchType}
        />
        <Form.Check
          checked={searchType === 'tag'}
          className="form-check-custom"
          data-type="tag"
          id="templates__search-tag"
          inline
          label="тегу"
          name="group1"
          type="radio"
          onChange={onChangeSearchType}
        />
        <Form.Check
          checked={searchType === 'template_id'}
          className="form-check-custom"
          data-type="template_id"
          id="templates__search-template_id"
          inline
          label="id шаблона"
          name="group1"
          type="radio"
          onChange={onChangeSearchType}
        />
      </div>
      <SearchForm
        className="templates__search-form"
        defaultValue={queryString.parse(window.location.search)?.query}
        onSearch={onSearch}
      />
    </div>
  );
}

TemplatesSearch.propTypes = {
  onSearchTemplates: PropTypes.func.isRequired,
};

export default memo(TemplatesSearch);
