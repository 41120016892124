export const FROM_SCRATCH_TYPES = {
  video: 'Видео',
  image: 'Изображение',
  ask: 'Спросить',
};

export const UNIT_TYPES = [
  { value: 'px', name: 'пикселей' },
  { value: 'cm', name: 'сантиметров' },
  { value: 'mm', name: 'миллиметров' },
  { value: 'in', name: 'дюймов' },
];
