import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { memo, useState, useRef } from 'react';
import {
  Form, Button, Dropdown,
} from 'react-bootstrap';

import CheckBox from '../../../UI/CheckBox/CheckBox';
import DatePicker from '../../../UI/DatePicker/DatePicker';
import Preloader from '../../../UI/Preloader/Preloader';

const plansList = [
  {
    promocode: '',
    short_name: 'Премиум',
    names: {
      ru: 'Тариф "Премиум" SUPA (30 дней)',
      en: 'SUPA Premium Plan (30 days)',
    },
    days: 30,
    sum: '792',
    group: 'premium_main',
    currency: 'RUB',
    promocode_only_firsttimers: true,
    promocode_last_period_end_date: null,
    promocode_registration_date: null,
    subscription: true,
  },
  {
    short_name: 'Премиум',
    names: {
      ru: 'Тариф "Премиум" SUPA (90 дней)',
      en: 'SUPA Premium Plan (90 days)',
    },
    days: 90,
    group: 'premium_main',
  },
  {
    short_name: 'Премиум',
    names: {
      ru: 'Тариф "Премиум" SUPA (365 дней)',
      en: 'SUPA Premium Plan (365 days)',
    },
    days: 365,
    group: 'premium_main',
  },
  {
    short_name: 'Премиум Плюс',
    names: {
      ru: 'Тариф "Премиум Плюс" SUPA (30 дней)',
      en: 'SUPA Premium Plus (30 days)',
    },
    days: 30,
    group: 'plus_main',
  },
  {
    short_name: 'Премиум Плюс',
    names: {
      ru: 'Тариф "Премиум Плюс" SUPA (90 дней)',
      en: 'SUPA Premium Plus (90 days)',
    },
    days: 90,
    group: 'plus_main',
  },
  {
    short_name: 'Премиум Плюс',
    names: {
      ru: 'Тариф "Премиум Плюс" SUPA (365 дней)',
      en: 'SUPA Premium Plus (365 days)',
    },
    days: 365,
    group: 'plus_main',
  },
  // {
  //   short_name: 'Команда',
  //   names: {
  //     ru: 'Тариф "Команда" SUPA (30 дней)',
  //     en: 'SUPA Team Plan (30 days)',
  //   },
  //   days: 30,
  //   group: 'team',
  // },
  // {
  //   short_name: 'Команда',
  //   names: {
  //     ru: 'Тариф "Команда" SUPA (90 дней)',
  //     en: 'SUPA Team Plan (90 days)',
  //   },
  //   days: 90,
  //   group: 'team',
  // },
  // {
  //   short_name: 'Команда',
  //   names: {
  //     ru: 'Тариф "Команда" SUPA (365 дней)',
  //     en: 'SUPA Team Plan (365 days)',
  //   },
  //   days: 365,
  //   group: 'team',
  // },
  // {
  //   short_name: 'Команда Плюс',
  //   names: {
  //     ru: 'Тариф "Команда Плюс" SUPA (30 дней)',
  //     en: 'SUPA Team Plus (30 days)',
  //   },
  //   days: 30,
  //   group: 'team_plus',
  // },
  // {
  //   short_name: 'Команда Плюс',
  //   names: {
  //     ru: 'Тариф "Команда Плюс" SUPA (90 дней)',
  //     en: 'SUPA Team Pus (90 days)',
  //   },
  //   days: 90,
  //   group: 'team_plus',
  // },
  // {
  //   short_name: 'Команда Плюс',
  //   names: {
  //     ru: 'Тариф "Команда Плюс" SUPA (365 дней)',
  //     en: 'SUPA Team Plus (365 days)',
  //   },
  //   days: 365,
  //   group: 'team_plus',
  // },
];

const PromocodesForm = ({
  buttonText,
  data,
  disabled,
  onSubmit = () => {},
}) => {
  const curDate = new Date();

  const [formData, setFormData] = useState(
    data?.id
      ? {
        ...data,
        sum: String(data.sum),
        promocode_from: new Date(data.promocode_from),
        promocode_to: new Date(data.promocode_to),
        promocode_last_period_end_date: data.promocode_last_period_end_date ? new Date(data.promocode_last_period_end_date) : new Date(curDate.getFullYear(), curDate.getMonth() - 6, curDate.getDate()),
        promocode_registration_date: data.promocode_registration_date ? new Date(data.promocode_registration_date) : new Date(curDate.getFullYear(), curDate.getMonth() - 6, curDate.getDate()),
        short_name: plansList.find((item) => item.group === data.group && item.days === data.days).short_name,
      }
      : {
        ...plansList[0],
        promocode_from: curDate,
        promocode_to: new Date(curDate.getFullYear(), curDate.getMonth() + 1, curDate.getDate()),
        promocode_last_period_end_date: new Date(curDate.getFullYear(), curDate.getMonth() - 6, curDate.getDate()),
        promocode_registration_date: new Date(curDate.getFullYear(), curDate.getMonth() - 6, curDate.getDate()),
      },
  );

  const [state, setState] = useState(
    data?.id ? {
      last_period_end_date_enabled: !!data.promocode_last_period_end_date,
      registration_date_enabled: !!data.promocode_registration_date,
    }
      : {
        last_period_end_date_enabled: false,
        registration_date_enabled: false,
      },
  );

  const inputRuRef = useRef(null);
  const inputEnRef = useRef(null);

  const handleChangeFormData = (props) => {
    setFormData((prev) => ({ ...prev, ...props }));
  };

  const handleChangeState = (props) => {
    setState((prev) => ({ ...prev, ...props }));
  };

  const handleChangeName = (value) => {
    handleChangeFormData({ promocode: value });

    const ruName = inputRuRef.current.dataset.name;
    const enName = inputEnRef.current.dataset.name;

    inputRuRef.current.value = `${ruName}, промокод ${value}`;
    inputEnRef.current.value = `${enName}, promocode ${value}`;
  };

  const handleChangePlan = (value) => {
    const baseName = plansList.find((item) => item.group === value.group && item.days === value.days);

    handleChangeFormData({
      ...value,
      names: {
        ru: baseName.names.ru,
        en: baseName.names.en,
      },
    });

    inputRuRef.current.value = `${baseName.names.ru}, промокод ${formData.promocode}`;
    inputEnRef.current.value = `${baseName.names.en}, promocode ${formData.promocode}`;
  };

  const handleSubmit = () => {
    if (!formData.promocode.trim()) return;

    const prepared = {
      ...formData,
      name: inputRuRef.current.value.trim(),
      promocode: formData.promocode.trim(),
      promocode_from: formData.promocode_from.toISOString(),
      promocode_to: formData.promocode_to.toISOString(),
      promocode_last_period_end_date: state.last_period_end_date_enabled ? formData.promocode_last_period_end_date.toISOString() : null,
      promocode_registration_date: state.registration_date_enabled ? formData.promocode_registration_date.toISOString() : null,
      names: {
        ru: inputRuRef.current.value.trim(),
        en: inputEnRef.current.value.trim(),
      },
      sum: +formData.sum,
    };

    onSubmit(prepared);
  };

  const validateCostInput = (e) => {
    const invalidChars = ['-', '+', 'e'];

    if (invalidChars.includes(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <div className="promocodes__form">
      <div className="promocodes__form-row">
        <div className="promocodes__form-label">Промокод:</div>
        <Form.Control
          disabled={disabled}
          placeholder="Название промокода"
          value={formData.promocode}
          onChange={(e) => handleChangeName(e.target.value)}
        />
      </div>
      <div className="promocodes__form-row">
        <div className="promocodes__form-label">Срок действия:</div>
        <div className="promocodes__form-datepicker">
          <span>c</span>
          <DatePicker
            value={formData.promocode_from}
            disableDaysAfter={dayjs(formData.promocode_to).add(-1, 'day').toDate()}
            onChange={(value) => handleChangeFormData({ promocode_from: value })}
          />
          <span>по</span>
          <DatePicker
            value={formData.promocode_to}
            disableDaysBefore={dayjs(formData.promocode_from).add(1, 'day').toDate()}
            onChange={(value) => handleChangeFormData({ promocode_to: value })}
          />
        </div>
      </div>
      <div className="promocodes__form-row promocodes__form-service">
        <div className="promocodes__form-label">Тариф:</div>
        <Dropdown>
          <Dropdown.Toggle as="button" className="btn btn-light" disabled={disabled}>
            {`${formData.short_name}, ${formData.days} дней`}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {plansList.map((item) => (
              <Dropdown.Item
                as="button"
                eventKey={JSON.stringify({
                  short_name: item.short_name,
                  days: item.days,
                  group: item.group,
                })}
                key={item.short_name + item.days}
                onSelect={(value) => handleChangePlan(JSON.parse(value))}
              >
                {`${item.short_name}, ${item.days} дней`}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="promocodes__form-row">
        <div className="promocodes__form-label">Стоимость:</div>
        <div className="promocodes__form-cost">
          <Form.Control
            disabled={disabled}
            type="number"
            min={1}
            value={String(formData.sum)}
            onKeyDown={validateCostInput}
            onChange={(e) => handleChangeFormData({ sum: e.target.value.replace(/^0+/, '') })}
          />
          <Dropdown>
            <Dropdown.Toggle as="button" className="btn btn-light" disabled={disabled}>
              {formData.currency}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                as="button"
                onClick={() => handleChangeFormData({ currency: 'RUB' })}
              >
                RUB
              </Dropdown.Item>
              <Dropdown.Item
                as="button"
                onClick={() => handleChangeFormData({ currency: 'USD' })}
              >
                USD
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className="promocodes__form-row">
        <div className="promocodes__form-label">Русское имя услуги:</div>
        <Form.Control
          defaultValue={formData.names.ru}
          data-name={plansList.find((item) => item.group === formData.group && item.days === formData.days).names.ru}
          ref={inputRuRef}
          readOnly={disabled}
          onChange={(e) => handleChangeFormData({ names: { ru: e.target.value, en: formData.names.en } })}
        />
      </div>
      <div className="promocodes__form-row">
        <div className="promocodes__form-label">Английское имя услуги:</div>
        <Form.Control
          defaultValue={formData.names.en}
          data-name={plansList.find((item) => item.group === formData.group && item.days === formData.days).names.en}
          ref={inputEnRef}
          readOnly={disabled}
          onChange={(e) => handleChangeFormData({ names: { ru: formData.names.ru, en: e.target.value } })}
        />
      </div>
      <div className="promocodes__form-row">
        <div className="promocodes__form-label">Только для новых пользователей:</div>
        <CheckBox
          className="mailing__checkbox"
          checked={formData.promocode_only_firsttimers}
          disabled={disabled}
          id="checkbox-promocode-only-firsttimers"
          onChange={(value) => handleChangeFormData({ promocode_only_firsttimers: value })}
        />
      </div>
      <div className="promocodes__form-row">
        <div className="promocodes__form-label">Последний период закончился:</div>
        <CheckBox
          className="mailing__checkbox"
          checked={state.last_period_end_date_enabled}
          disabled={disabled}
          id="checkbox-promocode-last-period-date"
          onChange={(value) => handleChangeState({ last_period_end_date_enabled: value })}
        />
        <div className="promocodes__form-datepicker">
          <span>не позднее</span>
          <DatePicker
            style={{ maxWidth: '140px' }}
            value={formData.promocode_last_period_end_date}
            onChange={(value) => handleChangeFormData({ promocode_last_period_end_date: value })}
          />
        </div>
      </div>
      <div className="promocodes__form-row">
        <div className="promocodes__form-label">Дата регистрации:</div>
        <CheckBox
          className="mailing__checkbox"
          checked={state.registration_date_enabled}
          disabled={disabled}
          id="checkbox-promocode-registration-date"
          onChange={(value) => handleChangeState({ registration_date_enabled: value })}
        />
        <div className="promocodes__form-datepicker">
          <span>не позднее</span>
          <DatePicker
            style={{ maxWidth: '140px' }}
            value={formData.promocode_registration_date}
            onChange={(value) => handleChangeFormData({ promocode_registration_date: value })}
          />
        </div>
      </div>
      <div className="promocodes__form-row">
        <div className="promocodes__form-label">Подписка:</div>
        <CheckBox
          className="mailing__checkbox"
          checked={formData.subscription}
          disabled={disabled}
          id="checkbox-promocode-subscription"
          onChange={(value) => handleChangeFormData({ subscription: value })}
        />
      </div>
      <div className="promocodes__form-row promocodes__form-submit">
        <Button
          disabled={disabled || !formData.promocode || !formData.names.ru || !formData.names.en || !formData.sum || Boolean(+formData.sum[0]) === false}
          onClick={handleSubmit}
        >
          {buttonText}
        </Button>
        {disabled && (
          <div className="promocodes__form-preloader">
            <Preloader medium />
          </div>
        )}
      </div>
    </div>
  );
};

PromocodesForm.propTypes = {
  buttonText: PropTypes.string,
  data: PropTypes.object,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default memo(PromocodesForm);
