import { connect } from 'react-redux';

import {
  RESET_CATEGORIES,
  createCategory,
  getCategories,
  removeCategory,
  saveCategoriesOrder,
  setCategoriesOrder,
  updateCategory,
} from '../../../../actions';

import Categories from './Categories';

const mapStateToProps = (state) => ({
  categories: state.categories,
});

const mapDispatchToProps = (dispatch) => ({
  onCreateCategory: (data, requestToken) => dispatch(createCategory(data, requestToken)),
  onGetCategories: (params, requestToken) => dispatch(getCategories(params, requestToken)),
  onRemoveCategory: (id, requestToken) => dispatch(removeCategory(id, requestToken)),
  onSaveCategoriesOrder: (data, requestToken) => dispatch(saveCategoriesOrder(data, requestToken)),
  onSetCategoriesOrder: (orderData) => dispatch(setCategoriesOrder(orderData)),
  onUpdateCategory: (data, requestToken) => dispatch(updateCategory(data, requestToken)),
  onUnload: () => dispatch({ type: RESET_CATEGORIES }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Categories);
