import {
  GET_COMPANIES,
  GET_COMPANY,
  CREATE_COMPANY,
  DELETE_COMPANY,
  UPDATE_COMPANY,
  RESET_COMPANIES,
} from '../actions';

const defaultState = {
  docs: [],
  error: null,
  isFetching: false,
  page: 0,
  pages: 1,
};

function returnError(response) {
  if (response.message) return { message: response.message, ...(response.params && { params: response.params }) };
  if (response.errors) return { message: response.errors[0] };

  return { message: 'admin_privileges_required' };
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case `${GET_COMPANIES}_REQUEST`:
      return {
        ...state,
        docs: action.payload.isSearch || action.payload.params.page === 1 ? [] : state.docs,
        error: null,
        isFetching: true,
      };
      // case `${DELETE_SERVICE}_REQUEST`:
    case `${GET_COMPANY}_REQUEST`:
    case `${CREATE_COMPANY}_REQUEST`:
    case `${UPDATE_COMPANY}_REQUEST`:
      return {
        ...state,
        error: null,
        isFetching: true,
      };
    case GET_COMPANIES:
      return {
        ...state,
        ...action.response,
        docs: action.response.page === 1
          ? action.response.docs
          : [...state.docs, ...action.response.docs],
        isFetching: false,
      };
    case UPDATE_COMPANY:
    case GET_COMPANY:
    case CREATE_COMPANY:
      return {
        ...state,
        isFetching: false,
      };
    case DELETE_COMPANY:
      return {
        ...state,
        docs: state.docs.filter((item) => item.id !== action.payload.id),
      };
    case `${GET_COMPANIES}_FAILURE`:
    case `${GET_COMPANY}_FAILURE`:
    case `${CREATE_COMPANY}_FAILURE`:
    case `${UPDATE_COMPANY}_FAILURE`:
    // case `${DELETE_SERVICE}_FAILURE`:
      return {
        ...state,
        error: returnError(action.response),
        isFetching: false,
      };
    case RESET_COMPANIES:
      return defaultState;
    default:
      return state;
  }
};
