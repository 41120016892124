import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, Route, withRouter } from 'react-router-dom';

class NoRequireAuthRoute extends React.PureComponent {
  state = {
    shouldRedirect: false,
  };

  componentDidMount() {
    const { token } = this.props;

    if (token) {
      this.setState({
        shouldRedirect: true,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { token, softRedirect } = this.props;

    if (!prevProps.token && token && !softRedirect) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        shouldRedirect: true,
      });
    }

    if (!token) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        shouldRedirect: false,
      });
    }
  }

  render() {
    const {
      component: Component,
      token,
      softRedirect,
      location,
      ...rest
    } = this.props;

    const { from } = location.state || { from: { pathname: '/' } };

    const { shouldRedirect } = this.state;

    return (
      <Route
        {...rest}
        render={(props) => (
          shouldRedirect ? (
            <Redirect to={from} />
          ) : (
            <Component {...props} />
          )
        )}
      />
    );
  }
}

NoRequireAuthRoute.propTypes = {
  token: PropTypes.string,
  softRedirect: PropTypes.bool,
  location: PropTypes.object,
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
});

export default withRouter(connect(mapStateToProps)(NoRequireAuthRoute));
