import PropTypes from 'prop-types';
import React, { memo, useEffect } from 'react';

import { smoothScrollTo } from '../../../../utils';

import PromocodesForm from '../PromocodesForm/PromocodesForm';

const PromocodesEdit = ({
  promocodes, history, match: { params: { id } }, isEditing, setViewTitle, onUpdatePromocode,
}) => {
  useEffect(() => {
    setViewTitle('Редактировать промокод');

    smoothScrollTo(document.documentElement, 0);
  }, [setViewTitle]);

  const handleUpdatePromocode = (formData) => {
    delete formData.id;
    delete formData._id;
    delete formData.items;
    delete formData.short_name;

    onUpdatePromocode(id, formData);
  };

  return (
    !promocodes.isFetching && promocodes.docs.length && id ? (
      <div className="promocodes__create">
        {/* eslint-disable-next-line */}
        <div role="button" onClick={() => history.goBack()} to="/promocodes" className="promocodes__link">
          Вернуться назад
        </div>
        <PromocodesForm
          buttonText="Сохранить"
          data={promocodes.docs.find((item) => item.id === +id)}
          disabled={isEditing}
          onSubmit={handleUpdatePromocode}
        />
      </div>
    ) : null
  );
};

PromocodesEdit.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  promocodes: PropTypes.object,
  isEditing: PropTypes.bool,
  setViewTitle: PropTypes.func,
  onUpdatePromocode: PropTypes.func,
};

export default memo(PromocodesEdit);
